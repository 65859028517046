import { ErrorMessage, Field, Form, Formik } from "formik";
import { del, post } from "network/http/api";
import { InviteEmployeeScheme } from "pages/Authentication/SchemaValidation";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Button, InputGroup, InputGroupText, Table } from "reactstrap";
import { Input } from "reactstrap";
import { Label, ModalFooter, Spinner, Modal, ModalBody, ModalHeader } from "reactstrap";
import { notificationErrorRequestAction, notificationSuccessRequestAction } from "store/notification/actions";



const DeleteEmployeeModal = ({ isOpen, toggle, user, refreshList }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
 
  const handleOnSubmit = () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    del(`/company/employee/${user.employee_id}`)
      .then(resp => {
        toggle()
        setIsLoading(false)
        dispatch(notificationSuccessRequestAction('Berhasil Menghapus Karyawan!!!'))
        refreshList()
      })
      .catch(err => {
        let { detail } = err
        if (!detail || detail === '') {
          detail = 'Terjadi kesalahan mohon coba kembali'
        }
        console.error(err)
        setIsLoading(false)
        dispatch(notificationErrorRequestAction(detail))
      })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={'invite-employee-modal'}>
      <ModalHeader>
        Konfirmasi Hapus Karyawan <b>{user?.name}</b>
      </ModalHeader>
      <ModalBody>
        <p>
          Seluruh akses ke RISA AI akan dihilangkan jika Anda menghapus karyawan. Apakah Anda yakin?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isLoading}
          onClick={handleOnSubmit}
          color="danger">
          Hapus
        </Button>
        <Button onClick={toggle} color="primary" outline>
          Kembali
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteEmployeeModal;