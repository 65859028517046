import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { connect } from 'react-redux';

// Actions
import { getShop } from 'store/shop/actions'
import { getProductCategory } from 'store/product/actions';
import { AUTH_USER_KEY } from 'network/http/jwt-token-access';


class ProductFilter extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ratingvalues: [],
      activeTab: "1",
    };
  }

  componentDidMount(){
    const {productCategories, getShop, getProductCategory} = this.props;
    const user = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    getShop()
    getProductCategory(user.company_id)
  }

  handleOnClickStore = (storeId) => {
    const {history} = this.props;

    history.push(`/ecommerce-products/store/${storeId}`)
  }

  handleOnClickCategory = (categoryId) => {
    const {history} = this.props;

    history.push(`/ecommerce-products/category/${categoryId}`)
  }

  render() {
    const {productCategories, shops} = this.props;
    const {match:{params}} = this.props

    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Filter</CardTitle>

          <div>
            <h5 className="font-size-14 mb-3">Shops</h5>
            <ul className="list-unstyled product-list">
                {shops.map((shop, key) => (
                  <li style={{
                    background: shop.id == params.storeId ? '#bfffbf' : 'transparent'
                  }} key={"_li_" + key}>
                    <Link

                      to={`/ecommerce-products/store/${shop.id}`} >
                      <i className="mdi mdi-chevron-right me-1" />{" "}
                      {shop.store_name} ({shop.platform.platform_name})
                    </Link>
                  </li>
                ))}
              </ul>
          </div>
          <div>
            <h5 className="font-size-14 mb-3">Categories</h5>
            {/* Render Cloth Categories */}
            <ul className="list-unstyled product-list">
              {productCategories.map((cat, key) => (
                <li
                  style={{background: cat.id == params.catId ? '#bfffbf' : 'transparent'}}
                  key={"_li_" + key}>
                  <Link to={`/ecommerce-products/category/${cat.id}`}
                  >
                    <i className="mdi mdi-chevron-right me-1" />{" "}
                    {cat.name} ({cat.total_product})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </CardBody>

      </Card>
    )
  }
}

const mapStateToProps = ({Product, Shop}) => {
  return {
    productCategories: Product.productCategories,
    shops: Shop.shops,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    getShop,
    getProductCategory,
  }
)(ProductFilter))
