import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import {
  getShopCategoryByCompanyIDSuccess,
  getShopCategoryByCompanyIDFailed,
} from "./actions";
import * as api from "./api";
import { GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID } from "./actionTypes";

function* fetchProductCategoryByCompanyId({ payload }) {
  try {
    const response = yield call(api.getShopProductCategoryByCompanyId, payload);
    yield put(getShopCategoryByCompanyIDSuccess(response));
  } catch (error) {
    yield put(getShopCategoryByCompanyIDFailed(error));
  }
}

function* shopDashboardSaga() {
  yield takeEvery(
    GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID,
    fetchProductCategoryByCompanyId
  );
}

export default shopDashboardSaga;
