import {
  GET_AGENT_INTENT,
  GET_AGENT_INTENT_FAIL,
  GET_AGENT_INTENT_SUCCESS,
  UPDATE_AGENT_INTENT,
  UPDATE_AGENT_INTENT_SUCCESS,
  UPDATE_AGENT_INTENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  isFetching: false,
  isUpdating: false,
  isUpdateComplete: false,
  agentIntents: [],
  error: null,
}

export const selectListAgentIntent = (state) => {
  return state['AgentIntent']['agentIntents']
}

const updateListAgentIntent = (state, payload) => {
  let agentIntents = state['agentIntents']
  agentIntents = agentIntents.concat(payload)

  return agentIntents
}

const updateAgentIntent = (state, payload) => {
  let agentIntents = state['agentIntents']
  for (let index = 0; index < agentIntents.length; index++) {
    const el = agentIntents[index];
    if(el.id == payload.id){
      agentIntents[index] = payload
    }
  }

  return agentIntents
}

const agentIntents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGENT_INTENT:
      return {
        ...state,
        isFetching: true,
        error: null,
        isUpdateComplete: false,
      }
    case GET_AGENT_INTENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        isUpdateComplete: false,
        agentIntents: action.payload,
      }

    case GET_AGENT_INTENT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        isUpdateComplete: false,
      }

    case UPDATE_AGENT_INTENT:
      return {
        ...state,
        isUpdating: true,
        isUpdateComplete: false,
        error: null,
      }

    case UPDATE_AGENT_INTENT_SUCCESS:
      return {
        ...state,
        agentIntents: updateAgentIntent(state, action.payload),
        isUpdating: false,
        isUpdateComplete: true,
        error: null,
      }

    case UPDATE_AGENT_INTENT_FAIL:
      return {
        ...state,
        isUpdating: false,
        isUpdateComplete: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default agentIntents
