// reducer.js
import { SET_FORM_STEP } from "./actions";

const initialState = {
  step: 0,
};

const stepReducerFormAction = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_STEP:
      return (state = action.payload.data);
    default:
      return state;
  }
};

export default stepReducerFormAction;
