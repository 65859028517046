import React, { useEffect, useState } from "react";
import { ReactComponent as IconTitle } from "assets/images/knowledge/title.svg";
import { get } from "network/http/api";
import { Spinner } from "reactstrap";

const KnowledgeSidebar = ({
  dataKnowledge,
  setDataknowledge,
  pageKnowledge,
  setPageKnowledge,
  setKnowledge,
  totalKnowledge,
  setTotalKnowledge,
  setListQuestion,
  setId,
  setPage,
  setTotalItem,
  setReset,
  setLoadingPage,
  selectedId,
}) => {
  const [loadingScroll, setLoadingScroll] = useState(false);

  const handleScroll = async () => {
    const sideBarDocuments = document.querySelector(".page-sidebar-knowledge");
    if (sideBarDocuments) {
      const { scrollTop, clientHeight, scrollHeight } = sideBarDocuments;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (!loadingScroll && pageKnowledge * 15 < totalKnowledge) {
          setLoadingScroll(true);
          try {
            const res = await get(
              `/knowledge-base?page=${pageKnowledge + 1}&&per_page=${15}`
            );
            setPageKnowledge(pageKnowledge + 1);
            setDataknowledge(prevData => [...prevData, ...res.knowledges]);
            setTotalKnowledge(res.total_item);
          } catch (err) {
            console.log(err);
          } finally {
            setTimeout(() => {
              setLoadingScroll(false);
            }, 1500);
          }
        } else {
          console.log(pageKnowledge, "out of data");
        }
      }
    }
  };

  useEffect(() => {
    const sideBarDocuments = document.querySelector(".page-sidebar-knowledge");
    if (sideBarDocuments) {
      sideBarDocuments.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (sideBarDocuments) {
        sideBarDocuments.removeEventListener("scroll", handleScroll);
      }
    };
  }, [pageKnowledge, totalKnowledge]);

  const onPickById = async paramsId => {
    setLoadingPage(true);
    try {
      const res = await get(`/knowledge-base/${paramsId}`);
      setPage(1);
      setId(res.content.id);
      setKnowledge({
        title: res.content.title,
        content: res.content.content,
      });
      setListQuestion(res.question_answer.slice(0, 10));
      setTotalItem(res.total_question_answer);
      setLoadingPage(false);
    } catch (err) {
      console.log(err);
      setLoadingPage(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-sidebar">
        <div className="page-sidebar-knowledge">
          <div className="header-siderbar-knowledge">
            <div className="btn-create-knowledge" onClick={setReset}>
              <span>+ Create Your Knowledge base</span>
            </div>
          </div>
          <div className="mt-2 content-sidebar-knowledge">
            {dataKnowledge.length ? (
              <>
                {dataKnowledge.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className="knowledge"
                      onClick={() => onPickById(item.content.id)}
                      style={{ backgroundColor: item.content.id === selectedId ? '#e5e5e5' : '' }}
                    >
                      <div className="knowledge-title">
                        <IconTitle />
                        <div className="knowledge-question">
                          <span>{item.content.title}</span>
                          <span className="subtitle">
                            Question ({item.question_answer.length})
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="knowledge-disable">
                <div className="knowledge-title">
                  <IconTitle height={30} width={30} fill={"#a3a1a1"} />
                  <div className="knowledge-question">
                    <h4>Title ...</h4>
                    <span>Question (0)</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {loadingScroll && (
          <div className="loading-sidebar">
            <Spinner color="primary" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default KnowledgeSidebar;
