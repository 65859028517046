import CommonEditor from "components/Common/CommonEditor";
import { get, post } from "network/http/api";
import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Button,
  Row,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import MockLiveChatForm from "./MockLiveChatForm";

const InputForm = ({ notificationError, notificationSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [setting, setSetting] = useState({
    title: "Risa AI",
    logo: "",
    primary_color: "#FFFFFF",
    message_color: "#6ea9d7",
    description: "",
    name_field: {
      required: true,
      label: "Nama",
      disabled: false,
    },
    email_field: {
      required: true,
      label: "Email",
      disabled: false,
    },
    phone_field: {
      required: false,
      label: "Phone Number",
      disabled: false,
    },
  });

  useEffect(() => {
    get("/live-chat-setting")
      .then(resp => {
        if (!resp) {
          return;
        }
        setSetting(resp);
      })
      .catch(err => {
        console.error(err);
        notificationError("Terjadi kesalahan mohon refresh halaman ini");
      });
  }, []);

  const handleOnChangeDescription = text => {
    const { value } = text.target;
    if (value > 150) {
      return;
    }
    setSetting({
      ...setting,
      description: value,
    });
  };

  const handleOnChangeSetting = (key, field, value) => {
    let data = {
      ...setting,
    };

    data[key][field] = value;
    setSetting(data);
  };

  const handleSaveFormSettings = () => {
    const payload = setting;

    setIsLoading(true);
    post("/live-chat-setting", payload)
      .then(res => {
        setIsLoading(false);
        notificationSuccess("Tampilan Chat berhasil diubah!");
      })
      .catch(err => {
        console.error(err);
        notificationError("Tampilan Chat gagal diubah!");
        setIsLoading(false);
      });
  };

  return (
    <div className="tab-general">
      <div className="content-container">
        <p className="font-size-14">
          Anda dapat melakukan konfigurasi terhadap daftar pertanyaan yang akan
          ditanyakan ke pelanggan sebelum sesi web chat dimulai. Informasi ini
          nantinya Anda membantu mengetahui informasi pelanggan secara
          terperinci.
        </p>
        <Row>
          <Col xl={6} xs={12}>
            <div>
              <div className="field-form description mb-5">
                <span className="title">Form Description</span>
                <Input
                  id="exampleText"
                  name="text"
                  type="textarea"
                  placeholder="Masukkan judul chat..."
                  onChange={e => handleOnChangeDescription(e)}
                  value={setting.description}
                />
                <div className="text-editor-count">
                  <span className="subtitle">
                    Masukkan informasi deskripsi form, yang akan muncul sebelum
                    pelanggan Anda akan memulai sesi chat.
                  </span>
                  <span>{setting.description?.length}/150</span>
                </div>
              </div>
              <div className="field-form input-configuration">
                <Form>
                  <FormGroup row className="input-configuration-list">
                    <Col xs={10} className="input-configuration-field">
                      <Input
                        className="field-input name"
                        value={setting.name_field.label}
                        onChange={e =>
                          handleOnChangeSetting(
                            "name_field",
                            "label",
                            e.target.value
                          )
                        }
                        placeholder="Placeholder Name"
                      />
                      {/* <FormGroup switch>
                                            <Label>Wajib</Label>
                                            <Input
                                                type="switch"
                                                role="switch"
                                                bsSize="md"
                                                className="field-input"
                                                checked={setting.name_field.required}
                                                onClick={e => {
                                                    console.log(e.target.value)
                                                    handleOnChangeSetting(
                                                        "name_field",
                                                        "required",
                                                        !setting.name_field.required
                                                    )
                                                }
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup switch>
                                            <Label>Matikan</Label>
                                            <Input
                                                type="switch"
                                                role="switch"
                                                bsSize="md"
                                                className="field-input"
                                                checked={setting.name_field.disabled}
                                                onClick={e =>
                                                    handleOnChangeSetting(
                                                        "name_field",
                                                        "disabled",
                                                        !setting.name_field.disabled
                                                    )
                                                }
                                            />
                                        </FormGroup> */}
                    </Col>
                  </FormGroup>
                </Form>
              </div>
              <div className="field-form">
                <Form>
                  <FormGroup row className="input-configuration-list">
                    <Col xs={10} className="input-configuration-field">
                      <Input
                        className="field-input input-mobile"
                        value={setting.email_field.label}
                        onChange={e =>
                          handleOnChangeSetting(
                            "email_field",
                            "label",
                            e.target.value
                          )
                        }
                        placeholder="Placeholder Email"
                      />
                      <FormGroup switch>
                        <Label>Wajib</Label>
                        <Input
                          type="switch"
                          role="switch"
                          bsSize="md"
                          className="field-input"
                          checked={setting.email_field.required}
                          onChange={e =>
                            handleOnChangeSetting(
                              "email_field",
                              "required",
                              !setting.email_field.required
                            )
                          }
                        />
                      </FormGroup>
                      <FormGroup switch>
                        <Label>Matikan</Label>
                        <Input
                          type="switch"
                          role="switch"
                          bsSize="md"
                          className="field-input"
                          checked={setting.email_field.disabled}
                          onChange={e =>
                            handleOnChangeSetting(
                              "email_field",
                              "disabled",
                              !setting.email_field.disabled
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </div>
              <div className="field-form">
                <Form>
                  <FormGroup row className="input-configuration-list">
                    <Col xs={10} className="input-configuration-field">
                      <Input
                        className="field-input input-mobile"
                        value={setting.phone_field.label}
                        onChange={e =>
                          handleOnChangeSetting(
                            "phone_field",
                            "label",
                            e.target.value
                          )
                        }
                        placeholder="Placeholder Phone Number"
                      />
                      <FormGroup switch>
                        <Label>Wajib</Label>
                        <Input
                          type="switch"
                          role="switch"
                          bsSize="md"
                          className="field-input"
                          checked={setting.phone_field.required}
                          onChange={e =>
                            handleOnChangeSetting(
                              "phone_field",
                              "required",
                              !setting.phone_field.required
                            )
                          }
                        />
                      </FormGroup>
                      <FormGroup switch>
                        <Label>Matikan</Label>
                        <Input
                          type="switch"
                          role="switch"
                          bsSize="md"
                          className="field-input"
                          checked={setting.phone_field.disabled}
                          onChange={e =>
                            handleOnChangeSetting(
                              "phone_field",
                              "disabled",
                              !setting.phone_field.disabled
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </div>
            </div>
            <div className="btn-custom">
              <Button color="primary" onClick={() => handleSaveFormSettings()}>
                Simpan
              </Button>
            </div>
          </Col>
          <Col xl={6} xs={12}>
            <MockLiveChatForm data={setting} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InputForm;
