import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { get } from "network/http/api";

const ConversationStats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [noData, setNoData] = useState({
    text: "Loading...",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: "#000000",
      fontSize: "14px",
      fontFamily: "Helvetica",
    },
  });
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    noData: noData,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "01 Jan",
        "02 Jan",
        "03 Jan",
        "04 Jan",
        "05 Jan",
        "06 Jan",
        "07 Jan",
      ],
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
  });

  useEffect(() => {
    handleOnFetchStats();
  }, []);

  const handleOnFetchStats = () => {
    setIsLoading(true);
    get("/dashboard/conversation-stats")
      .then(resp => {
        const { date, platform, total_conversation } = resp;
        let series = [];
        platform.map((p, i) => {
          series.push({
            name: p,
            data: total_conversation[i],
          });
        });
        options["xaxis"] = {
          categories: date,
        };
        setSeries(series);
        setOptions(options);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
        setErrorMessage("Terjadi kesalahan, mohon refresh halaman ini");
      });
  };

  return (
    <Card className="conversation-stats">
      <CardBody>
        <div className="card-title">
          <h4 className="mb-2">Grafik Riwayat Percakapan</h4>
          <p>
            Grafik ini menggambarkan banyaknya percakapan yang berhasil dibantu
            oleh layanan RISA AI dari masing masing platform yang terintegrasi.
          </p>
        </div>

        <Row>
          <Col xs="12">
            <div id="line-chart" className="apex-charts" dir="ltr">
              {!isLoading && (
                <ReactApexChart
                  series={series}
                  options={options}
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ConversationStats;
