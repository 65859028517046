import { convertInt2Money } from "components/Product/ProductCardItem";
import { get } from "network/http/api";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { XMarkIcon } from "@heroicons/react/24/solid";

const CardPrice = ({ plan, selectedPlan, onPickTier }) => (
  <div className="tiers" key={plan.id} onClick={() => onPickTier(plan)}>
    <div className="checkbox">
      {selectedPlan.id === plan.id && (
        <div
          style={{
            background: "#24b39b",
            borderRadius: "50%",
            width: "10px",
            height: "10px",
          }}
        />
      )}
    </div>
    <div className="tiers-content">
      <span className="title">{plan.name}</span>
      <div className="content">
        <div className="content-description">
          <span className="title-content">{plan.description}</span>

          <span className="feature-content">
            <i className="fa fa-check-circle" aria-hidden="true" />
            Kuota pesan: {plan.max_number_message} /
            {plan.is_yearly ? "tahun" : "bulan"}
          </span>
          <span className="feature-content">
            <i className="fa fa-check-circle" aria-hidden="true" />
            Kuota produk: {plan.max_number_product}
          </span>
        </div>
        <div className="content-price">
          <div className="price-discount">
            <span>{convertInt2Money(plan.price_before_discount)}</span>
            <div className="line" />
          </div>
          <span className="price">{convertInt2Money(plan.price)}</span>
          <span className="price-suffix">
            {plan.is_yearly ? "per tahun" : "per bulan"}
          </span>
        </div>
      </div>
    </div>
  </div>
);

const CardEnterprise = ({ plan, selectedPlan, onPickTier }) => (
  <div className="tiers" key={-1} onClick={() => onPickTier(plan)}>
    <div className="checkbox">
      {selectedPlan.id === plan.id && (
        <div
          style={{
            background: "#24b39b",
            borderRadius: "50%",
            width: "10px",
            height: "10px",
          }}
        />
      )}
    </div>
    <div className="tiers-content">
      <span className="title">{plan.name}</span>
      <div className="content">
        <div className="content-description">
          <span className="title-content">{plan.description}</span>

          <span className="feature-content">
            <i className="fa fa-check-circle" aria-hidden="true" />
            Kuota pesan: Disesuaikan
          </span>
          <span className="feature-content">
            <i className="fa fa-check-circle" aria-hidden="true" />
            Kuota produk: Disesuaikan
          </span>
        </div>
        <div className="content-price">
          <span className="price">Harga Menyesuaikan</span>
          <span className="price-suffix">
            {plan.is_yearly ? "per tahun" : "per bulan"}
          </span>
        </div>
      </div>
    </div>
  </div>
);

const ChoosePackage = ({
  selectedPlan,
  currentPlanId,
  onPickTier,
  toggle,
  setStep,
  billing,
}) => {
  const [isYearly, setIsYearly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    get("/auth/plan-service")
      .then(resp => {
        setIsLoading(false);
        setPlans(resp);
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  }, []);

  const menuPackage = [
    {
      index: 0,
      value: "monthly",
      label: "Bulanan",
      is_yearly: false,
      priceSuffix: "per bulan",
    },
    {
      index: 1,
      value: "annually",
      label: "Tahunan",
      is_yearly: true,
      priceSuffix: "per tahun",
    },
  ];

  const renderPlanService = () => {
    if (isLoading) return null;

    return plans.map(plan => {
      if (plan.is_yearly !== isYearly) {
        return null;
      }

      if (plan.price < 1 || plan.id === currentPlanId) {
        return null;
      }

      if (plan.name === "ENTERPRISE") {
        return (
          <CardEnterprise
            key={plan.id}
            plan={plan}
            onPickTier={onPickTier}
            selectedPlan={selectedPlan}
          />
        );
      }

      if (
        billing.base_price > plan.price ||
        (billing.plan_name != "GRATIS" &&
          plan.is_yearly &&
          billing.plan_name !== plan.name)
      ) {
        return;
      }

      return (
        <CardPrice
          key={plan.id}
          plan={plan}
          onPickTier={onPickTier}
          selectedPlan={selectedPlan}
        />
      );
    });
  };

  return (
    <div className="section-content-modal-billing">
      <div className="mobile-header">
        <div className="content">
          <div className="icon-close">
            <XMarkIcon onClick={toggle} />
          </div>
          <div className="title">Pilih Paket</div>
          <div />
        </div>
      </div>
      <span className="title">Pilih paket berlangganan baru</span>
      <span className="sub-title">
        Silakan pilih periode tagihan dan paket berlangganan yang baru
      </span>
      <div className="section-package-menu">
        {menuPackage.map((items, idx) => {
          return (
            <div
              key={idx}
              onClick={() => setIsYearly(items.is_yearly)}
              className={
                items.is_yearly === isYearly ? "button-active" : "button"
              }
            >
              {items.label}
            </div>
          );
        })}

        {isYearly && (
          <div className="section-package-info">
            <i className="fa fa-tag" aria-hidden="true"></i>
            <span className="text-primary font-normal xl:text-base lg:text-sm">
              Gratis 2 Bulan
            </span>
          </div>
        )}
      </div>
      <div className="section-package-tiers">
        {isLoading && (
          <div className="loading">
            <Spinner color="primary" />
          </div>
        )}
        {renderPlanService()}
      </div>
      <div className="section-button">
        <div
          onClick={() => setStep(1)}
          className="button"
          style={
            selectedPlan === null
              ? { backgroundColor: "#CCC", color: "#777777" }
              : { backgroundColor: "#24b39b", color: "#fff" }
          }
        >
          Pilih paket
        </div>
        <span onClick={toggle}>Batal</span>
      </div>
      <p className="section-note">
        Untuk paket berlangganan Enterprise atau perbandingan paket berlangganan
        RISA AI selengkapnya bisa dilihat di{" "}
        <span>
          <a href="https://risa.ai/price" target="_blank">
            halaman harga
          </a>
        </span>
      </p>
    </div>
  );
};

export default ChoosePackage;
