import { get, post } from "network/http/api";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Alert, Badge, Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { notificationErrorRequestAction, notificationSuccessRequestAction } from "store/notification/actions";

const ModalZendesk = ({ isOpen, toggle, notificationError, notificationSuccess }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [zendesk, setZendesk] = useState({ email: '', subdomain: '' })
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        if (!isOpen) return
        handleOnFetch()
    }, [isOpen])

    const handleOnFetch = () => {
        setIsLoading(true)
        get("/integration/zendesk")
            .then(resp => {
                const { id } = resp

                setIsLoading(false)
                if (!id) return
                setZendesk(resp)
            })
            .catch(error => {
                console.error(error.detail)
                setIsLoading(false)
                setErrorMessage("Terjadi kesalahan, mohon refresh halaman ini.")
            })
    }

    const handleOnPush = () => {
        const data = {
            token: zendesk.token,
            email: zendesk.email,
            subdomain: zendesk.subdomain,
        }
        if (!zendesk.token || zendesk.token === "") {
            setErrorMessage("Token is required")
            return
        }
        setIsLoading(true)
        post("/integration/zendesk", data)
            .then(resp => {
                setErrorMessage(null)
                notificationSuccess("Berhasil menambahkan zendesk")
                setIsLoading(false)
                setZendesk({})
                toggle()
            })
            .catch(err => {
                console.error(err)
                let msg = err.detail
                if (!msg) {
                    msg = "Terjadi kesalahan, mohon mencoba kembali"
                }
                notificationError(msg)
                setIsLoading(false)
            })
    }

    const handleOnSyncArticle = () => {
        setIsLoading(true)
        get("/integration/zendesk-sync/article")
            .then(resp => {
                notificationSuccess("Berhasil synchornization dengan Zendesk")
                setIsLoading(false)
                setZendesk({})
                toggle()
            })
            .catch(err => {
                console.error(err)
                let msg = err.detail
                if (!msg) {
                    msg = "Terjadi kesalahan, mohon mencoba kembali"
                }
                notificationError(msg)
                setIsLoading(false)
            })
    }

    const handleOnChange = (key, value) => {
        let data = { ...zendesk }
        data[key] = value
        setZendesk(data)
    }

    return (<Modal isOpen={isOpen}>
        <ModalHeader>
            Zendesk Integration
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col xs="12" className="mb-4">
                    <div style={{ width: '100%' }}>
                        <InputGroup>
                            <InputGroupText>
                                https://
                            </InputGroupText>
                            <Input value={zendesk.subdomain} onChange={(e) => handleOnChange("subdomain", e.target.value)}></Input>
                            <InputGroupText>
                                .zendesk.com
                            </InputGroupText>
                            <br />

                        </InputGroup>
                        <small className="text-muted">Subdomain Zendesk Anda.</small>
                    </div>
                </Col>
                <Col xs="12">

                    <FormGroup floating>
                        <Input value={zendesk.email}
                            onChange={(e) => handleOnChange('email', e.target.value)}
                            id="emailZendesk"
                            name="email" placeholder="Email" type="email"></Input>

                        <Label for="emailZendesk" >Email</Label>

                        <small className="text-muted">Email Agent Zendesk Anda.</small>
                    </FormGroup>
                </Col>
                <Col xs="12" lg={12}>
                    <FormGroup floating>
                        <Input
                            id="examplePassword"
                            name="token"
                            placeholder="Token"
                            type="text"
                            value={zendesk.token}
                            onChange={(e) => handleOnChange('token', e.target.value)}
                        />
                        <Label for="examplePassword">
                            Token
                        </Label>

                        <small className="text-muted">
                            Masukan Zendesk Token API Anda, baca detail generate token api <a href="https://support.zendesk.com/hc/en-us/articles/4408889192858-Managing-access-to-the-Zendesk-API#topic_tcb_fk1_2yb" target="_blank"> disini</a>.
                        </small>
                    </FormGroup>
                </Col>

                {zendesk.id &&
                    <>
                        <Col>
                            <Alert color="primary">
                                Jumlah data yang disinkronkan dari Zendesk
                            </Alert>
                        </Col>
                        <Col xs={12} className="sync-article">
                            <ListGroup>
                                <ListGroupItem style={{ display: 'flex', alignItems: 'baseline' }} className="justify-content-between">
                                    <Link className="font-size-14" to="/service-knowledge">Service Knowledge</Link>
                                    <div style={{
                                        minWidth: 100, display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "baseline",
                                    }}>
                                        <Badge color="primary" className="font-size-14 mr-4">
                                            {zendesk.articles_sync ? zendesk.articles_sync.length : 0}
                                        </Badge>
                                        {zendesk.article_on_sync &&
                                            <div>
                                                <small className="text-muted">Sync in progress..</small>
                                            </div>
                                        }
                                        {!zendesk.article_on_sync &&
                                            <Button onClick={handleOnSyncArticle} outline color="primary" disabled={isLoading}>Sync</Button>
                                        }

                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Col>
                    </>
                }
                <Col xs={12}>
                    {errorMessage &&
                        <Alert color="danger">
                            {errorMessage}
                        </Alert>
                    }
                </Col>
            </Row>
            {isLoading &&
                <div className="text-center">
                    <Spinner type="grow" color="primary" />
                </div>
            }
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggle} outline color="primary">Batalkan</Button>
            <Button disabled={isLoading} onClick={handleOnPush} color="primary">Simpan</Button>
        </ModalFooter>
    </Modal >)
}

export default connect(null, {
    notificationError: notificationErrorRequestAction,
    notificationSuccess: notificationSuccessRequestAction,
})(ModalZendesk)