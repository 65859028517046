// reducer.js
import { SET_FORM_DATA_TOKOPEDIA } from "./actions";

const initialState = {
  store_name: "",
  store_url: "",
  platform: "tokopedia",
  policies: {
    warrant: {
      label: "warranty",
      information: "",
    },
    refund: {
      label: "refund",
      information: "",
    },
    after_sale: {
      label: "after_sale",
      information: "",
    },
  },
  retail_attribute: {
    store_location: "",
    shipping_location_city: "",
    shipping_location_district: "",
    is_offline_available: true,
    offline_store_location: "",
    note: "",
    shipment_regular_cut_off: {
      weekday: "08:00 pagi - 12:00 siang",
      saturday: "08:00 pagi - 12:00 siang",
      sunday: "Off",
      holiday: "Off",
      timezone: "WIB",
      note: "",
    },
    shipment_instant_cut_off: {
      weekday: "08:00 pagi - 12:00 siang",
      saturday: "08:00 pagi - 12:00 siang",
      sunday: "Off",
      holiday: "Off",
      timezone: "WIB",
      note: "",
    },
    attribute_checks: {
      name: true,
      store_location: true,
      policies: true,
      shipment_regular_cut_off: true,
      shipment_instant_cut_off: true,
    },
  },
};

const multiStepReducerTokopedia = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_DATA_TOKOPEDIA:
      return (state = action.payload.data);
    default:
      return state;
  }
};

export default multiStepReducerTokopedia;
