import React, { Component } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import images
import bukalapak_logo from 'assets/images/bukalapak-logo.png';
import tokopedia_logo from 'assets/images/tokopedia-logo.png';
import shopee_logo from 'assets/images/shopee-logo.png';

// actions
import {
  getPlatformRequest
} from 'store/platform/actions'
import { registerShop } from 'store/shop/actions'
import NewChannelModal from "./NewChannelModal/NewChannelModal";
import PlatformItem from "./PlatformItem";

class NewChannel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      store_url: '',
      store_name: '',
      platform: {},
    }
  }

  get_image(platform_name) {
    switch (platform_name) {
      case 'tokopedia':
        return tokopedia_logo
      case 'bukalapak':
        return bukalapak_logo
      case 'shopee':
        return shopee_logo;
      default:
        break;
    }
  }

  componentDidMount() {
    const { getPlatformRequest } = this.props;
    getPlatformRequest()
  }


  handleOnOpenModal = (platform) => {
    this.setState({
      platform,
      isOpen: true
    })
  }

  handleOnCloseModal = () => {
    this.setState({
      isOpen: false
    })
  }

  handleOnToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { isOpen, platform } = this.state;
    const { isRequested, platforms, register } = this.props;

    return (
      <React.Fragment>
        <NewChannelModal
          toggleModal={this.handleOnToggle}
          isOpen={isOpen}
          platform={platform} />


        <Col xl="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-1">Tambahkan Chat Room Platform E-commerce
              </h4>
              <div className="text-center text-muted">
                <Row>
                  {isRequested &&
                    <Col xs="12" className="text-center">
                      <Spinner />
                    </Col>
                  }

                  {platforms.map((v) => (
                    <Col xs="4" key={v.id}>
                      <div className="mt-4">
                        <Link to="#" onClick={() => this.handleOnOpenModal(v)}>
                          <img style={{ height: 80 }} src={this.get_image(v.platform_name)} alt={v.platform_name} />
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { Platform, Shop } = state;
  return {
    platforms: Platform.platforms,
    isRequested: Platform.isRequested,
    register: Shop.register,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    getPlatformRequest,
    registerShop,
  }
)(NewChannel));
