import React, { useCallback, useEffect, useRef, useState } from "react";
import { Prompt, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalChangeConfirmation = ({ isEdited }) => {
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)
    const [currentPath, setCurrentPath] = useState("");
    const unblockRef = useRef();

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            if (isEdited) {
                setCurrentPath(location.pathname);
                setIsOpen(true);
                return false;
            }
            return true;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [isEdited]);

    const handleOnContinue = useCallback(async () => {
        history.block(() => { });
        setIsOpen(false);
    }, [currentPath, history]);

    const handleCancel = useCallback(async () => {
        if (unblockRef) {
            unblockRef.current();
        }
        setIsOpen(false);
        history.push(currentPath);
    }, [currentPath, history]);


    return (<Modal className="scenario-modal-confirmation" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>
            <ModalHeader>Anda Akan Kehilangan Progress Anda</ModalHeader>
            Simpan hasil edit Anda untuk menyimpan perubahan dari Custom scenario.
        </ModalBody>
        <ModalFooter>
            <Button outline color="primary" onClick={handleCancel}>Keluar</Button>
            <Button color="primary" onClick={handleOnContinue}>Lanjut</Button>
        </ModalFooter>
    </Modal>)
}

export default ModalChangeConfirmation