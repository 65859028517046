import React, { useState, useRef, useEffect } from "react";
import { EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";

const CardsServiceKnowledge = ({
  itm,
  action,
  openModalDelete,
  setPickIdKnowledge,
}) => {
  const [showAction, setShowAction] = useState(false);
  const modalRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAction(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openModalAction = () => {
    setShowAction(!showAction);
  };

  const openModalDeleteAction = paramsItm => {
    setPickIdKnowledge(paramsItm);
    openModalDelete();
  };

  const goToEditServiceKnowledge = paramsId => {
    history.push(`/detail-service-knowledge-question/${paramsId}`);
  };

  if (action) {
    return (
      <div
        className="card-service-knowledge cursor-pointer"
        onClick={() => goToEditServiceKnowledge(itm.content.id)}
      >
        <div className="card-content" ref={modalRef}>
          <span className="title-service-knowledge cursor-pointer">
            {itm.content ? itm.content.title : "-"}
          </span>
          <span className="category-service-knowledge cursor-pointer">
            {itm.content.category ? itm.content.category : "-"}
          </span>
          <span className="topic-service-knowledge">
            {itm.question_answer.length !== 0
              ? itm.question_answer.length
              : "0"}{" "}
            Topik bahasan
          </span>
        </div>
        <div className="card-action">
          <div
            className="card-action-btn"
            onClick={e => {
              e.stopPropagation(), openModalAction();
            }}
          >
            <EllipsisHorizontalIcon className="icon" />
          </div>
          {showAction && (
            <div className="card-action-modal">
              <div
                className="btn-icon-action btn-edit"
                onClick={() =>
                  goToEditServiceKnowledge(itm.content ? itm.content.id : "")
                }
              >
                <PencilIcon className="icon" /> Edit
              </div>
              <div
                className="btn-icon-action btn-delete"
                onClick={() => openModalDeleteAction(itm.content)}
              >
                <TrashIcon /> Delete
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="card-service-knowledge"
      onClick={() =>
        goToEditServiceKnowledge(itm.content ? itm.content.id : "")
      }
    >
      <div className="card-content">
        <span className="title-service-knowledge">
          {itm.content ? itm.content.title : "-"}
        </span>
        <span className="category-service-knowledge">
          {itm.content.category ? itm.content.category : "-"}
        </span>
        <span className="topic-service-knowledge">
          {itm.question_answer.length !== 0 ? itm.question_answer.length : "0"}{" "}
          Topik bahasan
        </span>
      </div>
    </div>
  );
};

export default CardsServiceKnowledge;
