/* SCENARIO */
export const GET_SCENARIO = "GET_SCENARIO"
export const GET_SCENARIO_SUCCESS = "GET_SCENARIO_SUCCESS"
export const GET_SCENARIO_FAIL = "GET_SCENARIO_FAIL"

/* SCENARIO DETAIL*/
export const GET_SCENARIO_DETAIL = "GET_SCENARIO_DETAIL"
export const GET_SCENARIO_DETAIL_SUCCESS = "GET_SCENARIO_DETAIL_SUCCESS"
export const GET_SCENARIO_DETAIL_FAIL = "GET_SCENARIO_DETAIL_FAIL"

export const ADD_NEW_SCENARIO = "ADD_NEW_SCENARIO"
export const ADD_SCENARIO_SUCCESS = "ADD_SCENARIO_SUCCESS"
export const ADD_SCENARIO_FAIL = "ADD_SCENARIO_FAIL"

export const UPDATE_SCENARIO = "UPDATE_SCENARIO"
export const UPDATE_SCENARIO_SUCCESS = "UPDATE_SCENARIO_SUCCESS"
export const UPDATE_SCENARIO_FAIL = "UPDATE_SCENARIO_FAIL"

export const DELETE_SCENARIO = "DELETE_SCENARIO"
export const DELETE_SCENARIO_SUCCESS = "DELETE_SCENARIO_SUCCESS"
export const DELETE_SCENARIO_FAIL = "DELETE_SCENARIO_FAIL"

export const UPDATE_SCENARIO_NODE_DATA = "UPDATE_SCENARIO_NODE";
export const ADD_SCENARIO_NODE = "ADD_SCENARIO_NODE";
export const UPDATE_SCENARIO_EDGE = "UPDATE_SCENARIO_EDGE"
