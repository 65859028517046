import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const InputCopy = ({ id, text, label, description }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            setTooltipOpen(true)

            setInterval(() => {
                setTooltipOpen(false)
            }, 1000);
        });
    };

    return (
        <div className="input-container">
            <div className="input-copy">
                <label>{label}</label>
                <input
                    type="text"
                    disabled={true}
                    value={text}
                    className="copy-text"
                />

                <button
                    id={id} onClick={handleCopy} className="copy-button">
                    <i className="fas fa-copy"></i> {/* Font Awesome icon for copy */}
                </button>

                <Tooltip
                    placement="bottom"
                    isOpen={tooltipOpen}
                    autohide={true}
                    target={id}
                >
                    Copied!!
                </Tooltip>
            </div>
            <small className="text-muted">
                {description}
            </small>
        </div>
    );
};

export default InputCopy;