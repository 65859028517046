import React from "react";
import { ReactComponent as IconRobot } from "assets/images/knowledge/robotic-hand.svg";
import { Button, Spinner } from "reactstrap";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

const FormKnowledge = ({
  id,
  knowledge,
  onSubmitKnowledge,
  closePage,
  handleChangeKnowledge,
  isFocused,
  handleFocus,
  handleBlur,
  setIsOpen,
  loadingKnowledge,
}) => {
  return (
    <React.Fragment>
      <Formik
        className="p-5"
        enableReinitialize={true}
        initialValues={knowledge}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(),
          content: Yup.string().required(),
        })}
        onSubmit={values => onSubmitKnowledge(values)}
      >
        {({ errors, touched }) => (
          <Form className="form-knowledge">
            <div className="form-input-knowlede">
              <div className="close-form">
                <i
                  onClick={() => closePage()}
                  className={"fa fa-times-circle font-size-24"}
                />
              </div>
              <div className="field-knowledge mt-3 mb-3">
                {isFocused === 1 ? (
                  <IconRobot height={30} width={30} fill={"#1cac93"} />
                ) : (
                  <div style={{ height: 30, width: 30 }} />
                )}

                <Field
                  name="title"
                  placeholder="Title . . ."
                  type="text"
                  className={`form-control ${
                    errors.title && touched.title ? " is-invalid" : ""
                  }`}
                  value={knowledge.title}
                  onChange={handleChangeKnowledge}
                  onFocus={() => handleFocus(1)}
                  onBlur={() => handleBlur(1)}
                />
              </div>
              <div className="field-knowledge mb-3 items-start">
                {isFocused === 2 ? (
                  <IconRobot height={30} width={30} fill={"#1cac93"} />
                ) : (
                  <div style={{ height: 30, width: 30 }} />
                )}
                <Field
                  name="content"
                  placeholder="Write content here . . ."
                  type="text"
                  as="textarea"
                  value={knowledge.content}
                  className={`form-control text-area ${
                    errors.content && touched.content ? " is-invalid" : ""
                  }`}
                  onChange={handleChangeKnowledge}
                  onFocus={() => handleFocus(2)}
                  onBlur={() => handleBlur(2)}
                />
              </div>

              <div className="container-btn-save">
                {loadingKnowledge ? (
                  <div className="btn-spinner">
                    <Spinner color="white" size="sm" />
                  </div>
                ) : (
                  <Button
                    className="btn-knowledge"
                    color="primary"
                    type="submit"
                  >
                    {id !== null ? "Update Knowledge" : "Add Knowledge"}
                  </Button>
                )}
                {id !== null && (
                  <>
                    <Button
                      className="btn-knowledge"
                      color="danger"
                      onClick={() => setIsOpen(true)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default FormKnowledge;
