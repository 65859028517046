import React, { useState } from "react";
import { Input, Button, Alert } from "reactstrap";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const ModalContent = ({ type, onClose }) => {
  const [token, setToken] = useState();

  if (type === "facebook" || type === "instagram") {
    return (
      <>
        <span className="title">
          {type === "facebook"
            ? "Akses Facebook Messenger"
            : "Akses Instagram Message"}
        </span>
        <span className="subtitle">
          Untuk mengintegrasikan layanan RISA AI, silahkan masukkan token
          integrasi dari konfigurasi halaman akun bisnis Anda ke submisi di
          bawah. Akses tautan berikut untuk mengetahui informasi lebih lanjut.
        </span>
        <Input
          value={token}
          onChange={e => setToken(e.target.value)}
          placeholder="Masukkan Token Instagram di sini..."
        />
        <div className="section-btn-activation">
          <Button color="primary" onClick={onClose}>
            Submit
          </Button>
        </div>
      </>
    );
  }

  if (type === "whatsapp") {
    return (
      <>
        <span className="title">Akses Whatsapp Messenger</span>
      </>
    );
  }

  return (
    <>
      <span className="title">Integrasi Website</span>
      <span className="subtitle">
        Untuk mengintegrasi website personal Anda dengan sistem RISA AI,
        silahkan duplikasi API Script dibawah ini dan masukkan pada kolom yang
        tersedia di Website anda
      </span>
      <div className="section-btn-copy">
        <Input
          value={token}
          onChange={e => setToken(e.target.value)}
          placeholder="Token Web"
          type="textarea"
          className="input-token"
          disabled
        />
        <Button
          className="icon-btn"
          color="primary"
          onClick={() => console.log("Copy")}
        >
          <DocumentDuplicateIcon />
        </Button>
      </div>
      <div className="section-btn-activation">
        <Button color="primary" onClick={onClose}>
          Selesai
        </Button>
      </div>
    </>
  );
};

export default ModalContent;
