import { useEffect, useState } from "react";
import {
    Badge,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Spinner,
    UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import { post } from "network/http/api";
import { notificationErrorRequestAction } from 'store/notification/actions'

const BadgeScenario = (isTest) => (
    <div>
        {isTest &&
            <>
                <UncontrolledTooltip placement="left" target={"scenario-navbar-badge"}>
                    Versi draft tidak akan berdampak pada bot yang berhadapan dengan pengguna.
                </UncontrolledTooltip>
                Draft
            </>
        }
        {!isTest &&
            <>
                <UncontrolledTooltip placement="left" target={"scenario-navbar-badge"}>
                    Versi release akan berdampak pada bot yang berhadapan dengan pengguna.
                </UncontrolledTooltip>
                Release
            </>
        }
    </div>
)


const BadgeVersion = ({ isTest, platformName, notificationError }) => {
    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const handleOnChange = (value) => {
        setIsLoading(true)
        post(`/scenario/discard/${platformName}`)
            .then(resp => {
                setIsLoading(false)
                window.location.reload()
            })
            .catch(err => {
                setIsLoading(false)
                console.error(err)
                notificationError("Terjadi kesalahan, mohon coba kembali")
            })
    }


    if (!isTest) {
        return null
    }

    return (
        <Dropdown isOpen={isOpenDropDown} toggle={() => setIsOpenDropDown(!isOpenDropDown)} direction={'down'}>
            {/* <DropdownToggle disabled={isLoading} color="primary" id="scenario-navbar-badge" caret>
                {!isLoading && <BadgeScenario isTest={isTest} />}
                {isLoading &&
                    <Spinner color="primary" />
                }
            </DropdownToggle> */}
            <DropdownMenu onChange={handleOnChange}>
                <DropdownItem onClick={() => handleOnChange(false)} active={!isTest}>Discard</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

const mapStateToProps = state => {
    return {
        isTest: state.AgentScenarioBuilder.isTest,
    };
};

export default connect(mapStateToProps, {
    notificationError: notificationErrorRequestAction
})(BadgeVersion);
