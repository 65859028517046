import {
    NOTIFICATION_REQUEST,
    NOTIFICATION_ADD_TOKEN_WEB_REQUEST
} from "./actionTypes";

export const notificationErrorRequestAction = (msg) => ({
    type: NOTIFICATION_REQUEST,
    payload: {
        body: msg,
        type: 'error',
    }
})


export const notificationSuccessRequestAction = (msg) => ({
    type: NOTIFICATION_REQUEST,
    payload: {
        body: msg,
        type: 'success',
    }
})

export const notificationRequestAction = (payload) => ({
    type: NOTIFICATION_REQUEST,
    payload: payload
})


export const notificationAddTokenRequest = (token) => ({
    type: NOTIFICATION_ADD_TOKEN_WEB_REQUEST,
    payload: token
})