import React from "react";
import Skeleton from "react-loading-skeleton";
import CardsServiceKnowledge from "../CardsServiceKnowledge";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/20/solid";
import EmptySummaryDashboard from "../../EcommerceDashboard/EmptySummaryDashboard";

const ListServiceKnowledge = ({
  data,
  openModalDelete,
  setPickIdKnowledge,
  loading,
}) => {
  const history = useHistory();

  const goToAddServiceKnowledge = () => {
    history.push("/add-service-knowledge");
  };

  if (loading) {
    return (
      <>
        <div className="service-knowledge-product-add">
          <Button color="primary" className="btn-add-service-knowledge">
            <PlusIcon /> Tambah Pengetahuan Layanan
          </Button>
        </div>
        <div className="service-knowledge-list">
          {[...Array(15)].map((item, idx) => {
            return (
              <div className="card-skeleton" key={idx}>
                <Skeleton className="skeleton" height={30} />
                <div style={{ marginTop: "4px" }} />
                <Skeleton className="skeleton" height={18} width={100} />
                <div style={{ marginTop: "8px" }} />
                <Skeleton className="skeleton" height={18} width={100} />
              </div>
            );
          })}
        </div>
      </>
    );
  }

  if (!data || data?.length === 0) {
    return (
      <EmptySummaryDashboard
        type="service-knowledge"
        title="Tidak ada pengetahuan layanan"
        subtitle="Tambah pengetahuan layanan untuk membuat daftar pengetahuan layanan atau coba kata kunci lain."
        btn={{
          title: "Tambah Pengetahuan Layanan",
          url: "/add-service-knowledge",
          icon: <PlusIcon />,
        }}
      />
    );
  }

  return (
    <>
      <div className="service-knowledge-product-add">
        <Button
          color="primary"
          className="btn-add-service-knowledge"
          onClick={() => goToAddServiceKnowledge()}
        >
          <PlusIcon /> Tambah Pengetahuan Layanan
        </Button>
      </div>
      <div className="service-knowledge-list">
        {data.map((item, idx) => {
          return (
            <CardsServiceKnowledge
              key={idx}
              itm={item}
              action
              openModalDelete={openModalDelete}
              setPickIdKnowledge={setPickIdKnowledge}
            />
          );
        })}
      </div>
    </>
  );
};

export default ListServiceKnowledge;
