export const GET_AGENT_OPERATION_HOUR_REQUEST = 'GET_AGENT_OPERATION_HOUR_REQUEST'
export const GET_AGENT_OPERATION_HOUR_SUCCESS = 'GET_AGENT_OPERATION_HOUR_SUCCESS'
export const GET_AGENT_OPERATION_HOUR_FAILED = 'GET_AGENT_OPERATION_HOUR_FAILED'

export const INSERT_AGENT_OPERATION_HOUR_REQUEST = 'INSERT_AGENT_OPERATION_HOUR_REQUEST'
export const INSERT_AGENT_OPERATION_HOUR_SUCCESS = 'INSERT_AGENT_OPERATION_HOUR_SUCCESS'
export const INSERT_AGENT_OPERATION_HOUR_FAILED = 'INSERT_AGENT_OPERATION_HOUR_FAILED'

export const UPDATE_AGENT_OPERATION_HOUR_REQUEST = 'UPDATE_AGENT_OPERATION_HOUR_REQUEST'
export const UPDATE_AGENT_OPERATION_HOUR_SUCCESS = 'UPDATE_AGENT_OPERATION_HOUR_SUCCESS'
export const UPDATE_AGENT_OPERATION_HOUR_FAILED = 'UPDATE_AGENT_OPERATION_HOUR_FAILED'
