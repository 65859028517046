import React, { useEffect, useState, useRef } from "react";
import { useOutsideClick } from "helpers/hooks/useOutsideClick";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { withRouter } from "react-router-dom";
import OperationScheduleCard from "./OperationScheduleCard";
import { useDispatch } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";

// redux
import { connect } from "react-redux";
import { getAgentOperationHourRequest } from "store/agent-operation-hour/actions";
import { get } from "network/http/api";
import { notificationErrorRequestAction } from "store/notification/actions";

const OperationSchedule = () => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(() => {
    setIsOpen(false);
  }, modalRef);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const fetchSchedule = () => {
    get("/agent-operation-hour")
      .then(res => {
        const mergedData = res.platforms.map(platform => {
          const operationHours = res.operation_hours.find(
            hour => hour.platform_id === platform.id
          );

          return {
            ...platform,
            operation_hours: operationHours || null,
          };
        });

        setData(mergedData.length ? mergedData : []);
      })
      .catch(err => {
        dispatch(notificationErrorRequestAction(err.detail));
      });
  };

  useEffect(() => {
    fetchSchedule();
  }, []);

  const handleUpdateSchedule = () => {
    fetchSchedule();
  };

  return (
    <div className="accuracy-section" ref={modalRef}>
      <div className="btn-accuracy" onClick={handleToggleDropdown}>
        <span>Jadwal Operasi</span>
      </div>
      {isOpen && (
        <div className="modal-operation">
          <div className="modal-operation-section">
            <XMarkIcon className="icon-close" onClick={handleToggleDropdown} />
            <span className="title">Jadwal Operasi</span>
            <span className="subtitle">
              Menunjukkan waktu kapan RISA AI akan menjawab pesan dari pelanggan
              secara otomatis.
            </span>
            {data.length && (
              <div className="d-flex flex-column mt-3 modal-operation-section-content">
                {data
                  ?.filter(item => item.operation_hours !== null)
                  .map((item, idx) => {
                    return (
                      <OperationScheduleCard
                        key={idx}
                        item={item}
                        onUpdateSchedule={handleUpdateSchedule}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ AgentOperationHour }) => {
  const { agentOperationHour, isFetching, successMessage, error, isUpdate } =
    AgentOperationHour;
  return {
    agentOperationHour,
    isFetching,
    isUpdate,
    successMessage,
    error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onGetSchedule: getAgentOperationHourRequest,
  })(withTranslation()(OperationSchedule))
);
