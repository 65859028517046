/* AGENT_INTENT */
export const GET_AGENT_INTENT = "GET_AGENT_INTENT"
export const GET_AGENT_INTENT_SUCCESS = "GET_AGENT_INTENT_SUCCESS"
export const GET_AGENT_INTENT_FAIL = "GET_AGENT_INTENT_FAIL"

/* AGENT_INTENT DETAIL*/
export const GET_AGENT_INTENT_DETAIL = "GET_AGENT_INTENT_DETAIL"
export const GET_AGENT_INTENT_DETAIL_SUCCESS = "GET_AGENT_INTENT_DETAIL_SUCCESS"
export const GET_AGENT_INTENT_DETAIL_FAIL = "GET_AGENT_INTENT_DETAIL_FAIL"

export const UPDATE_AGENT_INTENT = "UPDATE_AGENT_INTENT"
export const UPDATE_AGENT_INTENT_SUCCESS = "UPDATE_AGENT_INTENT_SUCCESS"
export const UPDATE_AGENT_INTENT_FAIL = "UPDATE_AGENT_INTENT_FAIL"
