import React from "react";
import { Button, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import moment from "moment";
import TablePagination from "./TablePagination";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { convertInt2Money } from "components/PlanService";

moment.locale("id");

const TableReferralCommission = ({history, data, onChangePage, pagination, currentPage }) => {
  const statusStyles = {
    paid: {
      backgroundColor: "#EBF2EF",
      color: "#649B84",
    },
    expired: {
      backgroundColor: "#FCEBEB",
      color: "#E11A1A",
    },
    refund: {
      backgroundColor: "#D7DFF0",
      color: "#3660B3",
    },
    unpaid: {
      backgroundColor: "#F5F5F5",
      color: "#79747E",
    },
  };

  const RenderTable = ({ dataTabel }) => {
    const header = (<thead>
      <tr>
        <th>Date</th>
        <th>Ref Id</th>
        <th>Plan</th>
        <th>Price</th>
        <th>Percentage</th>
        <th>Commission</th>
        <th>Paid</th>
      </tr>
    </thead>)
    if (dataTabel?.length === 0) {
      return (
        <>
          <Table responsive>
            {header}
          </Table>
          <div className="tabel-no-data">Belum ada user referral untuk Anda.</div>
        </>
      );
    }

    return (
      <Table responsive>
        {header}
        <tbody>
          {data?.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>
                  {item.created_at ? moment(item.created_at).format("DD MMMM YYYY") : "-"}
                </td>
                <td style={{ fontWeight: "600", color: "#000" }}>
                  {item.referral_commission_id}
                </td>

                <td style={{ fontWeight: "600", color: "#000" }}>
                  {/* {item.user.email} */}
                  {item.plan_name}
                </td>
                <td>
                  {convertInt2Money(item.plan_price) || '-'}
                </td>
                <td>
                  {item.commission_percentage} %
                </td> 
                <td>
                  {convertInt2Money(item.commission_amount) || '-'}
                </td>
                <td className="cursor-pointer" style={{ fontWeight: "600", color: "#000" }}>
                  {item.is_paid ? <span style={{color: 'green', padding: 5}}>'PAID'</span> : <span style={{color: 'grey', padding: 5}}>UNPAID</span>}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Row>
      <Col xs={12} lg={12}>
        <div className="section-tabel">
          <RenderTable dataTabel={data} />
          <TablePagination onClick={onChangePage} totalPage={pagination.totalPage} currentPage={currentPage} />
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(TableReferralCommission);
