import {
  GET_AGENT_INTENT,
  GET_AGENT_INTENT_FAIL,
  GET_AGENT_INTENT_SUCCESS,
  GET_AGENT_INTENT_DETAIL,
  GET_AGENT_INTENT_DETAIL_FAIL,
  GET_AGENT_INTENT_DETAIL_SUCCESS,
  UPDATE_AGENT_INTENT,
  UPDATE_AGENT_INTENT_SUCCESS,
  UPDATE_AGENT_INTENT_FAIL,
} from "./actionTypes"

export const getAgentIntent = (page=1) => ({
  type: GET_AGENT_INTENT,
  payload: {page},
})

export const getAgentIntentSuccess = agentIntents => ({
  type: GET_AGENT_INTENT_SUCCESS,
  payload: agentIntents,
})

export const getAgentIntentFail = error => ({
  type: GET_AGENT_INTENT_FAIL,
  payload: error,
})

export const getAgentIntentDetail = agentIntentId => ({
  type: GET_AGENT_INTENT_DETAIL,
  agentIntentId,
})

export const getAgentIntentDetailSuccess = agentIntentDetails => ({
  type: GET_AGENT_INTENT_DETAIL_SUCCESS,
  payload: agentIntentDetails,
})

export const getAgentIntentDetailFail = error => ({
  type: GET_AGENT_INTENT_DETAIL_FAIL,
  payload: error,
})

export const updateAgentIntent = agentIntent => ({
  type: UPDATE_AGENT_INTENT,
  payload: agentIntent,
})

export const updateAgentIntentSuccess = agentIntent => ({
  type: UPDATE_AGENT_INTENT_SUCCESS,
  payload: agentIntent,
})

export const updateAgentIntentFail = error => ({
  type: UPDATE_AGENT_INTENT_FAIL,
  payload: error,
})
