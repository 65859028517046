import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap'
import SanitizeHTML from 'components/SanitizeHTML'
import { v4 as uuidv4 } from "uuid";

const defaultProcessorConfig = {
    'product': {
        'title': 'Pengetahuan produk',
        'desc': 'Jika dinyalakan, jawaban pengguna akan diarahkan berdasarkan informasi pengetahuan produk yang sudah diterapkan di sini.',
        'answer': false
    },
    'knowledge_base': {
        'title': 'Pengetahuan layanan',
        'desc': 'Jika dinyalakan, jawaban pengguna akan diarahkan berdasarkan informasi pengetahuan layanan yang sudah diterapkan di sini.',
        'answer': false
    },
    'human_takeover': {
        'title': 'Hubungkan ke operator',
        'desc': 'Jika dinyalakan, jawaban pengguna akan diarahkan ke operator ketika sistem menemukan bahwa pelanggan Anda membutuhkan bantuan operator.',
        'answer': false
    },
    'loop': {
        'title': 'Processor loop active',
        'desc': 'Jika dijalankan dalam loop maka customer akan tetap melakukan tanya jawab yang dibantu oleh AI.',
        'answer': false
    },
}

const ProcessorConfig = ({ node, variables, onChangeVariable }) => {
    const [processorVar, setProcessorVar] = useState(defaultProcessorConfig)
    useEffect(() => {
        let updateProcessor = { ...processorVar }
        variables.map((v) => {
            if (v.variable_name in updateProcessor) {
                updateProcessor[v.variable_name]['answer'] = v.answer?.toString() == 'true'
            }
        })

        if (updateProcessor === processorVar) return

        setProcessorVar(updateProcessor)
    }, [variables])

    const handleOnChangeValue = useCallback((key, value) => {
        processorVar[key].answer = value
        setProcessorVar(processorVar)

        if (!checkIfVariableNameExists(variables, key)) {
            variables.push({
                'id': uuidv4(),
                'question': defaultProcessorConfig[key].title,
                'answer': value.toString(),
                'validation_type': 0,
                'variable_name': key,
            })
        }

        onChangeVariable(variables.map(v => {
            if (v.variable_name === key) {
                v.answer = value.toString()
            }
            return v
        }))
    }, [variables, processorVar])

    const checkIfVariableNameExists = (variables, key) => {
        // convert variable to object
        let variableObj = {}
        variables.map(v => {
            variableObj[v.variable_name] = v
        })

        return key in variableObj
    }

    return <div className='processor-config'>
        {Object.keys(processorVar).map((key, idx) => (
            <div className='ask-question-config' key={idx}>
                <Card className='validation'>
                    <CardBody>
                        <div className='title'>
                            <SanitizeHTML html={processorVar[key].title} />
                        </div>
                        <p className='subtitle'>
                            {processorVar[key].desc}
                        </p>
                        <FormGroup switch>
                            <Input
                                checked={processorVar[key].answer}
                                onChange={(e) => handleOnChangeValue(key, e.target.checked)}
                                type='switch' />
                        </FormGroup>
                    </CardBody>
                </Card>
            </div >
        ))}
    </div>

}

export default ProcessorConfig