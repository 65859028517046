import {
  GET_BILLING_FAIL,
  GET_BILLING_SUCCESS,
  GET_BILLING,
} from "./actionTypes"

const INIT_STATE = {
  isFetching: false,
  billing: {
    plan_name: 'Gratis',
    base_price: 0,
  },
  error: {},
}

const Billing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLING:
      return {
        ...state,
        isFetching: true,
      }
    case GET_BILLING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        billing: action.payload,
      }

    case GET_BILLING_FAIL:
      return {
        ...state,
        isFetching: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Billing
