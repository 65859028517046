import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'reactflow/dist/style.css';
import { Row, UncontrolledAlert } from 'reactstrap';
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { get, post } from 'network/http/api';
import ScenarioEditorWrapper from './ScenarioEditorWrapper';
import { connect } from 'react-redux';

// actions
import { getAgentScenario } from 'store/agent-scenario-builder/actions'


const AgentBuilder = ({ isFetching, networks, actions, getAgentScenario }) => {
  const match = useRouteMatch()
  const history = useHistory()
  const [locationKeys, setLocationKeys] = useState([])
  const [isError, setIsError] = useState(false)
  const [platformName, setPlatformName] = useState('')

  useEffect(() => {
    const { platform } = match.params
    getAgentScenario(platform)
    setPlatformName(platform)
  }, [])

  return (
    <>
      {isError &&
        <UncontrolledAlert color='danger'>
          Something wrong please refresh this page
        </UncontrolledAlert>
      }
      <ScenarioEditorWrapper
        networks={networks}
        actions={actions}
        isLoading={isFetching}
        platformName={platformName}
        history={history} />
    </>
  );

}

const mapStateToProps = (state) => {
  const { AgentScenarioBuilder } = state
  return AgentScenarioBuilder
}

export default connect(mapStateToProps, {
  getAgentScenario
})(AgentBuilder)

