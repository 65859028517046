// CustomToast.js
import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

const CustomToast = ({ type, title, subtitle, onClose, icon }) => {
  let backgroundColor = "";

  if (type === "edit-success") {
    backgroundColor = "edit-success";
  } else if (type === "success") {
    backgroundColor = "success";
  } else if (type === "error") {
    backgroundColor = "error";
  } else {
    backgroundColor = "info";
  }

  return (
    <div
      className={`animate-enter ${backgroundColor} text-white notif-costume`}
    >
      <div className="notif-body">
        {icon && icon}
        <div className="notif-body-content">
          <div className="body-content">
            <p className="title">{title}</p>
            <p className="body">{subtitle}</p>
          </div>
        </div>
        <div onClick={onClose} className="btn-close-notif">
          <XMarkIcon />
        </div>
      </div>
    </div>
  );
};

export default CustomToast;
