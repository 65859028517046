import {
  GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID,
  GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_SUCCES,
  GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_FAILED,
} from "./actionTypes";

export const getShopCategoryByCompanyID = companyId => ({
  type: GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID,
  payload: companyId,
});

export const getShopCategoryByCompanyIDSuccess = payload => ({
  type: GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_SUCCES,
  payload,
});

export const getShopCategoryByCompanyIDFailed = payload => ({
  type: GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_FAILED,
  payload,
});
