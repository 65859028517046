import {
  GET_TICKETS_FAIL,
  GET_TICKETS_SUCCESS,
  GET_TICKET_DETAIL_FAIL,
  GET_TICKET_DETAIL_SUCCESS,
  ADD_TICKET_SUCCESS,
  ADD_TICKET_FAIL,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAIL,
  GET_TICKETS,
  GET_TICKET_DETAIL,
  UPDATE_TICKET,
  GET_CONVERSATION_TICKETS_SUCCESS,
  GET_CONVERSATION_TICKETS,
} from "./actionTypes"

const INIT_STATE = {
  tickets: [],
  conversationTickets: [],
  ticketDetail: {},
  isRequested: false,
  updateState: {
    id: 0,
    isRequested: false,
  },
  error: {},
}

const tickets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONVERSATION_TICKETS_SUCCESS:
      return {
        ...state,
        isRequested: false,
        conversationTickets: action.payload,
      }
    case GET_CONVERSATION_TICKETS:
    case GET_TICKETS:
      return {
        ...state,
        error: {},
        isRequested: true,
      }
    case GET_TICKETS_SUCCESS:
      return {
        ...state,
        isRequested: false,
        tickets: action.payload,
      }

    case GET_TICKETS_FAIL:
      return {
        ...state,
        isRequested: false,
        error: action.payload,
      }

    case GET_TICKET_DETAIL:
      return {
        ...state,
        isRequested: true,
        error: {},
      }

    case GET_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        isRequested: false,
        ticketDetail: action.payload,
        error: {},
      }

    case GET_TICKET_DETAIL_FAIL:
      return {
        ...state,
        isRequested: false,
        error: action.payload,
      }

    case ADD_TICKET_SUCCESS:
      return {
        ...state,
        isRequested: false,
        ticketDetail: action.payload,
      }

    case ADD_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TICKET:
      return {
        ...state,
        updateState: {
          id: action.payload.id,
          isRequested: true,
          error: {},
        },
      }

    case UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        isRequested: false,
        error: {},
        updateState: {
          id: 0,
          isRequested: false,
          error: {}
        },
        tickets: state.tickets.map(ticket =>
          ticket.id.toString() === action.payload.id.toString()
            ? { ticket, ...action.payload }
            : ticket
        ),
      }

    case UPDATE_TICKET_FAIL:
      return {
        ...state,
        updateState: {
          id: 0,
          isRequested: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default tickets
