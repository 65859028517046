import React, { useState } from "react";

const PasswordShowHide = ({ field, form, placeholder, styleClass }) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const hasError = form.touched[field.name] && form.errors[field.name];

  return (
    <div className="input-group auth-pass-inputgroup">
      <input
        type={showHidePassword ? "text" : "password"}
        {...field}
        className={`input-field form-control ${styleClass}`}
        placeholder={placeholder}
        style={{ height: "45px" }}
      />
      <button
        className="btn btn-light"
        style={{
          border: styleClass === "is-invalid" ? "1px solid #f46a6a" : "none",
        }}
        type="button"
        onClick={() => changeShowHidePassword(!showHidePassword)}
        id={placeholder}
      >
        {!showHidePassword && <i className="mdi mdi-eye-outline"></i>}
        {showHidePassword && <i className="mdi mdi-eye-off"></i>}
      </button>
    </div>
  );
};

export default PasswordShowHide;
