import React, { useState, useEffect } from "react";
import HeaderSection from "./ComponentsPage/HeaderSection";
import InputFieldCostume from "./ComponentsPage/InputField";
import { Button, Spinner, Input } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { ProfileUpdate } from "./SchemaValidation";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { editProfile } from "store/actions";
import Package from "./ComponentsPage/Package";
import { get, put } from "network/http/api";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";

const Profile = props => {
  const { notificationSuccess, notificationError } = props;
  const [profileUser, setProfileUser] = useState({
    fullname: "",
    email: "",
    phone_number: "",
  });
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGetProfile = () => {
      get("/user/detail")
        .then(res => {
          setProfileUser({
            fullname: res.fullname || "",
            email: res.email || "",
            phone_number: res.phone_number || "",
          });
        })
        .catch(err => {
          notificationError(err.detail);
        });
    };

    fetchGetProfile();
  }, []);

  useEffect(() => {
    if (profileUser.phone_number !== props.profile.phone_number) {
      setPhoneNumberChanged(true);
    } else {
      setPhoneNumberChanged(false);
    }
  }, [profileUser.phone_number, props.profile.phone_number]);

  const onChangeInput = (value, setFieldValue, name) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    setProfileUser({
      ...profileUser,
      phone_number: numericValue,
    });
    setFieldValue(numericValue, name);
  };

  const onSubmitIntegration = values => {
    setLoading(true);

    const data = {
      fullname: values.fullname,
      email: values.email,
      phone_number: values.phone_number,
    };

    put(`/user`, data)
      .then(resp => {
        notificationSuccess("Informasi Pribadi berhasil diubah!");
        setLoading(false);
      })
      .catch(error => {
        notificationError(error.detail);
        setLoading(false);
      });
  };

  return (
    <div className="settings-content">
      <HeaderSection
        title="Informasi Pribadi"
        subtitle="Berikut adalah informasi pribadi yang akan disimpan ke layanan RISA AI dengan aman, dan untuk data acuan pengguna."
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          fullname: profileUser.fullname,
          email: profileUser.email,
          phone_number: profileUser.phone_number,
        }}
        validationSchema={ProfileUpdate}
        onSubmit={values => {
          onSubmitIntegration(values);
        }}
      >
        {({ isValid, errors, touched, setFieldValue, values }) => (
          <Form className="form-integration-settings">
            <div className="integration-settings">
              <div className="field-form">
                <span className="title">Nama Lengkap</span>
                <Field
                  name="fullname"
                  component={InputFieldCostume}
                  placeholder="Masukkan nama lengkap"
                  disabled
                />
              </div>
              <div className="field-form">
                <span className="title">Email</span>
                <Field
                  name="email"
                  component={InputFieldCostume}
                  placeholder="Masukkan email"
                  disabled
                />
              </div>
              <div className="field-form">
                <span className="title">Nomor HP</span>
                <>
                  <Input
                    name="phone_number"
                    placeholder="Masukkan nomor handphone"
                    onChange={e =>
                      onChangeInput(
                        e.target.value,
                        setFieldValue,
                        "phone_number"
                      )
                    }
                    pattern="[0-9]*"
                    value={values.phone_number}
                    className={`field-input ${
                      errors["phone_number"] && touched["phone_number"]
                        ? "error"
                        : ""
                    }`}
                  />
                  {errors["phone_number"] && touched["phone_number"] && (
                    <div className="error-message">
                      {errors["phone_number"]}
                    </div>
                  )}
                </>
              </div>
            </div>

            <div className="field-btn-profile">
              <Button
                type="submit"
                color="primary"
                disabled={!isValid || !phoneNumberChanged}
              >
                {loading ? <Spinner color="light" size="sm" /> : "Simpan"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <HeaderSection
        title="Informasi Paket"
        subtitle="Paket berikut merepresentasikan paket yang sedang Anda pilih / sedang berlangganan."
      />
      <div className="settings-package">
        <div className="settings-package-content">
          <Package billing={props.billing} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Profile }) => {
  return {
    isLoading: Profile.isLoading,
    error: Profile.error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onEditProfile: editProfile,
    notificationSuccess: notificationSuccessRequestAction,
    notificationError: notificationErrorRequestAction,
  })(Profile)
);
