import {
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICES,
  GET_INVOICE_DETAIL,
} from "./actionTypes";

const INIT_STATE = {
  isFetching: false,
  invoices: [],
  billing: {
    plan_name: 'Memuat',
    base_price: 0,
  },
  totalInvoice: 0,
  invoiceDetail: {},
  quota: {
    message_quota: 0,
    product_quota: 0
  },
  error: {},
};

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES:
    case GET_INVOICE_DETAIL:
      return {
        ...state,
        isFetching: true,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        invoices: action.payload.invoices,
        billing: action.payload.billing,
        totalInvoice: action.payload.total,
        quota: action.payload.quota,
      };

    case GET_INVOICES_FAIL:
      return {
        ...state,
        isFetching: true,
        error: action.payload,
      };

    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
      };

    case GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Invoices;
