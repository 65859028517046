import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { del } from "network/http/api";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { TrashIcon } from "@heroicons/react/20/solid";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";

const ModalDeleteQuestion = ({
  question,
  notificationError,
  notificationSuccess,
}) => {
  const history = useHistory();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onDeleteQuestion = async () => {
    setLoadingDelete(true);
    del(`/knowledge-base/question-answer/${question.id}`)
      .then(async resp => {
        notificationSuccess("Berhasil menghapus pertanyaan");
        setLoadingDelete(false);
        setIsOpen(false);
        history.go(0);
      })
      .catch(err => {
        console.error(err, "err");
        setLoadingDelete(false);
        notificationError("Gagal menghapus pertanyaan");
      });
  };

  return (
    <>
      <div
        className="btn-icon-action btn-delete"
        onClick={() => setIsOpen(true)}
      >
        <TrashIcon /> Hapus
      </div>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader>
          <span className="title">Hapus Tanya Jawab?</span>
        </ModalHeader>
        <ModalBody>
          <div className="modal-cancel-edit">
            <p className="text-sm">
              Anda akan menghapus Tanya Jawab Dengan pertanyaan :{" "}
            </p>
            <strong className="text-lg">
              <span
                dangerouslySetInnerHTML={{
                  __html: question ? question.question : "-",
                }}
              />
            </strong>
          </div>
        </ModalBody>
        <ModalFooter>
          {loadingDelete ? (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          ) : (
            <>
              <Button
                color="danger"
                disabled={loadingDelete}
                onClick={() => onDeleteQuestion()}
              >
                Hapus Tanya Jawab
              </Button>
              <Button
                color="primary"
                disabled={loadingDelete}
                onClick={() => setIsOpen(!isOpen)}
                outline
              >
                Batal Hapus Tanya Jawab
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default connect(null, {
  notificationSuccess: notificationSuccessRequestAction,
  notificationError: notificationErrorRequestAction,
})(ModalDeleteQuestion);
