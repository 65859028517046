export const JWT_TOKEN_KEY = 'JWT_TOKEN_KEY'
export const AUTH_USER_KEY = 'authUser'

// LIVECHAT CONSTANT
export const LIVE_CHAT_JWT_TOKEN_KEY = 'LIVE_CHAT_JWT_TOKEN_KEY'
export const LIVE_CHAT_AUTH_USER_KEY = 'LIVE_CHAT_AUTH_USER_KEY'
export const LIVE_CHAT_CONVERSATION_KEY = 'LIVE_CHAT_CONVERSATION_KEY'

export default function authHeader() {
  const accessToken = localStorage.getItem(JWT_TOKEN_KEY)

  if (obj) {
    return { Authorization: accessToken }
  } else {
    return {}
  }
}
