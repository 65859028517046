import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Input, Spinner, Alert } from "reactstrap";
import { post, get } from "network/http/api";
import DropdownSelected from "components/DropdownSelected";
import BackButton from "components/BackButton";
import CommonEditor from "components/Common/CommonEditor";
import Skeleton from "react-loading-skeleton";

const AddServiceKnowledge = () => {
  const history = useHistory();
  const [isFieldComplete, setIsFieldComplete] = useState(false);
  const [title, setTitle] = useState("");
  const [loadingKnowledge, setLoadingKnowledge] = useState(false);
  const [dataCategory, setDataCategory] = useState([]);
  const [selected, setSelected] = useState(null);
  const [conclusion, setConclusion] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryKnowledgeBase = async () => {
      try {
        const res = await get("/knowledge-base/categories");
        const transformedArray = res.map(item => {
          return { name: transformName(item), value: item };
        });
        setDataCategory(transformedArray);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCategoryKnowledgeBase();
  }, []);

  useEffect(() => {
    if (
      selected === null ||
      title === "" ||
      conclusion === "" ||
      conclusion.length < 10
    ) {
      setIsFieldComplete(false);
      return;
    }

    setIsFieldComplete(true);
  }, [selected, title, conclusion]);

  const transformName = name => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const goToAddQuestion = paramsData => {
    setLoadingKnowledge(true);
    const payload = {
      title: title,
      category: paramsData.value,
      parent_id: "",
      content: conclusion,
      labels: [],
    };
    post(`/knowledge-base`, payload)
      .then(async resp => {
        setTimeout(() => {
          setLoadingKnowledge(false);
          history.push({
            pathname: `/add-service-knowledge-question/${resp.id}`,
            state: { fromPage: "add-knowledge", pageId: resp.id },
          });
        }, 1500);
      })
      .catch(err => {
        setLoadingKnowledge(false);
        setError(err.detail);
      });
  };

  const onChangeText = params => {
    setConclusion(params);
  };

  if (loadingKnowledge) {
    return (
      <div className="page-content">
        <div className="page-ecommerce-details">
          <Container fluid>
            <div className="header-section">
              <div className="page-header-content">
                <BackButton />
                <span className="title">Tambah Ringkasan Pengetahuan</span>
              </div>
            </div>
            <div className="page-add-service-knowledge">
              <div className="section-question">
                <div>
                  <span className="title">Kategori</span>
                  <Skeleton height={40} />
                </div>
                <div>
                  <span className="title">Nama Layanan</span>
                  <Skeleton height={40} />
                </div>
                <div>
                  <span className="title">Ringkasan</span>
                  <Skeleton height={200} />
                </div>
              </div>
              {error && <Alert color="danger">{error}</Alert>}
              <div className="section-button-action">
                <Button
                  color="primary"
                  className="btn-edit-product-recommend"
                  outline
                >
                  <Spinner color="primary" size="sm" />
                </Button>
                <Button color="primary" className="btn-edit-product-recommend">
                  <Spinner color="light" size="sm" />
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="page-ecommerce-details">
        <Container fluid>
          <div className="header-section">
            <div className="page-header-content">
              <BackButton />
              <span className="title">Tambah Ringkasan Pengetahuan</span>
            </div>
          </div>
          <div className="page-add-service-knowledge">
            <div className="section-question">
              <div>
                <div className="section-add-selected">
                  <div className="section-select">
                    <DropdownSelected
                      title="Kategori"
                      data={dataCategory}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                  {/* <div className="section-add">
                <Button color="primary" className="btn-add" outline>
                  <PlusIcon /> Tambah Kategori
                </Button>
              </div> */}
                </div>
              </div>
              <div>
                <span className="title">Nama Layanan</span>
                <Input
                  className="section-title"
                  onChange={e => setTitle(e.target.value)}
                  value={title}
                  placeholder="Ketik nama layanan di sini"
                  style={{ marginTop: "8px" }}
                />
              </div>
              <div>
                <span className="title">Ringkasan</span>
                <CommonEditor
                  onChange={e => onChangeText(e)}
                  text={conclusion}
                  placeholder="Masukkan ringkasan Anda di sini..."
                />
              </div>
              <span className="info">
                Masukkan informasi dari pengetahuan layanan yang dapat
                dipelajari oleh RISA AI.
              </span>
            </div>
            <div className="section-button-action">
              <Button
                color="primary"
                className="btn-edit-product-recommend"
                outline
                onClick={() => history.goBack()}
              >
                Batal
              </Button>
              <Button
                color="primary"
                disabled={!isFieldComplete}
                className="btn-edit-product-recommend"
                onClick={() => goToAddQuestion(selected)}
              >
                Lanjut
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AddServiceKnowledge;
