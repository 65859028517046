import {
  GET_TICKET_COMMENTS,
  GET_TICKET_COMMENTS_FAIL,
  GET_TICKET_COMMENTS_SUCCESS,
  ADD_NEW_TICKET_COMMENT,
  ADD_TICKET_COMMENT_SUCCESS,
  ADD_TICKET_COMMENT_FAIL,
  UPDATE_TICKET_COMMENT,
  UPDATE_TICKET_COMMENT_SUCCESS,
  UPDATE_TICKET_COMMENT_FAIL,
  DELETE_TICKET_COMMENT,
  DELETE_TICKET_COMMENT_SUCCESS,
  DELETE_TICKET_COMMENT_FAIL,
} from "./actionTypes"

export const getTicketComments = (ticketId, page = 1, limit = 10) => ({
  type: GET_TICKET_COMMENTS,
  payload: {
    ticketId, page, limit
  }
})

export const getTicketCommentsSuccess = payload => ({
  type: GET_TICKET_COMMENTS_SUCCESS,
  payload,
})

export const getTicketCommentsFail = error => ({
  type: GET_TICKET_COMMENTS_FAIL,
  payload: error,
})

export const addNewTicketComment = comment => ({
  type: ADD_NEW_TICKET_COMMENT,
  payload: comment,
})

export const addTicketCommentSuccess = comment => ({
  type: ADD_TICKET_COMMENT_SUCCESS,
  payload: comment,
})

export const addTicketCommentFail = error => ({
  type: ADD_TICKET_COMMENT_FAIL,
  payload: error,
})

export const updateTicketComment = (id, comment) => ({
  type: UPDATE_TICKET_COMMENT,
  payload: {id, comment},
})

export const updateTicketCommentSuccess = comment => ({
  type: UPDATE_TICKET_COMMENT_SUCCESS,
  payload: comment,
})

export const updateTicketCommentFail = error => ({
  type: UPDATE_TICKET_COMMENT_FAIL,
  payload: error,
})

export const deleteTicketComment = commentId => ({
  type: DELETE_TICKET_COMMENT,
  payload: commentId,
})

export const deleteTicketCommentSuccess = commentId => ({
  type: DELETE_TICKET_COMMENT_SUCCESS,
  payload: commentId,
})

export const deleteTicketCommentFail = error => ({
  type: DELETE_TICKET_COMMENT_FAIL,
  payload: error,
})
