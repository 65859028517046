import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Container, Row, Col, Spinner } from "reactstrap"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card
import CardShop from "./CardShop"
import { getShop } from "store/shop/actions"

class EcommerceShops extends Component {
  componentDidMount() {
    const { onGetShops } = this.props
    onGetShops()
  }

  render() {
    const { shops, isRequested } = this.props

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Ecommerce" breadcrumbItem="Shops" />
            {isRequested &&
              <div className="text-center">
                <Spinner type="grow" color="primary" />
              </div>
            }
            <Row>
              {map(shops, (shop, key) => (
                <CardShop shop={shop} key={"_shop_" + key} />
              ))}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EcommerceShops.propTypes = {
  shops: PropTypes.array,
  onGetShops: PropTypes.func,
}

const mapStateToProps = ({ Shop }) => ({
  shops: Shop.shops,
  isRequested: Shop.fetch.isRequested
})

const mapDispatchToProps = dispatch => ({
  onGetShops: () => dispatch(getShop()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceShops)
