import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { put } from "network/http/api";
import CommonEditor from "components/Common/CommonEditor";
import CustomToast from "components/CustomToast";
import { useHistory } from "react-router-dom";
import {
  EllipsisHorizontalIcon,
  TrashIcon,
  PlusIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import { Button, Spinner } from "reactstrap";
import { PencilIcon } from "@heroicons/react/24/outline";
import BackButton from "components/BackButton";
import ModalAddQuestionAnswer from "components/KnowledgeServer/ModalAddQuestionAnswer";

const HeaderSectionDetail = ({
  id,
  company_id,
  title,
  category,
  content,
  openModalDelete,
}) => {
  const history = useHistory();
  const modalRef = useRef(null);
  const [showAction, setShowAction] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [triggerEdit, setTriggerEdit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (triggerEdit) {
      setEditTitle(editTitle);
      setConclusion(conclusion);
    } else {
      setEditTitle(title);
      setConclusion(content);
    }
  }, [title, content, triggerEdit]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAction(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onEditServiceKnowledge = () => {
    setLoadingEdit(true);
    const payload = {
      title: editTitle,
      category: category ? category : "",
      content: conclusion,
      company_id: company_id,
      summary: "",
      vector_id: "",
    };

    put(`/knowledge-base/${id}`, payload)
      .then(async resp => {
        toast.custom(t => (
          <CustomToast
            type="success"
            title="Edit pengetahuan layanan berhasil"
            onClose={() => toast.dismiss(t.id)}
            icon={<CheckCircleIcon />}
          />
        ));
        setLoadingEdit(false);
        setShowEdit(false);
        setTriggerEdit(true);
      })
      .catch(err => {
        setLoadingEdit(false);
      });
  };

  const openModalAction = () => {
    setShowAction(!showAction);
  };

  const handleOnAdded = () => {
    window.location.reload();
  };

  const onShowEdit = () => {
    setShowEdit(true);
    setShowAction(false);
  };

  const goToAddQuestion = () => {
    history.push({
      pathname: `/add-service-knowledge-question/${id}`,
      state: { fromPage: "detail", pageId: id },
    });
  };

  const onChangeText = (value, setterFunction) => {
    setterFunction(value);
  };

  return (
    <>
      <div className="header-section-costume">
        <div className="page-header-content">
          <BackButton />
          {showEdit ? (
            <div className="section-edit-title">
              <input
                type="text"
                name="query"
                id="query"
                className="input-edit-title"
                placeholder="Ganti Pengetahuan Layanan"
                value={editTitle}
                onChange={e => onChangeText(e.target.value, setEditTitle)}
              />
            </div>
          ) : (
            <span className="title">{editTitle}</span>
          )}
        </div>
        <div className="page-header-action">
          {category && <span className="category">{category}</span>}
          <Button
            color="primary"
            className="btn-add-service-knowledge"
            onClick={() => setIsOpenModal(true)}
          >
            <PlusIcon className="icon" />
            Tambah Tanya Jawab
          </Button>

          <ModalAddQuestionAnswer
            knowledgeBaseId={id}
            type={"service-knowledge"}
            isOpen={isOpenModal}
            onAdded={() => handleOnAdded()}
            toggle={() => setIsOpenModal(!isOpenModal)}
          />

          <div className="card-action" ref={modalRef}>
            <div className="card-action-btn" onClick={() => openModalAction()}>
              <EllipsisHorizontalIcon className="icon" />
            </div>
            {showAction && (
              <div className="card-action-modal">
                <div
                  className="btn-icon-action btn-edit"
                  onClick={() => onShowEdit()}
                >
                  <PencilIcon className="icon" /> Edit
                </div>
                <div
                  className="btn-icon-action btn-delete"
                  onClick={() => openModalDelete("delete-knowledge")}
                >
                  <TrashIcon /> Delete
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="page-header-action-mobile">
          <div
            className="btn-icon-action btn-edit"
            onClick={() => onShowEdit()}
          >
            <PencilIcon className="icon" /> Edit
          </div>
          <div
            className="btn-icon-action btn-delete"
            onClick={() => openModalDelete("delete-knowledge")}
          >
            <TrashIcon /> Delete
          </div>
        </div>
      </div>
      <div className="page-add-service-knowledge">
        <div className="section-question-costume">
          <div>
            <span className="title">Ringkasan</span>
            {showEdit ? (
              <>
                <CommonEditor
                  onChange={e => onChangeText(e, setConclusion)}
                  text={conclusion}
                />
                <div className="section-button-action">
                  <Button
                    color="primary"
                    className="btn-edit-product-recommend"
                    outline
                    onClick={() => setShowEdit(false)}
                  >
                    Batal
                  </Button>
                  <Button
                    color="primary"
                    className="btn-edit-product-recommend"
                    onClick={() => onEditServiceKnowledge()}
                    disabled={loadingEdit}
                  >
                    {loadingEdit ? (
                      <Spinner color="light" size="sm" />
                    ) : (
                      "Simpan"
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <div className="conclusion">
                <span
                  dangerouslySetInnerHTML={{
                    __html: conclusion,
                  }}
                />
              </div>
            )}
          </div>
          <Button
            color="primary"
            className="btn-add-service-knowledge-mobile"
            onClick={() => setIsOpenModal(true)}
          >
            <PlusIcon className="icon" />
            Tambah Tanya Jawab
          </Button>
        </div>
      </div>
    </>
  );
};

export default HeaderSectionDetail;
