import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Label, Spinner, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";

// actions
import { apiError, loginUser } from "../../store/actions";

// import images
import SectionImage from "./SectionImage";
import SectionMobileLogo from "./SectionMobileLogo";

// component password
import PasswordShowHide from "components/PasswordShowHide";
import Auth0Vendor from "./Auth0Vendor";

import { LoginSchema } from "./SchemaValidation";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading, error } = this.props;

    return (
      <React.Fragment>
        <div className="auth-page">
          <div className="section-auth-full">
            <SectionImage />
            <div className="section-content">
              <SectionMobileLogo />
              <div className="content">
                <div className="content-auth">
                  <h3 className="text-center title-text">Masuk</h3>
                  <p className="text-center subtitle-text">
                    Mulai otomatisasi dan tingkatkan layanan pelanggan
                  </p>
                  <div>
                    <div className="error-msg">
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}
                    </div>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={LoginSchema}
                      onSubmit={values => {
                        this.props.loginUser(values, this.props.history);
                      }}
                    >
                      {({ errors, status, touched, isValid }) => (
                        <Form className="form-auth">
                          <div className="mb-3">
                            <Label for="email" className="form-label">
                              Email
                            </Label>
                            <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              style={{ height: "45px" }}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-2">
                            <Label for="password" className="form-label">
                              Kata Sandi
                            </Label>
                            <Field
                              name="password"
                              type="password"
                              autoComplete="true"
                              component={PasswordShowHide}
                              styleClass={
                                errors.password && touched.password
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            {errors.password && (
                              <ErrorMessage
                                name="password"
                                component="div"
                                className={`invalid-feedback ${
                                  errors.password ? "d-block" : ""
                                }`}
                              >
                                {errors.password.message}
                              </ErrorMessage>
                            )}
                          </div>
                          <Link to="/forgot-password" className="text-muted">
                            <span className="text-primary">
                              Lupa kata sandi?
                            </span>
                          </Link>

                          {loading && (
                            <div className="mt-3 d-grid">
                              <div className="text-center">
                                <Spinner color="primary" type="grow" />
                              </div>
                            </div>
                          )}

                          <div className="mt-3 d-grid section-button">
                            <Button
                              type="submit"
                              color="primary"
                              disabled={!isValid || loading}
                            >
                              Log In
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="position-relative my-4">
                    <div
                      className="position-absolute d-flex align-items-center"
                      aria-hidden="true"
                      style={{ inset: "0px" }}
                    >
                      <div
                        style={{
                          border: "1px solid #E1E2E4",
                          width: "80%",
                          margin: "auto",
                        }}
                      ></div>
                    </div>
                    <div className="position-relative d-flex justify-content-center">
                      <span
                        className="bg-white px-2 text-sm"
                        style={{ color: "#E1E2E4" }}
                      >
                        Atau
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Auth0Vendor title={"Login"} />
                  </div>
                  <div className="mt-5 text-center">
                    <p>
                      Belum punya akun?{" "}
                      <Link to="register" className="fw-medium text-primary">
                        Daftar di sini
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);
