import React, { useState } from "react";
import HeaderSection from "./ComponentsPage/HeaderSection";
import { Button, Spinner } from "reactstrap";
import { ChangePasswordSchema } from "./SchemaValidation";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { editProfile } from "store/actions";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { post } from "network/http/api";
import PasswordShowHide from "components/PasswordShowHide";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";

const Password = ({ notificationSuccess, notificationError }) => {
  const [isLoading, setLoading] = useState(false);

  const handleOnSubmit = (values, actions) => {
    setLoading(true);

    const data = {
      old_password: values.old_password,
      password: values.password,
      password_confirmation: values.confirm_password,
    };

    post(`/user/change-password`, data)
      .then(resp => {
        notificationSuccess("Password berhasil diubah!");
        setLoading(false);
        actions.resetForm();
      })
      .catch(error => {
        notificationError(error.detail);
        setLoading(false);
      });
  };

  return (
    <div className="settings-content">
      <HeaderSection
        title="Password"
        subtitle="Anda dapat mengubah akses password akun Anda. Pastikan Anda memasukkan password lama dan baru sesuai ketentuan."
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          old_password: "",
          password: "",
          confirm_password: "",
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, actions) => {
          handleOnSubmit(values, actions);
        }}
      >
        {({ isValid, errors, touched }) => (
          <Form className="form-integration-settings">
            <div className="integration-settings">
              <div className="field-form">
                <span className="title">Kata Sandi Lama</span>
                <Field
                  name="old_password"
                  type="password"
                  autoComplete="true"
                  component={PasswordShowHide}
                  styleClass={`${
                    errors.old_password && touched.old_password
                      ? "is-invalid"
                      : ""
                  } small-input-password`}
                />
                {errors.old_password && (
                  <ErrorMessage
                    name="old_password"
                    component="div"
                    className={`invalid-feedback ${
                      errors.old_password ? "d-block" : ""
                    }`}
                  >
                    {errors.old_password.message}
                  </ErrorMessage>
                )}
              </div>
              <div className="field-form">
                <span className="title">Kata Sandi</span>
                <Field
                  name="password"
                  type="password"
                  autoComplete="true"
                  component={PasswordShowHide}
                  styleClass={`${
                    errors.password && touched.password ? "is-invalid" : ""
                  } small-input-password`}
                />
                {errors.password && (
                  <ErrorMessage
                    name="password"
                    component="div"
                    className={`invalid-feedback ${
                      errors.password ? "d-block" : ""
                    }`}
                  >
                    {errors.password.message}
                  </ErrorMessage>
                )}
              </div>
              <div className="field-form">
                <span className="title">Konfirmasi Kata Sandi</span>
                <Field
                  name="confirm_password"
                  type="confirm_password"
                  autoComplete="true"
                  component={PasswordShowHide}
                  styleClass={`${
                    errors.confirm_password && touched.confirm_password
                      ? "is-invalid"
                      : ""
                  } small-input-password`}
                />
                {errors.confirm_password && (
                  <ErrorMessage
                    name="confirm_password"
                    component="div"
                    className={`invalid-feedback ${
                      errors.confirm_password ? "d-block" : ""
                    }`}
                  >
                    {errors.confirm_password.message}
                  </ErrorMessage>
                )}
              </div>
            </div>
            <div className="field-btn">
              <Button type="submit" color="primary" disabled={!isValid}>
                {isLoading ? <Spinner color="light" size="sm" /> : "Simpan"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ Profile }) => {
  return {
    isLoading: Profile.isLoading,
    error: Profile.error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onEditProfile: editProfile,
    notificationSuccess: notificationSuccessRequestAction,
    notificationError: notificationErrorRequestAction,
  })(Password)
);
