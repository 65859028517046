import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { useFormik } from "formik";
import { get } from "network/http/api";
import BackButton from "../ComponentsPage/BackButton";
import CostumInputField from "../ComponentsPage/CostumInputField";

const IntegrationWhatsappDetail = () => {
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    company_name: "",
    company_website_url: "",
    company_country_name: "",
    sid_twilio: "",
    id_business_manager_meta: "",
    use_case: "",
    message_recipient_country_name: "",
    first_year_monthly_message_count: "",
  });
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      company_name: "",
      company_website_url: "",
      company_country_name: "",
      sid_twilio: "",
      id_business_manager_meta: "",
      use_case: "",
      message_recipient_country_name: "",
      first_year_monthly_message_count: "",
      consent_for_twilio_voice_url_change: false,
      privacy_policy_consent: false,
    },
  });

  const fetchDetailWhatsappIntegration = async () => {
    setLoading(true);
    try {
      const res = await get("/integration");
      setData(res.whatsapp);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailWhatsappIntegration();
  }, []);

  const findYearCountMessage = params => {
    const option = [
      { name: "1 - 5.000", value: 5000 },
      { name: "5.000 - 10.000", value: 10000 },
      { name: "10.000 - 50.000", value: 50000 },
      { name: "50.000 - 100.000", value: 100000 },
      { name: "> 100.000", value: 200000 },
    ];
    const res = option.find(item => item.value === params);
    return res.name;
  };

  return (
    <div className="page-content page-integration">
      <div className="page-integration-shop">
        <Container fluid>
          <div className="page-integration-shop-details">
            <div className="header-section">
              <BackButton />
              <span className="title">Integrasi Whatssapp</span>
            </div>
            <div className="page-integration-shop-content">
              <div className="page-integration-shop-form">
                <div className="section-form">
                  <h6 className="subtitle">
                    Silahkan isi form di bawah ini untuk mengajukan akses
                    integrasi anatara Whatssapp Anda dengan sistem RISA AI
                  </h6>
                  <form className="form-integration-shop">
                    <div className="field-form">
                      <span className="title">
                        Email<span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="email"
                        placeholder="Masukkan Email Anda"
                        field={formik?.getFieldProps("email")}
                        form={formik}
                        disabled
                        value={data?.email}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Nama Depan<span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="first_name"
                        placeholder="Masukkan Nama Depan Anda"
                        field={formik?.getFieldProps("first_name")}
                        form={formik}
                        disabled
                        value={data?.first_name}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Nama Belakang<span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="last_name"
                        placeholder="Masukkan Nama Belakang Anda"
                        field={formik?.getFieldProps("last_name")}
                        form={formik}
                        disabled
                        value={data?.last_name}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Nama Legal Perusahaan
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="company_name"
                        placeholder="Masukkan Nama Perusahaan Anda"
                        field={formik?.getFieldProps("company_name")}
                        form={formik}
                        disabled
                        value={data?.company_name}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Website Perusahaan
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="company_website_url"
                        placeholder="Masukkan Url Website Anda"
                        field={formik?.getFieldProps("company_website_url")}
                        form={formik}
                        disabled
                        value={data?.company_website_url}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Lokasi Negara Kantor Pusat Perusahaan
                        <span style={{ color: "#DA2626" }}>**</span>
                      </span>
                      <CostumInputField
                        name="company_country_name"
                        placeholder="Masukkan Url Website Anda"
                        field={formik?.getFieldProps("company_country_name")}
                        form={formik}
                        disabled
                        value={data?.company_country_name}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        ID Twilio<span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="sid_twilio"
                        placeholder="Masukkan ID Twilio Anda"
                        field={formik?.getFieldProps("sid_twilio")}
                        form={formik}
                        disabled
                        value={data?.sid_twilio}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        ID Business Manager Meta/ Facebook
                        <span style={{ color: "#DA2626" }}>*</span>{" "}
                        <span className="title-small">
                          (Bukan ID Facebook Anda)
                        </span>
                      </span>
                      <CostumInputField
                        name="id_business_manager_meta"
                        placeholder="Masukkan ID Business Manager Meta Anda"
                        field={formik?.getFieldProps(
                          "id_business_manager_meta"
                        )}
                        form={formik}
                        disabled
                        value={data?.id_business_manager_meta}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Jelaskan Tujuan Anda menggunakan WhatsApp
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="use_case"
                        placeholder="Masukkan informasi tujuan Anda menggunakan WhatsApp"
                        field={formik?.getFieldProps("use_case")}
                        form={formik}
                        disabled
                        value={data?.use_case}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Negara Mana yang Anda Kirimi Pesan?
                        <span style={{ color: "#DA2626" }}>**</span>
                      </span>
                      <CostumInputField
                        name="message_recipient_country_name"
                        placeholder="Masukkan informasi tujuan Anda menggunakan WhatsApp"
                        field={formik?.getFieldProps(
                          "message_recipient_country_name"
                        )}
                        form={formik}
                        disabled
                        value={data?.message_recipient_country_name}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Berapa banyak pesan yang Anda kirim per bulan selama
                        tahun pertama?
                        <span style={{ color: "#DA2626" }}>**</span>
                      </span>
                      <CostumInputField
                        name="first_year_monthly_message_count"
                        placeholder="Pilih Banyak Pesan yang Anda Kirim Selama Tahun Pertama"
                        field={formik?.getFieldProps(
                          "first_year_monthly_message_count"
                        )}
                        form={formik}
                        disabled
                        value={
                          data?.first_year_monthly_message_count
                            ? findYearCountMessage(
                                data?.first_year_monthly_message_count
                              )
                            : "-"
                        }
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(IntegrationWhatsappDetail);
