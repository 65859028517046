const { get, put } = require("network/http/api")

export const getAgentIntent = () => {
  return get(`/agent/me`)
}

export const getAgentIntentDetail = (agentId) => {
  return get(`/agent/${agentId}`)
}

export const updateAgentIntent = (agent) => {
  return put("/agent/"+agent.id, agent)
}
