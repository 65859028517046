import React from 'react'
import { Alert } from "reactstrap"

const ShopeeIntegrationInfo = (props) => {
  return (
    <Alert typ="info">
      <h5>Tips</h5>
      <p>
        Klik untuk memulai sinkronisasi dan Anda akan dibawa ke halaman Shopee untuk masuk dan melakukan otorisasi.
      </p>
      <p></p>
      <p>
        Setelah otorisasi berhasil Anda dapat mengelola chat dari toko Anda di Risa
      </p>

    </Alert>
  )
}

export default ShopeeIntegrationInfo
