import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";

import { Container, Alert, Button } from "reactstrap";
import { getInvoices, setFilterRating } from "store/actions";
import { get, post } from "network/http/api";

import TabelSection from "./ComponentsPage/TableReview";
import CustomPagination from "components/CustomPagination";
import ModalFilter from "./ComponentsPage/ModalFilter";
import moment from "moment";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReviewList = ({ setFilterRating, filter }) => {
  const [modal, setModal] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [paramsPage, setParamsPage] = useState({
    perPage: 15,
    page: 1,
  });

  const [reviewListData, setReviewListData] = useState([]);
  let query = useQuery();

  const toggle = () => {
    setModal(!modal);
  };

  const fetchListReviewers = async () => {
    try {
      let params = `page=${paramsPage.page}&&per_page=${paramsPage.perPage}`;
      let url = `/conversation/reviewers`;
      if (query !== "") {
        url = `${url}/search?q=${query}`;
      } else {
        url = `${url}?${params}`;
      }

      const res = await post(`/conversation/reviewers`, {
        page: paramsPage.page,
        per_page: paramsPage.perPage,
        ...filter,
      });

      setReviewListData(res.reviews);
      setErrorFetch(null);
      const totalPage = res.total_item
        ? Math.ceil(res.total_item / paramsPage.perPage)
        : 0;
      setTotalPage(totalPage);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchListReviewers();
  }, [paramsPage, filter]);

  const handleExportCSV = async () => {
    try {
      post(`/conversation/reviewers/csv`, {
        ...filter,
      }).then(response => {
        const csvData = new Blob([response], { type: "text/csv" });
        const link = document.createElement("a");
        const href = URL.createObjectURL(csvData);

        const fileName = `rating_${moment(new Date()).format(
          "MM-DD-YYYY-H-m"
        )}.csv`;

        link.href = href;

        if (!link.href) return;
        link.download = fileName;
        link.click();
        link.remove();
      });
    } catch (err) {
      setErrorFetch(err.detail);
      setTimeout(() => {
        setErrorFetch(null);
      }, 1000);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="section-rating">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "32px",
              paddingBottom: "unset",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
              }}
            >
              <p className="title-rating-page">Hasil Feedback Kinerja Admin</p>
              <p className="rating-regular">
                Berikut adalah hasil rekap data informasi dari jawaban pelanggan
                Anda. Data yang ditampilkan akan disesuaikan skenario percakapan
                yang telah ditetapkan oleh Anda.{" "}
              </p>
            </div>

            <Button
              color="primary"
              onClick={handleExportCSV}
              outline
              style={{
                height: "fit-content",
              }}
            >
              Export ke CSV
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              padding: "32px",
              paddingBottom: "unset",
            }}
          >
            <Button
              color="primary"
              onClick={toggle}
              outline
              style={{
                height: "fit-content",
              }}
            >
              Filter Applied
            </Button>
          </div>
          <TabelSection data={reviewListData} />
          <div
            style={{
              padding: "32px",
              paddingTop: "unset",
            }}
          >
            <CustomPagination
              paramsPage={paramsPage}
              setParamsPage={setParamsPage}
              amountPage={totalPage}
            />
          </div>

          <ModalFilter
            isOpen={modal}
            toggle={toggle}
            filter={filter}
            setFilter={setFilterRating}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Ratings }) => ({
  filter: Ratings.filter,
});

const mapDispatchToProps = {
  setFilterRating: setFilterRating,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReviewList));
