import { post } from "network/http/api";
import React from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

const ModalAgentScenario = ({ isOpen, toggle, history, selectedPlatform, onSelected }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [scenario, setScenario] = useState("customer_service");


  const handleOnSubmit = () => {
    if (isLoading) {
      return;
    }

    const { platform_name } = selectedPlatform;
    const data = {
      scenario: scenario,
      test_version: false,
      platform_name: platform_name,
    };
    setIsLoading(true);

    post("/scenario/from-template", data)
      .then(resp => {
        setIsLoading(false);
        setIsError(false);
        history.push("/ai-builder/" + platform_name);
        // window.location.reload();
        toggle(false);
        onSelected()
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
        setIsError(false);
      });
  };

  return (
    <Modal className="modal-custom-scenario" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        Pilih Mode Custom Scenario
      </ModalHeader>
      <ModalBody>
        Pilihan Anda akan menentukan gaya menjawab RISA AI secara otomatis. Silakan pilih sesuai kebutuhan Anda.

        <Input
          className="mb-3 mt-3"
          type="select"
          defaultValue={0}
        >
          <option disabled value={0}>
            -- Cari dan pilih --
          </option>
          <option value="customer_service">Customer Service</option>

        </Input>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={toggle}>Batal</Button>
        <Button color="primary" onClick={handleOnSubmit}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(ModalAgentScenario);
