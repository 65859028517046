import React from "react";
import { Switch } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";

const SwitchCostume = ({ enabled, setEnabled, tooltipText }) => {
  return (
    <>
      {tooltipText ? (
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-content={tooltipText ? tooltipText : "-"}
          data-tooltip-place="bottom"
        >
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${enabled ? "bg-active" : "bg-inactive"} switch`}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${enabled ? "active" : "inactive"} switch-toggle`}
            />
            <CheckIcon
              className={`${enabled ? "active" : "inactive"} check-icon icon`}
            />
          </Switch>
        </a>
      ) : (
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${enabled ? "bg-active" : "bg-inactive"} switch`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${enabled ? "active" : "inactive"} switch-toggle`}
          />
          <CheckIcon
            className={`${enabled ? "active" : "inactive"} check-icon icon`}
          />
        </Switch>
      )}
      <Tooltip
        id="my-tooltip"
        style={{
          maxWidth: "250px",
          width: "100%",
          backgroundColor: "#323232",
          borderRadius: "8px",
          padding: "16px",
          fontFamily: '"Poppins", sans-serif',
          fontSize: "12px",
        }}
      />
    </>
  );
};

export default SwitchCostume;
