import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Input,
} from "reactstrap";
import { searchProduct } from 'store/product/actions'

const SearchProduct = ({ onSearchProduct, productIsFetching }) => {
  const [keyword, setKeyword] = useState('')

  const handleOnEnter = (evt) => {
    if (evt.keyCode !== 13) {
      return
    }

    if (productIsFetching) {
      return
    }

    onSearchProduct(keyword)
  }

  return (
    <div className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
      <div className="search-box me-2">
        <div className="position-relative">
          <Input
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={handleOnEnter}
            value={keyword}
            type="text"
            className="form-control border-0"
            placeholder="Search..."
          />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ Product }) => ({
  productIsFetching: Product.productIsFetching
})

export default connect(
  mapStateToProps,
  {
    onSearchProduct: searchProduct
  }
)(SearchProduct)
