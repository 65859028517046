import React, { useEffect, useState } from "react";
import InputCopy from "./ComponentsPage/InputCopy";
import HeaderSection from "./ComponentsPage/HeaderSection";
import { get } from "network/http/api";



const DisplayApiKey = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [clientId, setClientId] = useState('')
    const [secret, setSecret] = useState('')

    useEffect(() => {
        handleGetApiKey()
    }, [])

    const handleGetApiKey = () => {
        setIsLoading(true)
        get('/api-key')
            .then(resp => {
                const { is_active, secret, client_id } = resp

                setSecret(secret)
                setClientId(client_id)
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
            })
    }

    return (
        <div className="settings-content">
            <HeaderSection
                title="API Key"
                subtitle="Api key adalah kunci akses yang memungkinkan pengguna terhubung dan menggunakan layanan live chat pada website Anda."
            />
            <div className="input-wrapper">
                <InputCopy id={"api-client-id"} text={clientId} label={"Client ID"} />
                <InputCopy id={"api-secret-id"} text={secret} label={"Secret"} />
            </div>
        </div>
    )
}

export default DisplayApiKey