import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import SwitchCostume from "components/SwitchCostume";
import OperationScheduleDropdownCheckbox from "./OperationScheduleDropdownCheckbox";
import { operationDay, operationHour } from "./DefaultData";
import { put } from "network/http/api";
import { useDispatch } from "react-redux";
import { notificationErrorRequestAction } from "store/notification/actions";
import { debounce } from "lodash";

const OperationScheduleCard = ({ item, onUpdateSchedule }) => {
  const dispatch = useDispatch();
  const [activeSchedule, setActiveSchedule] = useState(
    item.operation_hours.is_active
  );
  const [selectedDay, setSelectedDay] = useState([]);
  const [selectedHour, setSelectedHour] = useState([]);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingDay, setLoadingDay] = useState(false);
  const [loadingHour, setLoadingHour] = useState(false);
  const [payload, setPayload] = useState({
    company_id: item.operation_hours.company_id,
    platform_id: item.operation_hours.platform_id,
    is_active: item.operation_hours.is_active,
    selected_days: item.operation_hours.selected_days,
    schedule_hours: item.operation_hours.schedule_hours,
    start_schedule_hour: item.operation_hours.start_schedule_hour,
    end_schedule_hour: item.operation_hours.end_schedule_hour,
  });

  useEffect(() => {
    if (item.operation_hours.selected_days) {
      if (item.operation_hours.selected_days === "all") {
        setSelectedDay(operationDay);
      } else if (item.operation_hours.selected_days.length) {
        const res = item.operation_hours.selected_days
          .split(",")
          .map(day => {
            return operationDay.find(operation => operation.value === day);
          })
          .filter(Boolean);
        setSelectedDay(res);
      }
    }

    if (item.operation_hours.schedule_hours) {
      if (item.operation_hours.schedule_hours === "all") {
        setSelectedHour(operationHour);
      } else if (item.operation_hours.schedule_hours.length) {
        const res = item.operation_hours.schedule_hours
          .split(",")
          .map(day => {
            return operationHour.find(operation => operation.value === day);
          })
          .filter(Boolean);
        setSelectedHour(res);
      }
    }
  }, [item]);

  const handleOnSelectedDay = debounce(day => {
    setLoadingDay(true);
    const res =
      day.length !== operationDay.length
        ? day.map(item => item.value).join(",")
        : "all";
    onUpdateAgentSchedule(
      {
        ...payload,
        selected_days: res,
      },
      setLoadingDay
    );
    setSelectedDay(day);
  }, 500);

  const handleOnSelectedHour = debounce(hour => {
    setLoadingHour(true);
    const res =
      hour.length !== operationHour.length
        ? hour.map(item => item.value).join(",")
        : "all";
    onUpdateAgentSchedule(
      {
        ...payload,
        schedule_hours: res,
      },
      setLoadingHour
    );
    setSelectedHour(hour);
  }, 500);

  const onOpenModalToggle = () => {
    setLoadingActive(true);
    onUpdateAgentSchedule(
      {
        ...payload,
        is_active: !activeSchedule,
      },
      setLoadingActive
    );
    setActiveSchedule(!activeSchedule);
  };

  const onUpdateAgentSchedule = (paramsPayload, setLoading) => {
    put(`/agent-operation-hour/${item.operation_hours.id}`, paramsPayload)
      .then(res => {
        setPayload(paramsPayload);
        onUpdateSchedule();
      })
      .catch(err => {
        dispatch(notificationErrorRequestAction(err.detail));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="content">
      <span className="title-content">{item.platform_name}</span>
      {loadingActive && (
        <div className="loading-switch">
          <Spinner color="primary" size="sm" />
        </div>
      )}
      <div style={{ display: `${loadingActive ? "none" : "flex"}` }}>
        <SwitchCostume
          enabled={activeSchedule}
          setEnabled={onOpenModalToggle}
        />
      </div>
      <div className="dropdown-content">
        <OperationScheduleDropdownCheckbox
          list={operationDay}
          selectedItems={selectedDay}
          setSelectedItems={handleOnSelectedDay}
          type="day"
          selectAllItems="Setiap Hari"
          loading={loadingDay}
        />
        <OperationScheduleDropdownCheckbox
          list={operationHour}
          selectedItems={selectedHour}
          setSelectedItems={handleOnSelectedHour}
          type="hour"
          selectAllItems="24 Jam"
          loading={loadingHour}
        />
      </div>
    </div>
  );
};

export default OperationScheduleCard;
