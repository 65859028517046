import React, { Component } from "react"
import {
  Button,
  Col,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import images
import bukalapak_logo from 'assets/images/bukalapak-logo.png';
import tokopedia_logo from 'assets/images/tokopedia-logo.png';
import shopee_logo from 'assets/images/shopee-logo.png';

// actions
import {
  getPlatformRequest
} from 'store/platform/actions'
import { getShop } from 'store/shop/actions'
import NewChannelModal from "./NewChannelModal/NewChannelModal";
import CustomizeTable from "components/CustomizeTable";
import { get } from "network/http/api";
import moment from "moment";

const columns = [
  {
    text: "Nama Toko",
    dataField: "store_name",
    formatter: (v, data) => {
      return <Link to={`/store/${data.id}`}>{v}</Link>
    }
  },
  {
    text: "Platform",
    formatter: (_, v) => {
      if (!v.platform) {
        return ''
      }
      return v.platform.platform_name
    }
  },
  {
    text: "Status",
    formatter: (_, v) => {
      return v.authorize_status
    }
  },
  {
    text: "Url",
    formatter: (_, v) => {
      return <a href={v.store_url} rel="noreferrer" target="_blank">{v.store_url}</a>
    }
  },
  {
    text: "Ditambahkan",
    formatter: (_, v) => {
      return moment(v.created_at).format(
        "DD MMM YYYY"
      )
    }
  },
]

class IntegrationChannel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      stores: [],
      page: 1,
      isLoading: false,
      totalPage: 0,
      pagePerSize: 10,
    }
  }

  get_image(platform_name) {
    switch (platform_name) {
      case 'tokopedia':
        return tokopedia_logo
      case 'bukalapak':
        return bukalapak_logo
      case 'shopee':
        return shopee_logo;
      default:
        break;
    }
  }

  componentDidMount() {
    const { getShop } = this.props;
    // getPlatformRequest()
    // getShop()
    this.fetchStore()
  }


  handleOnOpenModal = (platform) => {
    this.setState({
      platform,
      isOpen: true
    })
  }

  handleOnCloseModal = () => {
    this.setState({
      isOpen: false
    })
  }

  handleOnToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  handleOnChangePage = (page) => {
    this.setState({ page: page },
      this.fetchStore())
  }

  fetchStore = () => {
    this.setState({ isLoading: true })
    get(`/store/me?category=retail`)
      .then(resp => {
        this.setState({
          isLoading: false,
          stores: resp.stores,
          total: resp.total,
          totalPage: resp.total / this.state.pagePerSize
        })
      })
      .catch(e => {
        console.error('error', e)
        this.setState({
          isLoading: false
        })
      })
  }

  render() {
    const { isOpen, platform, stores, isLoading, page, pagePerSize, totalPage } = this.state;
    return (
      <React.Fragment>
        <NewChannelModal
          toggleModal={this.handleOnToggle}
          isOpen={isOpen}
          platform={
            { 'platform_name': 'Tokopedia' }
          } />

        <Button color="primary" onClick={this.handleOnToggle}>Tambahkan</Button>

        <Col xl="12">
          <CustomizeTable
            onPageChange={this.handleOnChangePage}
            loading={isLoading}
            page={page}
            totalPage={totalPage}
            columns={columns}
            data={stores}
            pagePerSize={pagePerSize}
          />
        </Col>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { Shop } = state;
  return {
    register: Shop.register,
    isRequested: Shop.fetch.isRequested,
    shops: Shop.shops,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    getPlatformRequest,
    getShop,
  }
)(IntegrationChannel));
