import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { menuList } from "../VerticalLayout/DefaultData";
import SideBarCostumeScenarioContent from "./SideBarCostumeScenarioContent";

import logoRisa from "../../assets/images/image2vector.svg";
import logoRisaSmall from "assets/images/sidebar/risa_logo.svg";

const SidebarCostumScenario = props => {
  const { showLargeSidebar, handleMouseEnter, handleMouseLeave } = props;
  const [submenuOpenStates, setSubmenuOpenStates] = useState(
    Array(menuList.length).fill(false)
  );

  const handleToggleSubmenu = index => {
    setSubmenuOpenStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <React.Fragment>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <SideBarCostumeScenarioContent
          submenuOpenStates={submenuOpenStates}
          showLargeSidebar={showLargeSidebar}
          handleToggleSubmenu={handleToggleSubmenu}
        />
      </div>
    </React.Fragment>
  );
};

SidebarCostumScenario.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(SidebarCostumScenario)));
