import { takeEvery, put, call } from "redux-saga/effects"

// Redux States
import * as actions from "./actions"
import * as api from './api'
import { GET_COMPANY_REQUEST } from "./actionTypes"

function* onGetCompany(){
  try{
    const response = yield call(api.getCompany)

    yield put(actions.getCompanySuccess(response))
  } catch(error) {
    console.log(error)
    actions.getCompanyFailed(error)
  }
}

function* companySaga() {
  yield takeEvery(GET_COMPANY_REQUEST, onGetCompany)
}

export default companySaga
