import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import HeaderSectionDetail from "./ComponentsPage/HeaderSectionDetail";
import LoadingDetail from "./ComponentsPage/LoadingDetail";
import ListQuestionAnswer from "./ComponentsPage/ListQuestionAnswer";
import { get } from "network/http/api";
import { Container } from "reactstrap";
import Question from "components/KnowledgeServer/Question";
import ModalDelete from "./ComponentsPage/ModalDelete";
import { connect } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";

const DetailServiceKnowledgeQuestion = ({ history }) => {
  const { id } = useParams();
  const [knowledge, setKnowledge] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingEditKnowledge, setLoadingEditKnowledge] = useState(false);
  const [typeModalDetele, setTypeModalDelete] = useState("delete-knowledge");
  const [showModalDelete, setShowModalDelete] = useState(false);

  const fetchServiceKnowledgeDetails = async paramsId => {
    setLoadingPage(true);
    try {
      const res = await get(`/knowledge-base/${paramsId}`);
      setKnowledge(res.content);
      setQuestions(res.question_answer);
      setLoadingPage(false);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchServiceKnowledgeDetails(id);
  }, [id]);

  const openModalDelete = paramsDel => {
    setTypeModalDelete(paramsDel);
    setShowModalDelete(!showModalDelete);
  };

  if (loadingPage) {
    return <LoadingDetail />;
  }

  return (
    <div className="page-content">
      <div className="page-ecommerce-details">
        <Container fluid>
          <HeaderSectionDetail
            id={id}
            company_id={knowledge?.company_id}
            title={knowledge?.title}
            category={knowledge?.category}
            content={knowledge?.content}
            loadingEditKnowledge={loadingEditKnowledge}
            setLoadingEditKnowledge={setLoadingEditKnowledge}
            openModalDelete={openModalDelete}
          />
          <div className="page-add-service-knowledge">
            <Question
              disableAddQuestion={true}
              type="knowlege-service"
              knowledgeBaseId={id}
            />
          </div>
        </Container>

        {showModalDelete && (
          <ModalDelete
            open={showModalDelete}
            knowledgeId={id}
            goBackAfterDelete
            type="delete-knowledge"
            toggleModalDelete={openModalDelete}
            setTriggerFetchList={() => history.push("/service-knowledge")}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ Product }) => ({
  product: Product.product,
  isFetching: Product.productIsFetching,
});

const mapDispatchToProps = dispatch => ({
  onGetProductDetail: id => dispatch(getProductDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailServiceKnowledgeQuestion));
