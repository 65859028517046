import {
  GET_PLATFORM_REQUEST,
  GET_PLATFORM_SUCCESS,
  GET_PLATFORM_FAILED,
} from "./actionTypes"

export const getPlatformRequest = () => ({
  type: GET_PLATFORM_REQUEST,
})

export const getPlatformSuccess = payload => ({
  type: GET_PLATFORM_SUCCESS,
  payload,
})

export const getPlatformFailed = payload => ({
  type: GET_PLATFORM_FAILED,
  payload,
})
