import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux';
import TextEditor from '../TextEditor/TextEditor'
import ErrorMessageInput from '../ConfigNode/ErrorMessageInput';

// actions
import { nodeUpdateVariable } from 'store/agent-scenario-builder/actions'
import { notificationErrorRequestAction, notificationSuccessRequestAction } from 'store/notification/actions'
import { put } from 'network/http/api';


const MessageScenarioConfigInput = ({ scenario, notificationSuccess, notificationError }) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [lastMessageText, setLastMessageText] = useState(scenario.last_message_text)

    useEffect(() => {
        if (!scenario || !scenario.last_message_text) {
            return
        }
        setLastMessageText(scenario.last_message_text)
    }, [scenario])

    const handleOnChangeQuestion = (e) => {
        setLastMessageText(e)
    }

    const handleOnSave = () => {
        setIsLoading(true)

        const data = {
            last_message_text: lastMessageText
        }
        put("/scenario/" + scenario.id, data)
            .then(resp => {
                notificationSuccess('perubahaan berhasil disimpan')
                setIsLoading(false)
                setErrorMessage(null)
            })
            .catch(err => {
                setIsLoading(false)
                notificationError(err.detail)
            })

    }

    return (
        <div className='ask-question-config'>
            <Card>
                <CardBody>
                    <p className='title'>Masukkan pesan Anda di sini</p>
                    <div className='input'>
                        {scenario.id &&
                            <TextEditor
                                id={scenario.id}
                                text={lastMessageText}
                                onChange={(e) => handleOnChangeQuestion(e)}
                            />
                        }
                    </div>
                    <p className='subtitle'>
                        Pesan ini akan muncul jika pengguna tidak aktif selama 15 menit.
                        <br />
                        <br />
                        Jika tidak ada respons dalam 30 menit berikutnya, RISA akan secara otomatis menutup percakapan.
                    </p>
                </CardBody>
                <Button disabled={isLoading} onClick={handleOnSave} color='primary'>
                    Simpan
                </Button>
            </Card>
            {/* <ErrorMessageInput
                nodeId={node.id}
                data={variables[0].question}
                errorMessage="Mohon berikan isian Anda terlebih dahulu" /> */}


        </div>
    )

}

const mapStateToProps = (state) => ({
    scenario: state.AgentScenarioBuilder.scenario
})

export default connect(mapStateToProps, {
    nodeUpdateVariable,
    notificationError: notificationErrorRequestAction,
    notificationSuccess: notificationSuccessRequestAction
})(MessageScenarioConfigInput)