import React, { useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { EmptyImage } from "../IconPage";

const ListSelectRecommend = ({ data }) => {
  const [editAble, setEditAble] = useState(false);

  const CardEmptySmall = ({ idx }) => {
    return (
      <div className="card-empty-recommend-small">
        <div className="card-content">
          <PlusCircleIcon />
          <span>Produk Rekomendasi {idx + 1}</span>
        </div>
      </div>
    );
  };

  const CardRecommendSmall = ({ item }) => {
    return (
      <div
        className={`card-recommend-small`}
        onClick={() => setEditAble(!editAble)}
      >
        {editAble && <div className={` active-edit`} />}
        {item.pictures[0].original_url ? (
          <img
            src={item.pictures[0].original_url}
            className="card-recommend-img"
          />
        ) : (
          <div className="card-store-img">
            <div className="empty-store-img">
              <EmptyImage />
            </div>
          </div>
        )}
        <span className="title-recommend-product">
          {item.name ? item.name : "-"}
        </span>
      </div>
    );
  };

  return (
    <>
      <span className="recommend-product-text">
        <span className="recommend-product-text-bold">
          {data.length ? data.length : "0"}/5
        </span>{" "}
        Produk rekomendasi ditambahkan
      </span>
      <div className="recommend-product-list">
        {[...Array(5)].map((item, idx) => {
          return data[idx] ? (
            <CardRecommendSmall key={idx} item={data[idx]} />
          ) : (
            <CardEmptySmall key={idx} idx={idx} />
          );
        })}
      </div>
    </>
  );
};

export default ListSelectRecommend;
