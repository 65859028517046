import {
  GET_AI_ASSISTANT_PROFILE,
  GET_AI_ASSISTANT_PROFILE_FAIL,
  GET_AI_ASSISTANT_PROFILE_SUCCESS,
  UPDATE_AI_ASSISTANT_PROFILE,
  UPDATE_AI_ASSISTANT_PROFILE_FAIL,
  UPDATE_AI_ASSISTANT_PROFILE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  stats: {
    total_conversation: 0,
    pending_conversation: 0,
    num_agent: 0,
  },
  profile: {},
  isFetching: false,
  error: {},
}


const profile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AI_ASSISTANT_PROFILE:
      return {
        ...state,
        isFetching: true,
      }
    case GET_AI_ASSISTANT_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        stats: {
          total_conversation: action.payload.total_conversation,
          pending_conversation: action.payload.pending_conversation,
          num_agent: action.payload.num_agent,
        },
        profile: action.payload.bot,
      }
    case GET_AI_ASSISTANT_PROFILE_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }

    case UPDATE_AI_ASSISTANT_PROFILE:
      return {
        ...state,
        isFetching: true
      }

    case UPDATE_AI_ASSISTANT_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profile: action.payload
      }

    case UPDATE_AI_ASSISTANT_PROFILE_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default profile
