import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ tabs, activeTab, handleTabClick, full }) => {
  const renderTabContent = () => {
    const activeTabContent = tabs.find(tab => tab.id === activeTab);
    return activeTabContent && activeTabContent.content;
  };

  return (
    <div className="tabs">
      <div className="tabs-nav">
        <nav className="tabs-nav-content" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <button
              onClick={() => handleTabClick(tabIdx, tab)}
              key={tab.name}
              className={classNames(
                tabIdx === activeTab
                  ? `btn-tabs-active ${tab.errors ? "btn-tabs-error" : ""}`
                  : "",
                `btn-tabs ${full ? "full" : ""}`
              )}
              aria-current={tabIdx === activeTab ? "page" : undefined}
            >
              {tab.icon ? tab.icon : <></>}
              <span className={`ml-3 ${tab.errors ? "error" : ""}`}>
                {tab.name}
              </span>
            </button>
          ))}
        </nav>
      </div>
      <div>{renderTabContent()}</div>
    </div>
  );
};

export default Tabs;
