import React, { useCallback, useEffect, useState, useRef } from "react";
import AskQuestionConfig from "./ConfigNode/AskQuestionConfig";
import { useOutsideClick } from "helpers/hooks/useOutsideClick";
import SendMessageConfig from "./ConfigNode/SendMessageConfig";
import ProcessorConfig from "./ConfigNode/ProcessorConfig";
import { debounce } from "lodash";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  List,
  ListGroup,
  ListGroupItem,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

const nodeConfigsComponent = {
  ask_question: {
    title: "Ajukan Pertanyaan",
  },
  send_message: {
    title: "Kirim Pesan",
  },
  processor: {
    title: "Prosesor",
  },
  end_session: {
    title: "Pengaturan Pesan Sesi Berakhir",
  },
};

const NodeConfiguration = ({ node, isOpen, toggle, onChange }) => {
  const [editableNode, setEditableNode] = useState(node);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const modalRefPopOver = useRef(null);

  useOutsideClick(() => {
    setIsOpenPopover(false);
  }, modalRefPopOver);

  onChange = debounce(onChange, 150);
  const [config, setConfig] = useState({
    title: "Empty",
    component: null,
  });

  useEffect(() => {
    setEditableNode(node);
  }, [node]);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    setIsOpenPopover(false);
  }, [isOpen]);

  useEffect(() => {
    if (!node) {
      setConfig({});
      return;
    }

    let tmpConfig = nodeConfigsComponent[node.type];
    if (!tmpConfig) {
      setConfig({});
      return;
    }

    setConfig(tmpConfig);
  }, [node]);

  const handleOnChangeVariable = useCallback(
    variables => {
      // console.log('variables change', data, 'variables', variables)
      const data = {
        ...editableNode,
        data: {
          ...node.data,
          variables: variables,
        },
      };
      setEditableNode(data);
      onChange(node.id, data);
    },
    [onChange, node]
  );

  const handleOnChangeNode = useCallback(
    updated => {
      const data = {
        ...editableNode,
        ...updated,
      };
      setEditableNode(data);
      onChange(node.id, data);
    },
    [onChange, node]
  );

  const handleOnClone = () => {
    node.data.onClone(node.id, []);
    toggle();
  };

  const handleOnDelete = () => {
    node.data.onDelete(node.id, []);
    toggle();
  };

  const handlePopOver = () => {
    setIsOpenPopover(!isOpenPopover);
  };

  const renderComponent = () => {
    if (!editableNode || !node) {
      return null;
    }

    if (editableNode.type === "ask_question") {
      return (
        <AskQuestionConfig
          key={editableNode.id}
          onChange={handleOnChangeVariable}
          variables={editableNode.data.variables}
          toggle={toggle}
          node={node}
        />
      );
    }

    if (node.type === "send_message") {
      return (
        <SendMessageConfig
          key={node.id}
          onChange={handleOnChangeVariable}
          toggle={toggle}
          variables={editableNode.data.variables}
          node={node}
        />
      );
    }

    if (node.type === "processor") {
      return (
        <ProcessorConfig
          key={node.id}
          onChangeVariable={handleOnChangeVariable}
          onChangeNode={handleOnChangeNode}
          variables={editableNode.data.variables}
          toggle={toggle}
          node={node}
        />
      );
    }
    return null;
  };

  return (
    <div className="node-configuration">
      <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`}>
        <div className="sidebar-wrapper">
          <div className="header trigger">
            <strong onClick={toggle}>{config["title"]}</strong>
            <div className="section-action-config-btn" ref={modalRefPopOver}>
              <Button
                outline
                id="node-config-ellipsis"
                onClick={handlePopOver}
                color="secondary"
              >
                <i className="fa fa-ellipsis-h" />
              </Button>
              <div className="action action-config">
                {isOpenPopover && (
                  <div className="section-action-config">
                    <div className="btn-costume" onClick={handleOnClone}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 copy"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                        />
                      </svg>
                      <p>Duplikat Objek</p>
                    </div>
                    <div className="btn-costume" onClick={handleOnDelete}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                      <p className="delete">Hapus Objek</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="icon-close-mobile" onClick={toggle}>
                <XMarkIcon className="icon" />
              </div>
            </div>
          </div>
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default NodeConfiguration;
