import React from "react";
import { Link } from "react-router-dom";

const NotificationInovice = ({ numUnpaidInvoice }) => {
  if (numUnpaidInvoice < 1) return null

  return (
    <div className="section-billing-notification">
      <span className="title">
        Tagihan paket berlangganan RISA AI Anda menunggu
      </span>
      <span className="subtitle">
        Silakan selesaikan proses tagihan paket berlangganan Anda{" "}
        <Link
          to={`/billing-invoice`}
          style={{
            fontWeight: "700",
            color: "#664d03"
          }}
        >
          di sini
        </Link>
      </span>
    </div>
  );
};

export default NotificationInovice;
