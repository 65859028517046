import React from "react";
import { Button, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { map } from "lodash";

const TablePagination = ({ onClick, totalPage, currentPage }) => {
  if (totalPage < 1) totalPage = 1
  console.log(onClick, totalPage, currentPage)
  return (
    <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          previous
          href="#"
          onClick={() => onClick(page - 1)}
        />
      </PaginationItem>
      {map(Array(totalPage), (item, i) => (
        <PaginationItem active={i + 1 === currentPage} key={"_k" + i}>
          <PaginationLink
            onClick={() => onClick(i + 1)}
            href="#"
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={currentPage === totalPage}>
        <PaginationLink
          next
          href="#"
          onClick={() => onClick(currentPage + 1)}
        />
      </PaginationItem>
    </Pagination>
  )
}

export default TablePagination