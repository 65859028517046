import * as actionTypes from "./actionTypes";

export const dashboardRequest = () => ({
    type: actionTypes.GET_DASHBOARD_REQUEST,
});

export const dashboardSuccess = (payload) => ({
  type: actionTypes.GET_DASHBOARD_SUCCESS,
  payload: payload,
});

export const dashboardFailed = (error) => ({
  type: actionTypes.GET_DASHBOARD_FAILED,
  payload: error
});
