import React from "react";
import { Button } from "reactstrap";

const SimulationChatButton = ({ apiKey }) => {
  const handleOnClick = () => {
    let url = `https://chat.risa.ai/?client_id=${apiKey.client_id}&secret=${apiKey.secret}`
    window.open(url, "_blank")
  }

  console.log("api key", apiKey)
  return (
    <div className="center" style={{ textAlign: 'center', marginTop: 15 }}>
      <Button color="secondary"
        onClick={()=>handleOnClick()}
        outline>
        Simulasi Chat
      </Button>
    </div>
  );
};

export default SimulationChatButton