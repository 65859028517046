import {
  GET_PLATFORM_REQUEST,
  GET_PLATFORM_SUCCESS,
  GET_PLATFORM_FAILED,
} from "./actionTypes"

const INIT_STATE = {
  platforms: [],
  isRequested: false,
  error: {},
}

const Platform = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLATFORM_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: {},
      }
      case GET_PLATFORM_SUCCESS:
        return {
          ...state,
          isRequested: false,
          platforms: action.payload,
        }
      case GET_PLATFORM_FAILED:
        return {
          ...state,
          error: action.payload,
          isRequested: false,
        }
    default:
      return state
  }
}

export default Platform
