import {
  GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID,
  GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_SUCCES,
  GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_FAILED,
} from "./actionTypes";

const INIT_STATE = {
  shopDashboardproductCategories: null,
  shopDashboardproductCategoryIsFetcing: false,
  error: null,
};

const ShopsDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID:
      return {
        ...state,
        shopDashboardproductCategoryIsFetcing: true,
      };
    case GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_SUCCES:
      return {
        ...state,
        shopDashboardproductCategoryIsFetcing: false,
        shopDashboardproductCategories: action.payload,
        error: null,
      };
    case GET_SHOP_CATEGORY_PRODUCT_BY_COMPANY_ID_FAILED:
      return {
        ...state,
        shopDashboardproductCategoryIsFetcing: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ShopsDashboard;
