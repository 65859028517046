import FbImage from "assets/images/integration/fb.png";
import IgImage from "assets/images/integration/ig.png";
import TokpedImage from "assets/images/integration/tokped.png";
import WaImage from "assets/images/integration/wa.png";
import WebImage from "assets/images/integration/web.png";
import ZendeskImage from "assets/images/zendesk.svg";
import ShopeeImage from "assets/images/agents/shopee.png";

const MenuAgentProfile = [
  {
    name: "Website",
    title: "Website",
    platform: "web",
    url: "/ai-builder/test/live-chat",
    icon: <img src={WebImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Shopee",
    title: "Shopee",
    platform: "shopee",
    url: "/ai-builder/shopee",
    icon: <img src={ShopeeImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Whatsapp",
    title: "Whatsapp",
    platform: "whatsapp",
    url: "/ai-builder/whatsapp",
    icon: <img src={WaImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Instagram",
    title: "Instagram",
    platform: "instagram",
    url: "/ai-builder/instagram",
    icon: <img src={IgImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Tokopedia",
    title: "Tokopedia",
    platform: "tokopedia",
    url: "/ai-builder/tokopedia",
    icon: <img src={TokpedImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Facebook",
    title: "Facebook",
    platform: "facebook",
    url: "/ai-builder/facebook",
    icon: <img src={FbImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "zendesk",
    title: "Zendesk",
    platform: "zendesk",
    url: "/ai-builder/test/live-chat",
    icon: <img src={ZendeskImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
];

const IconShop = ({ colors }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.9991 6H18.9991C19.5491 6 19.9991 5.55 19.9991 5C19.9991 4.45 19.5491 4 18.9991 4H4.9991C4.4491 4 3.9991 4.45 3.9991 5C3.9991 5.55 4.4491 6 4.9991 6ZM20.1591 7.8C20.0691 7.34 19.6591 7 19.1791 7H4.8191C4.3391 7 3.9291 7.34 3.8391 7.8L2.8391 12.8C2.7191 13.42 3.1891 14 3.8191 14H3.9991V19C3.9991 19.55 4.4491 20 4.9991 20H12.9991C13.5491 20 13.9991 19.55 13.9991 19V14H17.9991V19C17.9991 19.55 18.4491 20 18.9991 20C19.5491 20 19.9991 19.55 19.9991 19V14H20.1791C20.8091 14 21.2791 13.42 21.1591 12.8L20.1591 7.8ZM11.9991 18H5.9991V14H11.9991V18Z"
        fill={colors}
      />
    </svg>
  );
};

const IconShakeHand = ({ colors }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={colors}
    >
      <path
        fill={colors}
        d="M16.9797 10.4117C16.5897 10.8017 15.9397 10.8017 15.5497 10.4117L11.0797 5.95167L4.02969 12.9917L3.36969 12.3617C2.19969 11.1917 2.19969 9.29167 3.36969 8.12167L7.60969 3.88167C8.77969 2.71167 10.6797 2.71167 11.8497 3.88167L16.9797 9.00167C17.3697 9.39167 17.3697 10.0217 16.9797 10.4117ZM17.6797 8.29167C18.4597 9.07167 18.4597 10.3417 17.6797 11.1217C16.4097 12.3917 15.0697 11.3417 14.8497 11.1217L11.0897 7.36167L5.51969 12.9317C5.12969 13.3217 5.12969 13.9517 5.51969 14.3417C5.90969 14.7317 6.53969 14.7317 6.93969 14.3417L11.5597 9.72167L12.2697 10.4317L7.64969 15.0517C7.25969 15.4417 7.25969 16.0717 7.64969 16.4617C8.03969 16.8517 8.66969 16.8517 9.06969 16.4617L13.6897 11.8417L14.3997 12.5517L9.77969 17.1717C9.38969 17.5617 9.38969 18.1917 9.77969 18.5817C10.1697 18.9717 10.7997 18.9717 11.1897 18.5817L15.8097 13.9617L16.5197 14.6717L11.8997 19.2917C11.5097 19.6817 11.5097 20.3117 11.8997 20.7017C12.2897 21.0917 12.9197 21.0917 13.3097 20.7017L21.6297 12.3617C22.7997 11.1917 22.7997 9.29167 21.6297 8.12167L17.3897 3.88167C16.2397 2.73167 14.3797 2.71167 13.2097 3.82167L17.6797 8.29167Z"
      />
    </svg>
  );
};

const IconBike = ({ colors }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={colors}
    >
      <path d="M19.5 7C19.5 5.9 18.6 5 17.5 5H15.5C14.95 5 14.5 5.45 14.5 6C14.5 6.55 14.95 7 15.5 7H17.5V9.65L14.02 14H10.5V10C10.5 9.45 10.05 9 9.5 9H6.5C4.29 9 2.5 10.79 2.5 13V15C2.5 15.55 2.95 16 3.5 16H4.5C4.5 17.66 5.84 19 7.5 19C9.16 19 10.5 17.66 10.5 16H14.02C14.63 16 15.2 15.72 15.58 15.25L19.06 10.9C19.35 10.54 19.5 10.1 19.5 9.65V7ZM7.5 17C6.95 17 6.5 16.55 6.5 16H8.5C8.5 16.55 8.05 17 7.5 17Z" />
      <path d="M6.5 6H9.5C10.05 6 10.5 6.45 10.5 7C10.5 7.55 10.05 8 9.5 8H6.5C5.95 8 5.5 7.55 5.5 7C5.5 6.45 5.95 6 6.5 6Z" />
      <path d="M19.5 13C17.84 13 16.5 14.34 16.5 16C16.5 17.66 17.84 19 19.5 19C21.16 19 22.5 17.66 22.5 16C22.5 14.34 21.16 13 19.5 13ZM19.5 17C18.95 17 18.5 16.55 18.5 16C18.5 15.45 18.95 15 19.5 15C20.05 15 20.5 15.45 20.5 16C20.5 16.55 20.05 17 19.5 17Z" />
    </svg>
  );
};

const dataDay = [
  {
    name: "08:00 pagi - 12:00 siang",
    value: "08:00 pagi - 12:00 siang",
  },
  {
    name: "08:00 pagi - 16:00 sore",
    value: "08:00 pagi - 16:00 sore",
  },
  {
    name: "09:00 pagi - 15:00 sore",
    value: "09:00 pagi - 15:00 sore",
  },
  {
    name: "09:00 pagi - 17:00 sore",
    value: "09:00 pagi - 17:00 sore",
  },
  {
    name: "24:00 Jam",
    value: "24:00 Jam",
  },
  {
    name: "Libur",
    value: "Off",
  },
];

export { MenuAgentProfile, IconShop, IconShakeHand, IconBike, dataDay };
