import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Row, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

const EmptyConversation = () => {
  return (
    <Row>
      <Col xs="12" className="text-center">
        <p className="text">Belum ada channel yang terintegrasi</p>
        <Link to="/integration" className="button-view mb-2 btn-primary">
          Integrasi Chat
        </Link>
      </Col>
    </Row>
  );
};

const ConversationInformation = ({
  numChat,
  numIntegration,
  totalUnreadChat,
}) => {
  const [totalChat, setTotalChat] = useState(0);
  const [chatIntegration, setChatIntegration] = useState(0);
  const [totalUnread, setTotalUnread] = useState("Loading...");

  useEffect(() => {
    setTotalChat(numChat);
    if (numIntegration) setChatIntegration(numIntegration);
  }, [numChat]);

  useEffect(() => {
    setTotalUnread(totalUnreadChat);
  }, [totalUnreadChat]);

  return (
    <React.Fragment>
      <Card className="information-card conversation-information mini-stats-wid">
        <CardBody>
          <div className="card-title">
            <span>INFORMASI PERCAKAPAN</span>
            <div>
              <ExclamationCircleIcon
                id="conversation-information-tip"
                className="ml-5"
              />
              <UncontrolledTooltip
                placement="bottom"
                target="conversation-information-tip"
              >
                Berikut adalah ringkasan infromasi dari Total Chat/Percakapan
                yang ada di akun Anda.
              </UncontrolledTooltip>
            </div>
          </div>

          {totalChat < 1 && chatIntegration < 1 && <EmptyConversation />}
          {(totalChat > 0 || chatIntegration > 0) && (
            <div className="information-card-content">
              <div className="d-flex conversation">
                <div className="flex-grow-1">
                  <Link to="/chat" className="text-muted fw-medium mb-2">
                    Total Chat
                  </Link>

                  <p className="highlight">{totalChat}</p>
                </div>
              </div>
              <div className="d-flex conversation">
                <div className="flex-grow-1">
                  <Link to="/integration" className="text-muted fw-medium mb-2">
                    Chat Terintegrasi
                  </Link>

                  <p className="highlight">{chatIntegration}</p>
                </div>
              </div>
              <div className="d-flex conversation">
                <div className="flex-grow-1">
                  <Link
                    to="/billing-invoice"
                    className="text-muted fw-medium mb-2"
                  >
                    Chat Belum Terbaca
                  </Link>

                  <p className="highlight">{totalUnread}</p>
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ConversationInformation;
