import { call, put, select, takeEvery } from "redux-saga/effects"

// Redux States
import {
  GET_AGENT_SCENARIO_REQUEST,
  EVENT_ON_NODE_FIXED,
  EVENT_ON_NODE_ERROR
} from "./actionTypes"
import {
  getAgentScenarioSuccess,
  getAgentScenarioError,
  eventOnNodeErrorClean
} from "./actions"

// http api
import * as api from './api'
import { isEqual, merge, uniqBy } from "lodash"

function* getAgentScenario({ payload: { platform } }) {
  try {
    const response = yield call(api.getAIAgentScenario, platform)
    yield put(getAgentScenarioSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(getAgentScenarioError(error))
  }
}

function* cleanDuplicateNodeError({ payload }) {
  try {
    const nodeErrors = yield select(state => state.AgentScenarioBuilder.nodeErrors)

    let newNodeError = merge(payload, nodeErrors)
    newNodeError = uniqBy(newNodeError, 'id')
    yield put(eventOnNodeErrorClean(newNodeError))
  } catch (error) {
    console.log('cleanup duplicate node fail', error)
  }
}

function* removeNodeErrorFixed({ payload }) {
  try {
    const nodeErrors = yield select(state => state.AgentScenarioBuilder.nodeErrors)

    // old node errors id
    const oids = nodeErrors.filter(v => payload.id !== v.id && payload.type !== v.type)

    yield put(eventOnNodeErrorClean(oids))
  } catch (error) {
    console.log('remove node error failed', error)
  }
}

function* agentScenarioBuilderSaga() {
  yield takeEvery(GET_AGENT_SCENARIO_REQUEST, getAgentScenario)
  yield takeEvery(EVENT_ON_NODE_ERROR, cleanDuplicateNodeError)
  yield takeEvery(EVENT_ON_NODE_FIXED, removeNodeErrorFixed)
}

export default agentScenarioBuilderSaga
