import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import * as actions from "./actions"
import * as api from './api'
import * as actionTypes from "./actionTypes"

function* onGetagentOperationHour() {
  try {
    const response = yield call(api.getAgentOperationHour)
    yield put(actions.getAgentOperationHourSuccess(response))
  } catch (error) {
    if (error.status_code === 401) {
      window.location.replace("/login")
      return
    }
    yield put(actions.getAgentOperationHourFailed(error))
  }
}

function* onInsertagentOperationHour({ payload }) {
  try {
    const response = yield call(api.insertAgentOperationHour, payload)

    yield put(actions.insertAgentOperationHourSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.insertAgentOperationHourFailed(error))
  }
}

function* onUpdateagentOperationHour({ payload: { id, data } }) {
  try {
    const response = yield call(api.updateAgentOperationHour, id, data)

    yield put(actions.updateAgentOperationHourSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.updateAgentOperationHourFailed(error))
  }
}


function* agentOperationHourSaga() {
  yield takeEvery(actionTypes.GET_AGENT_OPERATION_HOUR_REQUEST, onGetagentOperationHour)
  yield takeEvery(actionTypes.UPDATE_AGENT_OPERATION_HOUR_REQUEST, onUpdateagentOperationHour)
  yield takeEvery(actionTypes.INSERT_AGENT_OPERATION_HOUR_REQUEST, onInsertagentOperationHour)
}

export default agentOperationHourSaga
