import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Button, Card, CardBody, Col, Container, Label, Row, Spinner, UncontrolledAlert } from "reactstrap";
import { Link, NavLink, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import images
import logo from '../../assets/images/logo_risa.svg'

// NETWORK
import { post } from "network/http/api";


class CompleteInvitationUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      errorMessage: null,
      userId: 0,
      token: '',
    };
  }

  handleOnSubmit = (values) => {
    if (!values.password || values.password == '') {
      this.setState({ errorMessage: 'Password is required' })
      return
    }

    if (!values.passwordConfirmation || values.passwordConfirmation == '') {
      this.setState({ errorMessage: 'Password Confirmation is required' })
      return
    }

    this.setState({ isLoading: true })
    const data = {
      'token': '',
      'user_id': this.state.userId,
      'password': values.password,
      'password_confirmation': values.passwordConfirmation
    }
    post(`/user/complete-account`, data)
      .then(resp => {
        this.setState({
          isLoading: false,
          isSuccess: true,
          errorMessage: null,
        })
        this.props.history.push("/dashboard")
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          isSuccess: false,
          errorMessage: err.detail,
        })
      })
  }


  render() {
    const { errorMessage, validationFailed, isLoading, isSuccess } = this.state;

    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-dark">
                        <img
                          src={logo}
                          alt=""
                        />
                        <div className="p-4">
                          <h5>Complete your account</h5>
                        </div>
                      </Link>
                    </div>


                    <div className="p-4">
                      {errorMessage ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {errorMessage}
                        </Alert>
                      ) : null}
                      {isSuccess ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          Succes reset your password, now start login
                        </Alert>
                      ) : null}

                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          password: "",
                          passwordConfirmation: "",
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string().required(
                            "Please Enter Your Password"
                          ),
                          passwordConfirmation: Yup.string().required(
                            "Please Enter Your Password Confirmation"
                          ),
                        })}
                        onSubmit={values => {
                          this.handleOnSubmit(values);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <Field
                                name="password"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password-confirmation" className="form-label">
                                Password Confirmation
                              </Label>
                              <Field
                                name="passwordConfirmation"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.passwordConfirmation && touched.passwordConfirmation
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="passwordConfirmation"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            {isLoading &&
                              <div className="mb-3 text-center">
                                <Spinner type="grow" />
                              </div>
                            }
                            <div className="text-end">
                              <button
                                disabled={isLoading}
                                className="btn btn-primary w-md"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(CompleteInvitationUserPage);
