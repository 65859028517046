import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Container, Spinner, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";
import { useFormik } from "formik";
import { post } from "network/http/api";
import { IntegrationWhatsappSchema } from "../SchemaValidation";
import BackButton from "../ComponentsPage/BackButton";
import CostumInputField from "../ComponentsPage/CostumInputField";
import CustomDropdownField from "../ComponentsPage/CostumDropdown";
import CostumRadioField from "../ComponentsPage/CostumRadioField";

const IntegrationWhatsapp = () => {
  const history = useHistory();
  const dataProfile = useSelector(state => state.Profile);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      company_name: "",
      company_website_url: "",
      company_country_name: "",
      sid_twilio: "",
      id_business_manager_meta: "",
      use_case: "",
      message_recipient_country_name: "",
      first_year_monthly_message_count: "",
      consent_for_twilio_voice_url_change: false,
      privacy_policy_consent: false,
    },
    onSubmit: values => {
      onSubmitIntegration(values, dataProfile);
    },
    validationSchema: IntegrationWhatsappSchema,
  });

  const onSubmitIntegration = (params, paramsProfile) => {
    setLoading(true);
    const payload = {
      email: params.email,
      first_name: params.first_name,
      last_name: params.last_name,
      uses_whatsapp_with_third_party: true,
      company_id: paramsProfile.user.company_id,
      company_name: params.company_name,
      company_website_url: params.company_website_url,
      company_country_name: params.company_country_name.value,
      sid_twilio: params.sid_twilio,
      id_business_manager_meta: params.id_business_manager_meta,
      use_case: params.use_case,
      message_recipient_country_name:
        params.message_recipient_country_name.value,
      first_year_monthly_message_count:
        params.first_year_monthly_message_count.value,
      consent_for_twilio_voice_url_change:
        params.consent_for_twilio_voice_url_change,
      privacy_policy_consent: params.privacy_policy_consent,
    };

    post("/whatsapp-integration/insert-form", payload)
      .then(res => {
        setLoading(false);
        notificationSuccessRequestAction("Berhasil Mendaftarkan Whatsapp");
        history.push("/integration");
      })
      .catch(err => {
        dispatch(notificationErrorRequestAction(err.detail));
        setLoading(false);
      });
  };

  return (
    <div className="page-content page-integration">
      <div className="page-integration-shop">
        <Container fluid>
          <div className="page-integration-shop-details">
            <div className="header-section">
              <BackButton />
              <span className="title">Integrasi Whatssapp</span>
            </div>
            <div className="page-integration-shop-content">
              <div className="page-integration-shop-form">
                <div className="section-form">
                  <h6 className="subtitle">
                    Silahkan isi form di bawah ini untuk mengajukan akses
                    integrasi anatara Whatssapp Anda dengan sistem RISA AI
                  </h6>
                  <form
                    className="form-integration-shop"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="field-form">
                      <span className="title">
                        Email<span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="email"
                        placeholder="Masukkan Email Anda"
                        field={formik?.getFieldProps("email")}
                        form={formik}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Nama Depan
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="first_name"
                        placeholder="Masukkan Nama Depan Anda"
                        field={formik?.getFieldProps("first_name")}
                        form={formik}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Nama Belakang
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="last_name"
                        placeholder="Masukkan Nama Belakang Anda"
                        field={formik?.getFieldProps("last_name")}
                        form={formik}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Nama Legal Perusahaan
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="company_name"
                        placeholder="Masukkan Nama Perusahaan Anda"
                        field={formik?.getFieldProps("company_name")}
                        form={formik}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Website Perusahaan
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="company_website_url"
                        placeholder="Masukkan Url Website Anda"
                        field={formik?.getFieldProps("company_website_url")}
                        form={formik}
                      />
                    </div>
                    <CustomDropdownField
                      name="company_country_name"
                      title="Lokasi Negara Kantor Pusat Perusahaan"
                      placeholder="Pilih Lokasi Kantor Pusat Anda"
                      optional
                      data={[{ name: "Indonesia", value: "indonesia" }]}
                      formik={formik}
                    />
                    <div className="field-form">
                      <span className="title">
                        ID Twilio
                        <span style={{ color: "#DA2626" }}>*</span>{" "}
                      </span>
                      <CostumInputField
                        name="sid_twilio"
                        placeholder="Masukkan ID Twilio Anda"
                        field={formik?.getFieldProps("sid_twilio")}
                        form={formik}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        ID Business Manager Meta/ Facebook
                        <span style={{ color: "#DA2626" }}>*</span>{" "}
                        <span className="title-small">
                          (Bukan ID Facebook Anda)
                        </span>
                      </span>
                      <CostumInputField
                        name="id_business_manager_meta"
                        placeholder="Masukkan ID Business Manager Meta Anda"
                        field={formik?.getFieldProps(
                          "id_business_manager_meta"
                        )}
                        form={formik}
                      />
                    </div>
                    <div className="field-form">
                      <span className="title">
                        Jelaskan Tujuan Anda menggunakan WhatsApp
                        <span style={{ color: "#DA2626" }}>*</span>
                      </span>
                      <CostumInputField
                        name="use_case"
                        placeholder="Masukkan informasi tujuan Anda menggunakan WhatsApp"
                        field={formik?.getFieldProps("use_case")}
                        form={formik}
                      />
                    </div>
                    <CustomDropdownField
                      name="message_recipient_country_name"
                      title="Negara Mana yang Anda Kirimi Pesan?"
                      placeholder="Pilih Negara yang Anda Kirimi Pesan"
                      optional
                      data={[{ name: "Indonesia", value: "indonesia" }]}
                      formik={formik}
                    />
                    <CustomDropdownField
                      name="first_year_monthly_message_count"
                      title="Berapa banyak pesan yang Anda kirim per bulan selama tahun pertama?"
                      placeholder="Pilih Banyak Pesan yang Anda Kirim Selama Tahun Pertama"
                      optional
                      data={[
                        { name: "1 - 5.000", value: 5000 },
                        { name: "5.000 - 10.000", value: 10000 },
                        { name: "10.000 - 50.000", value: 50000 },
                        { name: "50.000 - 100.000", value: 100000 },
                        { name: "> 100.000", value: 200000 },
                      ]}
                      formik={formik}
                    />
                    <CostumRadioField
                      title="Saya memahami bahwa Twilio dapat mengubah URL Suara
                            saya dan persetujuan tersebut hanya untuk merek yang
                            dikirimkan."
                      subtitle="Twilio mungkin perlu sementara mengubah URL webhook
                            nomor telepon Twilio Anda jika nomor tersebut tidak
                            dapat menerima SMS. Ini mungkin sementara
                            mengalihkan panggilan dari sistem Anda."
                      name="consent_for_twilio_voice_url_change"
                      options={[{ name: "Saya Paham", value: true }]}
                      mandatory
                      formik={formik}
                    />
                    <h6 className="subtitle">
                      Meminta akses juga memungkinkan kami mengirimi Anda
                      informasi tentang acara dan berita Twilio. Kami akan
                      menggunakan informasi Anda sesuai dengan kebijakan privasi
                      kami dan sesuai dengan kebijakan privasi WhatsApp. Anda
                      dapat mengubah preferensi Anda kapan saja menggunakan
                      pusat preferensi Anda
                    </h6>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h6 className="subtitle">
                        <span style={{ color: "#DA2626" }}>*</span>Wajib untuk
                        diisi oleh pelanggan RISA AI untuk proses integrasi ke
                        WA via Twillio
                      </h6>
                      <h6 className="subtitle">
                        <span style={{ color: "#DA2626" }}>**</span>Field wajib
                        diisi, dan dibutuhkan untuk mengetahui tempat area
                        bisnis Anda beroperasi ketika telah terintegrasi ke
                        WA/Twillio (contoh: Indonesia).
                      </h6>
                    </div>

                    <div className="field-form">
                      <span className="title">
                        Saya setuju dengan{" "}
                        <a
                          className="title-link"
                          href="https://risa.ai/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Syarat dan Ketentuan
                        </a>{" "}
                        serta{" "}
                        <a
                          className="title-link"
                          href="https://risa.ai/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Kebijakan Privasi
                        </a>
                      </span>
                      <CostumRadioField
                        name="privacy_policy_consent"
                        options={[{ name: "Saya Setuju", value: true }]}
                        formik={formik}
                      />
                    </div>
                    {loading ? (
                      <div className="field-btn">
                        <Spinner color="primary" size="sm" />
                      </div>
                    ) : (
                      <div className="field-btn">
                        <Button
                          color="primary"
                          outline
                          style={{ marginRight: "8px" }}
                          onClick={() => history.goBack()}
                        >
                          Batal
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={!formik.isValid}
                        >
                          Kirim
                        </Button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(IntegrationWhatsapp);
