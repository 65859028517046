import { JWT_TOKEN_KEY } from "network/http/jwt-token-access";


const useWhatsAppSignup = () => {
  window.FB.login(
    response => {
      if (response.authResponse) {
        const accessToken = response.authResponse.code;
        fetch(
          `${process.env.REACT_APP_API_URL}/platform-integration/whatsapp_callback`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem(JWT_TOKEN_KEY)}`
            },
            body: JSON.stringify({ access_token: accessToken }),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:");
          })
          .catch(error => {
            console.error("Error:", error);
          });
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    {
      // TODO: change hardcoded codes
      config_id: "891132716395374", 
      auth_type: "rerequest",
      response_type: "code", 
      override_default_response_type: true,
      extras: {
        sessionInfoVersion: 2, 
        setup: {
          solutionID: "1248456419923931", 
        }
      }
    }
  );
};

export default useWhatsAppSignup;
