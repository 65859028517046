import React, { useEffect, useState } from "react";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment";

const BillingInformation = ({ billing }) => {
  const [planName, setPlanName] = useState("Loading...");
  const [quotaMain, setQuotaMain] = useState("Loading...");
  const [quotaWA, setQuotaWA] = useState("Loading...");
  const [nextCycleDate, setNextCycleDate] = useState("Loading...");

  useEffect(() => {
    if (!billing.id) return;

    setPlanName(billing.plan_name);
    setQuotaMain(billing.max_number_message + billing.max_number_product);
    if (billing.plan_name.toLowerCase() == "gratis") {
      setNextCycleDate("--/--/----");
    } else {
      setNextCycleDate(moment(billing.next_cycle_date).format("DD/MM/YYYY"));
    }
    // TODO: Set WA QUOTA
    setQuotaWA("0");
  }, [billing]);

  return (
    <React.Fragment>
      <Card className="information-card mini-stats-wid">
        <CardBody>
          <div className="card-title">
            <span>INFORMASI TAGIHAN</span>
            <div>
              <ExclamationCircleIcon id="billing-information-tip" />
              <UncontrolledTooltip
                placement="bottom"
                target="billing-information-tip"
              >
                Berikut adalah informasi tagihan dari paket yang Anda miliki.
              </UncontrolledTooltip>
            </div>
          </div>

          <div className="information-card-content">
            <div className="d-flex billing">
              <div className="flex-grow-1">
                <Link
                  to="/billing-invoice"
                  className="text-muted fw-medium mb-2"
                >
                  Paket Anda
                </Link>

                <p className="highlight">{planName}</p>
              </div>
            </div>
            <div className="d-flex billing">
              <div className="flex-grow-1">
                <Link
                  to="/billing-invoice"
                  className="text-muted fw-medium mb-2"
                >
                  Kuota Paket Utama
                </Link>

                <p className="highlight">{quotaMain}</p>
              </div>
            </div>
            <div className="d-flex billing">
              <div className="flex-grow-1">
                <Link
                  to="/billing-invoice"
                  className="text-muted fw-medium mb-2"
                >
                  Kuota WhatsApp
                </Link>

                <p className="highlight">{quotaWA}</p>
              </div>
            </div>
            <div className="d-flex billing">
              <div className="flex-grow-1">
                <Link
                  to={"/billing-invoice"}
                  className="text-muted fw-medium mb-2"
                >
                  Masa Tenggat
                </Link>

                <p className="next-cycle-date">
                  {nextCycleDate}
                  <Link to={"/billing-invoice"}>Bayar Sekarang {">"}</Link>
                </p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default BillingInformation;
