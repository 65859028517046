import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
  getProfile,
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import NewSideBar from "./NewSideBar";
import ActivationLink from "components/ActivationLink";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      width: 0,
      height: 0,
      webSocketInit: false,
      menuRightBar: false,
      showMobileSidebar: false,
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.hideRightbar = this.hideRightbar.bind(this);
    this.handleSideBarMobile = this.handleSideBarMobile.bind(this);
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    const { history } = this.props;
    this.props.getProfile(history);

    document.body.addEventListener("click", this.hideRightbar, true);

    // Scroll Top to 0
    window.scrollTo(0, 0);

    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.leftSideBarThemeImage) {
      this.props.changeSidebarThemeImage(this.props.leftSideBarThemeImage);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
  }

  toggleMenuCallback = () => {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
      this.setState({
        menuRightBar: !this.state.menuRightBar,
      });
    }
  };

  // //hides right sidebar on body click
  hideRightbar = event => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      if (document.body.classList.contains("right-bar-enabled")) {
        this.props.toggleRightSidebar(false);
      }
    }
  };

  handleSideBarMobile = () => {
    this.setState({
      showMobileSidebar: !this.state.showMobileSidebar,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header handleToggleMenu={this.handleSideBarMobile} />
          <NewSideBar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            showSidebar={this.state.showMobileSidebar}
            handleShowSidebar={this.handleSideBarMobile}
            showMenuRightBar={this.state.menuRightBar}
          />
          <div className="main-content">
            {/* TODO: add validation */}
            <ActivationLink />

            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.bool,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  toggleRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStateToProps, {
  getProfile,
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout));
