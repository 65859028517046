import { getConnectedEdges } from 'reactflow';


export const checkDuplicateVariableName = (nodeId, variableNames, name) => {
    let isDuplicate = false
    Object.keys(variableNames).map(key => {
        if (variableNames[key] === name && key !== nodeId) {
            isDuplicate = true
        }
    })
    return isDuplicate
}

// check if all the nodes is connected and return unconnected node
export const checkNetworkNode = (edges, nodes) => {
    let connected = getConnectedEdges(nodes, edges)

    let unconnectedNodes = []
    nodes.map((node) => {
        if (node.type === 'starting_point') {
            return
        }

        let isConnected = false
        for (let index = 0; index < connected.length; index++) {
            if (connected[index].target === node.id) {
                isConnected = true
                break
            }
        }

        if (!isConnected) {
            unconnectedNodes.push(node)
        }
    })

    return unconnectedNodes
}