import {
    EVENT_SC_DISPLAY_NODE_OPTION,
    EVENT_SC_ON_DRAW_LINE,
    EVENT_SC_ON_NODE_ADDED,
    EVENT_SC_ON_NODE_DELETED,
    EVENT_SC_ON_PANE_CLICK,
    EVENT_SC_ON_START_CONNECTION,
    GET_AGENT_SCENARIO_FAILED,
    GET_AGENT_SCENARIO_REQUEST,
    GET_AGENT_SCENARIO_SUCCESS,
    EVENT_SC_ON_NODE_OPEN_TOOLTIP,
    ON_NODE_UPDATE_VARIABLE,
    EVENT_ON_NODE_ERROR,
    EVENT_ON_NODE_ERROR_CLEAN,
    EVENT_ON_NODE_FIXED
} from "./actionTypes";

export const getAgentScenario = (platform) => ({
    type: GET_AGENT_SCENARIO_REQUEST,
    payload: { platform }
})

export const getAgentScenarioSuccess = (payload) => ({
    type: GET_AGENT_SCENARIO_SUCCESS,
    payload,
})

export const getAgentScenarioError = (error) => ({
    type: GET_AGENT_SCENARIO_FAILED,
    payload: error,
})

export const eventOnPaneClick = () => ({
    type: EVENT_SC_ON_PANE_CLICK,
})

export const eventOnStartConnection = (nodeId) => ({
    type: EVENT_SC_ON_START_CONNECTION,
    payload: nodeId
})


export const eventOnNodeAdded = (nodeId) => ({
    type: EVENT_SC_ON_NODE_ADDED,
    payload: nodeId
})

export const eventOnNodeDeleted = (nodeId) => ({
    type: EVENT_SC_ON_NODE_DELETED,
    payload: nodeId
})

export const eventOnDrawLine = () => ({
    type: EVENT_SC_ON_DRAW_LINE,
})

export const eventDisplayNodeOption = (nodeId, sourceHandle) => ({
    type: EVENT_SC_DISPLAY_NODE_OPTION,
    payload: {
        nodeId,
        sourceHandle
    }
})

export const eventOnOpenTooltip = (nodeId) => ({
    type: EVENT_SC_ON_NODE_OPEN_TOOLTIP,
    payload: nodeId
})

export const nodeUpdateVariable = (payload) => ({
    type: ON_NODE_UPDATE_VARIABLE,
    payload: payload
})

export const eventOnNodeError = (payload) => ({
    type: EVENT_ON_NODE_ERROR,
    payload,
})


export const eventOnNodeErrorClean = (payload) => ({
    type: EVENT_ON_NODE_ERROR_CLEAN,
    payload,
})

export const eventOnNodeFixed = (payload) => ({
    type: EVENT_ON_NODE_FIXED,
    payload
})