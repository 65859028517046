import {
  SHOP_REGISTER_FAILED,
  SHOP_REGISTER_REQUEST,
  SHOP_REGISTER_SUCCES,
  GET_SHOP_FAILED,
  GET_SHOP_REQUEST,
  GET_SHOP_SUCCES,
  GET_SHOP_DETAIL_REQUEST,
  GET_SHOP_DETAIL_SUCCES,
  GET_SHOP_DETAIL_FAILED,
  GET_SHOP_TRAIN_STATE_REQUEST,
  GET_SHOP_TRAIN_STATE_SUCCES,
  GET_SHOP_TRAIN_STATE_FAILED,
  SHOP_REGISTER_RESET,
  UPDATE_SHOP_POLICY_REQUEST,
  UPDATE_SHOP_POLICY_FAILED,
  UPDATE_SHOP_POLICY_SUCCES,

  UPDATE_SHOP_REQUEST,
  UPDATE_SHOP_SUCCES,
  UPDATE_SHOP_FAILED,
  UPDATE_SHOP_SHIPMENT_REQUEST
} from "./actionTypes"

const INIT_STATE = {
  detail: {
    isRequested: false,
    isSuccess: false,
    shop: null,
  },
  fetch: {
    isRequested: false,
    isSuccess: false,
  },
  shops: [],
  register: {
    isRequested: false,
    isSuccess: false,
  },
  trainState: {
    isRequested: false,
    isSuccess: false,
    state: null,
  },
  update: {
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  },
  updatePolicy: {
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  },
  informationStore: {
    totalCategories: 0,
    totalIntegrated: 0,
    totalProduct: 0,
    lastSync: ''
  },
  error: null,
}

const updatePolicyShop = (state, policy) => {
  let { shop } = state.detail

  console.log('shop', shop.policies)
  if (!shop.policies) {
    shop.policies = [policy]
    return shop
  }

  shop.policies = shop.policies.map(p => {
    if (p.id === policy.id) {
      return policy
    }
    return p
  })

  return shop
}

const Shop = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SHOP_REQUEST:
    case UPDATE_SHOP_SHIPMENT_REQUEST:
      return {
        ...state,
        update: {
          isRequested: true,
          isSuccess: false,
          isFailed: false,
        },
        error: null,
      }
    case UPDATE_SHOP_SUCCES:
      return {
        ...state,
        update: {
          isRequested: false,
          isSuccess: true,
          isFailed: false,
        },
        detail: {
          isRequested: false,
          isSuccess: false,
          shop: action.payload,
        },
        error: null,
      }
    case UPDATE_SHOP_FAILED:
      return {
        ...state,
        update: {
          isRequested: false,
          isSuccess: false,
          isFailed: true,
        },
        error: action.payload,
      }

    case UPDATE_SHOP_POLICY_REQUEST:
      return {
        ...state,
        updatePolicy: {
          isRequested: true,
          isSuccess: false,
          isFailed: false,
        },
        error: null,
      }
    case UPDATE_SHOP_POLICY_SUCCES:
      return {
        ...state,
        detail: {
          isRequested: false,
          isSuccess: false,
          shop: updatePolicyShop(state, action.payload),
        },
        updatePolicy: {
          isRequested: false,
          isSuccess: true,
          isFailed: false,
        },
        error: null,
      }
    case UPDATE_SHOP_POLICY_FAILED:
      return {
        ...state,
        updatePolicy: {
          isRequested: false,
          isSuccess: false,
          isFailed: true,
        },
        error: action.payload,
      }
    case SHOP_REGISTER_RESET:
      return {
        ...state,
        register: {
          isRequested: false,
          isSuccess: false,
          shop: null,
        },
        error: null,
      }
    case GET_SHOP_TRAIN_STATE_REQUEST:
      return {
        ...state,
        trainState: {
          isRequested: true,
          isSuccess: false,
          state: null,
        },
        error: null,
      }
    case GET_SHOP_TRAIN_STATE_SUCCES:
      return {
        ...state,
        trainState: {
          isRequested: false,
          isSuccess: true,
          state: action.payload,
        },
        error: null,
      }
    case GET_SHOP_TRAIN_STATE_FAILED:
      return {
        ...state,
        trainState: {
          isRequested: false,
          isSuccess: false,
          state: null,
        }
      }
    case GET_SHOP_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          isRequested: true,
          isSuccess: false,
          shop: null,
        },
        update: {
          isRequested: false,
          isSuccess: false,
          isFailed: false,
        },
      }
    case GET_SHOP_DETAIL_SUCCES:
      return {
        ...state,
        register: {
          isRequested: false,
          isSuccess: false,
          shop: null,
        },
        detail: {
          isRequested: false,
          isSuccess: true,
          shop: action.payload,
        },
        error: null,
      }
    case GET_SHOP_DETAIL_FAILED:
      return {
        ...state,
        detail: {
          isRequested: false,
          isSuccess: false,
          shop: null,
        }
      }
    case SHOP_REGISTER_REQUEST:
      return {
        ...state,
        register: {
          isRequested: true,
          isSuccess: false,
          shop: null,
        },
        error: null,
      }
    case SHOP_REGISTER_SUCCES:
      return {
        ...state,
        register: {
          isRequested: false,
          isSuccess: true,
        },
        detail: {
          isRequested: false,
          isSuccess: false,
          shop: action.payload,
        },
        error: null,
      }
    case SHOP_REGISTER_FAILED:
      return {
        ...state,
        error: action.payload,
        register: {
          isRequested: false,
          isSuccess: false,
          shop: null,
        }
      }
    case GET_SHOP_REQUEST:
      return {
        ...state,
        fetch: {
          isRequested: true,
          isSuccess: false,
        },
        error: null,
      }
    case GET_SHOP_SUCCES:
      return {
        ...state,
        fetch: {
          isRequested: false,
          isSuccess: true,
        },
        error: null,
        informationStore: {
          totalCategories: action.payload.total_categories,
          totalIntegrated: action.payload.total_integrated,
          totalProduct: action.payload.total_product,
          lastSync: action.payload.last_sync,
        },
        total_shop: action.payload.total,
        shops: action.payload.stores,
      }
    case GET_SHOP_FAILED:
      return {
        ...state,
        error: action.payload,
        fetch: {
          isRequested: false,
          isSuccess: false,
        }
      }
    default:
      return state
  }
}

export default Shop
