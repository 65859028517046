import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { menuList } from "../VerticalLayout/DefaultData";

import logoRisa from "../../assets/images/image2vector.svg";
import logoRisaSmall from "assets/images/sidebar/risa_logo.svg";

const SideBarCostumeScenarioContent = props => {
  const { submenuOpenStates, showLargeSidebar, handleToggleSubmenu } = props;

  if (showLargeSidebar) {
    return (
      <div className={`vertical-menu-scenario large-sidebar`}>
        <div className={`navbar-brand-box large-sidebar`}>
          <Link to="/" className="logo">
            <img src={logoRisa} alt="" height="60" />
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <div className={`h-100 menu-new`}>
              <ul className="menu-list">
                {menuList.map((menu, index) => {
                  if (menu.submenu) {
                    return (
                      <li key={index}>
                        <div
                          className="menu"
                          style={
                            menu.name === "All Chat"
                              ? { position: "relative" }
                              : {}
                          }
                        >
                          <Link
                            to={menu.link}
                            onClick={() => handleToggleSubmenu(index)}
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: menu.icon }}
                            />
                            <span
                              style={{
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {menu.name}{" "}
                              {submenuOpenStates[index] ? (
                                <i className={`bx bx-chevron-down`} />
                              ) : (
                                <i className={`bx bx-chevron-right`} />
                              )}
                            </span>
                          </Link>
                        </div>

                        {submenuOpenStates[index] && (
                          <div className="submenu">
                            {menu.submenu.map((sub, i) => (
                              <div style={{ position: "relative" }} key={i}>
                                <Link to={sub.link}>{sub.name}</Link>
                              </div>
                            ))}
                          </div>
                        )}
                      </li>
                    );
                  }

                  return (
                    <li key={index}>
                      <div
                        className="menu"
                        style={
                          menu.name === "All Chat"
                            ? { position: "relative" }
                            : {}
                        }
                      >
                        <Link to={menu.link}>
                          <span
                            dangerouslySetInnerHTML={{ __html: menu.icon }}
                          />
                          <span style={{ marginLeft: "10px" }}>
                            {menu.name}
                          </span>
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`vertical-menu-scenario small-sidebar`}>
      <div className={`navbar-brand-box small-sidebar`}>
        <Link to="/" className="logo">
          <img src={logoRisaSmall} alt="" height="30" />
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <div className={`h-100 menu-hide-bar`}>
            <ul className="menu-list">
              {menuList.map((menu, index) => {
                return (
                  <li key={index}>
                    <div
                      className="menu"
                      style={
                        menu.name === "All Chat" ? { position: "relative" } : {}
                      }
                    >
                      <Link to={menu.link}>
                        <span dangerouslySetInnerHTML={{ __html: menu.icon }} />
                      </Link>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

SideBarCostumeScenarioContent.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(SideBarCostumeScenarioContent)));
