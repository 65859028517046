import BackButton from "components/BackButton";
import { get, post } from "network/http/api";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { connect } from "react-redux";
import {
  Col,
  Container,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";
import CodeHighlight from "./CodeHighlight";
import StyleForm from "./StyleForm";
import InputForm from "./InputForm";

const fileTypes = ["JPG", "PNG", "JPEG", "SVG"];

const IntegrationWeb = ({ user, notificationSuccess, notificationError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("general");
  const [apiKey, setApiKey] = useState({});

  useEffect(() => {
    handleOnGetAPIKey();
  }, [currentTab]);

  const handleOnGetAPIKey = () => {
    setIsLoading(true);
    get("/api-key")
      .then(resp => {
        setIsLoading(false);
        if (!resp) {
          return;
        }
        setApiKey(resp);
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
        notificationError("Terjadi kesalahan mohon refresh halaman ini");
      });
  };

  return (
    <div className="integration-web">
      <div className="page-content page-integration">
        <div className="page-integration-shop">
          <Container fluid>
            <div className="page-integration-shop-details">
              <div className="header-section">
                <BackButton />
                <span className="title">Pengaturan Chat</span>
              </div>
              <div className="page-integration-shop-content">
                <div className="page-integration-shop-form">
                  <div className="section-form">
                    <h6 className="subtitle">
                      Atur kebutuhan chat lewat web Anda di halaman pengaturan. Anda dapat melakukan kustomisasi terhadap tampilan, dan mengakses informasi instalasi web chat pada tab “Instalasi Web Chat”
                    </h6>
                  </div>
                  <div className="content">
                    <Nav tabs>
                      <NavItem onClick={() => setCurrentTab("general")}>
                        <NavLink active={currentTab === "general"}>
                          Tampilan Chat
                        </NavLink>
                      </NavItem>
                      <NavItem onClick={() => setCurrentTab("form")}>
                        <NavLink active={currentTab === "form"}>
                          Konfigurasi Form
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => setCurrentTab("install")}
                          active={currentTab === "install"}
                        >
                          Install Web Chat
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="tab-content">
                      {currentTab === "general" && (
                        <StyleForm
                          apiKey={apiKey}
                          notificationSuccess={notificationSuccess}
                          notificationError={notificationError} />
                      )}
                      {currentTab === "form" && (
                        <InputForm
                          notificationSuccess={notificationSuccess}
                          notificationError={notificationError} />
                      )}
                      {currentTab === "install" && (
                        <div className="tab-install">
                          {isLoading && (
                            <div className="text-center">
                              <Spinner />
                            </div>
                          )}

                          <CodeHighlight apiKey={apiKey} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Profile }) => {
  return {
    user: Profile.user,
  };
};

export default connect(mapStateToProps, {
  notificationError: notificationErrorRequestAction,
  notificationSuccess: notificationSuccessRequestAction,
})(IntegrationWeb);
