import SanitizeHTML from 'components/SanitizeHTML';
import React from 'react'

const TextWrapper = ({ text, isHtml = false }) => {
  if (!text) {
    return text;
  }
  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  if (isHtml) {
    return <SanitizeHTML html={truncateString(text, 30)} />
  }

  return truncateString(text, 30)
}

export default TextWrapper
