import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { Container, Alert, Spinner } from "reactstrap";

import { getInvoices } from "store/actions";
import { get } from "network/http/api";
import { notificationErrorRequestAction } from "store/notification/actions";
import TableReferral from "./ComponentsPage/TableReferral";
import ReferralCard from "./ComponentsPage/ReferralCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReferralCommissionCard from "./ComponentsPage/ReferralCommisionCard";
import TableReferralCommission from "./ComponentsPage/TableReferralCommission";

const ReferralComissionList = props => {
  const dispatch = useDispatch()
  const { referralUserId } = useParams();
  const [currentPage, setCurrentPage] = useState(1)
  const [referral, setReferral] = useState({});
  const [referralCommissionList, setReferralCommissionList] = useState([]);
  const [user, setUser] = useState({ email: '', name: '' })
  const [pagination, setPagination] = useState({
    "totalItem": 0,
    "totalPage": 0,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchReferralList()
  }, []);

  const fetchReferralList = async (page = 1) => {
    if (isLoading) {
      return
    }

    if (page != currentPage) {
      setCurrentPage(page)
    }

    setIsLoading(true)
    try {
      const res = await get(`/referral/commission/${referralUserId}?page=${page}`);
      const { referral_commissions, pagination, username, email } = res
      setUser({ email, username })
      setReferralCommissionList(referral_commissions)
      setPagination({
        totalItem: pagination.total_item,
        totalPage: pagination.total_page,
      })
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      dispatch(notificationErrorRequestAction("Something wrong, please try again!"))
    }
  };

  return (
    <React.Fragment>
      <div className="page-content referral">
        <Container fluid className="section-billing">


          {isLoading &&
            <div className="text-center loading-table-overlay">
              <Spinner color="primary" />
            </div>
          }

          <ReferralCommissionCard username={user.username} email={user.email} />
          <TableReferralCommission data={referralCommissionList} pagination={pagination} currentPage={currentPage} />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Invoice }) => ({
  invoices: Invoice.invoices,
  billing: Invoice.billing,
  quota: Invoice.quota,
  totalInvoice: Invoice.totalInvoice,
  isFetching: Invoice.isFetching,
  error: Invoice.error,
});

const mapDispatchToProps = {
  onGetInvoice: getInvoices,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReferralComissionList));
