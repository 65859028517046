import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import * as actions from "./actions"

import * as api from './api'
import { GET_PLATFORM_REQUEST } from "./actionTypes"

function* onGetPlatform() {
  try {
    const response = yield call(api.getPlatform)

    yield put(actions.getPlatformSuccess(response))
  } catch (error) {
    console.log(error)
    actions.getPlatformFailed(error)
  }
}


function* platformSaga() {
  yield takeEvery(GET_PLATFORM_REQUEST, onGetPlatform)
}

export default platformSaga
