import classNames from "classnames";
import React, { Component } from "react"
import { Row, Col, Button } from "reactstrap"

class NewChannelTimeline extends Component {
  render() {
    //meta title
    const { currentNavigation, onNavigate } = this.props;


    return (
      <React.Fragment>
        <Row>
          <Col lg="12">

            <div className="hori-timeline">
              <div
                className="owl-carousel owl-theme  navs-carousel events"
                id="timeline-carousel"
              >
                <Row>
                  <Col xs="3">
                    <div
                      className={classNames(
                        "item event-list",
                        currentNavigation == 'register' ? 'active' : ''
                      )}
                      style={{ display: "inline-table" }}
                    >
                      <div>
                        <div className="event-date">
                          <div className="text-primary mb-1">
                            Tahap Pertama
                          </div>
                          <h5 className="mb-4">Daftar</h5>
                        </div>
                        <div className="event-down-icon">
                          <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                        </div>

                        <div className="mt-3 px-3">
                          <p className="text-muted">
                            Register Toko
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div
                      className={classNames(
                        "item event-list",
                        currentNavigation == 'policy_review' ? 'active' : ''
                      )}
                      style={{ display: "inline-table" }}
                    >
                      <div>
                        <div className="event-date">
                          <div className="text-primary mb-1">
                            Tahap Kedua
                          </div>
                          <h5 className="mb-4">Polis</h5>
                        </div>
                        <div className="event-down-icon">
                          <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                        </div>

                        <div className="mt-3 px-3">
                          <p className="text-muted">
                            Polis membantu AI dalam mendajawab inquiry customer
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div
                      className={classNames(
                        "item event-list",
                        currentNavigation == 'shipment' ? 'active' : ''
                      )}
                      style={{ display: "inline-table" }}
                    >
                      <div>
                        <div className="event-date">
                          <div className="text-primary mb-1">
                            Tahap ketiga
                          </div>
                          <h5 className="mb-4">Pengiriman</h5>
                        </div>
                        <div className="event-down-icon">
                          <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                        </div>

                        <div className="mt-3 px-3">
                          <p className="text-muted">
                            Jadwal pengiriman
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div
                      className={classNames(
                        "item event-list",
                        currentNavigation == 'authorize_access' ? 'active' : ''
                      )}
                      style={{ display: "inline-table" }}
                    >
                      <div>
                        <div className="event-date">
                          <div className="text-primary mb-1">
                            Finish
                          </div>
                          <h5 className="mb-4">Otorisation</h5>
                        </div>
                        <div className="event-down-icon">
                          <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                        </div>

                        <div className="mt-3 px-3">
                          <p className="text-muted">
                            Authorize access is different from every platforms
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* BOTTOM NAVIGATION */}
                {currentNavigation !== 'register' &&
                  <div
                    className="owl-nav"
                    style={{ textAlign: "center" }}
                  >
                    <button
                      type="button"
                      onClick={() => onNavigate(false)}
                      className="border-0"
                      disabled={currentNavigation == 'policy_review'}
                    >
                      <i className="mdi mdi-chevron-left"></i>
                    </button>
                    <button
                      type="button"
                      onClick={() => onNavigate(true)}
                      className="border-0"
                      disabled={currentNavigation == 'authorize_access' || currentNavigation == 'register'}
                    >
                      <i className="mdi mdi-chevron-right"></i>
                    </button>
                  </div>
                }
              </div>
            </div>

          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default NewChannelTimeline
