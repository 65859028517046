import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Scenario
import Scenario from "./scenario/reducer";

// Websocket
import WebSocket from "./websocket/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

// Agent Intent
import AgentIntent from "./agent-intent/reducer";

// Platform
import Platform from "./platform/reducer";

// Shop
import Shop from "./shop/reducer";

// Product
import Product from "./product/reducer";

// Ticket
import Ticket from "./ticket/reducer";
import TicketComment from "./ticket-comment/reducer";

// QNA Dictionary
import qnaDictionary from "./qna-dictionary/reducer";

// AI Assistant
import AIAssistant from "./ai-assistant/reducer";

// Invoice
import Invoice from "./invoices/reducer";

// Billing
import Billing from "./billing/reducer";

// Company
import Company from "./company/reducer";

// Learning Mode
import AgentOperationHour from "./agent-operation-hour/reducer";

// Onboarding
import Onboarding from "./onboarding/reducer";

// Agent Action Builder
import AgentScenarioBuilder from "./agent-scenario-builder/reducer";

// Notification
import Notification from "./notification/reducer";

// Shop Dashboard
import ShopDashboard from "./shopDashbord/reducer";

// Status Bar
import StatusBar from "./status-bar/reducer";

// Create Store Tokopedia
import MultiStepTokopedia from "./integration/tokopedia/reducer";
import PageStepAction from "./integration/stepintegration/reducer";

// Rating List
import Ratings from "./rating/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  Dashboard,
  ForgetPassword,
  Profile,
  QNADictionary: qnaDictionary,
  Scenario,
  WebSocket,
  ecommerce,
  AgentIntent,
  Platform,
  Shop,
  Ticket,
  TicketComment,
  Product,
  AIAssistant,
  Invoice,
  Billing,
  Company,
  AgentOperationHour,
  Onboarding,
  AgentScenarioBuilder,
  Notification,
  ShopDashboard,
  StatusBar,
  MultiStepTokopedia,
  PageStepAction,
  Ratings,
});

export default rootReducer;
