import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const DropdownSelected = ({
  title,
  typeTitle = "bold",
  data,
  selected = null,
  setSelected,
  placeholder,
  error,
}) => {
  const onSelectedData = params => {
    setSelected(params);
  };

  return (
    <div className="dropdown-costume-selected">
      {title && (
        <span className={typeTitle === "light" ? "title-light" : "title"}>
          {title}
        </span>
      )}
      <Listbox value={selected} onChange={onSelectedData}>
        <div className="dropdown-selected">
          <Listbox.Button
            className={`dropdown-selected-button ${
              error ? "error-selected" : ""
            }`}
          >
            <span
              className={`dropdown-selected-title ${selected ? "" : "default"}`}
            >
              {selected
                ? selected.name
                  ? selected.name
                  : selected
                : placeholder
                ? placeholder
                : `Pilih ${title}`}
            </span>
            <span className="dropdown-selected-icon">
              <ChevronDownIcon className="icon" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="dropdown-selected-list">
              {data.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    `list ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`dropdown-selected-title ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {item.name}
                      </span>
                      {selected ? (
                        <span className="list-icon">
                          <CheckIcon className="icon" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default DropdownSelected;
