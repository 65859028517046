import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Container, Alert } from "reactstrap";

import { getInvoices } from "store/actions";
import { get } from "network/http/api";

import PackageSection from "./ComponentsPage/PackageSection";
import TabelSection from "./ComponentsPage/TableInvoice";
import ModalNewMember from "./ComponentsPage/ModalNewMember";
import ModalPackage from "./ComponentsPage/ModalPackage";
import ModalAddtionalQuota from "./ComponentsPage/ModalAddtionalQuota";
import Countdown from "./ComponentsPage/CountDownPayment";

const InvoiceList = props => {
  const { onGetInvoice, quota, invoices, billing, error, history } = props;
  const [modal, setModal] = useState(false);
  const [modalAddtionalQuota, setModalAddtionalQuota] = useState(false);
  const [modalNewMember, setModalNewMember] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);
  const [pendingInvoice, setPendingInvoice] = useState(null);
  const [page, setPage] = useState(1);
  const [invoiceListData, setInvoiceListData] = useState([]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleModalAddQuota = () => {
    setModalAddtionalQuota(!modalAddtionalQuota);
  };

  const toggleModalNewMember = () => {
    setModalNewMember(!modalNewMember);
    history.push("/billing-invoice?onboarding=false");
  };

  useEffect(() => {
    onGetInvoice();
    const params = new URLSearchParams(props.location.search);
    const isNewMember = params.get("onboarding") === "true";
    if (isNewMember) {
      setModalNewMember(true);
    }
  }, [props.location.search]);

  useEffect(() => {
    const fetchBiling = async () => {
      try {
        const res = await get(`/billing/me`);
        setPendingInvoice(res.last_pending_invoice);
        setErrorFetch(null);
      } catch (err) {
        setErrorFetch(err.detail);
        setTimeout(() => {
          setErrorFetch(null);
        }, 1000);
      }
    };

    const fetchListInovices = async () => {
      try {
        const res = await get(`/invoice/me?page=${page}`);
        setInvoiceListData(res.invoices);
        setErrorFetch(null);
      } catch (err) {
        setErrorFetch(err.detail);
        setTimeout(() => {
          setErrorFetch(null);
        }, 1000);
      }
    };

    fetchBiling();
    fetchListInovices();
  }, [triggerFetch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="section-billing">
          {error && error.detail && (
            <Alert color="danger">{error.detail}</Alert>
          )}
          {errorFetch && <Alert color="danger">{errorFetch}</Alert>}

          <Countdown targetDate={pendingInvoice?.due_date} />
          <PackageSection
            openModal={toggle}
            openModalAddQuota={toggleModalAddQuota}
            billing={billing}
            quota={quota}
            pendingInvoice={pendingInvoice}
          />
          <TabelSection data={invoiceListData} history={history} />
          <ModalNewMember
            billing={billing}
            isOpen={modalNewMember}
            toggle={toggleModalNewMember}
            setTriggerFetch={setTriggerFetch}
          />
          <ModalPackage
            billing={billing}
            isOpen={modal}
            toggle={toggle}
            setTriggerFetch={setTriggerFetch}
          />
          <ModalAddtionalQuota
            billing={billing}
            isOpen={modalAddtionalQuota}
            toggle={toggleModalAddQuota}
            setTriggerFetch={setTriggerFetch}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Invoice }) => ({
  invoices: Invoice.invoices,
  billing: Invoice.billing,
  quota: Invoice.quota,
  totalInvoice: Invoice.totalInvoice,
  isFetching: Invoice.isFetching,
  error: Invoice.error,
});

const mapDispatchToProps = {
  onGetInvoice: getInvoices,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceList));
