import React, { useEffect, useState } from "react";
import QuestionList from "./QuestionList";
import { get } from "network/http/api";

const Question = ({
  productId,
  knowledgeBaseId,
  disableAddQuestion,
  type = "product",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    handleOnFetch();
  }, []);

  const handleOnFetch = () => {
    let baseUrl = `/knowledge-base/product/${productId}`;
    if (type !== "product") {
      baseUrl = `/knowledge-base/${knowledgeBaseId}`;
    }

    setIsLoading(true);
    get(`${baseUrl}/question-answer?page=1&&per_page=10`).then(resp => {
      const { question_answer, total_item } = resp;
      setIsLoading(false);
      setQuestions(question_answer);
      setCurrentPage(1);
      const resTotalPage = total_item ? Math.ceil(total_item / 10) : 0;
      setTotalPage(resTotalPage);
    });
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (currentPage !== totalPage && !loadingMore) {
        setLoadingMore(true);
        let baseUrl = `/knowledge-base/product/${productId}`;
        if (type !== "product") {
          baseUrl = `/knowledge-base/${knowledgeBaseId}`;
        }

        const nextPage = currentPage + 1;
        get(`${baseUrl}/question-answer?page=${nextPage}&&per_page=10`).then(
          resp => {
            const { question_answer } = resp;
            setLoadingMore(false);
            setCurrentPage(nextPage);
            setQuestions(prevData => [...prevData, ...question_answer]);
          }
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [totalPage, currentPage]);

  return (
    <div className="question-details-product-content">
      <QuestionList
        disableAddQuestion={disableAddQuestion}
        questions={questions}
        knowledgeBaseId={knowledgeBaseId}
        productId={productId}
        onChangePage={handleOnFetch}
        type={type}
        loading={isLoading}
        loadingMore={loadingMore}
      />
    </div>
  );
};

export default Question;
