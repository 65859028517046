import React from "react";
import { Modal } from "reactstrap";
import ChoosePackageNewMember from "./ChoosePackageNewMember";

const ModalNewMember = ({ billing, isOpen, toggle, setTriggerFetch }) => {
  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="section-modal-billing"
    >
      <ChoosePackageNewMember
        currentPlanId={billing.plan_id}
        toggle={toggle}
        setTriggerFetch={setTriggerFetch}
      />
    </Modal>
  );
};

export default ModalNewMember;
