import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import SourceHandler from './SourceHandler';


function StartingNode({ id, data }) {
  return (
    <div className='starting-point custom-node'>
      <div className="custom-node__body starting-body">
        <strong>Mulai percakapan</strong>
        <br />
        <small className='text-muted'>Dimulai dari sini</small>
      </div>
      <Handle type="source" position={Position.Right} id={id} />
      <SourceHandler id={id} data={data} />
    </div>
  );
}

export default memo(StartingNode);
