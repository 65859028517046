import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logoRisa from "assets/images/logo-white-risa.svg";
import { menuList } from "./DefaultData";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import triangle from "assets/images/sidebar/triangle.svg";
import MenuMobile from "./MenuMobile";

const NewSideBar = props => {
  const { showSidebar, handleShowSidebar } = props;
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [step, setStep] = useState(1);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isNewMember = params.get("new-member");
    if (isNewMember === "true") {
      setShowOnboarding(true);
    } else {
      setShowOnboarding(false);
    }
  }, []);

  const handleSubMenuToggle = index => {
    handleShowSidebar();
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const handleCloseOnboarding = () => {
    const params = new URLSearchParams(location.search);
    params.set("new-member", "false");
    const path = location.pathname;
    history.replace(`${path}?${params.toString()}`);
    setShowOnboarding(false);
  };

  const handleNextStep = () => {
    setStep(2);
  };

  return (
    <div className={`new-sidebar-menu ${showSidebar ? "open-side-bar" : ""}`}>
      <div className="navbar-brand-box">
        <Link to="/" className="logo">
          <img src={logoRisa} alt="" height="24" />
        </Link>
      </div>
      <div className="navbar-header-mobile">
        <div className="section-header">
          <div className="icon-menu" onClick={handleShowSidebar}>
            <XMarkIcon />
          </div>
          <Link to="/" className="section-logo">
            <img src={logoRisa} alt="logo" />
          </Link>
          <div style={{ flex: "1" }} />
        </div>
      </div>
      <div className="list-menu-sidebar">
        <ul className="list-menu">
          {menuList.map((item, idx) => {
            return (
              <li key={idx} className="list">
                <Link
                  to={item.link}
                  onClick={() => handleSubMenuToggle(idx)}
                  className={`list-link ${
                    location.pathname.includes(item.link) ||
                    (item.submenu &&
                      item.submenu.some(subItem =>
                        location.pathname.includes(subItem.link)
                      ))
                      ? "active"
                      : ""
                  } `}
                >
                  <span
                    className="icon"
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                  />
                  {item.name}
                  {item.submenu && (
                    <>
                      {openSubMenu === idx ? (
                        <ChevronDownIcon className="list-chevron" />
                      ) : (
                        <ChevronRightIcon className="list-chevron" />
                      )}
                    </>
                  )}
                </Link>
                {item.submenu && openSubMenu === idx && (
                  <ul className="list-sub-menu">
                    {item.submenu.map((items, idxSub) => {
                      const isActive = location.pathname.includes(items.link);
                      return (
                        <li key={idxSub} className="list-sub">
                          <Link
                            to={items.link}
                            className={`list-link ${isActive ? "active" : ""}`}
                          >
                            {items.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {showOnboarding && (
                  <>
                    {step === 1 && (
                      <>
                        {item.name === "Chat" && (
                          <div className="onboarding-menu">
                            <div className="onboarding-menu-card">
                              <div className="onboarding-card-title">
                                <span>Mulai Pengalaman Anda!</span>
                              </div>
                              <div className="onboarding-card-content">
                                <span>
                                  Anda dapat menggunakan menu samping yang
                                  disediakan untuk mengakses fitur RISA AI.
                                </span>
                                <div className="d-flex flex-row section-button">
                                  <button
                                    className="btn-skip"
                                    onClick={() => handleCloseOnboarding()}
                                  >
                                    Lewati
                                  </button>
                                  <button
                                    onClick={() => handleNextStep(2, idx)}
                                    className="btn-next"
                                  >
                                    Selanjutnya
                                  </button>
                                </div>
                              </div>
                              <div className="triangle-icon">
                                <img
                                  src={triangle}
                                  alt="triangle"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {step === 2 && (
                      <>
                        {item.name === "Custom Scenario" && (
                          <div className="onboarding-submenu">
                            <div className="onboarding-submenu-card">
                              <div className="onboarding-submenu-card-title">
                                <span>Mulai Konfigurasi</span>
                              </div>
                              <div className="onboarding-submenu-card-content">
                                <span>
                                  Pilih menu “Custom Scenario” untuk membuat
                                  skenario percakapan sesuai kebutuhan.
                                  <br />
                                  <br />
                                  Selanjutnya Anda dapat mengatur kebutuhan
                                  paket Anda dengan memilih paket yang sesuai.
                                </span>
                                <div className="d-flex flex-row section-button">
                                  <button
                                    onClick={() => {
                                      history.replace(
                                        "/billing-invoice?onboarding=true"
                                      );
                                      setShowOnboarding(false);
                                    }}
                                  >
                                    Atur Paket
                                  </button>
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translatey(-50%)",
                                    left: -15,
                                  }}
                                >
                                  <img
                                    src={triangle}
                                    alt="triangle"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <MenuMobile />
    </div>
  );
};

export default NewSideBar;
