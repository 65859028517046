import { convertInt2Money } from 'components/PlanService';
import React from 'react';

const ReferralCommissionCard = ({ username, email }) => {
  return (
    <div className="referral-code-container">
      <h2 className="referral-code-title">User Information</h2>
      <div className="referral-code-content">
        <div className="referral-code-item">
          <span className="label">Name:</span>
          <span className="value bold">{username || '-'}</span>
        </div>
        <div className="referral-code-item">
          <span className="label">Email:</span>
          <span className="value"> { email }</span>
        </div>
      </div>
    </div>
  );
};

export default ReferralCommissionCard;