import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import BackButton from "../ComponentsPage/BackButton";
import SectionStepper from "../ComponentsPage/SectionStepper";
import SectionForm from "./SectionForm";
import "moment/locale/id";

moment.locale("id");

const IntegrationTokped = () => {
  const formStepDataRedux = useSelector(state => state.PageStepAction);
  const formDataRedux = useSelector(state => state.MultiStepTokopedia);
  const [step, setStepper] = useState(formStepDataRedux.step);
  const [data, setData] = useState(formDataRedux);

  return (
    <div className="page-content page-integration">
      <div className="page-integration-shop">
        <Container fluid>
          <div className="page-integration-shop-details">
            <div className="header-section">
              <BackButton />
              <span className="title">Integrasi Toko</span>
            </div>
            <div className="page-integration-shop-content">
              <SectionStepper
                step={step}
                setStepper={setStepper}
                data={data}
                setData={setData}
              />
              <SectionForm
                step={step}
                setStep={setStepper}
                data={data}
                setData={setData}
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(IntegrationTokped);
