const { get, put, del } = require("network/http/api")

export const getAIProfile = () => {
  return get(`/ai-assistant/profile`)
}

export const updateAIProfile = (payload) => {
  return put(`/ai-assistant/profile`, payload)
}

