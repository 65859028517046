import { XMarkIcon } from "@heroicons/react/24/solid";
import Image from "components/Image";
import { uploadFile } from "network/http/api";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { connect } from "react-redux";
import { Alert, Spinner, UncontrolledAlert, Input } from "reactstrap";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";

const fileTypes = ["JPG", "PNG", "JPEG", "SVG"];
const UploadLogo = ({
  logo,
  onChangeImage,
  notificationSuccess,
  notificationError,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!logo) return;
    if (logo === image) return;

    setImage(logo);
  }, [logo]);

  const handleChange = event => {
    setFile(event.target.files[0]);
  };

  const handleOnUpload = file => {
    const headers = { "Content-Type": file.type };

    setIsLoading(true);
    uploadFile("/file/image", file, headers)
      .then(resp => {
        setIsLoading(false);
        setImage(resp.data.path);
        onChangeImage(resp.data.path);
        notificationSuccess("Upload image berhasil");
      })
      .catch(err => {
        notificationError("Upload image gagal");
        setIsLoading(false);
        setImage(null);
        // onImageRemove(addUpdateIndex)
        console.error(err);
      });
  };

  const removeImage = () => {
    setImage(null);
    onChangeImage("");
  };

  return (
    <div className="upload-logo">
      <div className="field-form">
        <Input
          className="field-input"
          name="file"
          type="file"
          onChange={handleChange}
          placeholder="Masukkan judul chat.."
        />
        <button className="button-upload" onClick={() => handleOnUpload(file)}>
          Upload
        </button>
      </div>
      {image && (
        <div className="thumbnail">
          {/* <div className="close-icon" onClick={removeImage}>
            <XMarkIcon />
          </div> */}
          <Image src={image} />
        </div>
      )}
      {isLoading && (
        <div className="loading text-center">
          <Spinner type="grow" color="primary" />
        </div>
      )}
      {errorMessage && (
        <UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert>
      )}
    </div>
  );
};

export default connect(null, {
  notificationError: notificationErrorRequestAction,
  notificationSuccess: notificationSuccessRequestAction,
})(UploadLogo);
