export const ON_START_CONNECTION = "ON_START_CONNECTION"
export const ON_ADD_NEW_NODE = 'ON_ADD_NEW_NODE'

export const GET_AGENT_SCENARIO_REQUEST = 'GET_AGENT_SCENARIO_REQUEST'
export const GET_AGENT_SCENARIO_SUCCESS = 'GET_AGENT_SCENARIO_SUCCESS'
export const GET_AGENT_SCENARIO_FAILED = 'GET_AGENT_SCENARIO_FAILED'

export const ON_UPDATE_NODE = "ON_UPDATE_NODE"
export const ON_UPDATE_EDGES = "ON_UPDATE_EDGES"
export const ON_NODE_UPDATE_VARIABLE = 'ON_NODE_UPDATE_VARIABLE'

export const EVENT_SC_ON_START_CONNECTION = 'EVENT_SC_ON_START_CONNECTION'
export const EVENT_SC_ON_PANE_CLICK = 'EVENT_SC_ON_PANE_CLICK'
export const EVENT_SC_ON_NODE_DELETED = 'EVENT_SC_ON_NODE_DELETED'
export const EVENT_SC_ON_NODE_ADDED = 'EVENT_SC_ON_NODE_ADDED'
export const EVENT_SC_ON_DRAW_LINE = 'EVENT_SC_ON_DRAW_LINE'
export const EVENT_SC_DISPLAY_NODE_OPTION = 'EVENT_SC_DISPLAY_NODE_OPTION'
export const EVENT_SC_ON_NODE_OPEN_TOOLTIP = 'EVENT_SC_ON_NODE_OPEN_TOOLTIP'
export const EVENT_ON_NODE_ERROR = 'EVENT_ON_NODE_ERROR'
export const EVENT_ON_NODE_ERROR_CLEAN = 'EVENT_ON_NODE_ERROR_CLEAN'
export const EVENT_ON_NODE_FIXED = 'EVENT_ON_NODE_FIXED'