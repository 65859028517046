import { AUTH_USER_KEY, JWT_TOKEN_KEY } from "network/http/jwt-token-access"

const { get, post, put, del } = require("network/http/api")

export const register_user = (payload) => {
  return post('auth/register', payload)
}

export const setAccessToken = (response) => {
  localStorage.setItem(JWT_TOKEN_KEY, response.access_token)
  localStorage.setItem(AUTH_USER_KEY, JSON.stringify(response.user))
}
