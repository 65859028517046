import React from "react";
import DropdownSelected from "components/DropdownSelected";

const CustomDropdownField = ({
  name,
  title,
  subtitle,
  placeholder,
  data,
  mandatory,
  optional,
  formik,
}) => {
  const { touched, errors } = formik || {};
  const field = formik.getFieldProps(name);

  return (
    <div className="field-form">
      <span className="title">
        {title}
        {mandatory && <span style={{ color: "#DA2626" }}>*</span>}
        {optional && <span style={{ color: "#DA2626" }}>**</span>}
      </span>
      {subtitle && <span className="subtitle">{subtitle}</span>}
      <DropdownSelected
        placeholder={placeholder}
        data={data}
        selected={field.value}
        setSelected={value =>
          field.onChange({ target: { value, name: field.name } })
        }
      />
      {errors[field.name] && touched[field.name] && (
        <div className="error-message">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default CustomDropdownField;
