import React, { Component, Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import NotificationInovice from "components/Dashboard/NotificationInovice";
// import redux actions
import { dashboardRequest } from "store/dashboard/actions";
import Skeleton from "react-loading-skeleton";
import ConversationStats from "components/Dashboard/ConversationStats";
import UserWelcome from "components/Dashboard/UserWelcome";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BillingInformation from "components/Dashboard/BillingInformation";
import ConversationInformation from "components/Dashboard/ConversationInformation";
import ScenarioList from "components/Dashboard/ScenarioList";

const Dashboard = ({
  onDashboardRequest,
  isFetching,
  billing,
  conversationStats,
  numConversation,
  numUnpaidInvoice,
  totalIntegration,
  totalUnreadChat,
  onboard,
}) => {
  const location = useLocation();
  useEffect(() => {
    onDashboardRequest();
  }, []);

  useEffect(() => {
    if (!billing.id || billing.base_price === 0) {
      const params = new URLSearchParams(location.search);
      const isNewMember = params.get("new-member");

      // if (!isNewMember) {
      // return;
      // }
      // console.log('isDsiplayBudlePacakge', this.state.isDsiplayBudlePacakge)
      // this.setState({ isDsiplayBudlePacakge: true })
    }
  }, [billing]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="dashboard">
          <Container fluid>
            <Row>
              <Col xs="12">
                <NotificationInovice numUnpaidInvoice={numUnpaidInvoice} />

                <UserWelcome />

                <div className="section-dashboard">
                  {isFetching && (
                    <div className="text-center">
                      <Skeleton count={4} />
                    </div>
                  )}

                  {!isFetching && (
                    <div className="section-dashboard-content">
                      <div className="section-billing">
                        <BillingInformation billing={billing} />
                      </div>
                      <div className="section-chat">
                        <ConversationInformation
                          numChat={numConversation}
                          numIntegration={totalIntegration}
                          totalUnreadChat={totalUnreadChat}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col xs="12">
                <ConversationStats conversationStats={conversationStats} />
              </Col>
              <Col xs="12">
                <ScenarioList />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Dashboard, Profile }) => {
  return {
    user: Profile.user,
    ...Dashboard,
  };
};

export default connect(mapStateToProps, {
  onDashboardRequest: dashboardRequest,
})(Dashboard);
