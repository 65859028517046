import {
  SET_FILTER_RATING_LIST,
  RESET_FILTER_RATING_LIST,
} from "./actionTypes";

export const setFilterRating = payload => ({
  type: SET_FILTER_RATING_LIST,
  payload: payload,
});

export const resetFilterRating = () => ({
  type: RESET_FILTER_RATING_LIST,
});
