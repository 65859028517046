import { post } from "network/http/api";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { notificationErrorRequestAction, notificationSuccessRequestAction } from "store/notification/actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const TestScenario = ({ onClick, isEdited, isLoading }) => {
    const [isOpenModal, setIsOpenModal] = useState(false)
    let textBtn = <span>Simpan</span>

    if (isEdited) {
        textBtn = <span>Simpan dan Luncurkan</span>
    }

    return (
        <>
            <Modal isOpen={isOpenModal} toggle={() => setIsOpenModal(!isOpenModal)}>
                <ModalHeader>
                    Perubahan Akan Diluncurkan
                </ModalHeader>
                <ModalBody>
                    <p>
                        Perubahan pada Custom Scenario akan langsung diluncurkan. Alur percakapan dari sisi pelanggan Anda akan langsung diperbaharui dengan perubahan terakhir.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button outline onClick={() => setIsOpenModal(false)} color="primary">
                        Batal
                    </Button>
                    <Button onClick={onClick}
                        disabled={isLoading}
                        color="primary">
                        {isLoading &&
                            <span>Loading...</span>
                        }
                        {!isLoading &&
                            textBtn
                        }
                    </Button>
                </ModalFooter>
            </Modal>
            <Button color="primary"
                onClick={() => setIsOpenModal(true)}
                disabled={isLoading}
                outline>
                Simpan
            </Button>
        </>
    )
}



export default connect(null, {
    notificationError: notificationErrorRequestAction,
    notificationSuccess: notificationSuccessRequestAction,
})(withRouter(TestScenario))