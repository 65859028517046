import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
// import projectsSaga from "./projects/saga"
import dashboardSaga from "./dashboard/saga";
import scenarioSaga from "./scenario/saga";
import agentIntentSaga from "./agent-intent/saga";
import platformSaga from "./platform/saga";
import shopSaga from "./shop/saga";
import ticketSaga from "./ticket/saga";
import ticketCommentSaga from "./ticket-comment/saga";
import productSaga from "./product/saga";
import qnaDictionarySaga from "./qna-dictionary/saga";
import aiProfileSaga from "./ai-assistant/saga";
import invoiceSaga from "./invoices/saga";
import billingSaga from "./billing/saga";
import companySaga from "./company/saga";
import agentOperationHourSaga from "./agent-operation-hour/saga";
import onboardingSaga from "./onboarding/saga";
import agentScenarioBuilderSaga from "./agent-scenario-builder/saga";
import shopDashboard from "./shopDashbord/saga";
import notificationSaga from "./notification/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(scenarioSaga),
    fork(agentIntentSaga),
    fork(platformSaga),
    fork(shopSaga),
    fork(ticketSaga),
    fork(ticketCommentSaga),
    fork(productSaga),
    fork(qnaDictionarySaga),
    fork(aiProfileSaga),
    fork(invoiceSaga),
    fork(billingSaga),
    fork(companySaga),
    fork(agentOperationHourSaga),
    fork(onboardingSaga),
    fork(agentScenarioBuilderSaga),
    fork(shopDashboard),
    fork(notificationSaga),
  ]);
}
