import React, { useState, useRef, useEffect } from "react";
import SwitchCostume from "components/SwitchCostume";
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import {
  TrashIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";
import { useOutsideClick } from "helpers/hooks/useOutsideClick";
import { Modal } from "reactstrap";
import ModalActivationIntegration from "../ModalActivationIntegration";

const CardContentTokopedia = ({ item, icon, setShowRightSidebar }) => {
  const [enabled, setEnabled] = useState(item.integration ? true : false);
  const history = useHistory();
  const modalRef = useRef();
  const [showAction, setShowAction] = useState(false);
  const [showModalToggle, setShowModalToggle] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const openSideBar = () => {
    setShowRightSidebar({
      show: true,
      data: item.integration,
    });
  };

  const onOpenDetail = paramsItem => {
    if (paramsItem.integration) {
      return;
    }
    history.push(`/integration/tokopedia`);
  };

  const openModalAction = () => {
    setShowAction(!showAction);
  };

  const findStatus = paramsStatus => {
    switch (paramsStatus) {
      case "register":
        return <span className="status">Menunggu email konfirmasi</span>;
      case "review_policy":
        return <span className="status">Menunggu email konfirmasi</span>;
      case "platform_review":
        return <span className="status">Cek email Anda untuk konfirmasi</span>;
      case "active":
        return <span className="status">{item.integration.store_name}</span>;
      case "deactivated":
        return <span className="error">Integrasi gagal, silahkan ulang</span>;
      default:
        return "Unknown";
    }
  };

  useOutsideClick(() => {
    setShowAction(false);
  }, modalRef);

  const onReRegister = () => {
    history.push(`/integration/tokopedia`);
  };

  const onOpenModal = () => {
    setShowModalToggle(!showModalToggle);
  };

  const onUpdateIntegration = () => {
    setShowModalToggle(!showModalToggle);
  };

  return (
    <>
      <div
        ref={modalRef}
        className="page-integration-menu"
        onClick={() => onOpenDetail(item)}
      >
        <div className="page-integration-menu-title">
          {icon}
          <div className="page-integration-status">
            <span className="title">{item.platform_name}</span>
            {item.integration
              ? findStatus(item.integration?.authorize_status)
              : ""}
          </div>
        </div>
        <div className="page-integration-action">
          {item.integration?.authorize_status === "active" && (
            <div className="action" onClick={e => e.stopPropagation()}>
              <SwitchCostume
                enabled={enabled}
                setEnabled={onOpenModal}
                tooltipText="Mengubah pengaturan toogle akan mengaktifkan/ menonaktifkan integrasi platform Anda dengan sistem RISA AI."
              />
              <div className="action-menu" onClick={() => openModalAction()}>
                <EllipsisVerticalIcon className="icon-menu" />
                {showAction && (
                  <div className="action-modal">
                    {item.platform_name === "tokopedia" && (
                      <div
                        className="btn-icon-action btn-edit"
                        onClick={() => openSideBar()}
                      >
                        <DocumentMagnifyingGlassIcon className="icon" />
                        Review Data
                      </div>
                    )}
                    {/* <div
                      className="btn-icon-action btn-delete"
                      onClick={() => console.log("delete")}
                    >
                      <TrashIcon /> Hapus Integrasi
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          )}
          {(!item.integration ||
            item.integration.authorize_status === "deactivated") && (
              <div className="action-chevron" onClick={() => onReRegister()}>
                <ChevronRightIcon className="icon-chevron" />
              </div>
            )}
        </div>
      </div>
      <Modal
        isOpen={showModalToggle}
        toggle={onOpenModal}
        className="modal-integration"
        centered
      >
        <ModalActivationIntegration
          enabled={enabled}
          name={item.platform_name}
          error={error}
          loading={loading}
          onUpdateIntegration={() => onUpdateIntegration()}
          setShowModalToggle={() => setShowModalToggle(false)}
        />
      </Modal>
    </>
  );
};

export default CardContentTokopedia;
