import { get, post, put, del } from "network/http/api"

export const fetchTicket = (page = 1, limit = 10) => {
  return get(`/ticket?page=${page}&&limit=${limit}`)
}

export const fetchConversationTicket = (id) => {
  return get(`/ticket/conversation/${id}`)
}

export const updateTicket = (id, data) => {
  return put(`/ticket/${id}`, data)
}

export const createTicket = (data) => {
  return post(`/ticket`, data)
}

export const detailTicket = (id) => {
  return get(`/ticket/${id}`)
}
