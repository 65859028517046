import React, { memo, useEffect, useState } from 'react';
import { NodeToolbar, useReactFlow } from 'reactflow';
import { useNodeId } from 'reactflow';
import { Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';

// icon
import iconSendMessage from 'assets/images/kirim-pesan-icon.svg'
import iconRobot from 'assets/images/robot-icon.svg'
import iconDoubleMessage from 'assets/images/double-bubble-message-icon.svg'



const ActionNodeDialog = ({ data, sourceHandle, isConnectable, displayOptionOnNode }) => {
    const nodeId = useNodeId();
    const reactInstance = useReactFlow()
    const node = reactInstance.getNode(nodeId)

    if (!isConnectable) {
        return
    }

    let isOpen = false
    if (sourceHandle) {
        isOpen = displayOptionOnNode === `${nodeId}_${sourceHandle}`
    } else {
        isOpen = displayOptionOnNode === nodeId
    }

    return (
        <NodeToolbar isVisible={isOpen} position={'right'}>
            <div className="action-list " aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <Card>
                    <CardBody>
                        <ListGroup>
                            <ListGroupItem
                                onClick={() => data.onAddNode(nodeId, node, 'send_message', sourceHandle)}>
                                <div className='item-body'>
                                    <img src={iconSendMessage} />

                                    <div className='description'>
                                        <strong>Kirim Pesan</strong>
                                        <p>
                                            Kirim pesan teks atau media ke pelanggan
                                        </p>
                                    </div>
                                </div>
                            </ListGroupItem>
                            <ListGroupItem
                                onClick={() => data.onAddNode(nodeId, node, 'ask_question', sourceHandle)}>
                                <div className='item-body'>
                                    <img src={iconDoubleMessage} />

                                    <div className='description'>
                                        <strong>Ajukan Pertanyaan</strong>
                                        <p>
                                            Ajukan pertanyaan ke pelanggan dan pilih tipe validasi jawaban
                                        </p>
                                    </div>
                                </div>
                            </ListGroupItem>
                            <ListGroupItem
                                onClick={() => data.onAddNode(nodeId, node, 'processor', sourceHandle)}>

                                <div className='item-body'>
                                    <img src={iconRobot} />


                                    <div className='description'>
                                        <strong>Processor</strong>
                                        <p>
                                            Pilih sumber pengetahuan atau hubungkan ke operator
                                        </p>
                                    </div>
                                </div>
                            </ListGroupItem>
                        </ListGroup>
                    </CardBody>
                </Card>
                <div className='chevron'></div>
            </div>
        </NodeToolbar>
    )
}

const mapStateToProps = (states) => {
    return states.AgentScenarioBuilder.config
}

export default connect(mapStateToProps)(memo(ActionNodeDialog))
