import React, { Component } from 'react'
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, ListGroup, ListGroupItem, Row, UncontrolledAlert } from 'reactstrap'
import ShopPolicyCard from './ShopPolicyCard';
import { connect } from 'react-redux';

class ShopPolicy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'refund',
      policies: [
        {
          'title': 'Refund Policy',
          'label': 'refund',
          'description': 'Policy ini akan digunakan Risa untuk merespon ketika Customer ingin menanyakan refund prosess/pengajuan refund'
        },
        {
          'title': 'Warranty Policy',
          'label': 'warranty',
          'description': 'Apakah product anda memiliki garansi? \n Policy ini akan digunakan Risa untuk menjawab pertanyaan tentang garansi product'
        },
        {
          'title': 'After Sale',
          'label': 'after_sale',
          'description': 'Apakah product anda memiliki customer after sale? \n Policy ini akan digunakan Risa untuk menjawab pertanyaan tentang after sale product e.g Customer Service, Lokasi Service Spare Part dan lain-lain'
        }
      ]
    }
  }

  getPolicyContent = () => {
    const { policies } = this.props.shop;
    const { activeTab } = this.state

    if (!policies) {
      return null
    }

    for (let index = 0; index < policies.length; index++) {
      const el = policies[index];
      if (el.label == activeTab) {
        return el
      }
    }

    return null
  }

  getPolicyDescription = () => {
    const { activeTab, policies } = this.state

    if (!policies) {
      return ''
    }

    for (let index = 0; index < policies.length; index++) {
      const el = policies[index];
      if (el.label == activeTab) {
        return el
      }
    }

    return ''
  }

  render() {
    const { title, updatePolicyState, error } = this.props;
    const { activeTab, policies } = this.state;
    const content = this.getPolicyContent()
    const desc = this.getPolicyDescription()

    return (<Card>
      {title &&
        <CardHeader tag="h4" color='white'>
          {title}
        </CardHeader>
      }
      <CardBody>
        <CardTitle>
          Store Policies
        </CardTitle>
        <Row>
          <Col xs="12">
            {policies.map((policy, index) => (
              <ListGroup
                onClick={() => this.setState({ activeTab: policy.label })}
                style={{ 'cursor': 'pointer' }}
                key={index}
                className="mb-3">
                <ListGroupItem active={activeTab == policy.label} className='font-size-15'>
                  {policy.title}
                </ListGroupItem>
              </ListGroup>
            ))}
          </Col>
          <Col xs="12">
            <Card outline>
              <CardBody>
                <Alert color='info'>
                  {desc.description}
                </Alert>

                {(error && error.message) &&
                  <UncontrolledAlert color='danger'>
                    {error.message}
                  </UncontrolledAlert>
                }

                <ShopPolicyCard
                  label={desc.label}
                  description={desc.description}
                  title={desc.title}
                  policy={content} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>

    </Card>)
  }
}

const mapStateToProps = ({ Shop }) => ({
  updatePolicyState: Shop.updatePolicy,
  error: Shop.error,
})

export default connect(mapStateToProps, null)(ShopPolicy);
