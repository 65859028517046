import React from "react";
import { Button } from "reactstrap";
import EmptySummaryDashboard from "../EmptySummaryDashboard";
import CardsServiceKnowledge from "../../ServiceKnowledge/CardsServiceKnowledge";
import { PlusIcon } from "@heroicons/react/20/solid";
import Skeleton from "react-loading-skeleton";

const ListKnowledgeProduct = ({ loading, data, goToAddServiceKnowledge }) => {
  if (loading) {
    return (
      <div className="store-summary-product">
        {[...Array(5)].map((itm, idx) => {
          return (
            <div className="card-skeleton-knowledge-service" key={idx}>
              <Skeleton className="skeleton" height={30} />
              <div style={{ marginTop: "4px" }} />
              <Skeleton className="skeleton" height={18} width={100} />
              <div style={{ marginTop: "8px" }} />
              <Skeleton className="skeleton" height={18} width={100} />
            </div>
          );
        })}
      </div>
    );
  }

  if (!data || data?.length === 0) {
    return (
      <EmptySummaryDashboard
        title="Belum Ada Pengetahuan Layanan"
        subtitle="Anda belum memasukkan informasi pengetahuan layanan."
        btn={{
          title: "Tambah Pengetahuan Layanan",
          url: "/add-service-knowledge",
          icon: <PlusIcon />,
        }}
      />
    );
  }

  return (
    <>
      <div className="section-edit-product-recommend">
        <Button
          color="primary"
          className="btn-edit-product-recommend"
          onClick={() => goToAddServiceKnowledge()}
        >
          <PlusIcon /> Tambah Pengetahuan Layanan
        </Button>
      </div>
      <div className="store-summary-product">
        {data.slice(0, 5).map((itm, idx) => {
          return <CardsServiceKnowledge key={idx} itm={itm} />;
        })}
      </div>
    </>
  );
};

export default ListKnowledgeProduct;
