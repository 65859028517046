import React from "react";
import Skeleton from "react-loading-skeleton";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

const SectionInformation = ({ loading, informationStore }) => {
  if (loading) {
    return (
      <div className="store-summary-information-content">
        {[...Array(2)].map((items, idx) => {
          return (
            <div key={idx} className="card-store-information">
              <div className="card-store-information-content">
                <Skeleton height={16} width={200} />
                <Skeleton height={24} width={80} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="store-summary-information-content">
      {informationStore.map((items, idx) => {
        return (
          <div key={idx} className="card-store-information">
            <div className="card-store-information-content">
              <div className="title-card-store-information">
                <span>{items.title ? items.title : "0"}</span>
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={items.tooltip ? items.tooltip : "-"}
                  data-tooltip-place="bottom"
                >
                  <InformationCircleIcon />
                </a>
              </div>
              <span className="value-card-store-information">
                {items.value ? items.value : "0"}
              </span>
            </div>
            <Tooltip
              id="my-tooltip"
              style={{
                maxWidth: "250px",
                width: "100%",
                backgroundColor: "#323232",
                borderRadius: "8px",
                padding: "16px",
                fontFamily: '"Poppins", sans-serif',
                fontSize: "12px",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SectionInformation;
