import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { get } from "network/http/api";
import { useOutsideClick } from "helpers/hooks/useOutsideClick";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { notificationErrorRequestAction } from "store/notification/actions";
import OperationScheduleCard from "../OperationHour/OperationScheduleCard";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAgentOperationHourRequest } from "store/agent-operation-hour/actions";

const MenuMobile = () => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [dataSchedule, setDataSchedule] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSchedule, setIsOpenSchedule] = useState(false);
  const history = useHistory();

  useOutsideClick(() => {
    setIsOpen(false);
  }, modalRef);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleDropdownSchedule = () => {
    setIsOpenSchedule(!isOpenSchedule);
  };

  const fetchAccuracyData = () => {
    get("/user/ai-accuracy-score")
      .then(res => {
        setData(res);
      })
      .catch(err => {
        console.error(err);
        dispatch(notificationErrorRequestAction(err.detail));
      });
  };

  const fetchSchedule = () => {
    get("/agent-operation-hour")
      .then(res => {
        const mergedData = res.platforms.map(platform => {
          const operationHours = res.operation_hours.find(
            hour => hour.platform_id === platform.id
          );

          return {
            ...platform,
            operation_hours: operationHours || null,
          };
        });

        setDataSchedule(mergedData.length ? mergedData : []);
      })
      .catch(err => {
        dispatch(notificationErrorRequestAction(err.detail));
      });
  };

  useEffect(() => {
    fetchAccuracyData();
  }, []);

  useEffect(() => {
    fetchSchedule();
  }, []);

  const handleUpdateSchedule = () => {
    fetchSchedule();
  };

  const findBackground = params => {
    if (params <= 30) {
      return "#FBE9E9";
    } else if (params <= 60) {
      return "#FEFAE9";
    } else if (params <= 90) {
      return "#ECF2FD";
    } else if (params === 100) {
      return "#E8F9ED";
    } else {
      return "#e5e7eb";
    }
  };

  const findStatus = params => {
    if (params === 0) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>
            Status : Anda belum menghubungkan RISA AI ke informasi produk dan
            layanan
          </span>
          , Tambahkan agar RISA AI dapat mulai menjawab secara otomatis.
        </span>
      );
    } else if (params <= 30) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Kurang Baik</span>,
          sistem RISA AI belum mampu menjawab pertanyaan pelanggan dengan baik.
          Tambahkan informasi produk dan layanan.
        </span>
      );
    } else if (params <= 60) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Cukup Baik</span>,
          tingkatkan akurasi RISA AI dengan menambahkan informasi produk dan
          layanan.
        </span>
      );
    } else if (params <= 90) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Sangat Baik</span>,
          sistem RISA AI telah dapat menjawab pertanyaan pelanggan Anda dengan
          sangat baik.
        </span>
      );
    } else if (params === 100) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Terbaik</span>, sistem
          RISA AI telah dapat menjawab pertanyaan pelanggan Anda dengan performa
          terbaik.
        </span>
      );
    } else {
      return (
        <span className="status" style={{ fontWeight: "700" }}>
          No Data
        </span>
      );
    }
  };

  const goToPage = params => {
    history.push(params);
  };

  return (
    <div className="list-menu-mobile">
      <div className="list" onClick={handleToggleDropdown}>
        <span>Akurasi Risa AI: {data?.score}%</span>
      </div>
      <div className="list" onClick={handleToggleDropdownSchedule}>
        <span>Jadwal Operasi</span>
      </div>
      {isOpen && (
        <div className="modal-mobile-sidebar">
          <div className="mobile-header">
            <div className="content">
              <div className="icon-close">
                <XMarkIcon
                  className="icon-close"
                  onClick={handleToggleDropdown}
                />
              </div>
              <div className="title">Akurasi Risa AI</div>
              <div />
            </div>
          </div>
          <div className="modal-accuracy-section">
            <span className="title">Akurasi RISA AI</span>
            <span className="subtitle">
              Menunjukkan akurasi jawaban yang diberikan secara otomatis ke pada
              pelanggan Anda.
            </span>
            <div
              className="modal-accuracy-section-content"
              style={{
                backgroundColor: findBackground(data?.score),
              }}
            >
              <span className="score">{data?.score}%</span>
              {findStatus(data?.score)}
              <div className="section-button">
                <Button
                  outline
                  color="primary"
                  onClick={() => goToPage("/ecommerce")}
                >
                  Informasi Produk
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() => goToPage("/service-knowledge")}
                >
                  Informasi Layanan
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpenSchedule && (
        <div className="modal-mobile-sidebar">
          <div className="mobile-header">
            <div className="content">
              <div className="icon-close">
                <XMarkIcon
                  className="icon-close"
                  onClick={handleToggleDropdownSchedule}
                />
              </div>
              <div className="title">Jadwal Operasi</div>
              <div />
            </div>
          </div>
          <div className="modal-operation-section">
            <span className="title">Jadwal Operasi</span>
            <span className="subtitle">
              Menunjukkan waktu kapan RISA AI akan menjawab pesan dari pelanggan
              secara otomatis.
            </span>
            {dataSchedule.length && (
              <div className="d-flex flex-column mt-3 modal-operation-section-content">
                {dataSchedule
                  ?.filter(item => item.operation_hours !== null)
                  .map((item, idx) => {
                    return (
                      <OperationScheduleCard
                        key={idx}
                        item={item}
                        onUpdateSchedule={handleUpdateSchedule}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ AgentOperationHour }) => {
  const { agentOperationHour, isFetching, successMessage, error, isUpdate } =
    AgentOperationHour;
  return {
    agentOperationHour,
    isFetching,
    isUpdate,
    successMessage,
    error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onGetSchedule: getAgentOperationHourRequest,
  })(withTranslation()(MenuMobile))
);
