import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import Tabs from "components/Tabs";
import ContentTabsRefund from "./ContentTabsRefund";
import ContentTabsWarranty from "./ContentTabsWarranty";
import ContentTabsAfterSale from "./ContentTabsAfterSale";

const SecondStepInputDataPolis = ({ step, setStep, data, setData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [errorField, setErrorField] = useState({
    refund: false,
    warrant: false,
    after_sale: false,
  });
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handleTabClick = params => {
    setActiveTab(params);
  };

  useEffect(() => {
    const isInformationEmpty =
      errorField.refund || errorField.warrant || errorField.after_sale;

    if (isInformationEmpty) {
      setErrorField({
        ...errorField,
        refund: errorField.refund,
        warrant: errorField.warrant,
        after_sale: errorField.after_sale,
      });
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [data]);

  const DataRender = [
    {
      id: 0,
      name: "Kebijakan Pengembalian/Refund",
      content: (
        <ContentTabsRefund
          desc="Policy ini akan digunakan Risa untuk merespon ketika Customer ingin menanyakan refund prosess/pengajuan refund"
          data={data}
          setData={setData}
          error={errorField}
          setError={setErrorField}
        />
      ),
      url: "",
      errors: errorField.refund,
    },
    {
      id: 1,
      name: "Kebijakan Garansi",
      content: (
        <ContentTabsWarranty
          desc="Apakah product anda memiliki garansi? Policy ini akan digunakan Risa
            untuk menjawab pertanyaan tentang garansi product"
          data={data}
          setData={setData}
          error={errorField}
          setError={setErrorField}
        />
      ),
      url: "",
      errors: errorField.warrant,
    },
    {
      id: 2,
      name: "After Sale",
      content: (
        <ContentTabsAfterSale
          desc="Apakah product anda memiliki customer after sale? Policy ini akan
            digunakan Risa untuk menjawab pertanyaan tentang after sale product
            e.g Customer Service, Lokasi Service Spare Part dan lain-lain"
          data={data}
          setData={setData}
          error={errorField}
          setError={setErrorField}
        />
      ),
      url: "",
      errors: errorField.after_sale,
    },
  ];

  const goToNext = () => {
    const isErrorField =
      errorField.refund || errorField.warrant || errorField.after_sale;

    const isInformationEmpty =
      data.policies.refund === "" ||
      data.policies.warrant === "" ||
      data.policies.after_sale === "";

    if (isInformationEmpty || isErrorField) {
      setErrorField({
        ...errorField,
        refund: data.policies.refund === "",
        warrant: data.policies.warrant === "",
        after_sale: data.policies.after_sale === "",
      });
      setDisabledBtn(true);
    } else {
      setStep(100);
    }
  };

  return (
    <div className="page-integration-shop-form">
      <div
        style={{
          borderBottom: "1px solid #c2c7cf",
          margin: "36px 0px",
        }}
      />
      <h6 className="subtitle">
        Data polis membantu RISA AI dalam menjawab inquiry customer terkait
        polis toko Anda.
      </h6>
      <div className="page-integration-shop-form-content">
        <Tabs
          tabs={DataRender}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          full
        />
        <div
          style={{
            borderBottom: "1px solid #C2C7CF",
            margin: "20px 0px",
          }}
        />
        <div className="field-btn">
          <Button
            color="primary"
            onClick={() => goToNext()}
            disabled={disabledBtn}
          >
            Selanjutnya
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SecondStepInputDataPolis;
