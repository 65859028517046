import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

const FilterBottom = ({ list, selectedItems, setSelectedItems, onClose }) => {
  const handleCheckboxChange = item => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem.id !== item.id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleOnChecked = item => {
    let selected = false;
    selectedItems.map(v => {
      if (item.id === v.id) {
        selected = true;
      }
    });
    return selected;
  };

  return (
    <div className="section-filter-bottom">
      <div className="section-header">
        <span>Filter Kategori</span>
        <XMarkIcon onClick={onClose} />
      </div>
      <div className="list-filter">
        {Boolean(list?.length) && (
          <>
            {list.map((item, index) => (
              <label key={index} className="checkbox-label">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  value={item.name}
                  checked={handleOnChecked(item)}
                  onChange={() => handleCheckboxChange(item)}
                />
                {item.name}
              </label>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default FilterBottom;
