import {
  ONBOARDING,
  ONBOARDING_SUCCESS,
  ONBOARDING_API_ERROR,
} from "./actionTypes";

const initialState = {
  data: {},
  error: "",
  loading: false,
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ONBOARDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload,
      };
      break;
    case ONBOARDING_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default onboarding;
