import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, List, ListGroup, ListGroupItem, Row } from "reactstrap";

class ShopDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }
  render() {
    const {shop} = this.props;
    return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="avatar-xs me-4">
              <span className="avatar-title">
                {shop.store_name.charAt(0)}
              </span>
            </div>

            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15">
                {shop.store_name}
              </h5>
              <p className="text-muted">{shop.tag_line}</p>
            </div>
          </div>

          <p className="font-size-15 mt-4">
            <strong>Description</strong>: {shop.description}
          </p>

          <h5 className="font-size-15 mt-4">Store Details :</h5>
          <div className="font-size-14">
            <ListGroup>
              <ListGroupItem>
                <strong>Integration Status</strong>: {shop.authorize_status}
                {shop.authorize_status !== 'completed' &&
                  <Alert className="mt-3">
                    <p>
                      Pengajuan Integrasi menunggu approval dari Anda dan tim Tokopedia.
                    </p>
                    <p>
                      Anda perlu menyetujui sendiri integrasi melalui Email yang dikirimkan pihak Tokopedia ke email Tokopedia merchant anda.
                    </p>
                  </Alert>
                }
              </ListGroupItem>
              <ListGroupItem>
                <strong>Platform</strong>:<br/><span></span>{shop.platform ? shop.platform.platform_name : ''}
              </ListGroupItem>
              <ListGroupItem>
                <strong>Domain</strong>:<br/>{shop.domain}
              </ListGroupItem>
              <ListGroupItem>
                <strong>Store URL</strong>:<br/>
                <a href={shop.store_url}> {shop.store_url} </a>
              </ListGroupItem>
            </ListGroup>
          </div>
        </CardBody>
      </Card>
    </Fragment>
    );
  }
}

ShopDetail.propTypes = {
  project: PropTypes.object,
};

export default ShopDetail;
