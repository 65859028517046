import React, { useState } from "react";
import { Modal } from "reactstrap";
import ChoosePackage from "./ChoosePackage";
import UpgradePackage from "./UpgradePackage";

const ModalPackage = ({ billing, isOpen, toggle }) => {
  const [step, setStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [menu, setMenu] = useState({
    index: 0,
    value: "monthly",
    label: "Bulanan",
    priceSuffix: "per bulan",
  });

  const resetStep = () => {
    setStep(0);
    toggle();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="section-modal-billing"
    >
      {step === 0 && (
        <ChoosePackage
          key="choose-package"
          menu={menu}
          billing={billing}
          currentPlanId={billing.plan_id}
          selectedPlan={selectedPlan}
          onPickTypeTier={(paramsItem) => setMenu(paramsItem)}
          onPickTier={(paramsItem) => setSelectedPlan(paramsItem)}
          toggle={toggle}
          setStep={setStep}
        />
      )}

      {step === 1 && (
        <UpgradePackage
          key="upgrade-package"
          type={menu}
          currentPlanId={billing.plan_id}
          packageUpgrade={selectedPlan}
          setStep={setStep}
          toggle={resetStep}
        />
      )}
    </Modal>
  );
};

export default ModalPackage;