import { ErrorMessage, Field, Form, Formik } from "formik";
import { post } from "network/http/api";
import { InviteEmployeeScheme } from "pages/Authentication/SchemaValidation";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Button, InputGroup, InputGroupText, Table } from "reactstrap";
import { Input } from "reactstrap";
import { Label, ModalFooter, Spinner, Modal, ModalBody, ModalHeader } from "reactstrap";
import { notificationErrorRequestAction, notificationSuccessRequestAction } from "store/notification/actions";



const InviteEmployeeModal = ({ isOpen, toggle, refreshList }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleOnSubmit = (data) => {
    if(isLoading){
      return
    }

    setIsLoading(true)
    const body ={
      ...data,
      company_id: 0,
    }
    post('/company/employee/invite', body)
    .then(resp=>{
      console.log('success invite user')
      toggle()
      setIsLoading(false)
      dispatch(notificationSuccessRequestAction('Berhasil Mengundang Karyawan!!!'))
      refreshList()
    })
    .catch(err => {
      let {detail} =err
      if(!detail || detail === ''){
        detail = 'Terjadi kesalahan mohon coba kembali'
      }
      console.error(err)
      setIsLoading(false)
      dispatch(notificationErrorRequestAction(detail))
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={'invite-employee-modal'}>
      <ModalHeader>
        Undang Karyawan
      </ModalHeader>
      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: "",
          }}
          validationSchema={InviteEmployeeScheme}
          onSubmit={values => {
            handleOnSubmit(values)
          }}
        >
          {({ errors, status, touched, isValid }) => (
            <Form className="form-auth">
              <div className="mb-3">
                <Label for="name" className="form-label">
                  Nama
                </Label>
                <Field
                  name="name"
                  type="text"
                  className={
                    "form-control" +
                    (errors.name && touched.name
                      ? " is-invalid"
                      : "")
                  }
                  style={{ height: "45px" }}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="mb-3">
                <Label for="email" className="form-label">
                  Email
                </Label>
                <Field
                  name="email"
                  type="email"
                  className={
                    "form-control" +
                    (errors.email && touched.email
                      ? " is-invalid"
                      : "")
                  }
                  style={{ height: "45px" }}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="mb-3">
                <Label for="email" className="form-label">
                  Password
                </Label>
                <Field
                  name="password"
                  type="password"
                  className={
                    "form-control" +
                    (errors.password && touched.password
                      ? " is-invalid"
                      : "")
                  }
                  style={{ height: "45px" }}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              {isLoading && (
                <div className="mt-3 d-grid">
                  <div className="text-center">
                    <Spinner color="primary" type="grow" />
                  </div>
                </div>
              )}

              <div className="action">
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isValid || isLoading}
                >
                  Undang
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>

      </ModalFooter>
    </Modal>
  )
}

export default InviteEmployeeModal;