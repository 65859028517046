import React, { Component, Fragment, useEffect, useState } from 'react'

// Import Editor
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ToolbarVariable from './ToolbarVariable'

const defaultToolbarOptions = {
    // HACK: we change default component for color picker with ToolbarVariable
    options: ['emoji', 'inline',],
    inline: {
        options: ['bold', 'italic'],
    },
}

const TextEditor = ({ text, onChange, id, readOnly }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [toolbarOptions, setToolbarOptions] = useState(defaultToolbarOptions)

    useEffect(() => {
        const content = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(text)
            )
        )

        setEditorState(content)
    }, [])

    useEffect(() => {
        const content = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(text)
            )
        )

        setEditorState(content)
    }, [id])


    const handleOnChangeEditor = (e) => {
        setEditorState(e)

        if (onChange) {
            let content = draftToHtml(convertToRaw(e.getCurrentContent()))
            onChange(content)
        }

    }

    return (
        <Editor
            wrapperId={id}
            toolbar={toolbarOptions}
            // defaultContentState={defaultContent}
            editorState={editorState}
            readOnly={readOnly}
            toolbarCustomButtons={[<ToolbarVariable />]}
            onEditorStateChange={handleOnChangeEditor}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            placeholder="Masukan pesan Anda di sini..."
        />
    )

}

export default TextEditor
