const { get, post, put, del } = require("network/http/api")

export const getScenario = (page=1) => {
  return get(`/scenario?page=${page}`)
}

export const getScenarioDetails = (scenarioId) => {
  return get(`/scenario/${scenarioId}`)
}

export const addNewScenario = (scenario) => {
  return post("/scenario", scenario)
}

export const updateScenario = (scenario) => {
  return put("/scenario", scenario)
}

export const deleteScenario = (scenarioId) => {
  return del(`/scenario/${scenarioId}`)
}
