import React, { useCallback } from 'react'
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap'
import TextEditor from '../TextEditor/TextEditor'
import ReactDragListView from 'react-drag-listview';
import { v4 as uuidv4 } from 'uuid';
import UploadImage from 'components/UploadImage';
import Image from 'components/Image';
import classNames from 'classnames';

const SendMessageConfig = ({ node, onChange, variables }) => {

    const handleOnChangeQuestion = useCallback((i, e) => {
        variables[i].question = e
        onChange(variables)
    }, [variables])

    const handleOnClone = useCallback(variable => {
        let updated = [...variables];

        let id = uuidv4()
        updated.push({
            ...variable,
            id,
        });

        onChange(updated)
    }, [variables])

    const handleOnDelete = useCallback(i => {
        let updated = [...variables];
        updated.splice(i, 1);

        onChange(updated)
    }, [variables])

    const handleOnAddVariable = useCallback(() => {
        let updated = [...variables]

        updated.push({
            id: uuidv4(),
            ai_node_action_id: node.id,
            answer: "",
            question: "",
            variable_name: "",
            validation_type: 0
        })
        onChange(updated)
    }, [variables])

    const handleOnAddImage = useCallback((file) => {
        let updated = [...variables]

        updated.push({
            id: uuidv4(),
            ai_node_action_id: node.id,
            answer: "",
            question: file.path,
            variable_name: "image",
            validation_type: 11
        })
        onChange(updated)
    }, [variables])

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            let updated = [...variables];
            const item = updated.splice(fromIndex, 1)[0];
            updated.splice(toIndex, 0, item);

            onChange(updated)
        },
        nodeSelector: '.ask-message-item',
        handleSelector: '.ask-message-item .handle'
    };


    const renderVariable = () => {
        if (!variables || variables.length < 1) {
            return <Alert color="secondary">Empty</Alert>
        }

        return variables.map((variable, idx) => (
            <div className='ask-message-item' key={idx}>
                <Card>
                    <CardBody>
                        <div className='handlers'>
                            <ul>
                                <li onClick={() => handleOnClone(variable)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                    </svg>

                                </li>
                                <li onClick={() => handleOnDelete(idx)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>

                                </li>
                                <li className='handle'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                                    </svg>


                                </li>
                            </ul>
                        </div>
                        <div key={idx} className={classNames('input', variable.validation_type == 11 ? 'image' : '')}>
                            {variable.validation_type !== 11 &&
                                <TextEditor
                                    id={variable.id}
                                    text={variable.question}
                                    placeholder="Masukan pesan anda disini"
                                    onChange={(e) => handleOnChangeQuestion(idx, e)}
                                />
                            }
                            {variable.validation_type == 11 &&
                                <Image src={variable.question} />
                            }

                        </div>
                        <p className='small-note'>Pesan akan muncul sesuai urutan yang Anda berikan. Semakin atas, pesan akan muncul terlebih dahulu.</p>
                    </CardBody>
                </Card>
            </div>
        ))
    }



    return <div className='send-message-config'>
        <ReactDragListView
            {...dragProps}>
            {renderVariable()}
        </ReactDragListView>

        <Row>
            <Col>
                <Button
                    onClick={handleOnAddVariable} color='primary' outline>
                    Tambahkan pesan
                </Button>
            </Col>
            <Col>
                <UploadImage
                    onChangeImage={handleOnAddImage}
                    text={"Tambahkan media"} />
            </Col>
        </Row>

    </div>

}

export default SendMessageConfig