import axios from "axios"
import { JWT_TOKEN_KEY } from "./jwt-token-access"

//apply base url for axios
export const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)


function addBearerToken(config) {
  if (!localStorage.getItem(JWT_TOKEN_KEY)) {
    return config
  }


  if ('headers' in config) {
    if (config['headers']['Authorization']) {
      return config
    }

    config['headers'] = {
      ...config['headers'],
      "Authorization": 'Bearer ' + localStorage.getItem(JWT_TOKEN_KEY)
    }
  } else {
    config = {
      'headers': {
        "Authorization": 'Bearer ' + localStorage.getItem(JWT_TOKEN_KEY)
      }
    }
  }

  return config
}


export const handleErrorRequest = (error) => {
  console.error('[handleErrorRequest] error', error.request)
  if (error.detail) {
    return error.detail
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return error.response.data.detail
  } else if (error.request) {
    if (error.request.status == 404) {
      return error.request.statusText
    }

    if (error.request.status == 0) {
      return "Internal Server Error"
    }
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request.statusText
  }

  // Something happened in setting up the request that triggered an Error
  return "Internal Server Error"
}


export async function get(url, config = {}) {
  config = addBearerToken(config)
  return await axiosApi.get(url, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': error.request.status,
      }
      throw errorDetail
    })
}

export async function post(url, data, config = {}) {
  config = addBearerToken(config)
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': error.request.status,
      }
      throw errorDetail
    })
}

export async function put(url, data, config = {}) {
  config = addBearerToken(config)
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': error.request.status,
      }
      throw errorDetail
    })
}

export async function del(url, config = {}) {
  config = addBearerToken(config)
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': error.request.status,
      }
      throw errorDetail
    })
}

export async function uploadFile(url, file, headers) {
  var formData = new FormData();
  formData.append("file", file);
  return await axiosApi.post(url, formData, {
    'headers': {
      "Authorization": 'Bearer ' + localStorage.getItem(JWT_TOKEN_KEY),
      ...headers,
    }
  })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    })
}
