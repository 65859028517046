import CommonEditor from "components/Common/CommonEditor";
import { post } from "network/http/api";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { XMarkIcon } from "@heroicons/react/24/solid";

const ModalAddQuestionAnswer = ({
  isOpen,
  toggle,
  type,
  productId,
  knowledgeBaseId,
  onAdded,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOnSubmit = () => {
    if (isLoading) return;

    if (question === "" || answer === "") {
      setErrorMessage("Field is required");
      return;
    }

    if (type === "product" && !productId) {
      setErrorMessage("Product not found");
      return;
    }

    if (type !== "product" && !knowledgeBaseId) {
      setErrorMessage("Knowledge service not found");
      return;
    }
    const data = {
      product_id: productId,
      knowledge_id: knowledgeBaseId,
      qnas: [
        {
          question: question,
          answer: answer,
          product_id: productId,
          knowledge_base_id: knowledgeBaseId,
        },
      ],
    };
    setIsLoading(true);
    post("/knowledge-base/question-answer", data)
      .then(resp => {
        setIsLoading(false);
        setErrorMessage(null);
        setQuestion("");
        setAnswer("");
        onAdded();
        toggle();
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
        if (err.detail) {
          setErrorMessage(err.detail);
          return;
        }
        setErrorMessage("Terjadi kesalahan, mohon coba kembali");
      });
  };

  const handleOnCancel = () => {
    setQuestion("");
    setAnswer("");
    setErrorMessage(null);
    setIsLoading(false);
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="modal-add-question-answer"
    >
      <ModalHeader className="section-title">
        Tambah Pengetahuan <XMarkIcon onClick={toggle} />
      </ModalHeader>
      <ModalBody className="section-body">
        <div className="mb-3">
          <span className="title">Pertanyaan</span>
          <CommonEditor
            onChange={e => setQuestion(e)}
            text={question}
            placeholder="Masukan pertanyaan anda di sini"
          />
        </div>
        <div>
          <span className="title">Jawaban</span>
          <CommonEditor
            className="mt-1"
            onChange={e => setAnswer(e)}
            text={answer}
            placeholder="Masukan jawaban anda di sini"
          />
        </div>
        {isLoading && (
          <div className="text-center">
            <Spinner type="grow" color="primary" />
          </div>
        )}
        {errorMessage && (
          <div className="mt-3 mb-3">
            <Alert color="danger">{errorMessage}</Alert>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={isLoading}
          outline
          onClick={handleOnCancel}
        >
          Cancel
        </Button>
        <Button color="primary" disabled={isLoading} onClick={handleOnSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAddQuestionAnswer;
