import { call, put, takeEvery } from "redux-saga/effects"

// Ticket Comment Redux States
import { GET_TICKET_COMMENTS, ADD_NEW_TICKET_COMMENT, DELETE_TICKET_COMMENT, UPDATE_TICKET_COMMENT } from "./actionTypes"
import {
  getTicketCommentsSuccess,
  getTicketCommentsFail,
  addTicketCommentFail,
  addTicketCommentSuccess,
  updateTicketCommentSuccess,
  updateTicketCommentFail,
  deleteTicketCommentSuccess,
  deleteTicketCommentFail,
} from "./actions"

//Include Both Helper File with needed methods
import * as api from './api'

function* fetchTicketComments({payload: {ticketId, page, limit}}) {
  try {
    const response = yield call(api.fetchComent, ticketId, page, limit)
    yield put(getTicketCommentsSuccess(response))
  } catch (error) {
    yield put(getTicketCommentsFail(error))
  }
}

function* onUpdateTicketComment({ payload: {comment, id} }) {
  try {
    const response = yield call(api.updateComment, id, comment)
    yield put(updateTicketCommentSuccess(response))
  } catch (error) {
    yield put(updateTicketCommentFail(error))
  }
}

function* onDeleteTicketComment({ payload }) {
  try {
    const response = yield call(api.deleteComment, payload)
    yield put(deleteTicketCommentSuccess(response))
  } catch (error) {
    yield put(deleteTicketCommentFail(error))
  }
}

function* onAddNewTicketComment({ payload }) {
  try {
    const response = yield call(api.createComment, payload)
    yield put(addTicketCommentSuccess(response))
  } catch (error) {
    yield put(addTicketCommentFail(error))
  }
}

function* projectsSaga() {
  yield takeEvery(GET_TICKET_COMMENTS, fetchTicketComments)
  yield takeEvery(ADD_NEW_TICKET_COMMENT, onAddNewTicketComment)
  yield takeEvery(UPDATE_TICKET_COMMENT, onUpdateTicketComment)
  yield takeEvery(DELETE_TICKET_COMMENT, onDeleteTicketComment)
}

export default projectsSaga
