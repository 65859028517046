import * as Yup from "yup";

const ProfileUpdate = Yup.object().shape({
  fullname: Yup.string().required("Nama Lengkap tidak boleh kosong"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Anda harus memasukkan email sesuai format"
    )
    .required("Email tidak boleh kosong"),
  phone_number: Yup.string()
    .min(10, "Nomor Handphone harus terdiri dari minimal 10 digit")
    .max(13, "Nomor Handphone harus terdiri dari maksimal 13 digit")
    .required("Nomor Handphone tidak boleh kosong"),
});

const ProfileCompanyUpdate = Yup.object().shape({
  name: Yup.string().required("Nama Perusahaan tidak boleh kosong"),
  employees: Yup.string().required("Besar Perusahaan tidak boleh kosong"),
  industry: Yup.string().required("Industry Perusahaan tidak boleh kosong"),
  department: Yup.string().required("Department Perusahaan tidak boleh kosong"),
});

const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(
      8,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .required(
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    ),
  password: Yup.string()
    .min(
      8,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .required(
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    ),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Untuk konfirmasi kata sandi, mohon masukkan kata sandi seperti yang sudah Anda masukkan sebelumnya"
    )
    .required(
      "Untuk konfirmasi kata sandi, mohon masukkan kata sandi seperti yang sudah Anda masukkan sebelumnya"
    ),
});

export { ProfileUpdate, ProfileCompanyUpdate, ChangePasswordSchema };
