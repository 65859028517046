import React, { memo, useState } from 'react';
import { useStore, useReactFlow } from 'reactflow';
import TextWrapper from './TextWrapper';
import NodeWrapper from './NodeWrapper';

import iconSendMessage from 'assets/images/kirim-pesan-icon.svg'
import SourceHandler from './SourceHandler';
import threeDotIcon from 'assets/images/three-dot.svg'

const connectionNodeIdSelector = (state) => state.connectionNodeId;

function SendMessage({ id, data, selected, focusable }) {
  const [displayNodeOption, setDisplayNodeOption] = useState(false)

  const reactInstance = useReactFlow()
  const connectionNodeId = useStore(connectionNodeIdSelector);

  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;

  const handleOnOpenConfig = () => {
    const node = reactInstance.getNode(id)
    data.onOpenConfig(node)
  }

  return (
    <NodeWrapper
      title={"Kirim Pesan"}
      titleIcon={iconSendMessage}
      displayNodeOption={displayNodeOption}
      id={id}
      data={data}
      selected={selected}
      isConnecting={isConnecting}
      isTarget={isTarget}>
      <div className="custom-node__body">
        {data && data.variables.map((v, idx) => (
          <div key={idx} className='text-wrapper mb-3'>
            {v.validation_type !== 11 &&
              <div key={idx} className='text-input'>
                <div>
                  <TextWrapper text={v.question || "Masukkan pesan Anda di sini…"} isHtml={true} />
                </div>
                <img className='three-dot-icon' src={threeDotIcon} />
              </div>
            }
            {v.validation_type === 11 &&
              <div key={idx} className='text-input image'>
                <div>
                  [Image]
                </div>
                <img className='three-dot-icon' src={threeDotIcon} />
              </div>
            }
          </div>
        ))}
        <SourceHandler id={id} data={data} selected={selected} />
      </div>
    </NodeWrapper>

  );
}

export default memo(SendMessage);
