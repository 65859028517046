import PropTypes from "prop-types";
import React, { Component } from "react";
import { Label, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import SectionImage from "./SectionImage";
import SectionMobileLogo from "./SectionMobileLogo";
import { ForgetPasswordSchema } from "./SchemaValidation";

// NETWORK
import { post } from "network/http/api";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      errorMessage: null,
      email: "",
    };
  }

  handleOnSubmit = values => {
    if (!values.email || values.email == "") {
      this.setState({ errorMessage: "Email is required" });
      return;
    }

    this.setState({ isLoading: true });
    post(`/auth/forgot-password`, { email: values.email })
      .then(resp => {
        this.setState({
          isLoading: false,
          isSuccess: true,
          errorMessage: null,
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          isSuccess: false,
          errorMessage: err.detail,
        });
        setTimeout(() => {
          this.setState({ errorMessage: null });
        }, 2000);
      });
  };

  render() {
    const { errorMessage, isLoading, isSuccess } = this.state;

    return (
      <React.Fragment>
        {errorMessage && (
          <div
            className="w-100 py-2 position-absolute text-center left-0 top-0 text-white"
            style={{ backgroundColor: "#FF5555", zIndex: 1 }}
          >
            {errorMessage || "Sorry, please try again"}
          </div>
        )}
        <div className="auth-page">
          <div className="section-auth-full">
            <SectionImage />
            <div className="section-content">
              <SectionMobileLogo />
              <div className="content">
                {isSuccess ? (
                  <div className="content-verification">
                    <h3 className="fs-2 text-center">Email Telah Dikirim</h3>
                    <span className="text-center">
                      Silakan cek dan ikuti instruksi di email yang telah kami
                      kirimkan untuk melanjutkan proses ubah sandi
                    </span>
                  </div>
                ) : (
                  <div className="content-auth">
                    <h3 className="fs-2 text-center">Temukan Akunmu</h3>
                    <p className="font-size-16 text-center sub-text-auth">
                      Masukkan email yang terkait dengan akun Anda untuk
                      mengubah sandi.
                    </p>
                    <div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: (this.state && this.state.email) || "",
                        }}
                        validationSchema={ForgetPasswordSchema}
                        onSubmit={values => {
                          this.handleOnSubmit(values);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-auth">
                            <div className="mb-2">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <Link to="/login" className="text-muted">
                              <span>Sudah punya akun?</span>
                              <span className="text-primary"> Login</span>
                            </Link>

                            {isLoading && (
                              <div className="mb-3 text-center">
                                <Spinner color="primary" type="grow" />
                              </div>
                            )}
                            <div className="mt-3 d-grid">
                              <button
                                disabled={isLoading}
                                className="btn btn-primary btn-block p-3"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
);
