import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  ONBOARDING,
  ONBOARDING_SUCCESS,
  ONBOARDING_API_ERROR,
} from "./actionTypes";

import { onboardingSuccess, onboardingApiError } from "./actions";

//Include Both Helper File with needed methods
import * as api from "./api";

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

function* onboardingRegister({ payload: { data, history } }) {
  try {
    // call api
    let response = yield call(api.onboardingPost, data);

    sleep(500);
    yield put(onboardingSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      yield put(onboardingApiError(data.detail));
    }
    console.error(error);
  }
}

function* onboardingSaga() {
  yield takeEvery(ONBOARDING, onboardingRegister);
}

export default onboardingSaga;
