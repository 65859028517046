import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { post, get, put, del } from "network/http/api";
import FormKnowledge from "./FormKnowledge";
import FormQuestionAnswer from "./FormQuestionAnswer";
import KnowledgeSidebar from "./KnowledgeSidebar";
import ListQuestionAnswer from "./ListQuestionAnswer";
import ModalQuestion from "./ModalQuestion";
import { useQuery } from "components/URLQuery";

const KnowledgePage = props => {
  let query = useQuery()
  const [selected, setSelected] = useState(1);
  const [knowledgeExiest, setKnowledgeExiest] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [dataKnowledge, setDataknowledge] = useState([]);
  const [pageKnowledge, setPageKnowledge] = useState(1);
  const [page, setPage] = useState(1);
  const [listQuestion, setListQuestion] = useState([]);
  const [id, setId] = useState(null);
  const [totalKnowledge, setTotalKnowledge] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [knowledge, setKnowledge] = useState({
    title: "",
    content: "",
  });
  const [questionAnswer, setQuestionAnswer] = useState({
    question: "",
    answer: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [editQuestionAnswer, setEditQuestionAnswer] = useState({
    id: "",
    question: "",
    answer: "",
    type: "",
    qna_id: "",
  });
  const [isOpenQuestion, setIsOpenQuestion] = useState(false);
  const [loadingKnowledge, setLoadingKnowledge] = useState(false);
  const [loadingQuestionAnswer, setLoadingQuestionAnswer] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const handleFocus = params => {
    setIsFocused(params);
  };

  const handleBlur = () => {
    setIsFocused(0);
  };

  const firstFetch = async () => {
    try {
      const res = await get(`/knowledge-base?page=${1}&&per_page=${15}`);
      setDataknowledge(res.knowledges);
      setTotalKnowledge(res.total_item);
      setPageKnowledge(1);
      if (res.total_item > 0) {
        setKnowledgeExiest(true)
      }
    } catch (err) {
      console.log(err);
      setLoadingKnowledge(false);
    }
  };

  useEffect(() => {
    const id = query.get('id')
    if (id) {
      setId(id)
      handleOnGetById(id)
    }
    firstFetch();
  }, []);

  const handleChangeKnowledge = e => {
    setKnowledge({
      ...knowledge,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitKnowledge = paramsValue => {
    const payload = {
      title: paramsValue.title,
      category: "",
      parent_id: "",
      content: paramsValue.content,
      labels: [],
    };
    setLoadingKnowledge(true);
    if (id !== null) {
      put(`/knowledge-base/${id}`, payload)
        .then(async resp => {
          await new Promise(resolve => setTimeout(resolve, 1000));
          setId(resp.id);
          fetchKnowledgeQuestion(1);
          firstFetch();
          setLoadingKnowledge(false);
        })
        .catch(err => {
          console.log(err, "err");
          setLoadingKnowledge(false);
        });
    } else {
      post(`/knowledge-base`, payload)
        .then(async resp => {
          await new Promise(resolve => setTimeout(resolve, 2000));
          setId(resp.id);
          firstFetch();
          setLoadingKnowledge(false);
        })
        .catch(err => {
          console.log(err, "err");
          setLoadingKnowledge(false);
        });
    }
  };

  const fetchKnowledgeQuestion = async paramsPage => {
    setLoadingQuestionAnswer(true);
    try {
      const res = await get(
        `/knowledge-base/${id}/question-answer?page=${paramsPage}&&per_page=${10}`
      );
      setTotalItem(res.total_item);
      setListQuestion(res.question_answer);
      setPage(paramsPage);
      setTimeout(() => {
        setLoadingQuestionAnswer(false);
      }, 1000);
    } catch (err) {
      console.log(err);
      setLoadingQuestionAnswer(false);
    }
  };

  const onSubmitQuestion = async paramsValue => {
    const payload = {
      knowledge_base_id: id,
      remark: "",
      question: paramsValue.question,
      answer: paramsValue.answer,
    };

    await post(`/knowledge-base/${id}/question-answer`, payload)
      .then(async resp => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setPage(1);
        firstFetch();
        fetchKnowledgeQuestion(1);
        setQuestionAnswer({
          question: "",
          answer: "",
        });
      })
      .catch(err => {
        console.log(err, "err");
      });
  };

  const handleChangeQuestion = e => {
    setQuestionAnswer({
      ...questionAnswer,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditQuestionAnswer = e => {
    setEditQuestionAnswer({
      ...editQuestionAnswer,
      [e.target.name]: e.target.value,
    });
  };

  const prevNavigation = paramsPage => {
    const prevPage = paramsPage - 1;
    fetchKnowledgeQuestion(prevPage);
  };

  const nextNavigation = paramsPage => {
    const nextPage = paramsPage + 1;
    fetchKnowledgeQuestion(nextPage);
  };

  const resetState = () => {
    setId(null);
    setPage(1);
    setKnowledge({
      title: "",
      content: "",
    });
    setQuestionAnswer({
      question: "",
      answer: "",
    });
  };

  const closePage = () => {
    setSelected(0);
    resetState();
  };

  const deleteKnowledge = () => {
    setLoadingPage(true);
    del(`/knowledge-base/${id}`)
      .then(async resp => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        resetState();
        fetchKnowledgeQuestion(1);
        setPageKnowledge(1);
        firstFetch();
        setIsOpen(false);
        setLoadingPage(false);
      })
      .catch(err => {
        console.log(err, "err");
        setLoadingPage(false);
      });
  };

  const handleOnGetById = async paramsId => {
    setLoadingPage(true);
    try {
      const res = await get(`/knowledge-base/${paramsId}`);
      setId(res.content.id);
      setKnowledge({
        title: res.content.title,
        content: res.content.content,
      });
      setListQuestion(res.question_answer.slice(0, 10));
      setTotalItem(res.total_question_answer);
      setLoadingPage(false);
    } catch (err) {
      console.log(err);
      setLoadingPage(false);
    }
  };

  const openModalQuestion = (type, paramsItems) => {
    setIsOpenQuestion(true);
    setEditQuestionAnswer({
      id: id,
      type: type,
      question: paramsItems.question,
      answer: paramsItems.answer,
      qna_id: paramsItems.id,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="page-menu-knowledge">
          {knowledgeExiest ? (
            <div className="page-container-knowledge">
              <KnowledgeSidebar
                dataKnowledge={dataKnowledge}
                setDataknowledge={setDataknowledge}
                pageKnowledge={pageKnowledge}
                setPageKnowledge={setPageKnowledge}
                setKnowledge={setKnowledge}
                totalKnowledge={totalKnowledge}
                setTotalKnowledge={setTotalKnowledge}
                setListQuestion={setListQuestion}
                setId={setId}
                selectedId={id}
                setPage={setPage}
                setTotalItem={setTotalItem}
                setReset={() => resetState()}
                setLoadingPage={setLoadingPage}
              />
              {!loadingPage ? (
                <div className="page-content-knowledge">
                  <div className="content-knowledge">
                    {selected === 0 && (
                      <div className="parent-create-knowledge">
                        <div
                          className="create-knowledge"
                          onClick={() => setSelected(1)}
                        >
                          <h2>Create Your Knowledge Base</h2>
                        </div>
                      </div>
                    )}
                    {selected === 1 && (
                      <>
                        <FormKnowledge
                          id={id}
                          knowledge={knowledge}
                          onSubmitKnowledge={onSubmitKnowledge}
                          closePage={closePage}
                          handleChangeKnowledge={handleChangeKnowledge}
                          isFocused={isFocused}
                          handleFocus={handleFocus}
                          handleBlur={handleBlur}
                          setIsOpen={setIsOpen}
                          loadingKnowledge={loadingKnowledge}
                        />
                        {id !== null && (
                          <div className="section-question">
                            <div className="example-knowledge-question">
                              <h2>Example Question</h2>
                              <span>
                                You can use this example to train you AI!
                              </span>
                            </div>
                            <FormQuestionAnswer
                              questionAnswer={questionAnswer}
                              onSubmitQuestion={onSubmitQuestion}
                              handleChangeQuestion={handleChangeQuestion}
                              isFocused={isFocused}
                              handleFocus={handleFocus}
                              handleBlur={handleBlur}
                            />
                            <ListQuestionAnswer
                              listQuestion={listQuestion}
                              openModalQuestion={openModalQuestion}
                              page={page}
                              prevNavigation={prevNavigation}
                              nextNavigation={nextNavigation}
                              totalItem={totalItem}
                              loadingQuestionAnswer={loadingQuestionAnswer}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="page-content-knowledge">
                  <div className="page-loading">
                    <div className="skeleton-box" style={{ width: "100%" }} />
                    <div
                      className="skeleton-box"
                      style={{ width: "100%", height: "300px" }}
                    />
                    <div className="skeleton-box" style={{ width: "350px" }} />
                    <div className="skeleton-box" style={{ width: "200px" }} />
                    <div className="skeleton-box" style={{ width: "100%" }} />
                    <div className="skeleton-box" style={{ width: "100%" }} />
                    <div className="skeleton-box" style={{ width: "160px" }} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="page-container-knowledge">
              <div
                className="page-onboard-knowledge"
                onClick={() => setKnowledgeExiest(true)}
              >

                <h2>Create Your Costum AI +</h2>
              </div>
            </div>
          )}
        </div>
        <ModalQuestion
          editQuestionAnswer={editQuestionAnswer}
          isOpenQuestion={isOpenQuestion}
          setIsOpenQuestion={setIsOpenQuestion}
          handleEditQuestionAnswer={handleEditQuestionAnswer}
          fetchKnowledgeQuestion={fetchKnowledgeQuestion}
          page={page}
        />
        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered>
          <ModalBody>
            <ModalHeader>Delete Knowledge</ModalHeader>
            <ModalBody>
              <span>
                Are you sure want to delete{" "}
                <i style={{ color: "#c35555" }}>{knowledge.title}</i> ? after
                you delete you can't show again this knowledge!
              </span>
            </ModalBody>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => deleteKnowledge()}>
              Delete
            </Button>
            <Button color="dark" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default withRouter(KnowledgePage);
