import * as Yup from "yup";

const RegisterSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Anda harus memasukkan email sesuai format"
    )
    .required("Anda harus memasukkan email sesuai format"),
  phone_number: Yup.string().required(),
  password: Yup.string()
    .min(
      8,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .required(
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    ),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Untuk konfirmasi kata sandi, mohon masukkan kata sandi seperti yang sudah Anda masukkan sebelumnya"
    )
    .required(
      "Untuk konfirmasi kata sandi, mohon masukkan kata sandi seperti yang sudah Anda masukkan sebelumnya"
    ),
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Email yang kamu masukkan salah"
    )
    .required("Email yang kamu masukkan salah"),
  password: Yup.string()
    .min(8, "Anda harus memasukkan minimal 8 karakter")
    .required("Kata sandi yang kamu masukkan salah"),
});


const InviteEmployeeScheme = Yup.object().shape({
  name: Yup.string()
    .required("Nama tidak boleh kosong"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Email yang kamu masukkan salah"
    )
    .required("Email yang kamu masukkan salah"),
  password: Yup.string()
    .min(8, "Anda harus memasukkan minimal 8 karakter")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .required("Kata tidak boleh kosong"),
});

const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Email tidak boleh kosong"),
});

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(
      8,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    )
    .required(
      "Anda harus memasukkan minimal 8 karakter, yang terdiri atas karakter, angka, dan karakter spesial"
    ),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Untuk konfirmasi kata sandi, mohon masukkan kata sandi seperti yang sudah Anda masukkan sebelumnya"
    )
    .required(
      "Untuk konfirmasi kata sandi, mohon masukkan kata sandi seperti yang sudah Anda masukkan sebelumnya"
    ),
});

export {
  RegisterSchema,
  LoginSchema,
  ForgetPasswordSchema,
  ResetPasswordSchema,
  InviteEmployeeScheme,
};
