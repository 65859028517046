import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFormDataTokopedia } from "store/actions";
import DropdownSelected from "components/DropdownSelected";
import Tabs from "components/Tabs";
import { Button, Input } from "reactstrap";
import ScheduleDeliveryInstant from "./ScheduleDeliveryInstant";
import ScheduleDeliveryRegular from "./ScheduleDeliveryRegular";
import { useHistory } from "react-router-dom";

const ThirdStepInputScheduleDelivery = ({ data, setData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dataShopOffline = [
    {
      name: "Yes",
      value: "yes",
      store: true,
    },
    {
      name: "No",
      value: "no",
      store: false,
    },
  ];
  const [haveShopOffline, setHaveShopOffline] = useState(null);
  const [errorShopOffline, setErrorShopOffline] = useState(false);
  const [shoOfflineLocation, setShopOfflineLocation] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const DataRender = [
    {
      id: 0,
      name: "Instant Cut Off",
      content: <ScheduleDeliveryInstant data={data} setData={setData} />,
      url: "",
    },
    {
      id: 1,
      name: "Regular Cut Off",
      content: <ScheduleDeliveryRegular data={data} setData={setData} />,
      url: "",
    },
  ];

  useEffect(() => {
    if (data.retail_attribute.offline_store !== null) {
      const resOfflaneStore = dataShopOffline.find(
        item => item.store === data.retail_attribute.is_offline_available
      );
      setHaveShopOffline(resOfflaneStore);
    }
    console.log('data', data)
    if (data.retail_attribute.store_location) {
      console.log('data', data.retail_attribute.store_location)
      setShopOfflineLocation(data.retail_attribute.store_location)
    }
  }, []);

  const handleTabClick = params => {
    setActiveTab(params);
  };

  const onChangeSelected = params => {
    if (params.value) {
      setErrorShopOffline(false);
    }

    setHaveShopOffline(params);
    setData({
      ...data,
      retail_attribute: {
        ...data.retail_attribute,
        is_offline_available: params.store,
      },
    });
  };

  const onChangeInput = params => {
    setShopOfflineLocation(params);
    setData({
      ...data,
      retail_attribute: {
        ...data.retail_attribute,
        offline_store_location: params,
      },
    });
  };

  const goToSummary = () => {
    if (data.retail_attribute.is_offline_available === null) {
      setErrorShopOffline(true);
      return;
    }

    dispatch(setFormDataTokopedia(data));
    history.push("/integration/tokopedia/summary");
  };

  return (
    <div className="page-integration-shop-form">
      <div
        style={{
          borderBottom: "1px solid #c2c7cf",
          margin: "36px 0px",
        }}
      />
      <h6 className="subtitle">
        Jadwal pengiriman membantu sistem RISA AI untuk menjawab terkait jadwal
        pengirimin yang toko Anda sediakan.
      </h6>
      <div className="page-integration-shop-form-content">
        <span className="title-content-page" style={{ marginTop: "8px" }}>
          Informasi Toko Offline
        </span>
        <div className="form-integration-shop">
          <DropdownSelected
            title="Apakah Anda Memiliki Offline store?"
            typeTitle="light"
            data={dataShopOffline}
            selected={haveShopOffline}
            setSelected={onChangeSelected}
            placeholder="Pilih salah satu jawaban"
            error={errorShopOffline}
          />
          {haveShopOffline?.value === "yes" && (
            <div className="field-form">
              <span className="title">Lokasi Toko</span>
              <Input
                className="section-title"
                onChange={e => onChangeInput(e.target.value)}
                value={shoOfflineLocation}
                placeholder="Masukkan lokasi toko Anda di sini..."
                style={{ marginTop: "8px" }}
              />
            </div>
          )}
        </div>
        <span className="title-content-page" style={{ marginTop: "24px" }}>
          Jadwal Pengiriman
        </span>
        <Tabs
          tabs={DataRender}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          full
        />
        <div
          style={{
            borderBottom: "1px solid #C2C7CF",
            margin: "20px 0px",
          }}
        />
        <div className="field-btn">
          <Button color="primary" onClick={() => goToSummary()}>
            Selanjutnya
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThirdStepInputScheduleDelivery;
