import {
  GET_SCENARIO,
  GET_SCENARIO_FAIL,
  GET_SCENARIO_SUCCESS,
  GET_SCENARIO_DETAIL,
  GET_SCENARIO_DETAIL_FAIL,
  GET_SCENARIO_DETAIL_SUCCESS,
  ADD_NEW_SCENARIO,
  ADD_SCENARIO_SUCCESS,
  ADD_SCENARIO_FAIL,
  UPDATE_SCENARIO,
  UPDATE_SCENARIO_SUCCESS,
  UPDATE_SCENARIO_FAIL,
  DELETE_SCENARIO,
  DELETE_SCENARIO_SUCCESS,
  DELETE_SCENARIO_FAIL,
  UPDATE_SCENARIO_NODE_DATA,
  UPDATE_SCENARIO_EDGE,
  ADD_SCENARIO_NODE,
} from "./actionTypes"

export const updateScenarioNode = (node)=> ({
  type: UPDATE_SCENARIO_NODE_DATA,
  payload: node,
})

export const addScenarioNode = (node)=> ({
  type: ADD_SCENARIO_NODE,
  payload: node,
})

export const updateScenarioEdge = (edges) => ({
  type: UPDATE_SCENARIO_EDGE,
  payload: edges,
})

export const getScenario = (page=1) => ({
  type: GET_SCENARIO,
  payload: {page},
})

export const getScenarioSuccess = scenarios => ({
  type: GET_SCENARIO_SUCCESS,
  payload: scenarios,
})

export const getScenarioFail = error => ({
  type: GET_SCENARIO_FAIL,
  payload: error,
})

export const getScenarioDetail = scenarioId => ({
  type: GET_SCENARIO_DETAIL,
  scenarioId,
})

export const getScenarioDetailSuccess = scenarioDetails => ({
  type: GET_SCENARIO_DETAIL_SUCCESS,
  payload: scenarioDetails,
})

export const getScenarioDetailFail = error => ({
  type: GET_SCENARIO_DETAIL_FAIL,
  payload: error,
})

export const addNewScenario = (scenario, history) => ({
  type: ADD_NEW_SCENARIO,
  payload: scenario,
  history,
})

export const addScenarioSuccess = scenario => ({
  type: ADD_SCENARIO_SUCCESS,
  payload: scenario,
})

export const addScenarioFail = error => ({
  type: ADD_SCENARIO_FAIL,
  payload: error,
})

export const updateScenario = scenario => ({
  type: UPDATE_SCENARIO,
  payload: scenario,
})

export const updateScenarioSuccess = scenario => ({
  type: UPDATE_SCENARIO_SUCCESS,
  payload: scenario,
})

export const updateScenarioFail = error => ({
  type: UPDATE_SCENARIO_FAIL,
  payload: error,
})

export const deleteScenario = scenario => ({
  type: DELETE_SCENARIO,
  payload: scenario,
})

export const deleteScenarioSuccess = scenario => ({
  type: DELETE_SCENARIO_SUCCESS,
  payload: scenario,
})

export const deleteScenarioFail = error => ({
  type: DELETE_SCENARIO_FAIL,
  payload: error,
})
