import React, { Component } from "react";
import { Label, Spinner } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import PasswordShowHide from "components/PasswordShowHide";

// import images
import SectionImage from "./SectionImage";
import SectionMobileLogo from "./SectionMobileLogo";

// NETWORK
import { get, post } from "network/http/api";
import { ResetPasswordSchema } from "./SchemaValidation";

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      errorMessage: null,
      userId: 0,
      token: "",
      password: "",
      password_confirmation: "",
    };
  }

  componentDidMount() {
    this.getParam();
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }
    this.getParam();
  }

  getParam = () => {
    const { match } = this.props;
    this.setState({
      token: match.params.token,
      userId: match.params.userId,
    });
  };

  handleOnSubmit = values => {
    if (!values.password || values.password == "") {
      this.setState({ errorMessage: "Password is required" });
      return;
    }

    if (!values.confirm_password || values.confirm_password == "") {
      this.setState({ errorMessage: "Password Confirmation is required" });
      return;
    }

    this.setState({ isLoading: true });
    const data = {
      user_id: this.state.userId,
      token: this.state.token,
      password: values.password,
      password_confirmation: values.confirm_password,
    };
    post(`/auth/reset-password`, data)
      .then(resp => {
        this.setState({
          isLoading: false,
          isSuccess: true,
          errorMessage: null,
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          isSuccess: false,
          errorMessage: err.detail,
        });
        setTimeout(() => {
          this.setState({ errorMessage: null });
        }, 2000);
      });
  };

  render() {
    const { errorMessage, validationFailed, isLoading, isSuccess } = this.state;

    return (
      <React.Fragment>
        {errorMessage && (
          <div
            className="w-100 py-2 position-absolute text-center left-0 top-0 text-white"
            style={{ backgroundColor: "#FF5555", zIndex: 1 }}
          >
            {errorMessage || "Sorry, please try again"}
          </div>
        )}
        <div className="auth-page">
          <div className="section-auth-full">
            <SectionImage />
            <div className="section-content">
              <SectionMobileLogo />
              <div className="content">
                {isSuccess ? (
                  <div className="content-verification">
                    <h3 className="fs-2 text-center">
                      Ganti password berhasil
                    </h3>
                    <span className="text-center">
                      Kamu bisa login ulang, dengan password yang baru.
                    </span>
                    <Link
                      to="/login"
                      className="btn btn-primary btn-block p-3 mt-3"
                    >
                      Login sekarang
                    </Link>
                  </div>
                ) : (
                  <div className="content-auth">
                    <h3 className="fs-2 text-center">Reset Kata Sandi</h3>
                    <p className="font-size-16 text-center">
                      Masukkan kata sandi baru Anda
                    </p>
                    <div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          password: "",
                          confirm_password: "",
                        }}
                        validationSchema={ResetPasswordSchema}
                        onSubmit={values => {
                          this.handleOnSubmit(values);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-auth">
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Kata Sandi Baru
                              </Label>
                              <Field
                                name="password"
                                type="password"
                                autoComplete="true"
                                component={PasswordShowHide}
                                styleClass={
                                  errors.password && touched.password
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                              {errors.password && (
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className={`invalid-feedback ${
                                    errors.password ? "d-block" : ""
                                  }`}
                                >
                                  {errors.password.message}
                                </ErrorMessage>
                              )}
                            </div>
                            <div className="mb-3">
                              <Label
                                for="confirm_password"
                                className="form-label"
                              >
                                Konfirmasi Kata Sandi
                              </Label>
                              <Field
                                name="confirm_password"
                                type="confirm_password"
                                autoComplete="true"
                                component={PasswordShowHide}
                                styleClass={
                                  errors.confirm_password &&
                                  touched.confirm_password
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                              {errors.confirm_password && (
                                <ErrorMessage
                                  name="confirm_password"
                                  component="div"
                                  className={`invalid-feedback ${
                                    errors.confirm_password ? "d-block" : ""
                                  }`}
                                >
                                  {errors.confirm_password.message}
                                </ErrorMessage>
                              )}
                            </div>
                            {isLoading && (
                              <div className="mb-3 text-center">
                                <Spinner type="grow" />
                              </div>
                            )}
                            <div className="mt-3 d-grid">
                              <button
                                disabled={isLoading}
                                className="btn btn-primary btn-block p-3"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ResetPasswordPage);
