import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import Select, { components, DropdownIndicatorProps } from "react-select";

const availableDayOption = [
  { value: 0, label: "Monday" },
  { value: 1, label: "Tuesday" },
  { value: 2, label: "Wednesday" },
  { value: 3, label: "Thursday" },
  { value: 4, label: "Friday" },
  { value: 5, label: "Saturday" },
  { value: 6, label: "Sunday" },
  { value: 7, label: "Weekday" },
  { value: 8, label: "Weekend" },
];

const availableWorkingHours = [
  "Off",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
];

const SelectWeekdayOption = ({ hour, title, onChangeHour }) => {
  return (
    <FormGroup>
      <Label for="exampleSelect">{title + " "}</Label>
      <InputGroup>
        <Input
          type="select"
          onChange={e => onChangeHour(e.target.value)}
          value={hour}
        >
          {availableWorkingHours.map(v => (
            <option key={v}>{v}</option>
          ))}
        </Input>
        <InputGroupText>WIB</InputGroupText>
      </InputGroup>
    </FormGroup>
  );
};

const SelectWeekdayContainer = ({ shop, onChange, value }) => {
  const [weekday, setWeekday] = useState(value["weekday"]);
  const [saturday, setSaturday] = useState(value["saturday"]);
  const [sunday, setSunday] = useState(value["sunday"]);
  const [holiday, setHoliday] = useState(value["holiday"]);

  useEffect(() => {
    setWeekday(value["weekday"]);
    setSaturday(value["saturday"]);
    setSunday(value["sunday"]);
    setHoliday(value["holiday"]);
  }, [value]);

  const handleOnChange = (key, v) => {
    value[key] = v;
    onChange({ ...value });
  };

  return (
    <Row>
      <SelectWeekdayOption
        title="Weekday ( Monday-Friday )"
        hour={weekday}
        onChangeHour={(e) => handleOnChange('weekday', e)}
      />

      <SelectWeekdayOption
        title="Saturday"
        hour={saturday}
        onChangeHour={e => handleOnChange("saturday", e)}
      />

      <SelectWeekdayOption
        title="Sunday"
        hour={sunday}
        onChangeHour={e => handleOnChange("sunday", e)}
      />

      <SelectWeekdayOption
        title="Holiday"
        hour={holiday}
        onChangeHour={e => handleOnChange("holiday", e)}
      />
    </Row>
  );
};

export default SelectWeekdayContainer;
