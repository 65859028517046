import React, { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { post } from "network/http/api";
import { Container, Button, Spinner, Alert } from "reactstrap";
import SectionQustionAnswer from "./SectionQuestionAnswer";
import BackButton from "components/BackButton";
import CustomToast from "components/CustomToast";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const AddServiceKnowledgeQuestion = () => {
  const location = useLocation();
  const { fromPage, pageId } = location.state;
  const { id } = useParams();
  const idParams = Number(id);
  const history = useHistory();
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataSelected, setDataSelected] = useState([
    { question: "", answer: "", knowledge_base_id: idParams, remark: "" },
  ]);
  const [error, setError] = useState(null);
  const isAllDataValid = dataSelected.every(
    item => item.question !== "" && item.answer !== ""
  );

  const handleAddQuestionAnswer = () => {
    const newQuestionAnswer = {
      question: "",
      answer: "",
      knowledge_base_id: idParams,
      remark: "",
    };
    const newDataSelected = [...dataSelected, newQuestionAnswer];
    setDataSelected(newDataSelected);
  };

  const handleQuestionAnswerChange = (index, type, value) => {
    const newDataSelected = [...dataSelected];
    newDataSelected[index][type] = value;
    setDataSelected(newDataSelected);
  };

  const handleDeleteQuestionAnswer = index => {
    const newDataSelected = [...dataSelected];
    newDataSelected.splice(index, 1);
    setDataSelected(newDataSelected);
  };

  const onSaveQuestion = async paramsId => {
    if (!isAllDataValid) {
      <CustomToast
        type="error"
        title="Silahkan isi pertanyaan atau jawabaan terlebih dahulu"
        onClose={() => toast.dismiss(t.id)}
      />;
      return;
    }

    const payload = {
      qnas: dataSelected,
      knowledge_id: paramsId,
    };

    setLoadingPage(true);
    await post(`/knowledge-base/question-answer`, payload)
      .then(async resp => {
        setLoadingPage(false);
        if (fromPage === "detail") {
          toast.custom(t => (
            <CustomToast
              type="success"
              title="Pertanyaan baru berhasil dibuat"
              onClose={() => toast.dismiss(t.id)}
              icon={<CheckCircleIcon />}
            />
          ));
          setLoadingPage(false);
          history.push(`/detail-service-knowledge-question/${pageId}`);
        } else {
          toast.custom(t => (
            <CustomToast
              type="success"
              title="Pengetahuan layanan berhasil dibuat"
              onClose={() => toast.dismiss(t.id)}
              icon={<CheckCircleIcon />}
            />
          ));
          setLoadingPage(false);
          history.push("/ecommerce?tab=service-knowledge");
        }
      })
      .catch(err => {
        setLoadingPage(false);
        setError(err.detail);
      });
  };

  return (
    <div className="page-content">
      <div className="page-ecommerce-details">
        <Container fluid>
          <div className="header-section">
            <div className="page-header-content">
              <BackButton />
              <span className="title">Tambah Tanya Jawab</span>
            </div>
          </div>
          <div className="page-add-service-knowledge">
            {loadingPage ? (
              <div className="section-question">
                <div>
                  <div className="section-top">
                    <span className="title">Pertanyaan</span>
                  </div>
                  <Skeleton height={150} />
                </div>
                <div>
                  <span className="title">Jawaban</span>
                  <Skeleton height={150} />
                </div>
              </div>
            ) : (
              <>
                {dataSelected.map((item, idx) => {
                  return (
                    <SectionQustionAnswer
                      key={idx}
                      idx={idx}
                      question={item.question}
                      answer={item.answer}
                      onChangeText={(e, type) =>
                        handleQuestionAnswerChange(idx, type, e)
                      }
                      onDelete={() => handleDeleteQuestionAnswer(idx)}
                    />
                  );
                })}
              </>
            )}
            {error && <Alert color="danger">{error}</Alert>}
            <div className="section-button-action-add-question">
              <Button
                color="primary"
                className="btn-edit-product-recommend"
                outline
                onClick={() => history.goBack()}
              >
                Kembali
              </Button>
              <Button
                color="primary"
                className="btn-edit-product-recommend"
                outline
                onClick={() => handleAddQuestionAnswer()}
              >
                Tambah Pertanyaan Lain
              </Button>
              <Button
                color="primary"
                className="btn-edit-product-recommend"
                onClick={() => onSaveQuestion(id)}
                disabled={!isAllDataValid || loadingPage}
              >
                {loadingPage ? <Spinner color="light" size="sm" /> : "Simpan"}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Product }) => ({
  product: Product.product,
  isFetching: Product.productIsFetching,
});

const mapDispatchToProps = dispatch => ({
  onGetProductDetail: id => dispatch(getProductDetail(id)),
});

export default AddServiceKnowledgeQuestion;
