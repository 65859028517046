import React, { useEffect, useState } from "react";
import * as DOMPurify from 'dompurify';
import { connect } from "react-redux";
import { eventOnNodeError, eventOnNodeFixed } from 'store/agent-scenario-builder/actions'
import { Alert } from "reactstrap";

const defaultOptions = {
    ALLOWED_TAGS: ['#text'],
    ALLOWED_ATTR: [],
    USE_PROFILES: { html: false },
    SAFE_FOR_TEMPLATES: true,
    KEEP_CONTENT: true,

};

export const cleanSanitizeHTML = (dirty) => ({
    __html: DOMPurify.sanitize(
        dirty,
        defaultOptions
    )
});

const ErrorMessageInput = ({ nodeId, eventOnNodeError, eventOnNodeFixed, data, errorMessage, nodeErrors }) => {
    const [displayError, setDisplayError] = useState(false)

    useEffect(() => {
        if (!nodeId) return

        let sanitize_text = cleanSanitizeHTML((data)).__html
        sanitize_text = sanitize_text.replace(/(?:\r\n|\r|\n|\t)/g, '')
        if (!sanitize_text || sanitize_text === '' || sanitize_text === '<p></p>') {
            setDisplayError(true)
            console.log('display error', data)
            nodeErrors.push({ id: nodeId, 'type': 'input-required' })
            eventOnNodeError(nodeErrors)
        } else {
            eventOnNodeFixed({ 'id': nodeId, type: 'input-required' })
            setDisplayError(false)
        }
    }, [data])

    if (!nodeId) {
        return <Alert color="danger"> Node ID NOT FOUND</Alert>
    }

    if (displayError) {
        return <p className='input-error'>
            {errorMessage}
        </p>
    }

    return null
}

const mapStateToProps = (state) => {
    return {
        nodeErrors: state.AgentScenarioBuilder.nodeErrors
    }
}

export default connect(mapStateToProps, {
    eventOnNodeError,
    eventOnNodeFixed
})(ErrorMessageInput)