import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Sendrequest, onMessageListener } from "network/firebase/firebase";
import LogoR from "assets/images/logo-R.png";
import NotifSound from "assets/notification-bubble.mp3";
import { connect } from "react-redux";
import { notificationRequestAction, notificationAddTokenRequest } from "store/notification/actions";

const audio = new Audio(NotifSound);

const Notification = ({ notification, notificationRequest, notificationAddToken }) => {
  const playNotificationSound = () => {
    audio.play();
  };

  const notifyChat = () =>
    toast.custom(t => (
      <div className={`${t.visible ? "animate-enter" : "animate-leave"} notif`}>
        <div className="notif-body">
          <div className="notif-body-content">
            <div className="body-logo">
              <img className="logo-notif" src={LogoR} alt="" />
            </div>
            <div className="body-content">
              <p className="title">{notification?.title}</p>
              <p className="body">{notification?.body}</p>
            </div>
          </div>
        </div>
        <div className="notif-close">
          <button onClick={() => toast.dismiss(t.id)} className="btn-notif">
            Close
          </button>
        </div>
      </div>
    ));

  useEffect(() => {
    if (!notification || notification.body == '' || notification.type == '') {
      return
    }

    switch (notification.type) {
      case "chat":
        notifyChat();
        break;
      case "error":
        toast.error(notification.body)
        break
      case "success":
        toast.success(notification.body)
        break
      default:
        break;
    }

    if (notification.withSound) playNotificationSound();

  }, [notification]);

  Sendrequest(notificationAddToken);

  onMessageListener()
    .then(payload => {
      notificationRequest({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
        type: "chat",
        withSound: true
      })
    })
    .catch(err => console.error("failed: ", err));

  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        // Define default options
        duration: 5000,
      }}
    />
  );
};

const mapStateToProps = ({ Notification }) => ({
  notification: Notification
})

export default connect(mapStateToProps, {
  notificationRequest: notificationRequestAction,
  notificationAddToken: notificationAddTokenRequest
})(Notification);
