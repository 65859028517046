import React from "react";
import { Container, Button, Spinner } from "reactstrap";
import { EllipsisHorizontalIcon, PlusIcon } from "@heroicons/react/20/solid";
import BackButton from "components/BackButton";
import Skeleton from "react-loading-skeleton";

const LoadingDetail = () => {
  return (
    <div className="page-content">
      <div className="page-ecommerce-details">
        <Container fluid>
          <div className="header-section-costume">
            <div className="page-header-content">
              <BackButton />
              <Skeleton height={36} width={200} />
            </div>
            <div className="page-header-action">
              <Button color="primary" className="btn-add-service-knowledge">
                <PlusIcon className="icon" />
                Tambah Pertanyaan
              </Button>
              <div className="card-action">
                <div className="card-action-btn">
                  <EllipsisHorizontalIcon className="icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="page-add-service-knowledge">
            <div className="section-question-costume">
              <div>
                <span className="title">Ringkasan</span>
                <Skeleton height={250} />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LoadingDetail;
