import React from 'react';
import { connect } from 'react-redux';
import { eventOnDrawLine } from 'store/actions';

const BuilderConnectionLine = ({
  fromX,
  fromY,
  fromPosition,
  toX,
  toY,
  toPosition,
  connectionLineType,
  connectionLineStyle,
  displayOptionOnNode,
  eventOnDrawLine,
}) => {
  if (displayOptionOnNode !== '' && ((fromX - toX) > 3 || (fromX - toX) < -3)) {
    eventOnDrawLine()
  }
  return (
    <g>
      <path
        fill="none"
        stroke="#34c38f"
        strokeWidth={1.5}
        className="animated"
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle cx={toX} cy={toY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
    </g>
  );
};

const mapStateToProps = (states) => {
  return states.AgentScenarioBuilder.config
}

export default connect(mapStateToProps,
  { eventOnDrawLine })(BuilderConnectionLine)