import React from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

const BackButton = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div
      style={{
        width: "24px",
        maxWidth: "24px",
        cursor: "pointer",
      }}
      onClick={() => handleGoBack()}
    >
      <ArrowLeftIcon />
    </div>
  );
};

export default BackButton;
