import React from "react";
import { Modal, Button } from "reactstrap";

const ModalEditQuestionCancel = ({
  confirmationEdit,
  setConfirmationEdit,
  handleCancel,
}) => {
  return (
    <Modal
      isOpen={confirmationEdit}
      toggle={setConfirmationEdit}
      className="modal-cancel-edit"
      centered
    >
      <div className="modal-cancel-edit">
        <span className="title">Batalkan Edit Pengetahuan Produk?</span>
        <span className="subtitle">
          Anda akan kehilangan data yang sudah dimasukkan
        </span>
        <Button
          color="primary"
          onClick={() => setConfirmationEdit(!confirmationEdit)}
        >
          Lanjutkan Edit
        </Button>
        <Button color="primary" onClick={handleCancel} outline>
          Batal Edit
        </Button>
      </div>
    </Modal>
  );
};

export default ModalEditQuestionCancel;
