import { call, put, takeEvery } from "redux-saga/effects"

// Redux States
import { GET_AI_ASSISTANT_PROFILE, UPDATE_AI_ASSISTANT_PROFILE } from "./actionTypes"
import * as actions from "./actions"

// API endpoint
import * as api from './api'

function* onGetAIAssistantProfile() {
  try {
    const response = yield call(api.getAIProfile)
    yield put(actions.getAIProfileSuccess(response))
  } catch (error) {
    yield put(actions.getAIProfileFail(error))
  }
}

function* onUpdateAIAsistantProfile({payload}) {
  try {
    const response = yield call(api.updateAIProfile, payload)
    yield put(actions.updateAIProfileSuccess(response))
  } catch (error) {
    yield put(actions.updateAIProfileFail(error))
  }
}

function* aiProfileSaga() {
  yield takeEvery(GET_AI_ASSISTANT_PROFILE, onGetAIAssistantProfile)
  yield takeEvery(UPDATE_AI_ASSISTANT_PROFILE, onUpdateAIAsistantProfile)
}

export default aiProfileSaga
