import { get, post, put, del } from "network/http/api"

export const get_profile = () => {
  return get('user/me')
}

export const get_profile_detail = () => {
  return get('user/detail')
}

export const updateProfile = (data) => {
  return put('/user', data)
}
