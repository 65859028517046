import React from "react";
import { Col, Row } from "reactstrap";
import formatCurrency from "helpers/currency";

const Details = ({ data }) => {
  const { parent, variants, options } = data;

  const checkCategoryProduct = (paramsDetail, type) => {
    const result = paramsDetail.find(detail => detail.title === type);
    if (result) {
      return result.text;
    } else {
      return "-";
    }
  };

  return (
    <div className="info-details-product-content">
      <Row>
        <Col xl="4">
          {parent && (
            <img
              src={parent.pictures[0].original_url}
              className="details-product-img"
            />
          )}
        </Col>
        <Col xl="8">
          <div className="details-product-info">
            {parent && (
              <span className="badge-product-info">
                {checkCategoryProduct(parent?.details, "Kategori")}
              </span>
            )}

            <span className="price-product-info">
              {parent ? formatCurrency(parent.price) : "Rp.0"}
            </span>
            <span className="stock-product-info">
              Stok tersedia : {parent ? parent.stock : "0"}
            </span>

            {parent && (
              <div className="desc-product-info">
                {parent?.details.length && (
                  <>
                    {parent.details.map((item, idx) => {
                      return (
                        <div className="info" key={idx}>
                          <span className="title">{item.title}</span>
                          <span className="description">{item.text}</span>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
