import * as actionTypes from "./actionTypes";

const INIT_STATE = {
  isFetching: false,
  error: {},
  conversationStats: {},
  conversations: {},
  tickets: [],
  onboard: null,
  billing: {
    num_agent: 0,
  },
  numConversation: 0,
  numUnpaidInvoice: 0,
  totalIntegration: 0,
  totalUnreadChat: 0,
};

const Dashboard = (state = INIT_STATE, action) => {
  const { payload } = action
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.GET_DASHBOARD_SUCCESS:
      return {
        isFetching: false,
        conversationStats: payload.conversation_stats || {},
        conversations: payload.conversations,
        tickets: payload.tickets,
        billing: payload.billing,
        numConversation: payload.num_conversation,
        numUnpaidInvoice: payload.num_unpaid_invoice,
        onboard: payload.onboard,
        totalIntegration: payload.total_integration,
        totalUnreadChat: payload.unread_chat,
      }
    case actionTypes.GET_DASHBOARD_FAILED:
      return {
        ...state,
        error: payload,
        isFetching: false
      }
    default:
      return state;
  }
};


export default Dashboard;
