const { get, put, del } = require("network/http/api")

export const getQnaDictionary = (product_id) => {
  return get(`/qna-dictionary/product/${product_id}`)
}

export const deleteQnaDictionary = (id) => {
  return del(`/qna-dictionary/${id}`)
}

