import {
  GET_SCENARIO_FAIL,
  GET_SCENARIO_SUCCESS,
  GET_SCENARIO_DETAIL_FAIL,
  GET_SCENARIO_DETAIL_SUCCESS,
  ADD_SCENARIO_SUCCESS,
  ADD_SCENARIO_FAIL,
  UPDATE_SCENARIO_SUCCESS,
  UPDATE_SCENARIO_FAIL,
  DELETE_SCENARIO_SUCCESS,
  DELETE_SCENARIO_FAIL,
  UPDATE_SCENARIO_NODE_DATA,
  ADD_SCENARIO_NODE,
  UPDATE_SCENARIO_EDGE,
} from "./actionTypes"

const INIT_STATE = {
  scenarios: [],
  scenarioDetail: {
    nodes: [],
    edges: [],
    name: '',
    id: '',
  },
  error: {},
}

export const selectListScenario = (state) => {
  return state['Scenario']['scenarios']
}

export const selectDetailScenario = (state) => {
  return state['Scenario']['scenarioDetail']
}

const updateScenarioNodeState = (scenarioDetail, updateData) => {
  scenarioDetail.nodes = scenarioDetail.nodes.map(node=>{
    if(node.id === updateData.id){
      node = {
        ...node,
        data: updateData.data,
      }
    }

    return node
  })

  return scenarioDetail
}

const addScenarioNodeState = (scenarioDetail, node) => {
  scenarioDetail.nodes.push(node)
  return scenarioDetail
}

const updateScenarioEdgesState = (scenarioDetail, edges) => {
  scenarioDetail.edges = edges
  return scenarioDetail
}

const updateListScenario = (state, payload) => {
  let scenarios = state['scenarios']
  scenarios = scenarios.concat(payload)

  return scenarios
}

const scenarios = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: updateListScenario(state, action.payload),
      }

    case GET_SCENARIO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SCENARIO_DETAIL_SUCCESS:
      return {
        ...state,
        scenarioDetail: action.payload || {},
      }

    case GET_SCENARIO_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: [...state.scenarios, action.payload],
      }

    case ADD_SCENARIO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.map(scenario =>
          scenario.id.toString() === action.payload.id.toString()
            ? { scenario, ...action.payload }
            : scenario
        ),
      }
    case UPDATE_SCENARIO_NODE_DATA:
      return {
        ...state,
        scenarioDetail: updateScenarioNodeState(state.scenarioDetail, action.payload)
      }
    case ADD_SCENARIO_NODE:
      return {
        ...state,
        scenarioDetail: addScenarioNodeState(state.scenarioDetail, action.payload)
      }
    case UPDATE_SCENARIO_EDGE:
      return {
        ...state,
        scenarioDetail: updateScenarioEdgesState(state.scenarioDetail, action.payload)
      }
    case UPDATE_SCENARIO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.filter(
          scenario => scenario.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_SCENARIO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default scenarios
