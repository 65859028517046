/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_STORE_PRODUCTS = "GET_STORE_PRODUCTS"
export const GET_ID_CATEGORY_PRODUCTS = 'GET_ID_CATEGORY_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* PRODUCT DETAILS */
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

/* PRODUCT CATEGORY */
export const GET_PRODUCTS_CATEGORY = "GET_PRODUCTS_CATEGORY"
export const GET_PRODUCTS_CATEGORY_SUCCESS = "GET_PRODUCTS_CATEGORY_SUCCESS"
export const GET_PRODUCTS_CATEGORY_FAIL = "GET_PRODUCTS_CATEGORY_FAIL"

// PRODUCT SEARCH
export const SEARCH_PRODUCT = "SEARCH_PRODUCT"
export const SEARCH_PRODUCT_SUCCESS = "SEARCH_PRODUCT_SUCCESS"
export const SEARCH_PRODUCT_FAIL = "SEARCH_PRODUCT_FAIL"
