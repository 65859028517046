import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onboardingPost, onboardingApiError } from "store/actions";
import EmbedChat from "components/EmbedChat";

const OnboardingPage = props => {
  return (
    <React.Fragment>
      <div className="page-onboarding">
        <EmbedChat location={"/onboarding"} />
      </div>
    </React.Fragment>
  );
};

OnboardingPage.propTypes = {
  onboardingApiError: PropTypes.any,
  error: PropTypes.any,
  data: PropTypes.object,
  onboardingPost: PropTypes.func,
};

const mapStateToProps = state => {
  const { data, error, loading } = state.Onboarding;
  return { data, error, loading };
};

export default withRouter(
  connect(mapStateToProps, { onboardingPost, onboardingApiError })(
    OnboardingPage
  )
);
