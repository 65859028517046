import { get, post } from "network/http/api";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useFormik } from "formik";
import CostumRadioField from "../../Integration/ComponentsPage/CostumRadioField";
//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const dateRangeOptions = [
  {
    name: "1 Minggu Terakhir",
    value: "one_week",
  },
  {
    name: "2 Minggu Terakhir ",
    value: "two_weeks",
  },
  {
    name: "3 Minggu Terakhir ",
    value: "three_weeks",
  },
  {
    name: "1 Bulan Terakhir",
    value: "one_month",
  },
  {
    name: "3 Bulan Terakhir",
    value: "three_months",
  },
  {
    name: "Custom",
    value: null,
  },
];

const ModalFilter = ({ isOpen, toggle, filter, setFilter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [zendesk, setZendesk] = useState({ email: "", subdomain: "" });
  const [errorMessage, setErrorMessage] = useState(null);
  const [listPlatforms, setListPlatforms] = useState([]);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const formik = useFormik({
    initialValues: {
      date_range: undefined,
      start_date: null,
      end_date: null,
    },
  });

  const { values, setFieldValue, resetForm } = formik;

  console.log(values);

  const fetchListPlatforms = async () => {
    try {
      const res = await get(`/platform`);
      setListPlatforms(res);
      setLoadingFetch(false);
      setErrorFetch(null);
    } catch (err) {
      setErrorFetch(err.detail);
      setTimeout(() => {
        setErrorFetch(null);
      }, 1000);
      setLoadingFetch(false);
    }
  };

  const handleCheckboxChange = item => {
    if (selectedPlatforms?.includes(item)) {
      setSelectedPlatforms(
        selectedPlatforms?.filter(selectedItem => selectedItem !== item)
      );
    } else {
      setSelectedPlatforms([...selectedPlatforms, item]);
    }
  };

  const handleOnChecked = item => {
    return selectedPlatforms?.includes(item);
  };

  const resetFilter = () => {
    setSelectedPlatforms([]);
    resetForm();
  };

  const handleSetFilter = () => {
    setFilter({
      platform_name: selectedPlatforms,
      start_range_date:
        values.range_date_option &&
        moment(values.start_date).format("MM-DD-YYYY"),
      finish_range_date:
        values.range_date_option &&
        moment(values.end_date).format("MM-DD-YYYY"),
      range_date_option: values.date_range,
    });

    toggle();
  };

  useEffect(() => {
    fetchListPlatforms();
  }, []);

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader close={closeBtn}>Filter Data Feedback</ModalHeader>
      <ModalBody>
        <Row
          style={{
            padding: "16px",
          }}
        >
          <div className="rating-modal-section">Platform</div>
          <Col xs={4}>
            {listPlatforms.slice(0, 4).map((item, index) => (
              <label key={index} className="checkbox-label">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  value={item.platform_name}
                  checked={handleOnChecked(item.platform_name)}
                  onChange={() => handleCheckboxChange(item.platform_name)}
                />
                {item.platform_name}
              </label>
            ))}
          </Col>
          <Col xs={4}>
            {listPlatforms.slice(4).map((item, index) => (
              <label key={index} className="checkbox-label">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  value={item.platform_name}
                  checked={handleOnChecked(item.platform_name)}
                  onChange={() => handleCheckboxChange(item.platform_name)}
                />
                {item.platform_name}
              </label>
            ))}
          </Col>

          <div className="rating-modal-section">Interval Tanggal</div>

          <Col xs={6}>
            <CostumRadioField
              name="date_range"
              options={dateRangeOptions.slice(0, 3)}
              formik={formik}
            />
          </Col>
          <Col xs={6}>
            <CostumRadioField
              name="date_range"
              options={dateRangeOptions.slice(3)}
              formik={formik}
            />

            {(!values.date_range || values.date_range === "") && (
              <Row>
                <Col md="6">
                  <DatePicker
                    className="form-control"
                    selected={values.start_date}
                    onChange={val => {
                      formik, setFieldValue("start_date", val);
                    }}
                  />
                </Col>
                <Col md="6" className="ps-md-0">
                  <DatePicker
                    className="form-control"
                    selected={values.end_date}
                    onChange={val => {
                      formik, setFieldValue("end_date", val);
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </Col>
        </Row>
        {loadingFetch && (
          <div className="text-center">
            <Spinner type="grow" color="primary" />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={resetFilter} outline color="primary">
          Hapus Semua
        </Button>
        <Button disabled={isLoading} onClick={handleSetFilter} color="primary">
          Simpan
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(null, {})(ModalFilter);
