import React from "react";
import { Button, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import moment from "moment";
import TablePagination from "./TablePagination";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

moment.locale("id");

const TableReferral = ({history, data, onChangePage, pagination, currentPage }) => {
  const statusStyles = {
    paid: {
      backgroundColor: "#EBF2EF",
      color: "#649B84",
    },
    expired: {
      backgroundColor: "#FCEBEB",
      color: "#E11A1A",
    },
    refund: {
      backgroundColor: "#D7DFF0",
      color: "#3660B3",
    },
    unpaid: {
      backgroundColor: "#F5F5F5",
      color: "#79747E",
    },
  };

  const RenderTable = ({ dataTabel }) => {
    if (dataTabel?.length === 0) {
      return (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
          </Table>
          <div className="tabel-no-data">Belum ada user referral untuk Anda.</div>
        </>
      );
    }

    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>
                  {item.created_at ? moment(item.created_at).format("DD MMMM YYYY") : "-"}
                </td>
                <td style={{ fontWeight: "600", color: "#000" }}>
                  {item.user.fullname}
                </td>

                <td style={{ fontWeight: "600", color: "#000" }}>
                  {item.user.email}
                </td>
                <td className="cursor-pointer">
                  <Button onClick={()=> history.push(`/referral-commission/${item.id}`)} color="primary" outline>
                    View Detail
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Row>
      <Col xs={12} lg={12}>
        <div className="section-tabel">
          <RenderTable dataTabel={data} />
          <TablePagination onClick={onChangePage} totalPage={pagination.totalPage} currentPage={currentPage} />
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(TableReferral);
