import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { setStatusBarClose } from "store/actions";

const StatusBar = ({ type, status }) => {
  const dataReduxStatusBar = useSelector(state => state.StatusBar);
  const dispatch = useDispatch();
  const [data, setData] = useState(dataReduxStatusBar);

  useEffect(() => {
    setData(dataReduxStatusBar);
  }, [dataReduxStatusBar]);

  const onClose = paramsType => {
    dispatch(setStatusBarClose(paramsType, true));
  };

  const findStatus = params => {
    switch (params) {
      case "register":
      case "review_policy":
      case "platform_review":
      case "sync_product":
        return "bg-info";
      case "active":
        return "bg-success";
      case "deactivated":
        return "bg-error";
      default:
        return "";
    }
  };

  const findIcon = params => {
    switch (params) {
      case "register":
      case "review_policy":
      case "platform_review":
      case "sync_product":
        return <BuildingStorefrontIcon />;
      case "active":
        return <CheckCircleIcon />;
      case "deactivated":
        return <XCircleIcon />;
      default:
        return null;
    }
  };

  const findTitle = params => {
    switch (params) {
      case "register":
        return (
          <span className="with-icon">
            Integrasi Toko sedang berlangsung, untuk melihat status integrasi
            klik{" "}
            <Link
              to={`/integration`}
              style={{
                fontWeight: "700",
                color: "#fff",
                textDecoration: "underline",
              }}
            >
              di sini
            </Link>
          </span>
        );
      case "review_policy":
        return (
          <span className="with-icon">
            Toko Anda sedang dalam proses tinjauan kebijakan
          </span>
        );
      case "platform_review":
        return (
          <span className="with-icon">
            Toko Anda sedang dalam proses review platform
          </span>
        );
      case "sync_product":
        return (
          <span className="with-icon">
            Sinkronisasi produk sedang BERLANGSUNG, Mohon Menunggu atau check
            secara berkala.
          </span>
        );
      case "active":
        return <span className="with-icon">Toko Anda Sudah Aktif</span>;
      case "deactivated":
        return <span className="with-icon">Toko Anda Tidak Aktif</span>;
      default:
        return null;
    }
  };

  if (data[type]?.btn_close) {
    return null;
  }

  return (
    <div className={`status-bar ${status ? findStatus(status) : ""}`}>
      <div className="spacer" />
      <div className="status-bar-content">
        {status ? findIcon(status) : null}
        {status ? findTitle(status) : null}
      </div>
      <XMarkIcon className="close-icon" onClick={() => onClose(type)} />
    </div>
  );
};

export default StatusBar;
