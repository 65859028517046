import React, { useState } from "react";
import { Col, Row, Tooltip } from "reactstrap";
import formatCurrency from "helpers/currency";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const PackageSection = ({
  openModal,
  openModalAddQuota,
  quota,
  billing,
  pendingInvoice,
}) => {
  const [componentTooltip, setComponents] = useState([
    { id: 1, name: "tooltip tambah kuota", isActive: false },
    { id: 2, name: "tooltip kuota product", isActive: false },
    { id: 3, name: "tooltip kuota message", isActive: false },
  ]);
  const addtionalQuota = billing.additional_quota_added;

  const handleToggle = id => {
    setComponents(prevComponents =>
      prevComponents.map(component =>
        component.id === id
          ? { ...component, isActive: !component.isActive }
          : component
      )
    );
  };

  const openModalPackage = () => {
    if (pendingInvoice) {
      return;
    }

    openModal();
  };

  const openModalQuota = () => {
    if (pendingInvoice) {
      return;
    }

    openModalAddQuota();
  };

  return (
    <Row>
      <Col xs={12} lg={6} md={12} sm={12}>
        <div className="section-package">
          <div className="section-package-now">
            <span className="title-package">PAKET SEKARANG</span>
            <div className="package">
              <span className="name">{billing ? billing.plan_name : "-"}</span>
              {addtionalQuota && (
                <span id="kuota_add" style={{ alignSelf: "flex-start" }}>
                  dan kuota Tambahan{" "}
                  <i className="fa fa-info-circle" aria-hidden="true" />
                  <Tooltip
                    style={{ marginTop: "10px" }}
                    isOpen={componentTooltip[0].isActive}
                    target="kuota_add"
                    toggle={() => handleToggle(1)}
                    placement="bottom"
                  >
                    Kuota Tambahan layanan RISA AI.
                  </Tooltip>
                </span>
              )}
            </div>

            <div className="section-package-button">
              <span
                className={`${pendingInvoice ? "info" : "upgrade"} `}
                onClick={() => openModalPackage()}
              >
                Upgrade Paket{" "}
                <i className="fa fa-chevron-right" aria-hidden="true" />
              </span>
              <span
                className={`${pendingInvoice ? "info" : "upgrade"} `}
                onClick={() => openModalQuota()}
              >
                Beli Kuota Tambahan{" "}
                <i className="fa fa-chevron-right" aria-hidden="true" />
              </span>
            </div>
          </div>
          <div className="section-quota">
            <span className="title-package">KUOTA TERSISA</span>
            <div className="section-quota-remaining">
              <div className="quota">
                <h6>
                  {quota ? quota.usage_product_quota : "0"}
                  <span style={{ fontSize: "18px" }}>/</span>
                  {billing ? billing.max_number_product : "0"}
                </h6>
                <span id="kuota_product">
                  Kuota Produk{" "}
                  <i className="fa fa-info-circle" aria-hidden="true" />
                  <Tooltip
                    style={{ marginTop: "10px" }}
                    isOpen={componentTooltip[1].isActive}
                    target="kuota_product"
                    toggle={() => handleToggle(2)}
                    placement="bottom"
                  >
                    Kuota Produk merujuk ke total banyak informasi produk yang
                    dapat dipelajari layanan RISA AI.
                  </Tooltip>
                </span>
              </div>
              <div className="quota">
                <h6>
                  {quota ? quota.usage_message_quota : "0"}
                  <span style={{ fontSize: "18px" }}>/</span>
                  {billing ? billing.max_number_message : "0"}
                </h6>
                <span id="kuota_message">
                  Kuota Pesan{" "}
                  <i className="fa fa-info-circle" aria-hidden="true" />
                  <Tooltip
                    style={{ marginTop: "10px" }}
                    isOpen={componentTooltip[2].isActive}
                    target="kuota_message"
                    toggle={() => handleToggle(3)}
                    placement="bottom"
                  >
                    Kuota pesan merujuk ke total jawaban dari pertanyaan yang
                    dapat dihasilkan oleh layanan RISA AI.
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6} md={12} sm={12}>
        <div className="section-package">
          <div className="section-bill">
            <span className="title-package">DETAIL TAGIHAN</span>
            <span className="bill">
              {billing ? formatCurrency(billing.base_price) : "Rp.0"}
            </span>
            <span className="info-bill">
              Tagihan Anda selanjutnya diperkirakan akan jatuh pada tanggal{" "}
              <span style={{ fontWeight: "600" }}>
                {billing
                  ? moment(billing.next_cycle_date).format("DD MMMM YYYY")
                  : "-"}
              </span>
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

PackageSection.args = {
  autohide: true,
  flip: true,
};

export default PackageSection;
