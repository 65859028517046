import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { initializeApp } from "firebase/app";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import Notification from "./components/Notification";

// Import scss
import "./assets/scss/theme.scss";
import "react-loading-skeleton/dist/skeleton.css";

// Import fackbackend Configuration file
import Pages404 from "pages/Utility/pages-404";

const App = ({ layout }) => {
  const getLayout = () => {
    return VerticalLayout;
  };
  const Layout = getLayout();

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          <Layout>
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Layout>

          <Route path="*">
            <Pages404 />
          </Route>
        </Switch>
      </Router>

      <Notification />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);
