import React, { Component } from "react";
// import CarouselPage from "./CarouselPage";
import SectionImage from "./SectionImage";
import SectionMobileLogo from "./SectionMobileLogo";
// import images
import { Spinner } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { get } from "network/http/api";

class ValidateEmailVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      validationSuccess: false,
      validationFailed: false,
      resendNewToken: false,
    };
  }

  componentDidMount() {
    this.handleOnValidateToken();
  }

  componentWillUnmount() {
    this.setState({
      isLoading: true,
      validationSuccess: false,
      validationFailed: false,
      resendNewToken: false,
    });
  }

  handleOnValidateToken = () => {
    const {
      match: { params },
      history,
    } = this.props;
    get(`/auth/verify/${params.userId}?token=${params.token}`)
      .then(resp => {
        this.setState({
          validationSuccess: true,
          isLoading: false,
        });

        setTimeout(() => {
          history.push("/login");
        }, 4000);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          validationFailed: true,
        });
      });
  };

  handleOnGenerateToken = () => {
    const {
      match: { params },
      history,
    } = this.props;

    this.setState({ isLoading: true });
    get(`/auth/new-token/email/${params.userId}`)
      .then(resp => {
        this.setState({
          validationSuccess: false,
          resendNewToken: true,
          isLoading: false,
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isLoading: false,
          resendNewToken: false,
        });
      });
  };

  goToLogin = () => {
    const {
      match: { params },
      history,
    } = this.props;

    history.push("/login");
  };

  render() {
    const { isLoading, validationSuccess, resendNewToken, validationFailed } =
      this.state;
    return (
      <React.Fragment>
        <div className="auth-page">
          <div className="section-auth-full">
            <SectionImage />
            <div className="section-content bg-mobile-verify">
              <SectionMobileLogo />
              <div className="content">
                <div className="content-verification">
                  {validationSuccess && (
                    <>
                      <h3 className="fs-2 text-center">
                        Verifikasi Email berhasil
                      </h3>
                      <span className="text-center">
                        Anda sekarang dapat masuk ke akun Anda. Tunggu sebentar,
                        Anda akan diarahkan ke halaman login... <br /> <br />{" "}
                        Jika tidak dialihkan dalam beberapa detik, <br /> klik
                        tombol di bawah.
                      </span>
                      <Link
                        to="/login"
                        className="btn btn-primary btn-block p-3 mt-3"
                      >
                        Login sekarang
                      </Link>
                    </>
                  )}

                  {validationFailed && (
                    <>
                      <h3 className="fs-2 text-center">Verifikasi Gagal</h3>
                      <span className="text-center">
                        Link verifikasi sudah tidak berlaku. Silakan klik tombol
                        di bawah ini untuk mengirimkan ulang email verifikasi.
                      </span>
                      {isLoading && (
                        <div className="mt-4 text-center">
                          <Spinner />
                        </div>
                      )}
                      <button
                        disabled={isLoading}
                        onClick={this.handleOnGenerateToken}
                        className="btn btn-primary btn-block p-3 mt-3"
                      >
                        {resendNewToken
                          ? "Check Ulang Email Kamu"
                          : "Kirim Email Verifikasi"}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ValidateEmailVerification);
