import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import logoRisa from "assets/images/logo-R.png";
import Image from "components/Image";

const MockLiveChatForm = ({ data }) => {
    const defaultWelcomeText = "Hai, selamat datang di Support. Tim Kami akan merespons kebutuhan kamu segera. Silakan lengkapi form dibawah ini."

    const isOptionalText = (isRequired) => {
        if (isRequired) {
            return <span className="annotation required">*</span>
        }

        return <span className="annotation">(Optional)</span>
    }

    return (
        <div className="mock-live-chat form-container">
            <div className="container">
                <Card>
                    <CardHeader style={{ background: data['primary_color'] }}>
                        {!data["logo"] &&
                            <img src={logoRisa} />
                        }
                        {data["logo"] &&
                            <Image src={data["logo"]} />
                        }
                        <div className="title">{data['title']}</div>
                    </CardHeader>
                    <CardBody>
                        <div className="welcome-text">
                            {data.description || defaultWelcomeText}
                        </div>

                        <div class="login-form-container">
                            <form>
                                {!data.name_field.disabled &&
                                    <div class="form-group">
                                        <label for="name">{data.name_field.label} {isOptionalText(data.name_field.required)}</label>
                                        <input type="text" id="name" required={data.name_field.required} />
                                    </div>
                                }
                                {!data.email_field.disabled &&
                                    <div class="form-group">
                                        <label for="email">{data.email_field.label} {isOptionalText(data.email_field.required)}</label>
                                        <input type="email" id="email" required={data.email_field.required} />
                                    </div>
                                }
                                {!data.phone_field.disabled &&
                                    <div class="form-group">
                                        <label for="phoneNumber">{data.phone_field.label} {isOptionalText(data.phone_field.required)}</label>
                                        <input type="tel" id="phoneNumber" required={data.phone_field.required} />
                                    </div>
                                }
                                <div class="action">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default MockLiveChatForm