import React, { useState } from "react";
import { Button, Alert } from "reactstrap";
import { put } from "network/http/api";
import { PencilIcon } from "@heroicons/react/24/outline";
import CommonEditor from "components/Common/CommonEditor";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { notificationSuccessRequestAction } from "store/notification/actions";
import ModalDeleteQuestion from "./ComponentsPage/ModalDeleteQuestion";
import ModalEditQuestionCancel from "./ComponentsPage/ModalEditQuestionCancel";

const CardsQandAKnowledge = ({
  item,
  onUpdateQuestion,
  notificationSuccess,
  editingQuestionId,
  setEditingQuestionId,
}) => {
  const [loading, setLoading] = useState(false);
  const [QandA, setQandA] = useState({
    question: "",
    answer: "",
  });
  const [error, setError] = useState(null);
  const [confirmationEdit, setConfirmationEdit] = useState(false);

  const onChangeEdit = paramsId => {
    if (editingQuestionId && editingQuestionId !== paramsId) {
      setConfirmationEdit(true);
    } else {
      setLoading(true);
      setEditingQuestionId(paramsId);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  const handleCancelEdit = () => {
    setLoading(true);
    setConfirmationEdit(false);
    setEditingQuestionId(null);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const onChangeText = (params, field) => {
    setQandA(prevState => ({
      ...prevState,
      [field]: params,
    }));
  };

  const handleOnEditQuestionAnswer = () => {
    setLoading(true);
    const payload = {
      question: QandA.question,
      answer: QandA.answer,
    };

    put(`/knowledge-base/question-answer/${item.id}`, payload)
      .then(resp => {
        notificationSuccess("Edit Pertanyaan Berhasil");
        setLoading(false);
        setEditingQuestionId(null);
        onUpdateQuestion(resp);
      })
      .catch(err => {
        setLoading(false);
        setError(err.detail);
      });
  };

  if (loading) {
    return (
      <div className="card-knowledge-question-product">
        <div style={{ flex: 1, width: "100%" }}>
          <span className="title-question">Pertanyaan</span>
          <Skeleton height={150} />
        </div>
        <div className="spacer" />
        <div style={{ flex: 1, width: "100%" }}>
          <span className="title-question">Jawaban</span>
          <Skeleton height={150} />
        </div>
      </div>
    );
  }

  if (editingQuestionId === item.id) {
    return (
      <div className="card-knowledge-question-product">
        <span className="title-question">Pertanyaan</span>
        <CommonEditor
          onChange={e => onChangeText(e, "question")}
          text={item.question}
        />
        <div className="spacer" />
        <span className="title-question">Jawaban</span>
        <CommonEditor
          onChange={e => onChangeText(e, "answer")}
          text={item.answer}
        />
        {error && <Alert color="danger">{error}</Alert>}
        <div className="card-knowledge-question-product-action">
          <Button color="primary" onClick={() => handleCancelEdit()} outline>
            Batal
          </Button>
          <Button color="primary" onClick={handleOnEditQuestionAnswer}>
            Simpan
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="card-knowledge-question-product">
      <span className="title-question">Pertanyaan</span>
      <span
        className="question"
        dangerouslySetInnerHTML={{
          __html: item.question,
        }}
      />
      <div className="spacer" />
      <span className="title-question">Jawaban</span>
      <span
        className="question"
        dangerouslySetInnerHTML={{
          __html: item.answer,
        }}
      />
      <div className="card-knowledge-question-product-action">
        <div
          className="btn-icon-action btn-edit"
          onClick={() => onChangeEdit(item.id)}
        >
          <PencilIcon className="icon" /> Edit
        </div>
        <ModalDeleteQuestion question={item} />
      </div>
      <ModalEditQuestionCancel
        confirmationEdit={confirmationEdit}
        setConfirmationEdit={setConfirmationEdit}
        handleCancel={() => handleCancelEdit()}
      />
    </div>
  );
};

export default connect(null, {
  notificationSuccess: notificationSuccessRequestAction,
})(CardsQandAKnowledge);
