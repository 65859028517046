import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Redux States
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as api from './api'

function* getDashboardRequest() {
    try {
        const response = yield call(api.companyDashboard);
        yield put(actions.dashboardSuccess(response));
    } catch (error) {
        if (error.status_code === 401) {
            window.location.replace("/login")
            return
        }
        yield put(actions.dashboardFailed(error));
    }
}


export function* watchDashboardRequest() {
    yield takeEvery(actionTypes.GET_DASHBOARD_REQUEST, getDashboardRequest);
}

function* dashboardSaga() {
    yield all([fork(watchDashboardRequest)]);
}

export default dashboardSaga;
