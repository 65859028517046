import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SummaryToggle from "./SummaryToggle";

const RightSideBarIntegration = ({ showRightSidebar, setShowRightSidebar }) => {
  const { data } = showRightSidebar;

  const onCloseRightsidebar = () => {
    setShowRightSidebar({
      ...showRightSidebar,
      show: false,
    });
  };

  const handleContentClick = event => {
    event.stopPropagation();
  };

  return (
    <div
      className="overlay-sidebar-right"
      onClick={() => onCloseRightsidebar()}
    >
      <div
        className="sidebar-right-content"
        onClick={event => handleContentClick(event)}
      >
        <div className="section-header">
          <span>Data Dibagikan - Tokopedia </span>
          <XMarkIcon onClick={() => onCloseRightsidebar()} />
        </div>
        <div className="section-content">
          <span className="description">
            Data yang ada disini Anda sudah setujui untuk dibagikan ke{" "}
          </span>
          <SummaryToggle
            title="Nama Toko"
            description={data ? data.store_name : "-"}
            tooltipText={
              data.store_name
                ? "Anda memiliki informasi nama toko"
                : "Tidak dapat menyalakan toggle karena Anda tidak memiliki informasi nama toko."
            }
          />
          <SummaryToggle
            title="Alamat Toko"
            description={data ? data.address : "-"}
            tooltipText={
              data.address
                ? "Anda memiliki informasi alamat toko"
                : "Tidak dapat menyalakan toggle karena Anda tidak memiliki informasi alamat toko."
            }
          />
          <SummaryToggle
            title="URL Toko"
            description={data ? data.store_url : "-"}
            tooltipText={
              data.store_name
                ? "Anda memiliki informasi url toko"
                : "Tidak dapat menyalakan toggle karena Anda tidak memiliki informasi url toko."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RightSideBarIntegration;
