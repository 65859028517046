import React, { useState } from "react";
import { Button, Spinner, Modal } from "reactstrap";
import { del } from "network/http/api";
import { useHistory } from "react-router-dom";

const ModalDelete = ({
  knowledgeId,
  type,
  toggleModalDelete,
  question,
  setTriggerFetchList,
  goBackAfterDelete,
  open,
}) => {
  const history = useHistory();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const onDeleteQuestion = async () => {
    setLoadingDelete(true);
    del(`/knowledge-base/${question.id}/question-answer/${question.id}`)
      .then(async resp => {
        setLoadingDelete(false);
        setTriggerFetchList(1);
        toggleModalDelete();
      })
      .catch(err => {
        console.log(err, "err");
        setLoadingDelete(false);
      });
  };

  const onDeleteKnowledge = async () => {
    setLoadingDelete(true);
    del(`/knowledge-base/${knowledgeId}`)
      .then(async resp => {
        setLoadingDelete(false);
        toggleModalDelete();
        if (goBackAfterDelete) {
          history.goBack();
        }
      })
      .catch(err => {
        setLoadingDelete(false);
      });
  };

  if (type === "delete-knowledge-question") {
    return (
      <div className="modal-cancel-edit">
        <span className="title">Hapus Tanya Jawab?</span>
        <span className="subtitle">
          Anda akan menghapus Tanya Jawab Dengan pertanyaan :{" "}
          <strong>
            <span
              dangerouslySetInnerHTML={{
                __html: question ? question.question : "-",
              }}
            />
          </strong>
        </span>
        {loadingDelete ? (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <Button color="danger" onClick={() => onDeleteQuestion()}>
              Hapus Tanya Jawab
            </Button>
            <Button color="primary" onClick={() => toggleModalDelete()} outline>
              Batal Hapus Tanya Jawab
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <Modal backdrop={true} isOpen={open} style={{ width: "fit-content" }}>
      <div className="modal-cancel-edit">
        <span className="title">Hapus Pengetahuan Layanan?</span>
        <span className="subtitle">
          Anda akan menghapus pengetahuan layanan
        </span>
        {loadingDelete ? (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <Button color="danger" onClick={() => onDeleteKnowledge()}>
              Hapus
            </Button>
            <Button color="primary" onClick={() => toggleModalDelete()} outline>
              Batal
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalDelete;
