import { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
} from "reactstrap";
import { eventDisplayNodeOption } from "store/actions";
import SidebarCostumScenario from "../SidebarCostumeScenario";
import MessageScenarioConfigIcon from "./MessageScenarioConfig";
import { connect } from "react-redux";
import TestScenario from "./TestScenario";
import BadgeVersion from "./BadgeVersion";
import moment from "moment";
import {
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import Header from "components/VerticalLayout/Header";
import NewSideBar from "components/VerticalLayout/NewSideBar";

const errorMessageMap = {
  unconnected: "Salah satu node tidak terhubung dalan scenario",
  "input-required": "Salah satu node input tidak valid",
};

const NavbarCostumScenario = ({
  platformId,
  platformName,
  scenario,
  onSave,
  nodeErrors,
  children,
  onFocusNode,
  isEdited,
  eventDisplayNodeOption,
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [onWarning, setOnWarning] = useState(nodeErrors.length > 0);
  const [onCheck, setOnCheck] = useState(true);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [showLargeSidebar, setShowLargeSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  const handleMouseEnter = () => {
    setShowLargeSidebar(true);
  };

  const handleMouseLeave = () => {
    setShowLargeSidebar(false);
  };

  useEffect(() => {
    setOnWarning(nodeErrors.length > 0);
  }, [nodeErrors]);

  const lastUpdateFormat = () => {
    const { updated_at } = scenario;
    let date = moment(scenario.updated_at).format(" DD MMM, YYYY");
    let hour = moment(scenario.updated_at).format("hh:mm A");
    return `Setelan terakhir diluncurkan pada ${date}, pukul ${hour}`;
  };

  const handleOnClickErrorItem = id => {
    onFocusNode(id, true);
  };

  const handleOnSave = async () => {
    if (onWarning) {
      setIsOpenDropDown(true);
      return;
    }

    setIsLoading(true)
    try {
      const resp = await onSave()
      setIsLoading(false)

      const { params } = match
      history.push(`/scenario-test/${scenario.id}?platform=${params.platform}`)
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSideBarMobile = () => {
    setShowMobileSidebar(!showMobileSidebar);
  };

  return (
    <div id="layout-wrapper">
      <div className="only-mobile">
        <Header handleToggleMenu={handleSideBarMobile} />
        <NewSideBar
          showSidebar={showMobileSidebar}
          handleShowSidebar={handleSideBarMobile}
        />
      </div>
      <Navbar
        full={"true"}
        className={`navbar-costume-scenario ${
          showLargeSidebar ? "large-navbar" : "small-navbar"
        }`}
      >
        <span className="title">{platformName}</span>
        <span className="date text-muted">{lastUpdateFormat()}</span>
        <div className="navbar-action p-2">
          <BadgeVersion platformName={platformName} />

          {onWarning && (
            <Dropdown
              isOpen={isOpenDropDown}
              direction="down"
              toggle={() => setIsOpenDropDown(!isOpenDropDown)}
            >
              <DropdownToggle className={`icon icon-warning-danger`}>
                <i className="fa fa-exclamation-triangle" aria-hidden="true" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  Terjadi kesalahan pada skenario
                </DropdownItem>
                {nodeErrors.map((nds, key) => (
                  <DropdownItem
                    onClick={() => handleOnClickErrorItem(nds.id)}
                    key={key}
                  >
                    {errorMessageMap[nds.type]}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
          {!onWarning && (
            <div
              className={`icon ${onCheck ? "icon-check" : "icon-check-danger"}`}
            >
              <i className="fa fa-check-circle" aria-hidden="true" />
            </div>
          )}
          <div className="message-setting">
            <MessageScenarioConfigIcon />
          </div>
          <TestScenario
            isLoading={isLoading}
            scenario={scenario}
            isEdited={isEdited}
            onWarning={onWarning}
            platformId={platformId}
            onClick={handleOnSave} />
        </div>
      </Navbar>
      <SidebarCostumScenario
        showLargeSidebar={showLargeSidebar}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      {children}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    nodeErrors: state.AgentScenarioBuilder.nodeErrors,
  };
};

export default connect(mapStateToProps, {
  eventDisplayNodeOption: eventDisplayNodeOption,
})(NavbarCostumScenario);
