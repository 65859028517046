import React from 'react'
import { Alert } from "reactstrap"

const TokopediaIntegrationInfo = (props) => {
  return (
  <>
    <Alert color='light' className='font-size-16'>
      <div style={{textAlign: 'center'}}
        className='text-align-center m-2 mb-5'>
        <h4>Integrasi Risa Hampir Selesai :)</h4>
      </div>
      <p></p>
      <p>
        Harap ikuti langkah berikut untuk menyelesaikan integrasi toko anda:
      </p>
      <p></p>
      <p>
        1. Tokopedia akan mengirimkan email konfirmasi ke email Tokopedia merchant.
      </p>
      <p></p>
      <p>
        2. Merchant perlu menyetujui sendiri integrasi melalui Email yang dikirimkan pihak Tokopedia ke email Tokopedia merchant.
      </p>
      <p>
        3. Risa akan membantu merchant melakukan integrasi setelah menerima konfirmasi persetujuan dari merchant untuk hal ini membutuhkan waktu 1 hari kerja.
      </p>
      <p></p>
      <p>
        Setelah toko diotorisasi, Anda dapat mengelola pesan pembeli toko di obrolan Risa.
      </p>
      {/* TODO: add blog about tokopedia integration */}
      {/* <a href="https://ginee.com/my/help/how-to-integrate-tokopedia-to-genie/" /> */}
    </Alert>

  </>
  )
}

export default TokopediaIntegrationInfo
