import FbImage from "assets/images/agents/fb.png";
import IgImage from "assets/images/agents/ig.png";
import TokpedImage from "assets/images/agents/tokopedia.png";
import WaImage from "assets/images/agents/wa.png";
import WebImage from "assets/images/agents/web.png";

const PlatformIcon = ({ platformName }) => {
    const icons = {
        "facebook": FbImage,
        "instagram": IgImage,
        "tokopedia": TokpedImage,
        "whatsapp": WaImage,
        "web": WebImage,
    }

    let icon = icons[platformName]
    if (!icon) {
        icon = WebImage
    }

    return <img src={icon} alt={platformName} />
}

export default PlatformIcon