import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { get } from "network/http/api";
import { useOutsideClick } from "helpers/hooks/useOutsideClick";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { notificationErrorRequestAction } from "store/notification/actions";

const AccuracyData = () => {
  const [data, setData] = useState(null);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useOutsideClick(() => {
    setIsOpen(false);
  }, modalRef);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const fetchAccuracyData = () => {
    get("/user/ai-accuracy-score")
      .then(res => {
        setData(res);
      })
      .catch(err => {
        console.error(err);
        dispatch(notificationErrorRequestAction(err.detail));
      });
  };

  useEffect(() => {
    fetchAccuracyData();
  }, []);

  const findBackground = params => {
    if (params <= 30) {
      return "#FBE9E9";
    } else if (params <= 60) {
      return "#FEFAE9";
    } else if (params <= 90) {
      return "#ECF2FD";
    } else if (params === 100) {
      return "#E8F9ED";
    } else {
      return "#e5e7eb";
    }
  };

  const findStatus = params => {
    if (params === 0) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>
            Status : Anda belum menghubungkan RISA AI ke informasi produk dan
            layanan
          </span>
          , Tambahkan agar RISA AI dapat mulai menjawab secara otomatis.
        </span>
      );
    } else if (params <= 30) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Kurang Baik</span>,
          sistem RISA AI belum mampu menjawab pertanyaan pelanggan dengan baik.
          Tambahkan informasi produk dan layanan.
        </span>
      );
    } else if (params <= 60) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Cukup Baik</span>,
          tingkatkan akurasi RISA AI dengan menambahkan informasi produk dan
          layanan.
        </span>
      );
    } else if (params <= 90) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Sangat Baik</span>,
          sistem RISA AI telah dapat menjawab pertanyaan pelanggan Anda dengan
          sangat baik.
        </span>
      );
    } else if (params === 100) {
      return (
        <span className="status">
          <span style={{ fontWeight: "700" }}>Status : Terbaik</span>, sistem
          RISA AI telah dapat menjawab pertanyaan pelanggan Anda dengan performa
          terbaik.
        </span>
      );
    } else {
      return (
        <span className="status" style={{ fontWeight: "700" }}>
          No Data
        </span>
      );
    }
  };

  const goToPage = params => {
    history.push(params);
  };

  return (
    <div className="accuracy-section" ref={modalRef}>
      <div className="btn-accuracy" onClick={handleToggleDropdown}>
        <span>Akurasi Risa AI: {data?.score}%</span>
      </div>
      {isOpen && (
        <div className="modal-accuracy">
          <div className="modal-accuracy-section">
            <XMarkIcon className="icon-close" onClick={handleToggleDropdown} />
            <span className="title">Akurasi RISA AI</span>
            <span className="subtitle">
              Menunjukkan akurasi jawaban yang diberikan secara otomatis ke pada
              pelanggan Anda.
            </span>
            <div
              className="modal-accuracy-section-content"
              style={{
                backgroundColor: findBackground(data?.score),
              }}
            >
              <span className="score">{data?.score}%</span>
              {findStatus(data?.score)}
              <div className="section-button">
                <Button
                  outline
                  color="primary"
                  onClick={() => goToPage("/ecommerce")}
                >
                  Informasi Produk
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() => goToPage("/service-knowledge")}
                >
                  Informasi Layanan
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccuracyData;
