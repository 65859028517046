import { call, put, takeEvery } from "redux-saga/effects"

// Redux States
import { GET_BILLING } from "./actionTypes"
import {
  getBillingFail,
  getBillingSuccess
} from "./actions"

// http api
import * as api from './api'

function* fetchBilling() {
  try {
    const response = yield call(api.getUserBilling)
    yield put(getBillingSuccess(response))
  } catch (error) {
    yield put(getBillingFail(error))
  }
}

function* billingSaga() {
  yield takeEvery(GET_BILLING, fetchBilling)
}

export default billingSaga
