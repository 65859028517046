import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Spinner } from 'reactstrap';

const WebSocketStatus = ({ status }) => {
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (status === '') {
      return
    }

    let interval = null
    if (status === 'connected') {
      interval = setInterval(() => {
        setShowAlert(false)
      }, 1500)
    } else {
      setShowAlert(true)
    }

    return () => {
      if (interval) clearInterval(interval)
    }


  }, [status])

  if (status == 'failure') {
    return <Alert color='danger'
      isOpen={showAlert}
      toggle={() => setShowAlert(!showAlert)}>
      <Spinner />
      <span className='font-size-14 p-0 m-2'>
        Retry connecting

      </span>
    </Alert>
  }

  if (status == 'connected') {
    return <Alert isOpen={showAlert} toggle={() => setShowAlert(!showAlert)}>Connected</Alert>
  }
  return null
}

const mapStateToProps = ({ WebSocket }) => ({
  ...WebSocket
})

export default connect(
  mapStateToProps, null
)(WebSocketStatus)
