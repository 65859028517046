import React, { useState, useEffect } from "react";

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = targetDate => {
    const targetTime = new Date(targetDate).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = targetTime - currentTime;

    if (timeDifference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return { hours, minutes, seconds: formattedSeconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [backgroundColor, setBackgroundColor] = useState({
    bg: "#434750",
    text: "#fff",
  });

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(targetDate);
      setTimeLeft(updatedTimeLeft);

      if (updatedTimeLeft.hours < 6) {
        setBackgroundColor({ bg: "#FFF3CD", text: "#664D03" });
      }

      if (updatedTimeLeft.hours < 1) {
        setBackgroundColor({ bg: "#F55", text: "#fff" });
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [targetDate]);

  if (!targetDate) {
    return <></>;
  }

  return (
    <div
      style={{
        position: "relative",
        padding: "0px 10px",
        marginBottom: "20px",
      }}
    >
      <div
        className="w-100 py-2 text-center"
        style={{
          backgroundColor: backgroundColor.bg,
          padding: "10px 0px",
          color: backgroundColor.text,
        }}
      >
        Waktu tersisa untuk menyelesaikan pembayaran Anda{" "}
        {timeLeft.hours ? timeLeft.hours : "-"} :{" "}
        {timeLeft.minutes ? timeLeft.minutes : "-"} :{" "}
        {timeLeft.seconds ? timeLeft.seconds : "-"}
      </div>
    </div>
  );
};

export default Countdown;
