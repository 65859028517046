import React from "react";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";
import formatCurrency from "helpers/currency";
import "moment/locale/id";
import RetryInvoiceButton from "./RetryInvoiceButton";

moment.locale("id");

const TableInvoice = ({ data }) => {
  const statusStyles = {
    paid: {
      backgroundColor: "#EBF2EF",
      color: "#649B84",
    },
    expired: {
      backgroundColor: "#FCEBEB",
      color: "#E11A1A",
    },
    refund: {
      backgroundColor: "#D7DFF0",
      color: "#3660B3",
    },
    unpaid: {
      backgroundColor: "#F5F5F5",
      color: "#79747E",
    },
  };

  const handleStatusName = paramsStatus => {
    switch (paramsStatus) {
      case "paid":
        return "Sukses";
      case "expired":
        return "Gagal";
      case "refund":
        return "Refund";
      case "unpaid":
        return "Menunggu";
      default:
        return "Unknown";
    }
  };

  const onViewDetail = invoice => {
    window.open(invoice.payment_url, "_blank");
  };

  const RenderTable = ({ dataTabel }) => {
    if (dataTabel.length === 0) {
      return (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th>Nomor Invoice</th>
                <th>Paket Pilihan</th>
                <th>Tanggal Terbit</th>
                <th>Total</th>
                <th>Status</th>
                <th>Aksi</th>
              </tr>
            </thead>
          </Table>
          <div className="tabel-no-data">Belum ada invoice untuk Anda.</div>
        </>
      );
    }

    const renderActionTextButton = (
      lastInvoiceStatus,
      invoiceStatus,
      isLastInvoice,
      item
    ) => {
      if (invoiceStatus !== "expired" || !isLastInvoice) {
        return (
          <span
            style={{ color: "#24B39B", cursor: "pointer" }}
            onClick={() => onViewDetail(item)}
          >
            Detail
          </span>
        );
      }

      if (lastInvoiceStatus === "expired") {
        return <RetryInvoiceButton />;
      }
    };

    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Nomor Invoice</th>
            <th>Paket Pilihan</th>
            <th>Tanggal Terbit</th>
            <th>Total</th>
            <th>Status</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => {
            return (
              <tr key={idx}>
                <td style={{ fontWeight: "600", color: "#000" }}>
                  {item.invoice_number}
                </td>
                <td>{item.plan_name}</td>
                <td>
                  {item.created_at
                    ? moment(item.created_at).format("DD MMMM YYYY")
                    : "-"}
                </td>
                <td>{item.total ? formatCurrency(item.total) : "Rp.0"}</td>
                <td>
                  <span
                    style={{
                      ...statusStyles[item.status],
                      textTransform: "capitalize",
                      padding: "4px 10px",
                      borderRadius: "12px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {item.status ? handleStatusName(item.status) : "-"}
                  </span>
                </td>
                <td>
                  {renderActionTextButton(
                    data[0].status,
                    item.status,
                    idx === 0,
                    item
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Row>
      <Col xs={12} lg={12}>
        <div className="section-tabel">
          <RenderTable dataTabel={data} />
        </div>
      </Col>
    </Row>
  );
};

export default TableInvoice;
