import React from "react";
import CardMenuIntegration from "./ComponentsPage/CardMenuIntegration";
import Skeleton from "react-loading-skeleton";
import { MenuAgentProfile } from "./DefaultData";

const ListIntegration = ({ data, loading, setShowRightSidebar }) => {
  if (loading) {
    return (
      <div className="page-integration-content">
        {[...Array(6)].map((itm, idx) => {
          return (
            <div className="page-integration-menu" key={idx}>
              <div className="page-integration-menu-title">
                <Skeleton className="skeleton" height={48} width={48} />
                <Skeleton className="skeleton" height={20} width={100} />
              </div>
              <div className="page-integration-action">
                <Skeleton className="skeleton" height={20} width={20} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (!data || data?.length === 0) {
    return <span>Data kosong</span>;
  }

  const findIcon = paramsName => {
    const platform = MenuAgentProfile.find(
      item => item.platform === paramsName
    );
    return platform ? platform.icon : null;
  };

  return (
    <div className="page-integration-content">
      {data.map((item, idx) => {
        return (
          <CardMenuIntegration
            key={idx}
            icon={findIcon(item.platform_name)}
            item={item}
            name={item.platform_name}
            setShowRightSidebar={setShowRightSidebar}
          />
        );
      })}
    </div>
  );
};

export default ListIntegration;
