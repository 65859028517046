import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter, useHistory } from "react-router-dom";
import { getAgentIntent } from "store/actions";
import { getShop } from "store/shop/actions";
import { selectListAgentIntent } from "store/agent-intent/reducer";
import { get, post } from "network/http/api";
import {
  Container,
  Col,
  Row,
  Spinner,
  Card,
  CardBody,
  UncontrolledAlert,
} from "reactstrap";
import ModalAgentScenario from "components/CustomScenario/ModalAgentScenario";
import { MenuAgentProfile } from "./DefaultData";
import "moment/locale/id";

import { notificationErrorRequestAction } from "store/notification/actions";

moment.locale("id");

const AgentProfile = props => {
  const history = useHistory();
  const { intents, isUpdating } = props;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataListScenario, setDataListScenario] = useState([]);
  const [dataListScenarioExiest, setListScenarioExiest] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const handleOnClickPlatform = params => {
    const platform = dataListScenarioExiest.find(
      item => item.platform_id === params.id
    );

    if (platform) {
      history.push("/ai-builder/" + params.platform_name);
    } else {
      handleOnCreateCustomScenario(params.platform_name);
    }
  };

  const handleOnCreateCustomScenario = platform_name => {
    const data = {
      platform_name: platform_name,
      scenario: "customer_service",
    };
    setIsLoading(true);

    post("/scenario/from-template", data)
      .then(resp => {
        history.push("/ai-builder/" + platform_name);
        // window.location.reload();
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
        props.notificationError(err.detail);
      });
  };

  useEffect(() => {
    const fetchListScenario = async () => {
      try {
        const res = await get("/scenario");
        setDataListScenario(res.platform);
        setListScenarioExiest(res.scenario);
        setIsError(false);
      } catch (err) {
        setIsError(true);
      }
    };

    fetchListScenario();
  }, []);

  const findIcon = paramsName => {
    const platform = MenuAgentProfile.find(
      item => item.platform === paramsName
    );
    return platform ? platform.icon : null;
  };

  const handleTitle = name => {
    if (name === "shopee") {
      return "Shopee";
    } else if (name === "whatsapp") {
      return "WhatsApp";
    } else {
      return name;
    }
  };

  return (
    <div className="page-content page-agent-profile">
      <Container fluid>
        {isError && (
          <UncontrolledAlert color="danger">
            Something wrong please refresh this page
          </UncontrolledAlert>
        )}
        <Row>
          {dataListScenario.map((item, idx) => {
            if (item.platform_name === "zendesk") return;
            return (
              <Col key={idx} xl="4" md="6">
                <Card className="overflow-hidden">
                  <CardBody>
                    <div
                      className="page-agent-profile-menu"
                      onClick={() => handleOnClickPlatform(item)}
                    >
                      {findIcon(item.platform_name)}
                      <div className="page-agent-profile-menu-title">
                        <span className="title">
                          {item.platform_name
                            ? handleTitle(item.platform_name)
                            : "-"}
                        </span>
                        <span className="date">
                          terakhir diubah pada{" "}
                          {moment(item.updated_at).format("DD MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
        <ModalAgentScenario
          history={history}
          isOpen={isLoading}
          selectedPlatform={selectedPlatform}
          toggle={e => setIsLoading(e)}
          // dataScenario={dataScenario}
        />
      </Container>
      {isUpdating && (
        <div style={{ textAlign: "center" }}>
          <Spinner color="info" type="grow">
            Updating..
          </Spinner>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { AIAssistant } = state;
  let intens = selectListAgentIntent(state);
  let isUpdating = state.AgentIntent.isUpdating;

  return {
    intents: intens,
    isUpdating: isUpdating,
    profile: AIAssistant.profile,
    isFetching: AIAssistant.isFetching,
    error: AIAssistant.error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getShop,
    getAgentIntent,
    notificationError: notificationErrorRequestAction,
  })(AgentProfile)
);
