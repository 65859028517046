import React from "react";
import DropdownSelected from "components/DropdownSelected";
import CommonEditor from "components/Common/CommonEditor";
import { dataDay } from "../DefaultData";

const ScheduleDeliveryInstant = ({ data, setData }) => {
  const onChangeDate = (field, params) => {
    setData(prevData => ({
      ...prevData,
      retail_attribute: {
        ...prevData.retail_attribute,
        shipment_instant_cut_off: {
          ...prevData.retail_attribute.shipment_instant_cut_off,
          [field]: params.value,
        },
      },
    }));
  };

  const onChangeNote = params => {
    setData(prevData => ({
      ...prevData,
      retail_attribute: {
        ...prevData.retail_attribute,
        shipment_instant_cut_off: {
          ...prevData.retail_attribute.shipment_instant_cut_off,
          note: params,
        },
      },
    }));
  };

  return (
    <div style={{ padding: "16px 0px" }}>
      <div className="form-integration-shop">
        <DropdownSelected
          title="Hari Kerja (Senin - Jumat)"
          typeTitle="light"
          data={dataDay}
          selected={data?.retail_attribute?.shipment_instant_cut_off?.weekday}
          setSelected={params => onChangeDate("weekday", params)}
          placeholder="Pilih Waktu"
        />
        <DropdownSelected
          title="Sabtu"
          typeTitle="light"
          data={dataDay}
          selected={data?.retail_attribute?.shipment_instant_cut_off?.saturday}
          setSelected={params => onChangeDate("saturday", params)}
          placeholder="Pilih Waktu"
        />
        <DropdownSelected
          title="Minggu"
          typeTitle="light"
          data={dataDay}
          selected={data?.retail_attribute?.shipment_instant_cut_off?.sunday}
          setSelected={params => onChangeDate("sunday", params)}
          placeholder="Pilih Waktu"
        />
        <DropdownSelected
          title="Hari Libur"
          typeTitle="light"
          data={dataDay}
          selected={data?.retail_attribute?.shipment_instant_cut_off?.holiday}
          setSelected={params => onChangeDate("holiday", params)}
          placeholder="Pilih Waktu"
        />
        <span className="noted">
          NOTE: Waktu terakhir order pengiriman Regular (JNE/J&T) dikirim pada
          hari yang sama.
        </span>
      </div>
      <div className="form-integration-shop-noted">
        <span className="title">Catatan Tambahan</span>
        <CommonEditor
          onChange={e => onChangeNote(e)}
          text={data?.retail_attribute?.shipment_instant_cut_off?.note}
        />
        <span className="note">
          Harap masukan informasi pengiriman secara mendetaill agar sistem dapat
          menjawab pertanyaan customer sesuai dengan kebijakan toko Anda.
        </span>
      </div>
    </div>
  );
};

export default ScheduleDeliveryInstant;
