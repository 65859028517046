import IntegrationTable from 'components/Channel/IntegrationTable';
import NewChannel from 'components/Channel/NewChannel';
import React, { Component } from 'react'
import { Container } from 'reactstrap';

class Integration extends Component {
  render() {
    return (
      <div className="page-content">
        <Container>
          <IntegrationTable />
        </Container>
      </div>
    )
  }
}


export default Integration;
