import React from "react";
import CommonEditor from "components/Common/CommonEditor";
import { Button } from "reactstrap";
import { TrashIcon } from "@heroicons/react/20/solid";

const SectionQustionAnswer = ({
  idx,
  question,
  answer,
  onChangeText,
  onDelete,
}) => {
  return (
    <div className="section-question">
      <div>
        <div className="section-top">
          <span className="title">Pertanyaan</span>
          {idx !== 0 && (
            <Button color="danger" className="btn-delete" onClick={onDelete}>
              <TrashIcon />
              Hapus Pertanyaan
            </Button>
          )}
        </div>
        <CommonEditor
          onChange={e => onChangeText(e, "question")}
          text={question}
          placeholder="Masukan pertanyaan anda di sini"
        />
      </div>
      <div>
        <span className="title">Jawaban</span>
        <CommonEditor
          onChange={e => onChangeText(e, "answer")}
          text={answer}
          placeholder="Masukan jawaban anda di sini"
        />
      </div>
    </div>
  );
};

export default SectionQustionAnswer;
