import classNames from 'classnames';
import React, { Fragment, memo, useCallback, useState } from 'react';
import { Handle, useReactFlow, Position } from 'reactflow';
import { getConnectedEdges, getIncomers, getOutgoers, useNodeId, useStore } from 'reactflow';
import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';

import ActionNodeDialog from './ActionNodeDialog';

// actions
import { eventOnOpenTooltip } from 'store/agent-scenario-builder/actions';

const selectorSource =
  (nodeId, isConnectable = true, maxConnections = 1) =>
    (s) => {
      // If the user props say this handle is not connectable, we don't need to
      // bother checking anything else.

      // if (!isConnectable) return false;

      const node = s.nodeInternals.get(nodeId);
      if (node.type === 'processor') {
        maxConnections = 10
      }

      const connectedEdges = getIncomers(node, s.getNodes(), s.edges);
      return connectedEdges.length < maxConnections;
    };


const selectorTarget =
  (nodeId, isConnectable = true, maxConnections = 1) =>
    (s) => {
      // If the user props say this handle is not connectable, we don't need to
      // bother checking anything else.
      if (!isConnectable) return false;

      const node = s.nodeInternals.get(nodeId);
      if (node.type === 'processor') {
        maxConnections = 10
      }

      const connectedEdges = getOutgoers(node, s.getNodes(), s.edges);
      return connectedEdges.length < maxConnections;
    };

const NodeWrapper = ({ children, data, isTarget, id, title, className, isConnecting,
  titleIcon, eventOnOpenTooltip, toolTip, nodeErrors }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const nodeId = useNodeId();
  const reactFlowInstance = useReactFlow();

  const isConnectable = useStore(
    useCallback(selectorSource(nodeId, isConnecting, 1), [
      nodeId,
      true,
      2,
    ])
  );

  const isTargetConnectable = useStore(
    useCallback(selectorTarget(nodeId, true, 1), [
      nodeId,
      true,
      2,
    ])
  );

  const handleOnClickHeader = () => {
    if (!data) {
      return
    }

    const node = reactFlowInstance.getNode(nodeId)
    data.onOpenConfig(node)
    setDropdownOpen(false)
  }

  const handleOnDelete = () => {
    data.onDelete(nodeId)
    setDropdownOpen(false)
    eventOnOpenTooltip('')
  }

  const handleOnClone = () => {
    let nodes = reactFlowInstance.getNodes()
    data.onClone(nodeId, nodes)
    setDropdownOpen(false)
    eventOnOpenTooltip('')
  }

  const handleOnClickTooltip = () => {
    if (eventOnOpenTooltip) {
      eventOnOpenTooltip(id)
    }
  }

  const getIsErrorNode = (nodeErrors, id) => {
    let isError = false
    nodeErrors.map(ne => {
      if (id === ne.id) {
        isError = true
      }
    })

    return isError
  }

  let isErrorClass = getIsErrorNode(nodeErrors, id) ? 'node-error' : ''
  console.log('isErrorClass', isErrorClass, nodeErrors)
  return (
    <div
      className={classNames('custom-node', className, isErrorClass,
        isConnectable ? 'source-connectable' : '',
        isTargetConnectable ? 'target-connectable' : '')}
      style={{
        borderWidth: 3,
        borderStyle: isTarget ? 'dashed' : 'solid',
        backgroundColor: isTarget ? 'yellow' : '',
      }}>

      <Handle
        isConnectable={isConnectable}
        type="target"
        className="handle-wrapper"
        position={Position.Left} id={id} />
      <div
        className='custom-body' style={{ position: 'relative', zIndex: 99 }}>
        <div className="custom-node__header">
          <div onClick={handleOnClickHeader} className='title'>
            <img src={titleIcon} />
            {title}
          </div>
          <div className='actions'>
            <i onClick={handleOnClickTooltip} className='fa fa-ellipsis-h' />

            <Card className={toolTip === id ? 'show' : 'hide'}>
              <CardBody>
                <ListGroup>
                  <ListGroupItem onClick={handleOnClone}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                    <p>Duplikat Objek</p>
                  </ListGroupItem>
                  <ListGroupItem onClick={handleOnDelete} className='action-delete'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                    <p>Hapus Objek</p>
                  </ListGroupItem>
                </ListGroup>
                <div className='chevron' />
              </CardBody>
            </Card>
          </div>
        </div>
        {React.cloneElement(children)}
      </div>
    </div>
  )
}

const mapStateToProps = ({ AgentScenarioBuilder }) => {
  const { config, nodeErrors } = AgentScenarioBuilder
  return {
    toolTip: config.toolTip,
    displayOptionOnNode: config.displayOptionOnNode,
    nodeErrors: nodeErrors,
  }
}

export default connect(mapStateToProps, {
  eventOnOpenTooltip,
})(memo(NodeWrapper))
