import { get } from "network/http/api";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notificationErrorRequestAction } from "store/notification/actions";
import HeaderSection from "./ComponentsPage/HeaderSection";
import { Col, Row, Button, InputGroup, InputGroupText, Table } from "reactstrap";
import { Input } from "reactstrap";
import moment from "moment";
import CustomPagination from "components/CustomPagination";
import InviteEmployeeModal from "./ComponentsPage/InviteEmployeeModal";
import DeleteEmployeeModal from "./ComponentsPage/DeleteEmployeeModal";

const Employee = () => {
  const dispatch = useDispatch()
  const [employees, setEmployees] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [pagination, setPagination] = useState({
    "total_count": 0,
    "page": 1,
    "totalPage": 1,
    "perPage": 10,
  })

  useEffect(() => {
    fetchEmployees(1)
  }, [])

  const fetchEmployees = (page = 1) => {
    if (isLoading) return

    setIsLoading(true)
    get(`/company/employee?limit=10&&page=${page}`)
      .then(resp => {
        const { users, pagination_metadata } = resp
        const { page, total_pages, total_count } = pagination_metadata
        setIsLoading(false)
        setEmployees(users)
        setPagination({
          page: page,
          perPage: 10,
          totalPage: total_pages,
        })
      })
      .catch(err => {
        setIsLoading(false)
        dispatch(notificationErrorRequestAction('Terjadi kesalahan'))
      })
  }

  const handleOnDelete = (emp) => {
    setSelectedUser(emp)
    setIsOpenDeleteModal(true)
    console.log('open delete', emp)
  }

  const handleOnChangePage = (page) => {
    setPagination(page)
    fetchEmployees(page.page)
  }

  return (
    <div className="settings-content employee">
      <HeaderSection
        title="Management Karyawan"
        subtitle="Anda dapat menambahkan Karyawan untuk membantu mengatur akun, dan menjawab pertanyaan dari pelanggan."
      />
      <div className="employee-invite">
        <InviteEmployeeModal isOpen={isOpenModal}
          refreshList={fetchEmployees}
          toggle={() => setIsOpenModal(!isOpenModal)} />
        <Button
          type="submit"
          color="primary"
          onClick={() => setIsOpenModal(true)}
          disabled={isLoading}
        >
          Undang
        </Button>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>Nama</th>
            <th>Email</th>
            <th>Role</th>
            <th>Tanggal Bergabung</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((item, idx) => {
            return (
              <tr key={idx}>
                <td style={{ fontWeight: "600", color: "#000" }}>
                  {item.name}
                </td>
                <td>{item.email}</td>

                <td>
                  {item.role}
                </td>
                <td>
                  {item.created_at
                    ? moment(item.created_at).format("DD MMMM YYYY")
                    : "-"}
                </td>
                <td>
                  <Button onClick={() => handleOnDelete(item)} color="danger"> Hapus </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div
        style={{
          padding: "32px",
          paddingTop: "unset",
        }}
      >
        <CustomPagination
          disableDropdown
          paramsPage={pagination}
          setParamsPage={handleOnChangePage}
          amountPage={pagination.totalPage}
        />
      </div>
      <DeleteEmployeeModal
        refreshList={fetchEmployees}
        user={selectedUser}
        isOpen={isOpenDeleteModal}
        toggle={() => setIsOpenDeleteModal(!isOpenDeleteModal)} />
    </div>
  )
}

export default Employee