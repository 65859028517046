import {
  SHOP_REGISTER_REQUEST,
  SHOP_REGISTER_SUCCES,
  SHOP_REGISTER_FAILED,
  GET_SHOP_FAILED,
  GET_SHOP_SUCCES,
  GET_SHOP_REQUEST,
  GET_SHOP_DETAIL_REQUEST,
  GET_SHOP_DETAIL_SUCCES,
  GET_SHOP_DETAIL_FAILED,
  GET_SHOP_TRAIN_STATE_REQUEST,
  GET_SHOP_TRAIN_STATE_FAILED,
  GET_SHOP_TRAIN_STATE_SUCCES,
  SHOP_REGISTER_RESET,
  UPDATE_SHOP_POLICY_REQUEST,
  UPDATE_SHOP_POLICY_SUCCES,
  UPDATE_SHOP_POLICY_FAILED,

  UPDATE_SHOP_REQUEST,
  UPDATE_SHOP_SUCCES,
  UPDATE_SHOP_FAILED,
  UPDATE_SHOP_SHIPMENT_REQUEST
} from "./actionTypes"

export const updateShopRequest = (id, shop) => ({
  type: UPDATE_SHOP_REQUEST,
  payload: {
    id,
    shop: shop,
  }
})

export const updateShopShipmentRequest = (id, data) => ({
  type: UPDATE_SHOP_SHIPMENT_REQUEST,
  payload: {
    id,
    data,
  }
})

export const updateShopSuccess = (payload) => ({
  type: UPDATE_SHOP_SUCCES,
  payload,
})

export const updateShopFailed = (error) => ({
  type: UPDATE_SHOP_FAILED,
  payload: error,
})

export const registerShopReset = () => ({
  type: SHOP_REGISTER_RESET,
})

export const registerShop = (payload, history) => ({
  type: SHOP_REGISTER_REQUEST,
  payload: {
    data: payload,
    history: history,
  },
})

export const registerShopFailed = payload => ({
  type: SHOP_REGISTER_FAILED,
  payload,
})

export const registerShopSuccess = payload => ({
  type: SHOP_REGISTER_SUCCES,
  payload,
})

export const getShop = () => ({
  type: GET_SHOP_REQUEST,
})

export const getShopFailed = payload => ({
  type: GET_SHOP_FAILED,
  payload,
})

export const getShopSuccess = payload => ({
  type: GET_SHOP_SUCCES,
  payload,
})


export const getShopDetail = (id) => ({
  type: GET_SHOP_DETAIL_REQUEST,
  payload: id,
})

export const getShopDetailFailed = payload => ({
  type: GET_SHOP_DETAIL_FAILED,
  payload,
})

export const getShopDetailSuccess = payload => ({
  type: GET_SHOP_DETAIL_SUCCES,
  payload,
})

export const getShopTrainState = (id) => ({
  type: GET_SHOP_TRAIN_STATE_REQUEST,
  payload: id,
})

export const getShopTrainStateFailed = payload => ({
  type: GET_SHOP_TRAIN_STATE_FAILED,
  payload,
})

export const getShopTrainStateSuccess = payload => ({
  type: GET_SHOP_TRAIN_STATE_SUCCES,
  payload,
})

export const updateShopPolicy = (storeId, policy) => ({
  type: UPDATE_SHOP_POLICY_REQUEST,
  payload: {
    storeId,
    policy
  }
})

export const updateShopPolicySuccess = (payload) => ({
  type: UPDATE_SHOP_POLICY_SUCCES,
  payload: payload,
})

export const updateShopPolicyFailed = (payload) => ({
  type: UPDATE_SHOP_POLICY_FAILED,
  payload,
})
