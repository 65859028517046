import React, { useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { PlusIcon } from "@heroicons/react/20/solid";
import ModalAddQuestionAnswer from "./ModalAddQuestionAnswer";
import { connect } from "react-redux";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";
import CardsQandAKnowledge from "pages/Ecommerce/ServiceKnowledge/CardsQandAKnowledge";
import EmptySummaryDashboard from "pages/Ecommerce/EcommerceDashboard/EmptySummaryDashboard";

const QuestionList = ({
  questions,
  productId,
  knowledgeBaseId,
  type,
  onChangePage,
  disableAddQuestion,
  notificationSuccessRequestAction,
  loading,
  loadingMore,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [questionList, setQuestionList] = useState(questions);
  const [editingQuestionId, setEditingQuestionId] = useState(null);

  useEffect(() => {
    setQuestionList(questions);
  }, [questions]);

  const handleOnAdded = () => {
    notificationSuccessRequestAction("Berhasil menambahkan pengetahuan");
    onChangePage(1);
  };

  const handleOnUpdateQuestion = data => {
    let updated = questionList.map(q => {
      if (q.id === data.id) {
        return data;
      }
      return q;
    });
    setQuestionList(updated);
  };

  if (loading) {
    return (
      <div className="section-detail-question-list">
        <div className="question-list">
          <div className="card-knowledge-question-product">
            <div style={{ flex: 1, width: "100%" }}>
              <span className="title-question">Pertanyaan</span>
              <Skeleton height={150} />
            </div>
            <div className="spacer" />
            <div style={{ flex: 1, width: "100%" }}>
              <span className="title-question">Jawaban</span>
              <Skeleton height={150} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {(!questionList || questionList?.length === 0) && (
        <EmptySummaryDashboard
          title={`${
            type === "product"
              ? "Belum Ada Pengetahuan Produk"
              : "Belum Ada Tanya Jawab"
          }`}
          subtitle={`${
            type === "product"
              ? "Anda harus memiliki minal satu pengetahuan produk"
              : "Anda belum memiliki Tanya Jawab"
          }`}
          actionButton={
            <>
              {type === "product" ? (
                <Button
                  color="primary"
                  className="btn-empty"
                  onClick={() => setIsOpenModal(true)}
                >
                  <PlusIcon />
                  Tambah Penghetahuan
                </Button>
              ) : (
                <></>
              )}
            </>
          }
        />
      )}
      {questionList && questionList?.length > 0 && (
        <div className="section-detail-question-list">
          {!disableAddQuestion && (
            <div className="question-list-add-knowledge">
              <Button
                color="primary"
                onClick={() => setIsOpenModal(true)}
                className="btn-add-service-knowledge"
              >
                <PlusIcon /> Tambah Tanya Jawab
              </Button>
            </div>
          )}
          <div className="question-list">
            {questionList.map((item, idx) => {
              return (
                <CardsQandAKnowledge
                  onUpdateQuestion={handleOnUpdateQuestion}
                  key={idx}
                  item={item}
                  editingQuestionId={editingQuestionId}
                  setEditingQuestionId={setEditingQuestionId}
                />
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "25px",
            }}
          >
            {loadingMore && <Spinner color="primary" />}
          </div>
        </div>
      )}
      {!disableAddQuestion && (
        <ModalAddQuestionAnswer
          productId={productId}
          knowledgeBaseId={knowledgeBaseId}
          type={type}
          isOpen={isOpenModal}
          onAdded={() => handleOnAdded()}
          toggle={() => setIsOpenModal(!isOpenModal)}
        />
      )}
    </>
  );
};

export default connect(null, {
  notificationSuccessRequestAction,
})(QuestionList);
