import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import * as actions from "../actions"
import * as api from './api'
import {
  GET_SHOP_DETAIL_REQUEST, GET_SHOP_REQUEST,
  GET_SHOP_TRAIN_STATE_REQUEST, SHOP_REGISTER_REQUEST,
  UPDATE_SHOP_POLICY_REQUEST, UPDATE_SHOP_REQUEST, UPDATE_SHOP_SHIPMENT_REQUEST
} from "./actionTypes"

function* onRegister({ payload: { data } }) {
  try {
    const response = yield call(api.registerShop, data)

    yield put(actions.registerShopSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.registerShopFailed(error))
  }
}

function* onFetchShop() {
  try {
    const response = yield call(api.fetchShop)

    yield put(actions.getShopSuccess(response))

    if (response.products) yield put(actions.getProductsSuccess(response.products, response.total_product, response.total_product))
  } catch (error) {
    console.error(error)
    yield put(actions.getShopFailed(error))
  }
}

function* onGetDetailShop({ payload }) {
  try {
    const response = yield call(api.getShopDetail, payload)

    yield put(actions.getShopDetailSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getShopDetailFailed(error))
  }
}


function* onGetTrainStateShop({ payload }) {
  try {
    const response = yield call(api.getShopTrainState, payload)

    yield put(actions.getShopTrainStateSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getShopTrainStateFailed(error))
  }
}

function* onUpdateShopPolicy({ payload: { storeId, policy } }) {
  try {
    const response = yield call(api.updateShopPolicy, storeId, policy)

    yield put(actions.updateShopPolicySuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopPolicyFailed(error))
  }
}

function* onUpdateShop({ payload: { id, shop } }) {
  try {
    const response = yield call(api.updateShop, id, shop)
    yield put(actions.updateShopSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopFailed(error))
  }
}

function* onUpdateShopShipment({ payload: { id, data } }) {
  try {
    const response = yield call(api.updateShopShipment, id, data)
    yield put(actions.updateShopSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopFailed(error))
  }
}

function* shopSaga() {
  yield takeEvery(UPDATE_SHOP_POLICY_REQUEST, onUpdateShopPolicy)
  yield takeEvery(SHOP_REGISTER_REQUEST, onRegister)
  yield takeEvery(GET_SHOP_REQUEST, onFetchShop)
  yield takeEvery(GET_SHOP_DETAIL_REQUEST, onGetDetailShop)
  yield takeEvery(GET_SHOP_TRAIN_STATE_REQUEST, onGetTrainStateShop)
  yield takeEvery(UPDATE_SHOP_REQUEST, onUpdateShop)
  yield takeEvery(UPDATE_SHOP_SHIPMENT_REQUEST, onUpdateShopShipment)
}

export default shopSaga
