import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Spinner,
  Modal,
  Button,
  Input,
  Alert,
} from "reactstrap";

import { convertInt2Money } from "components/Product/ProductCardItem";
import moment from "moment";
import { post, get } from "network/http/api";

const Package = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(null);
  const [billing, setBilling] = useState({});
  const [cancelResult, setCancelResult] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);

  useEffect(() => {
    const fetchBiling = async () => {
      setLoadingFetch(true);
      try {
        const res = await get(`/billing/me`);
        setBilling(res.billing);
        setCancelResult(res.cancel_request);
        setLoadingFetch(false);
        setErrorFetch(null);
      } catch (err) {
        setErrorFetch(err.detail);
        setTimeout(() => {
          setErrorFetch(null);
        }, 1000);
        setLoadingFetch(false);
      }
    };

    fetchBiling();
  }, [triggerFetch]);

  const onClickCancel = () => {
    const payload = {
      reason: reason,
    };

    setLoading(true);
    post(`/billing/cancel`, payload)
      .then(resp => {
        setTriggerFetch(true);
        setLoading(false);
        setModal(false);
      })
      .catch(error => {
        setLoading(false);
        setError(error.detail);
      });
  };

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="section-package">
      <Card>
        <CardBody>
          {loadingFetch ? (
            <div
              className="text-center"
              style={{
                height: "184px",
                display: "flex",
                flexDirection: "col",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div className="section-column-package">
              {errorFetch && <Alert color="danger">{errorFetch}</Alert>}
              <span className="package-name">
                {billing ? billing.plan_name : "-"}
              </span>
              <div className="section-package">
                <div className="content-description">
                  <span className="feature-content">
                    <i className="fa fa-check-circle" aria-hidden="true" />
                    Kuota pesan: {billing
                      ? billing.max_number_message
                      : "0"}{" "}
                    pesan /bulan
                  </span>
                  <span className="feature-content">
                    <i className="fa fa-check-circle" aria-hidden="true" />
                    Kuota produk: {billing
                      ? billing.max_number_product
                      : "0"}{" "}
                    produk
                  </span>
                </div>
                <div className="content-price">
                  <span className="price">
                    {billing ? convertInt2Money(billing.base_price) : "Rp.0"}
                  </span>
                  <span className="suffix">
                    {billing?.is_yearly ? "Per Tahun" : "Per Bulan"}
                  </span>
                </div>
              </div>
              {billing.base_price > 0 && (
                <button
                  className={`button ${cancelResult ? "text-gray" : ""}`}
                  onClick={() => toggle()}
                  disabled={cancelResult}
                >
                  {cancelResult
                    ? `Paket akan diberhentikan pada: ${
                        cancelResult?.created_at
                          ? moment(billing.next_cycle_date).format(
                              "Do MMMM, YYYY"
                            )
                          : "-"
                      }`
                    : "Batalkan Langganan"}
                </button>
              )}
            </div>
          )}
        </CardBody>
      </Card>

      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        className="section-modal-billing"
      >
        <div className="modal-cancel-billing">
          <span className="title">Batalkan Langganan</span>
          <span className="subtitle">
            Setelah membatalkan, Anda tidak akan mendapatkan tagihan dan
            langganan diberhentikan pada bulan berikutnya.
          </span>
          <div className="text-reason-modal-cancel">
            <label htmlFor="reason">Alasan:</label>
            <Input
              className="text-area-reason"
              type="textarea"
              value={reason}
              onChange={e => setReason(e.target.value)}
            />
          </div>
          {error && <Alert color="danger">{error}</Alert>}
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <div className="section-button">
              <div className="secondary" onClick={() => onClickCancel()}>
                Ya
              </div>
              <Button color="primary" onClick={() => toggle()}>
                Tidak Jadi
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Package;
