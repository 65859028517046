import { call, put, takeEvery } from "redux-saga/effects"

// Ticket Redux States
import {
  GET_TICKETS,
  GET_TICKET_DETAIL,
  ADD_NEW_TICKET,
  UPDATE_TICKET,
  GET_CONVERSATION_TICKETS
} from "./actionTypes"
import {
  getTicketsSuccess,
  getTicketsFail,
  getTicketDetailSuccess,
  getTicketDetailFail,
  addTicketFail,
  addTicketSuccess,
  updateTicketSuccess,
  updateTicketFail,
  getConversationTicketSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import * as api from './api'

function* fetchTickets({payload: {page, limit}}) {
  try {
    const response = yield call(api.fetchTicket, page, limit)
    yield put(getTicketsSuccess(response))
  } catch (error) {
    yield put(getTicketsFail(error))
  }
}

function* fetchTicketDetail({ payload }) {
  try {
    const response = yield call(api.detailTicket, payload)
    yield put(getTicketDetailSuccess(response))
  } catch (error) {
    yield put(getTicketDetailFail(error))
  }
}

function* onUpdateTicket({ payload: {id, ticket} }) {
  try {
    const response = yield call(api.updateTicket, id, ticket)
    yield put(updateTicketSuccess(response))
  } catch (error) {
    yield put(updateTicketFail(error))
  }
}

function* fetchConversationTicket({payload}){
  try {
    const response = yield call(api.fetchConversationTicket, payload.conversationId)
    yield put(getConversationTicketSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getTicketsFail(error))
  }
}

function* onAddNewTicket({ payload: {ticket, history, openNewTab} }) {
  try {
    const response = yield call(api.createTicket, ticket)
    yield put(addTicketSuccess(response))

    let ticketDetail = `/ticket-detail/${response.id}`
    if(!openNewTab){
      window.open(ticketDetail, '_blank')
    }else{
      history.push(ticketDetail)
    }
  } catch (error) {
    yield put(addTicketFail(error))
  }
}

function* ticketsSaga() {
  yield takeEvery(GET_TICKETS, fetchTickets)
  yield takeEvery(GET_TICKET_DETAIL, fetchTicketDetail)
  yield takeEvery(ADD_NEW_TICKET, onAddNewTicket)
  yield takeEvery(UPDATE_TICKET, onUpdateTicket)
  yield takeEvery(GET_CONVERSATION_TICKETS, fetchConversationTicket)
}

export default ticketsSaga
