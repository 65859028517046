import React from "react";
import formatCurrency from "helpers/currency";
import { useHistory } from "react-router-dom";
import { EmptyImage } from "../IconPage";

const CardsProductStore = ({ itm }) => {
  const history = useHistory();

  const checkCategoryProduct = paramsDetail => {
    const result = paramsDetail.find(detail => detail.title === "Kategori");
    if (result) {
      return result.text;
    } else {
      return "-";
    }
  };

  const goToDetail = () => {
    history.push(`/ecommerce-product-details/${itm.id}`);
  };

  return (
    <div className="card-store-product" onClick={() => goToDetail()}>
      {itm.pictures[0]?.original_url ? (
        <img src={itm.pictures[0].original_url} className="card-store-img" />
      ) : (
        <div className="card-store-img">
          <div className="empty-store-img">
            <EmptyImage />
          </div>
        </div>
      )}

      {itm.details.length && (
        <div className="badge-product-summary">
          {checkCategoryProduct(itm.details)}
        </div>
      )}
      <span className="title-product">{itm.name ? itm.name : "-"}</span>
      <span className="price-product">
        {itm.price ? formatCurrency(itm.price) : "Rp.0"}
      </span>
      <span className="stock-product">
        Stok tersedia : {itm.stock ? itm.stock : "0"}
      </span>
    </div>
  );
};

export default CardsProductStore;
