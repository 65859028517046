import { get, post, put, del } from "network/http/api"

export const fetchComent = (ticketId, page = 1, limit = 10) => {
  return get(`/ticket/comment/${ticketId}?page=${page}&&limit=${limit}`)
}

export const updateComment = (id, data) => {
  return put(`/ticket/comment/${id}`, data)
}

export const createComment = (data) => {
  return post(`/ticket/comment`, data)
}

export const deleteComment = (id) => {
  return del(`/ticket/comment/${id}`)
}
