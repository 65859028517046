import React, { useState, useEffect } from "react";
import { EmptyImage } from "../IconPage";
import { CheckIcon } from "@heroicons/react/20/solid";

const ListRecommend = ({ data, onSelectedDataChange }) => {
  const [activeItems, setActiveItems] = useState([]);

  const handleCheckboxClick = index => {
    setActiveItems(prev => {
      if (prev.includes(index)) {
        return prev.filter(item => item !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const getSelectedData = () => {
    const selectedData = activeItems.map(index => data[index]);
    return selectedData;
  };

  useEffect(() => {
    if (activeItems) {
      const newSelectedData = getSelectedData();
      onSelectedDataChange(newSelectedData);
    }
  }, [activeItems]);

  if (!data || data?.length === 0) {
    return <div>KOSONG</div>;
  }

  return (
    <div className="list-product">
      {data.map((itm, idx) => (
        <div
          className="card-product-checkbox"
          key={idx}
          onClick={() => handleCheckboxClick(idx)}
        >
          <div
            className={`checkbox ${activeItems.includes(idx) ? "active" : ""}`}
          >
            {activeItems.includes(idx) && (
              <span className="checkbox-number">
                {activeItems.indexOf(idx) + 1}
              </span>
            )}
          </div>
          {itm.pictures[0].original_url ? (
            <img
              src={itm.pictures[0].original_url}
              className="card-store-img"
            />
          ) : (
            <div className="card-store-img">
              <div className="empty-store-img">
                <EmptyImage />
              </div>
            </div>
          )}
          <span>{itm.name ? itm.name : "-"}</span>
        </div>
      ))}
    </div>
  );
};

export default ListRecommend;
