import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE, GET_PROFILE_DETAIL } from "./actionTypes";
import { profileSuccess, profileError, getProfileSuccess, getProfileDetailSuccess } from "./actions";

// http api
import * as api from './api'

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(api.updateProfile, user)
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* getProfile({ payload: { history } }) {
  try {
    const response = yield call(api.get_profile)
    yield put(getProfileSuccess(response));


    if (response.status === 'pending') {
      history.push('/user/complete-invitation')
      return
    } else if (response.status === 'deactivated') {
      history.push('/user/deactivated')
      return
    } else if (!response.is_email_verified) {
      history.push("/user/email-verification")
    } else if (!response.is_onboarding_finished) {
      history.push("/onboarding")
    }

  } catch (error) {
    yield put(profileError(error));
  }
}

function* getProfileDetail() {
  try {
    const response = yield call(api.get_profile_detail)
    yield put(getProfileDetailSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(GET_PROFILE, getProfile);
  yield takeEvery(GET_PROFILE_DETAIL, getProfileDetail)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
