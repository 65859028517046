import React from "react";
import { Button } from "reactstrap";

const ListQuestionAnswer = ({
  listQuestion,
  openModalQuestion,
  page,
  prevNavigation,
  nextNavigation,
  totalItem,
  loadingQuestionAnswer,
}) => {
  return (
    <React.Fragment>
      <div className="question-list">
        <h2>Your Example Question</h2>
        {!loadingQuestionAnswer ? (
          <>
            {listQuestion.length ? (
              <>
                <ul>
                  {listQuestion.map((items, key) => {
                    return (
                      <li key={key} className="question-container">
                        <div className="question-answer">
                          <span className="question">{items.question}</span>
                          <span className="answer">{items.answer}</span>
                        </div>
                        <div className="question-answer-icon">
                          <i
                            onClick={() => openModalQuestion("edit", items)}
                            className="fa fa-pencil-alt font-size-18 edit"
                            aria-hidden="true"
                          ></i>
                          <i
                            onClick={() => openModalQuestion("delete", items)}
                            className="fa fa-trash font-size-18 delete"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="pagination">
                  {page !== 1 && (
                    <Button
                      className="btn-pagination"
                      color="primary"
                      onClick={() => prevNavigation(page)}
                    >
                      Prev
                    </Button>
                  )}
                  {page * 10 < totalItem && (
                    <Button
                      className="btn-pagination"
                      color="primary"
                      onClick={() => nextNavigation(page)}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <div className="mt-4 empty-question">
                <i
                  className="fa fa-plus font-size-18"
                  style={{ color: "#1cac93" }}
                ></i>
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "0px",
                  }}
                >
                  You dont have Example Question, Please add your first example
                  question, to see your example question
                </h3>
              </div>
            )}
          </>
        ) : (
          <div className="mt-4">
            <div className="skeleton-box" style={{ width: "100%" }} />
            <div className="skeleton-box" style={{ width: "100%" }} />
            <div className="skeleton-box" style={{ width: "100%" }} />
            <div className="skeleton-box" style={{ width: "100%" }} />
            <div className="skeleton-box" style={{ width: "100%" }} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ListQuestionAnswer;
