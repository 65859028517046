import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { getProductDetail } from "store/product/actions";
import Tabs from "components/Tabs";
import BackButton from "components/BackButton";
import Details from "./Details";
import { useQuery } from "components/URLQuery";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Question from "components/KnowledgeServer/Question";

const ProductDetail = props => {
  const [activeTab, setActiveTab] = useState(0);
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const [productData, setProductData] = useState(null);
  const {
    match: { params },
    onGetProductDetail,
    product,
  } = props;

  useEffect(() => {
    if (params && params.id) {
      onGetProductDetail(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (product) {
      setProductData(product);
    }
  }, [product]);

  useEffect(() => {
    const tab = query.get("tab");
    setActiveTab(parseInt(tab) || 0);
  }, [location]);

  const handleTabClick = params => {
    // query.append("tab", params)
    // history.push()
    history.push({
      pathname: window.location.pathname,
      search: "?tab=" + params,
    });
  };

  return (
    <div className="page-content">
      <div className="page-ecommerce-details">
        <Container fluid>
          <BackButton goTo="/ecommerce" />
          <div className="page-ecommerce-details-content">
            <span className="title">
              {product ? product?.parent?.name : "-"}
            </span>
            <div className="section-details-content">
              {product.parent && (
                <Tabs
                  tabs={[
                    {
                      id: 0,
                      name: "Info Produk",
                      content: <Details data={product} />,
                      url: "summary",
                    },
                    {
                      id: 1,
                      name: "Tanya Jawab Produk",
                      content: (
                        <Question
                          type="product"
                          productId={product.parent.id}
                        />
                      ),
                      url: "product-ask-question",
                    },
                  ]}
                  activeTab={activeTab}
                  handleTabClick={handleTabClick}
                />
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Product }) => ({
  product: Product.product,
  isFetching: Product.productIsFetching,
});

const mapDispatchToProps = dispatch => ({
  onGetProductDetail: id => dispatch(getProductDetail(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
);
