import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { RightArrowIcon } from "../../IconPage";
import ListKnowledgeProduct from "./ListKnowledgeProduct";
import SectionInformation from "./SectionInformation";
import { get } from "network/http/api";
import { Toaster } from "react-hot-toast";

const KnowledgeProductDashboard = ({ data }) => {
  const history = useHistory();
  const [informationStore, setInformationStore] = useState([
    {
      title: "Total Kategori Pengetahuan Layanan ",
      value: 0,
      tooltip: "",
    },
    {
      title: "Total Tanya Jawab Layanan",
      value: 0,
      tooltip: "",
    },
  ]);
  const [listKnowledgeProductData, setListKnowledgeProductData] = useState([]);
  const [loadingKnowledgeList, setLoadingKnowledgeList] = useState(false);

  const fetchKnowledge = async () => {
    setLoadingKnowledgeList(true);
    try {
      const res = await get(`/knowledge-base?page=${1}&&per_page=${10}`);
      setListKnowledgeProductData(res.knowledges);
      setInformationStore([
        {
          title: "Total Kategori Pengetahuan Layanan ",
          value: res.total_item,
          tooltip:
            "Total Kategori Pengetahuan Layanan adalah banyaknya kategori pengetahuan layanan yang sudah dibuat.",
        },
        {
          title: "Total Tanya Jawab Layanan",
          value: res.total_question_answer,
          tooltip:
            "Total Tanya Jawab layanan banyaknya rangkaian tanya jawab secara keseluruhan yang telah dibuat.",
        },
      ]);
      setLoadingKnowledgeList(false);
    } catch (err) {
      setLoadingKnowledgeList(false);
    }
  };

  useEffect(() => {
    fetchKnowledge();
  }, []);

  const goToListServiceKnowledge = () => {
    history.push("/service-knowledge");
  };

  const goToAddServiceKnowledge = () => {
    history.push("/add-service-knowledge");
  };

  return (
    <div className="integrated-store-summary">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 1000,
        }}
      />
      <div className="store-summary-information">
        <h4 className="title">Pengetahuan Layanan Toko</h4>
        <span className="subtitle">
          Informasi terkait pengetahuan layanan toko dan produk Anda yang sudah
          terintegrasi dengan sistem RISA
        </span>
        <SectionInformation
          loading={loadingKnowledgeList}
          informationStore={informationStore}
        />
      </div>
      <div className="store-summary-information">
        <h4 className="title">Pengetahuan Layanan Terbaru</h4>
        <span className="subtitle">
          Daftar kategori pengetahuan layanan yang baru saja ditambahkan pada
          sistem RISA
        </span>

        <div className="store-summary-product-content">
          <ListKnowledgeProduct
            loading={loadingKnowledgeList}
            data={listKnowledgeProductData}
            goToAddServiceKnowledge={goToAddServiceKnowledge}
          />
          {listKnowledgeProductData?.length > 0 && (
            <div className="store-summary-btn">
              <Button
                color="ghost"
                className="btn-summary-list-product"
                onClick={() => goToListServiceKnowledge()}
              >
                Lihat Pengetahuan Layanan Lainnya
                <RightArrowIcon />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeProductDashboard;
