import React from "react";
import SwitchCostume from "components/SwitchCostume";

const SummaryToggle = ({
  type,
  title,
  description,
  tooltipText,
  toggleData,
  goToStep,
}) => {
  if (type) {
    return (
      <div className="summary-toggle">
        <div className="summary">
          <span className="title">{title} INTEGRATION</span>
          <span className="description">{description ? description : "-"}</span>
        </div>
        <SwitchCostume
          enabled={toggleData}
          setEnabled={goToStep}
          tooltipText={tooltipText}
        />
      </div>
    );
  }

  return (
    <div className="summary-toggle">
      <div className="summary">
        <span className="title">{title}</span>
        <span className="description">{description ? description : "-"}</span>
      </div>
      <SwitchCostume
        enabled={description}
        setEnabled={goToStep}
        tooltipText={tooltipText}
      />
    </div>
  );
};

export default SummaryToggle;
