const { get, post, put, del } = require("network/http/api")

export const getAgentOperationHour = () => {
  return get(`/agent-operation-hour`)
}

export const insertAgentOperationHour = (data) => {
  return post(`/agent-operation-hour`, data)
}

export const updateAgentOperationHour = (id, data) => {
  return put(`/agent-operation-hour/${id}`, data)
}
