import React from "react";
import CommonEditor from "components/Common/CommonEditor";

const ContentTabsAfterSale = ({ desc, data, setData, error, setError }) => {
  const onChangeText = params => {
    const emptyHtmlTagsRegex =
      /<\s*\w+\s*>(\s|&nbsp;|<\/?\s*\w*\s*>)*<\/\s*\w+\s*>/g;
    const cleanedInput = params.replace(emptyHtmlTagsRegex, "");

    if (cleanedInput.length < 5) {
      setError({
        ...error,
        after_sale: true,
      });
      return;
    }

    setError({
      ...error,
      after_sale: false,
    });
    setData(prevData => ({
      ...prevData,
      policies: {
        ...prevData.policies,
        after_sale: cleanedInput,
      },
    }));
  };

  return (
    <div className="tabs-content-input">
      <span className="tabs-content-input-description">{desc}</span>
      <CommonEditor
        className={error.after_sale ? "error" : ""}
        onChange={e => onChangeText(e)}
        text={data?.policies?.after_sale}
      />
      {error.after_sale && (
        <span className="tabs-content-error">
          After Sale tidak boleh kosong
        </span>
      )}
    </div>
  );
};

export default ContentTabsAfterSale;
