// REGISTER FORM
export const SHOP_REGISTER_REQUEST = 'SHOP_REGISTER_REQUEST'
export const SHOP_REGISTER_SUCCES = 'SHOP_REGISTER_SUCCES'
export const SHOP_REGISTER_FAILED = 'SHOP_REGISTER_FAILED'
export const SHOP_REGISTER_RESET = 'SHOP_REGISTER_RESET'

export const GET_SHOP_REQUEST = 'GET_SHOP_REQUEST'
export const GET_SHOP_SUCCES = 'GET_SHOP_SUCCES'
export const GET_SHOP_FAILED = 'GET_SHOP_FAILED'

export const UPDATE_SHOP_REQUEST = 'UPDATE_SHOP_REQUEST'
export const UPDATE_SHOP_SUCCES = 'UPDATE_SHOP_SUCCES'
export const UPDATE_SHOP_FAILED = 'UPDATE_SHOP_FAILED'
export const UPDATE_SHOP_SHIPMENT_REQUEST = 'UPDATE_SHOP_SHIPMENT_REQUEST'

export const UPDATE_SHOP_POLICY_REQUEST = 'UPDATE_SHOP_POLICY_REQUEST'
export const UPDATE_SHOP_POLICY_SUCCES = 'UPDATE_SHOP_POLICY_SUCCES'
export const UPDATE_SHOP_POLICY_FAILED = 'UPDATE_SHOP_POLICY_FAILED'

export const GET_SHOP_DETAIL_REQUEST = 'GET_SHOP_DETAIL_REQUEST'
export const GET_SHOP_DETAIL_SUCCES = 'GET_SHOP_DETAIL_SUCCES'
export const GET_SHOP_DETAIL_FAILED = 'GET_SHOP_DETAIL_FAILED'

export const GET_SHOP_TRAIN_STATE_REQUEST = 'GET_SHOP_TRAIN_STATE_REQUEST'
export const GET_SHOP_TRAIN_STATE_SUCCES = 'GET_SHOP_TRAIN_STATE_SUCCES'
export const GET_SHOP_TRAIN_STATE_FAILED = 'GET_SHOP_TRAIN_STATE_FAILED'
