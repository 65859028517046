import * as Yup from "yup";

const InsertShopSchema = Yup.object().shape({
  name: Yup.string().required("Nama Toko tidak boleh kosong"),
  url: Yup.string().matches(/^(?:https?:\/\/)?(?:[^.]+\.)?tokopedia\.com(\/.*)?$/, "Alamat toko anda tidak valid")
    .required("URL Toko tidak boleh kosong"),
});

const IntegrationWhatsappSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Anda harus memasukkan email sesuai format"
    )
    .required("Anda harus memasukkan email sesuai format"),
  first_name: Yup.string().required("Nama depan tidak boleh kosong"),
  last_name: Yup.string().required("Nama belakang tidak boleh kosong"),
  company_name: Yup.string().required("Nama perusahaan tidak boleh kosong"),
  company_website_url: Yup.string().required(
    "Website Perusahaan tidak boleh kosong"
  ),
  company_country_name: Yup.object()
    .nullable(false)
    .required("Pilih lokasi negara kantor pusat dahulu"),
  sid_twilio: Yup.string().required("ID Twilio tidak boleh kosong"),
  id_business_manager_meta: Yup.string().required(
    "ID Business Manager Meta/Facebook tidak boleh kosong"
  ),
  use_case: Yup.string().required(
    "Tujuan anda menggunakan whatsapp tidak boleh kosong"
  ),
  message_recipient_country_name: Yup.object()
    .nullable(false)
    .required("Pilih negara yang anda kirimi dahulu"),
  first_year_monthly_message_count: Yup.object()
    .nullable(false)
    .required("Pilih berapa banyak pesan yang anda kirimi dahulu"),
  consent_for_twilio_voice_url_change: Yup.bool().oneOf(
    [true],
    "Anda harus paham persyaratan"
  ),
  privacy_policy_consent: Yup.bool().oneOf([true], "Anda harus menyetujui ini"),
});

export { InsertShopSchema, IntegrationWhatsappSchema };
