import React, { Component } from "react"
import {
  Button,
  Modal, ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import images
import bukalapak_logo from 'assets/images/bukalapak-logo.png';
import tokopedia_logo from 'assets/images/tokopedia-logo.png';
import shopee_logo from 'assets/images/shopee-logo.png';

// actions
import { registerShop } from 'store/shop/actions'
import TokopediaIntegrationInfo from "./TokopediaIntegrationInfo";
import ShopeeIntegrationInfo from "./ShopeeIntegrationInfo";
import NewChannelTimeline from "./NewChannelTimeline";
import NewChannelForm from "./NewChannelForm";
import ShopInfoInput from 'components/Shop/ShopShipmentOperation';
import ShopPolicy from "components/Shop/ShopPolicy";

class NewChannelModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      store_url: '',
      store_name: '',
      platform: {},
      currentNavigation: 'register',
      navigationIndex: [
        'register',
        'policy_review',
        'shipment',
        'authorize_access'
      ]
    }
  }

  get_image(platform_name) {
    switch (platform_name) {
      case 'tokopedia':
        return tokopedia_logo
      case 'bukalapak':
        return bukalapak_logo
      case 'shopee':
        return shopee_logo;
      default:
        break;
    }
  }

  handleOnClickNavigation = (isNext) => {
    const { currentNavigation, navigationIndex } = this.state;
    let updateNavigation = ''
    let currentIndex = navigationIndex.findIndex((v) => v === currentNavigation)
    if (isNext) {
      if (currentIndex === 0) {
        return
      }
      updateNavigation = navigationIndex[currentIndex + 1]
    } else {
      if (currentIndex === navigationIndex.length - 1) {
        return
      }
      updateNavigation = navigationIndex[currentIndex + 1]
    }

    this.setState({
      currentNavigation: updateNavigation
    })
  }

  componentWillUnmount() {
    this.setState({
      user: null,
      isOpen: false,
      store_url: '',
      store_name: '',
      platform: {},
      currentNavigation: 'register',
    })
  }

  handleOnCloseModal = () => {
    this.setState({
      isOpen: false
    })
  }

  handleOnSubmitRegister = (values) => {
    const { history, register } = this.props;
    const { user, } = this.props;

    this.props.registerShop({
      platform: values.platform_name,
      store_url: values.store_url,
      store_name: values.store_name,
      company_id: user.company_id,
    }, history)
    this.setState({
      currentNavigation: this.state.navigationIndex[1]
    })
  }

  handleOnNavigateShopDetail = () => {
    const { register, shop, history } = this.props;

    history.push(`/store/${shop.id}`)
  }

  render() {
    const { isRequested, isOpen, platform, toggleModal, user, register, shop } = this.props;
    const { currentNavigation } = this.state;
    return (
      <React.Fragment>
        <Modal
          backdrop={true}
          fullscreen={true}
          title="Integrasi Store"
          toggle={toggleModal}
          isOpen={isOpen}>
          <ModalHeader toggle={toggleModal} >Integrasi Toko</ModalHeader>
          <ModalBody className="py-3 px-5">
            <NewChannelTimeline
              platform={platform}
              currentNavigation={currentNavigation}
              onNavigate={this.handleOnClickNavigation} />

            {register.isRequested &&
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            }
            {currentNavigation == 'register' &&
              <NewChannelForm
                isRequested={register.isRequested}
                onSubmit={this.handleOnSubmitRegister}
                user={user}
                platform={platform} />
            }
            {(currentNavigation === 'policy_review' && shop) &&
              <ShopPolicy shop={shop} />
            }
            {(currentNavigation === 'shipment' && shop) &&
              <ShopInfoInput shop={shop} />
            }
            {currentNavigation == 'authorize_access' &&
              <div>
                {platform.platform_name == 'tokopedia' &&
                  <TokopediaIntegrationInfo />
                }
                {platform.platform_name == 'shopee' &&
                  <ShopeeIntegrationInfo />
                }

                <Button onClick={this.handleOnNavigateShopDetail}>
                  Selesai
                </Button>
              </div>
            }
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { Platform, Shop, Profile } = state;
  return {
    register: Shop.register,
    shop: Shop.detail.shop,
    user: Profile.user,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    registerShop,
  }
)(NewChannelModal));
