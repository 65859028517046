import EmbedChat from "components/EmbedChat";
import { useQuery } from "components/URLQuery";
import { get, post } from "network/http/api";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Navbar, NavbarBrand, Spinner } from "reactstrap";
import { notificationErrorRequestAction } from "store/notification/actions";

const TestChatScenario = ({ match, history, notificationError }) => {
    const [scenarioId, setScenarioId] = useState(null)
    const [platformName, setPlatformName] = useState('')
    const query = useQuery()

    useEffect(() => {
        const { params } = match

        setPlatformName(query.get("platform").toUpperCase())
        setScenarioId(params.scenarioId)
    }, [])

    const closeConversation = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const conversationId = urlParams.get('conversation_id');
        if (!conversationId) {
            return
        }
        get("/conversation/close/" + conversationId)
            .then(resp => {
                history.push(`/ai-builder/${platformName.toLowerCase()}`)
            })
            .catch(err => {
                console.error(err)
                notificationError("Terjadi kesalahan mohon coba kembali")
            })
    }

    return (
        <React.Fragment>
            <div className="test-chat-scenario">
                <Navbar>
                    <NavbarBrand>
                        <Button outline onClick={() => history.goBack()}>
                            <i className="fa fa-chevron-left" />
                        </Button>
                        <p>{platformName}</p>
                    </NavbarBrand>
                    <Button color="primary" outline onClick={closeConversation} className="close-button">
                        Akhiri Percakapan
                    </Button>
                </Navbar>
                <div className="chat-scenario">
                    {!scenarioId &&
                        <Spinner />
                    }
                    {scenarioId &&
                        <EmbedChat data={{
                            scenarioId: scenarioId,
                        }} location={`/test-scenario`} />
                    }
                </div>
            </div>
        </React.Fragment>
    )
}


export default withRouter(connect(null, {
    notificationError: notificationErrorRequestAction
})(TestChatScenario))