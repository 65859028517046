import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

//Include Both Helper File with needed methods
import * as api from './api';
import { AUTH_USER_KEY, JWT_TOKEN_KEY } from "network/http/jwt-token-access"
import { sleep } from "../register/saga";

function* loginUser({ payload: { user, history } }) {
  try {
    // remove existing token
    localStorage.removeItem(JWT_TOKEN_KEY)
    localStorage.removeItem(AUTH_USER_KEY)

    // call api
    let response = yield call(api.login_user, user)

    localStorage.setItem(JWT_TOKEN_KEY, response.access_token)
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(response.user))

    sleep(500)
    user = response.user
    let is_onboarding_finished = response.is_onboarding_finished
    yield put(loginSuccess(user))

    if (user.status === 'pending') {
      history.push('/user/complete-invitation')
      return
    } else if (user.status === 'deactivated') {
      history.push('/user/deactivated')
      return
    }

    if (!is_onboarding_finished) {
      history.push('/onboarding')
    }

    history.push("/dashboard")
  } catch (error) {
    if (error.response) {
      const { data } = error.response
      yield put(apiError(data.detail))
    }
    console.error(error)
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem(JWT_TOKEN_KEY)
    localStorage.removeItem(AUTH_USER_KEY)
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
