import React from "react";
import "reactflow/dist/style.css";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ScenarioEditor from "./ScenarioEditor";

const ScenarioEditorWrapper = ({ platformName, networks, actions }) => {
  return (
    <>
      <div>
        <ScenarioEditor
          platformName={platformName}
          networks={networks}
          actions={actions} />
      </div>
    </>
  );
};

export default withRouter(ScenarioEditorWrapper);
