import {
  ONBOARDING,
  ONBOARDING_SUCCESS,
  ONBOARDING_API_ERROR,
} from "./actionTypes";

export const onboardingPost = (data, history) => {
  return {
    type: ONBOARDING,
    payload: { data, history },
  };
};

export const onboardingSuccess = data => {
  return {
    type: ONBOARDING_SUCCESS,
    payload: data,
  };
};

export const onboardingApiError = error => {
  return {
    type: ONBOARDING_API_ERROR,
    payload: error,
  };
};
