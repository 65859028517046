import React, { Component } from "react"
import {
  Row, Col, Modal, ModalBody,
  FormGroup,
  Label,
  Button,
  InputGroup,
  Alert,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import images
import bukalapak_logo from 'assets/images/bukalapak-logo.png';
import tokopedia_logo from 'assets/images/tokopedia-logo.png';
import shopee_logo from 'assets/images/shopee-logo.png';

// actions
import { registerShop } from 'store/shop/actions'

class NewChannelForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      isOpen: false,
      store_url: '',
      store_name: '',
      platform: {},
      currentNavigation: 'register',
    }
  }

  get_image(platform_name) {
    switch (platform_name) {
      case 'tokopedia':
        return tokopedia_logo
      case 'bukalapak':
        return bukalapak_logo
      case 'shopee':
        return shopee_logo;
      default:
        break;
    }
  }

  render() {
    const { onSubmit, platform, error, register } = this.props;
    return (
      <React.Fragment>
        <Formik
          className="p-5"
          enableReinitialize={true}
          initialValues={{
            platform_name: platform.platform_name.toUpperCase(),
            store_url: (this.state && this.state.store_url) || "",
            store_name: (this.state && this.state.store_name) || "",
          }}
          validationSchema={Yup.object().shape({
            store_name: Yup.string().required(
              "Tolong input nama toko"
            ),
            store_url: Yup.string().required(
              "Tolong input URL Toko"
            ).url(
              "Tolong input valid URL"
            ),
          })}
          onSubmit={values => onSubmit(values)}
        >
          {({ errors, _, touched }) => (
            <Form className="needs-validation">
              <Row>
                <Col xs="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom02">
                      Platform
                    </Label>
                    <Field
                      name="platform_name"
                      placeholder="Platform"
                      as="select"
                      className="form-control is-valid"
                    >
                      <option>Tokopedia</option>
                      <option>Shopee</option>

                    </Field>
                    <ErrorMessage
                      name="platform_name"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom02">
                      Store Name
                    </Label>
                    <Field
                      name="store_name"
                      placeholder="Nama Toko"
                      type="text"
                      className={
                        "form-control" +
                        (errors.store_name && touched.store_name
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="store_name"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">
                      URL Toko
                    </Label>
                    <InputGroup>
                      <Field
                        name="store_url"
                        placeholder="Toko URL"
                        type="text"
                        className={
                          "form-control" +
                          (errors.store_url && touched.store_url
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </InputGroup>
                    <ErrorMessage
                      name="store_url"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {error &&
                <Alert color="danger">
                  Gagal mendaftarkan toko, silahkan hubungi customer service kami jika masalah ini terus terjadi.

                </Alert>
              }
              <Button disabled={register.isRequested} color="primary" type="submit">
                Register
              </Button>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { Platform, Shop } = state;
  return {
    platforms: Platform.platforms,
    isRequested: Platform.isRequested,
    register: Shop.register,
    error: Shop.error,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    registerShop,
  }
)(NewChannelForm));
