import React from "react";
import { Input } from "reactstrap";

const CostumRadioField = ({
  title,
  subtitle,
  name,
  options,
  mandatory,
  optional,
  formik,
}) => {
  const { touched, errors } = formik || {};
  const field = formik.getFieldProps(name);

  const handleOptionChange = value => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="field-form">
      <span className="title">
        {title}
        {mandatory && <span style={{ color: "#DA2626" }}>*</span>}
        {optional && <span style={{ color: "#DA2626" }}>**</span>}
      </span>
      <span className="subtitle">{subtitle}</span>
      <div
        className="field-form-radio"
        style={{
          display: "flex",
          gap: "4px",
          flexWrap: "wrap",
        }}
      >
        {options.map((option, index) => (
          <label key={index} className="option-radio">
            <div
              className="option-radio"
              style={{
                display: "flex",
                gap: "4px",
              }}
            >
              <Input
                type="radio"
                name={name}
                value={option.value}
                checked={formik.values[name] === option.value}
                onChange={() => handleOptionChange(option.value)}
              />
              <span>{option.name}</span>
            </div>
          </label>
        ))}
      </div>
      {errors[field.name] && touched[field.name] && (
        <div className="error-message">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default CostumRadioField;
