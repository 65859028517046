import React, { useState } from "react";
import { Button } from "reactstrap";
import { get } from "network/http/api";
import { RefreshIcon, RightArrowIcon } from "../IconPage";
import EmptySummaryDashboard from "./EmptySummaryDashboard";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import ListStoreProductContainer from "./ListStoreProductContainer";
import { useDispatch } from "react-redux";
import { notificationErrorRequestAction } from "store/notification/actions";

const SummaryDashboard = ({
  storeId,
  products,
  informationStore,
  setStatusSyncProduct,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [syncProduct, setSyncProduct] = useState(false);

  const onSyncProduct = () => {
    setSyncProduct(!syncProduct);
    get(`/store/sync/${storeId}`)
      .then(resp => {
        setStatusSyncProduct("sync_product");
      })
      .catch(err => {
        console.error(err);
        dispatch(notificationErrorRequestAction(err.detail));
      });
  };

  if (!products) {
    return (
      <EmptySummaryDashboard
        title="Belum Ada Toko Terintegrasi"
        subtitle="Lakukan integrasi dengan Toko Ecommerce Anda untuk mengelola informasi produk yang ada"
        btn={{
          title: "Integrasi Toko",
          url: "/integrasi",
          onClick: () => console.log("go integrasi"),
        }}
      />
    );
  }

  return (
    <div className="integrated-store-summary mt-5">
      <div className="store-summary-information">
        <h4 className="title">Informasi Toko dan Produk</h4>
        <span className="subtitle">
          Informasi terkait toko dan produk Anda yang sudah terintegrasi dengan
          sistem RISA AI
        </span>
        <div className="store-summary-information-content">
          <div className="card-store-information">
            <div className="card-store-information-content">
              <span className="title-card-store-information">
                Produk Tersinkronisasi
                <ExclamationCircleIcon />
              </span>
              <span className="value-card-store-information">
                {informationStore.totalProduct}
              </span>
            </div>
            <i className="fa fa-refresh" aria-hidden="true" />
          </div>
          <div className="card-store-information">
            <div className="card-store-information-content">
              <span className="title-card-store-information">
                Total Kategori Produk
                <ExclamationCircleIcon />
              </span>
              <span className="value-card-store-information">
                {informationStore.totalCategories}
              </span>
            </div>
          </div>
          <div className="card-store-information">
            <div className="card-store-information-content product-sync">
              <div className="product-content">
                <span className="title-card-store-information">
                  Waktu Update Terakhir
                  <ExclamationCircleIcon />
                </span>
                <span className="value-card-store-information">
                  {informationStore.lastSync
                    ? moment(informationStore.lastSync).format(
                        "DD MMM YYYY, hh:mm"
                      )
                    : "-"}
                </span>
              </div>
                <div className="button-sync">
                  <Button color="primary" onClick={() => onSyncProduct()}>
                    <RefreshIcon className="icon" />
                    Sync Produk
                  </Button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="store-summary-information">
        <h4 className="title">Produk Terbaru</h4>
        <span className="subtitle">
          Daftar produk yang baru saja diintegrasikan dengan sistem RISA
        </span>
        <div className="store-summary-product-content">
          <ListStoreProductContainer sycnProduct={syncProduct} />
          {products?.length > 1 && (
            <div className="store-summary-btn">
              <Button
                color="ghost"
                onClick={() =>
                  history.push("/ecommerce?tab=synchronized-products")
                }
                className="btn-summary-list-product"
              >
                Lihat Produk Lainnya
                <RightArrowIcon />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Product, Shop }) => ({
  products: Product.products,
  informationStore: Shop.informationStore,
});

export default connect(mapStateToProps)(SummaryDashboard);
