import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILED,
} from "./actionTypes"

export const getCompanyRequest = () => ({
  type: GET_COMPANY_REQUEST,
})

export const getCompanySuccess = payload => ({
  type: GET_COMPANY_SUCCESS,
  payload,
})

export const getCompanyFailed = payload => ({
  type: GET_COMPANY_FAILED,
  payload,
})
