import React, { useEffect, useState } from "react";
import HeaderSection from "./ComponentsPage/HeaderSection";
import InputFieldCostume from "./ComponentsPage/InputField";
import InputSelected from "./ComponentsPage/InputSelected";
import { Button, Spinner, Input } from "reactstrap";
import { put } from "network/http/api";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import { ProfileCompanyUpdate } from "./SchemaValidation";
import { getCompanyRequest } from "store/company/actions";
import { SizeCompany, IndustryCompany, DepartmentCompany } from "./DefaultData";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";

const ProfileCompany = props => {
  const { onGetCompany, company, notificationSuccess, notificationError } =
    props;
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCompany = () => {
      onGetCompany();
    };

    fetchCompany();
  }, [onGetCompany]);

  const handleSubmit = values => {
    const data = {
      name: values.name,
      category: values.industry,
      company_size: values.employees,
      business_industry: values.industry,
      department: values.department,
      description: values.description,
    };

    setLoading(true);
    put(`/company/update`, data)
      .then(resp => {
        notificationSuccess("Profil Perusahaan berhasil diubah!");
        setLoading(false);
      })
      .catch(error => {
        notificationError(error.detail);
        setLoading(false);
      });
  };

  return (
    <div className="settings-content">
      <HeaderSection
        title="Profil Perusahaan"
        subtitle="Berikut adalah informasi perusahaan yang akan disimpan ke layanan RISA AI dengan aman, dan untuk data acuan pengguna."
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: company.name || "",
          employees: company.company_size || "",
          industry: company.business_industry || "",
          department: company.department || "",
          description: company.description || "",
        }}
        validationSchema={ProfileCompanyUpdate}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ isValid, errors, touched }) => (
          <Form className="form-integration-settings">
            <div className="integration-settings">
              <div className="field-form">
                <span className="title">Nama Perusahaan</span>
                <Field
                  name="name"
                  component={InputFieldCostume}
                  placeholder="Masukkan nama perusahaan"
                />
              </div>
              <div className="field-form">
                <span className="title">Ukuran Perusahaan</span>
                <InputSelected
                  name="employees"
                  options={SizeCompany}
                  touched={touched}
                  errors={errors}
                />
              </div>
              <div className="field-form">
                <span className="title">Industri</span>
                <InputSelected
                  name="industry"
                  options={IndustryCompany}
                  touched={touched}
                  errors={errors}
                />
              </div>
              <div className="field-form">
                <span className="title">Departemen</span>
                <InputSelected
                  name="department"
                  options={DepartmentCompany}
                  touched={touched}
                  errors={errors}
                />
              </div>
              <div className="field-form">
                <span className="title">Deskripsi Perusahaan</span>
                <Field name="description">
                  {({ field }) => (
                    <Input
                      type="textarea"
                      {...field}
                      placeholder="Masukkan deskripsi perusahaan"
                      className={`field-input ${
                        errors.description && touched.description ? "error" : ""
                      }`}
                      style={{
                        resize: "none",
                      }}
                      rows="7"
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="field-btn">
              <Button type="submit" color="primary" disabled={!isValid}>
                {isLoading ? <Spinner color="light" size="sm" /> : "Simpan"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ Company }) => {
  return {
    company: Company.company,
    isRequested: Company.isRequested,
  };
};

export default connect(mapStateToProps, {
  onGetCompany: getCompanyRequest,
  notificationSuccess: notificationSuccessRequestAction,
  notificationError: notificationErrorRequestAction,
})(ProfileCompany);
