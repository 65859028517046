import React, { useState, useEffect, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useOutsideClick } from "helpers/hooks/useOutsideClick";
import { Spinner } from "reactstrap";

const OperationScheduleDropdownCheckbox = ({
  list,
  selectAllItems,
  selectedItems,
  setSelectedItems,
  type,
  loading,
}) => {
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useOutsideClick(() => {
    setIsOpen(false);
  }, modalRef);

  useEffect(() => {
    const allSelected = list.every(item =>
      selectedItems.some(selectedItem => selectedItem.id === item.id)
    );
    setSelectAll(allSelected);
  }, [list, selectedItems]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = item => {
    if (item === "select-all") {
      setSelectAll(!selectAll);
      if (!selectAll) {
        setSelectedItems([...list]);
      } else {
        setSelectedItems([]);
      }
    } else {
      if (selectedItems.includes(item)) {
        setSelectedItems(
          selectedItems.filter(selectedItem => selectedItem.id !== item.id)
        );
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    }
  };

  const selectedText =
    type === "day"
      ? selectAll
        ? selectAllItems
        : selectedItems.map(item => item.name.charAt(0)).join(",")
      : selectAll
      ? selectAllItems
      : selectedItems.map(item => item.name).join(",");

  return (
    <div className="dropdown-checkbox-costum-operation" ref={modalRef}>
      <div className="dropdown-checkbox" onClick={handleToggleDropdown}>
        {loading ? (
          <Spinner color="primary" size="sm" />
        ) : (
          <span
            className={`dropdown-toggle ${
              selectedItems.length ? "active" : "inactive"
            }`}
          >
            {selectedItems.length ? selectedText : "Mati"}
          </span>
        )}
        <ChevronDownIcon className="chevron-down" />
      </div>
      {isOpen && (
        <div className="dropdown-checkbox-list">
          <label key="select-all" className="checkbox-label">
            <input
              className="checkbox-input"
              type="checkbox"
              checked={selectAll}
              onChange={() => handleCheckboxChange("select-all")} // Handler untuk "Pilih Semua"
            />
            {selectAllItems}
          </label>
          {Boolean(list?.length) && (
            <>
              {list.map((item, index) => (
                <div key={index}>
                  <label className="checkbox-label">
                    <input
                      className="checkbox-input"
                      type="checkbox"
                      value={item.name}
                      checked={selectedItems.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    {item.name}
                  </label>
                  {item.desc && (
                    <span style={{ marginLeft: "23px" }}>{item.desc}</span>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default OperationScheduleDropdownCheckbox;
