import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import logoRisa from "assets/images/logo-R.png";
import Image from "components/Image";

const MockLiveChat = ({ data }) => {
  return (
    <div className="mock-live-chat">
      <div className="container">
        <Card>
          <CardHeader style={{ background: data["primary_color"] }}>
            {!data["logo"] && <img src={logoRisa} />}
            {data["logo"] && <Image src={data["logo"]} />}
            <div className="title">{data["title"]}</div>
          </CardHeader>
          <CardBody>
            <div className="message-container">
              <div className="message-list">
                <div className="message-list-wrapper">
                  {/* BUBBLE FROM AI */}
                  <section
                    className="cs-message-group cs-message-group--incoming"
                    data-cs-message-group=""
                  >
                    <div className="cs-message-group__content">
                      <div className="cs-message-group__messages">
                        <div className="message-bubble-container text 1de0056af85d4d91925e8f35f3e581f6">
                          <section className="cs-message generated_by_ai">
                            <div className="cs-message__content-wrapper">
                              <div className="cs-message__content">
                                <div className="cs-message__html-content">
                                  Hi selamat datang di Toko kami kakaku, apakah
                                  ada yang dapat kami bantu?
                                </div>
                              </div>
                              <div className="cs-message__footer">
                                <div className="mb-2 mt-1 bubble-footer">
                                  <span className="font-size-10 footer-information">
                                    <span className="date">12:00 03 Mar</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                        <div className="message-bubble-container text 0205bfab977d48bc8f02459e611adad3">
                          <section
                            className="cs-message generated_by_ai"
                            data-cs-message=""
                            style={{ border: "1px solid transparent" }}
                          >
                            <div className="cs-message__content-wrapper">
                              <div className="cs-message__content">
                                <div className="cs-message__html-content">
                                  Apakah ada yang dapat kami bantu?
                                </div>
                              </div>
                              <div className="cs-message__footer">
                                <div
                                  className="mb-2 mt-1 bubble-footer"
                                  style={{ display: "block", width: "100%" }}
                                >
                                  <span className="font-size-10 footer-information">
                                    <span className="date">12:00 03 Mar</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* BUBBLE FROM USER */}
                  <section
                    className="cs-message-group cs-message-group--outgoing"
                    data-cs-message-group=""
                  >
                    <div className="cs-message-group__content">
                      <div className="cs-message-group__messages">
                        <div className="message-bubble-container text 1e85105c-5098-4e93-86eb-1008aaa527d6">
                          <section
                            className="cs-message"
                            data-cs-message=""
                            style={{ border: "1px solid transparent" }}
                          >
                            <div className="cs-message__content-wrapper">
                              <div
                                className="cs-message__content"
                                style={{
                                  backgroundColor: data["message_color"],
                                }}
                              >
                                <div className="cs-message__html-content">
                                  Halo, selamat pagi Risa AI
                                </div>
                              </div>
                              <div className="cs-message__footer">
                                <div
                                  className="mb-2 mt-1 bubble-footer"
                                  style={{ display: "block", width: "100%" }}
                                >
                                  <span className="font-size-10 footer-information">
                                    <span className="date">11:59</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="message-input">
                <div className="content-editor-wrapper">
                  <div className="tools">
                    <button disabled="" className="cs-button cs-button--send">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="paper-plane"
                        className="svg-inline--fa fa-paper-plane "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default MockLiveChat;
