import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Col, Container, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import { getShopDetail } from "store/shop/actions";
import ShopDetail from "./ShopDetail";
import ShopPolicy from "./ShopPolicy";
import ShopShipmentOperation from "./ShopShipmentOperation";
import StoreProducts from "components/Product/StoreProducts";

class ShopOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1
    }
  }

  componentDidMount() {
    const {
      match: { params },
      getShopDetail,
    } = this.props;

    getShopDetail(params.id);
  }

  handleOnUpdateActiveTab = (tabId) => {
    this.setState({ activeTab: tabId })
  }

  render() {
    const { shop, isRequested } = this.props;
    const { activeTab } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <>
              <Row>
                <Col xs="2">
                  <Nav justified pills>
                    <NavItem>
                      <NavLink active={activeTab == 1} onClick={() => this.handleOnUpdateActiveTab(1)}>
                        Overview
                      </NavLink>
                      <NavLink active={activeTab == 2} onClick={() => this.handleOnUpdateActiveTab(2)}>
                        Policy
                      </NavLink>
                      <NavLink active={activeTab == 3} onClick={() => this.handleOnUpdateActiveTab(3)}>
                        Operation & Shipment
                      </NavLink>
                      <NavLink active={activeTab == 4} onClick={() => this.handleOnUpdateActiveTab(4)}>
                        Product
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col lg="10">
                  {isRequested &&
                    <div style={{ textAlign: 'center' }}>
                      <Spinner color="primary" type="grow" />
                    </div>
                  }
                  {shop &&
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <ShopDetail shop={shop} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <ShopPolicy title="Store Policy" shop={shop} />
                      </TabPane>
                      <TabPane tabId={3}>
                        <Card>
                          <ShopShipmentOperation shop={shop} />
                        </Card>
                      </TabPane>
                      <TabPane tabId={4}>
                        <StoreProducts shop={shop} />
                      </TabPane>
                    </TabContent>
                  }

                </Col>
              </Row>
            </>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ShopOverview.propTypes = {
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
};

const mapStateToProps = ({ Shop }) => ({
  ...Shop.detail,
});


export default connect(
  mapStateToProps,
  {
    getShopDetail,
  }
)(withRouter(ShopOverview));
