import React, { useState } from "react";
import { Container } from "reactstrap";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { dataRekom } from "../IconPage";
import ListProductRecommend from "./ListSelectRecommend";
import ListRecommend from "./ListRecommend";
import BackButton from "components/BackButton";

const ProductRecommend = props => {
  const [activeTab, setActiveTab] = useState(0);
  const [productData, setProductData] = useState(null);
  const [dataSelected, setDataSelected] = useState([]);

  const handleSelectedDataChange = newSelectedData => {
    setDataSelected(newSelectedData);
  };

  return (
    <div className="page-content">
      <div className="page-ecommerce-details">
        <Container fluid>
          <div className="header-section">
            <div className="page-header-content">
              <BackButton />
              <span className="title">Tambah Produk Rekomendasi</span>
            </div>
            <span className="subtitle">
              Anda bisa menambahkan produk yang ingin direkomendasikan hingga 5
              produk.
            </span>
          </div>
          <div className="page-ecommerce-details-content">
            <div className="section-list-product-recommend-add">
              <ListProductRecommend data={dataSelected} />
            </div>
            <div className="section-search-product-recommend">
              <div className="input-search-product-recommend">
                <div className="input-icon">
                  <MagnifyingGlassIcon className="icon" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="query"
                  id="query"
                  className="input-search"
                  placeholder="Cari Produk di sini"
                />
              </div>
              <ListRecommend
                data={dataRekom}
                selectedData={dataSelected}
                onSelectedDataChange={handleSelectedDataChange}
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Product }) => ({
  product: Product.product,
  isFetching: Product.productIsFetching,
});

const mapDispatchToProps = dispatch => ({
  onGetProductDetail: id => dispatch(getProductDetail(id)),
});

export default ProductRecommend;
