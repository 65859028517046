import {
  GET_AI_ASSISTANT_PROFILE,
  GET_AI_ASSISTANT_PROFILE_FAIL,
  GET_AI_ASSISTANT_PROFILE_SUCCESS,
  UPDATE_AI_ASSISTANT_PROFILE,
  UPDATE_AI_ASSISTANT_PROFILE_FAIL,
  UPDATE_AI_ASSISTANT_PROFILE_SUCCESS
} from "./actionTypes"

export const getAIProfile = () => ({
  type: GET_AI_ASSISTANT_PROFILE,
})

export const getAIProfileSuccess = payload => ({
  type: GET_AI_ASSISTANT_PROFILE_SUCCESS,
  payload,
})

export const getAIProfileFail = error => ({
  type: GET_AI_ASSISTANT_PROFILE_FAIL,
  payload: error,
})

export const updateAIProfile = profile => ({
  type: UPDATE_AI_ASSISTANT_PROFILE,
  payload: profile,
})

export const updateAIProfileSuccess = profile => ({
  type: UPDATE_AI_ASSISTANT_PROFILE_SUCCESS,
  payload: profile,
})

export const updateAIProfileFail = error => ({
  type: UPDATE_AI_ASSISTANT_PROFILE_FAIL,
  payload: error,
})
