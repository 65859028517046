import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { post } from "network/http/api";
import { Container, Spinner, Button, Modal } from "reactstrap";
import BackButton from "../ComponentsPage/BackButton";
import SummaryToggle from "../ComponentsPage/SummaryToggle";
import SummaryContent from "../ComponentsPage/SummaryContent";
import "moment/locale/id";
import {
  notificationErrorRequestAction,
  notificationSuccessRequestAction,
} from "store/notification/actions";

moment.locale("id");

const SummaryIntegration = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();
  const formDataRedux = useSelector(state => state.MultiStepTokopedia);
  const [data, setData] = useState(formDataRedux);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [alreadyRegister, setAlreadyRegister] = useState(false);

  const goSubmitStore = () => {
    setIsLoading(true);

    post("/store/new", data)
      .then(resp => {
        setIsLoading(false);
        notificationSuccess(
          "Berhasil Integration Toko anda, tunggu konfirmasi dari tokopedia"
        );
        setModal(true);
        setAlreadyRegister(true);
      })
      .catch(err => {
        setIsLoading(false);
        notificationError(err.detail);
      });
  };

  const changeSwitchName = paramsValue => {
    const updatedData = {
      ...data,
      retail_attribute: {
        ...data.retail_attribute,
        attribute_checks: {
          ...data.retail_attribute.attribute_checks,
          name: paramsValue,
        },
      },
    };

    setData(updatedData);
  };

  const changeSwitchStore = paramsValue => {
    const updatedData = {
      ...data,
      retail_attribute: {
        ...data.retail_attribute,
        attribute_checks: {
          ...data.retail_attribute.attribute_checks,
          store_location: paramsValue,
        },
      },
    };

    setData(updatedData);
  };

  const goToServiceKnowledge = () => {
    history.push("/service-knowledge");
  };

  return (
    <div className="page-content page-integration">
      <div className="page-integration-shop">
        <Container fluid>
          <div className="page-integration-shop-details">
            <div className="header-section">
              <BackButton />
              <span className="title">Ringkasan Integrasi Toko</span>
            </div>
            <div
              style={{
                borderBottom: "1px solid #C2C7CF",
                margin: "24px 0px",
              }}
            />
            <div className="page-integration-shop-summary">
              <SummaryToggle
                type="integration"
                title="Nama Toko"
                description={data.store_name}
                tooltipText="Anda bisa menyalakan atau mematikan nama toko anda."
                toggleData={data.retail_attribute.attribute_checks.name}
                goToStep={changeSwitchName}
              />

              <SummaryToggle
                type="integration"
                title="Alamat Toko"
                description={
                  data.retail_attribute.store_location
                    ? data.retail_attribute.store_location
                    : data.retail_attribute.offline_store_location
                }
                tooltipText="Anda bisa menyalakan atau mematikan nama alamat toko anda"
                toggleData={
                  data.retail_attribute.attribute_checks.store_location
                }
                goToStep={changeSwitchStore}
              />
              <SummaryToggle
                title="URL Toko"
                description={data.store_url}
                tooltipText="Data informasi URL toko."
              />
              <SummaryContent
                title="Kebijakan Refund/Pengembalian"
                description={data.policies.refund}
              />
              <SummaryContent
                title="Kebijakan Garansi"
                description={data.policies.warrant}
              />
              <SummaryContent
                title="Kebijakan After Sale"
                description={data.policies.after_sale}
              />
              <SummaryContent
                title="Informasi Toko Offline"
                description={data.retail_attribute.offline_store_location}
              />
              <SummaryContent
                title="Jadwal Pengiriman - Instant Cut Off"
                description="is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                type="schedule"
                weekday={data.retail_attribute.shipment_instant_cut_off.weekday}
                saturday={
                  data.retail_attribute.shipment_instant_cut_off.saturday
                }
                sunday={data.retail_attribute.shipment_instant_cut_off.sunday}
              />
              <SummaryContent
                title="Jadwal Pengiriman - Regular Cut Off"
                description="is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                type="schedule"
                weekday={data.retail_attribute.shipment_regular_cut_off.weekday}
                saturday={
                  data.retail_attribute.shipment_regular_cut_off.saturday
                }
                sunday={data.retail_attribute.shipment_regular_cut_off.sunday}
              />
            </div>
            <div
              style={{
                borderBottom: "1px solid #C2C7CF",
                margin: "20px 0px",
              }}
            />
            {!alreadyRegister && (
              <div className="field-btn">
                <Button color="primary" onClick={() => goSubmitStore()}>
                  {isLoading ? (
                    <Spinner color="light" size="sm" />
                  ) : (
                    "Integrasi"
                  )}
                </Button>
              </div>
            )}
          </div>
        </Container>
        <Modal isOpen={modal} centered>
          <div
            style={{
              padding: "40px 30px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              textAlign: "center",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              Toko anda Berhasil Integrasi
            </span>
            <span style={{ fontSize: "14px", fontWeight: "400" }}>
              Tim kami akan mengreview toko anda. selagi menunggu lanjutkan
              untuk mengatur pengetahuan layanan produk toko anda
            </span>
            <Button
              style={{ marginTop: "30px" }}
              color="primary"
              onClick={() => goToServiceKnowledge()}
            >
              Atur Pengetahuan Layanan
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

// export default withRouter(SummaryIntegration);
export default connect(null, {
  notificationSuccess: notificationSuccessRequestAction,
  notificationError: notificationErrorRequestAction,
})(SummaryIntegration);
