import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  BackgroundVariant,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  ReactFlowProvider,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import { actionNodeType } from "./ActionTools";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom.min";

// custom node
import NodeConfiguration from "./NodeConfiguration";
import BuilderConnectionLine from "./BuilderConnectionLine";
import NavbarCostumScenario from "./Navbar/NavbarCostumScenario";

// actions
import {
  eventOnPaneClick,
  eventOnNodeAdded,
  eventOnStartConnection,
  eventOnNodeDeleted,
  eventOnNodeError,
} from "store/agent-scenario-builder/actions";
import { checkNetworkNode } from "./utils";
import { get, post } from "network/http/api";
import { notificationErrorRequestAction, notificationSuccessRequestAction } from "store/notification/actions";
import ModalChangeConfirmation from "./Navbar/ModalChangeConfirmation";

const markerEnd = {
  type: MarkerType.ArrowClosed,
  width: 20,
  height: 20,
  color: "#34c38f",
};

const edgeStyle = {
  strokeWidth: 2,
  stroke: "#34c38f",
};
const defaultMarker = {
  markerEnd: markerEnd,
  style: edgeStyle,
};

const nodeTypes = actionNodeType();

const defaultEdgeOptions = {
  style: edgeStyle,
  markerEnd: markerEnd,
};

const ScenarioEditor = ({
  actions, networks, scenario, platformId,
  eventOnPaneClick, platformName,
  eventOnStartConnection, eventOnNodeDeleted,
  nodeErrors, eventOnNodeError,
  notificationError, notificationSuccess,
}) => {
  const history = useHistory()
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isOpenConfig, setIsOpenConfig] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [currentConfigNode, setCurrentConfigNode] = useState(null);
  const [isEdited, setIsEdited] = useState(false)

  useEffect(() => {
    if (!isOpenConfig) {
      setCurrentConfigNode(null);
    }
  }, [isOpenConfig]);

  const onConnect = useCallback(new_edge => {
    setIsEdited(true)
    setEdges(eds => {
      new_edge.markerEnd = markerEnd;
      new_edge.style = edgeStyle;
      return addEdge(new_edge, eds);
    }),
      [];
  });

  useEffect(() => {
    if (!reactFlowInstance) {
      return;
    }
    const { edges, nodes } = reactFlowInstance.toObject();
    let unconnected = checkNetworkNode(edges, nodes);
    if (unconnected.length < 1) {
      // if the only errors node required
      // remove all node with type unnconnected
      let cleanErr = nodeErrors.filter(nds => {
        return nds.type !== "unconnected";
      });

      // store error nodes in redux store
      eventOnNodeError(cleanErr);
      return;
    }

    // convert unconnected node to map
    let unconnectedMap = {};
    unconnected.map(v => {
      unconnectedMap[v.id] = v;
    });

    // check if unconnected node already covered
    let isCovered = 0;
    for (let i = nodeErrors.length - 1; i == 0; i--) {
      const en = nodeErrors[i];
      if (en.id in unconnectedMap) {
        isCovered += 1;
        break;
      }
    }

    if (isCovered >= unconnected.length) return;

    // remove all node with type unnconnected
    let cleanErr = nodeErrors.filter(nds => {
      return nds.type !== "unconnected";
    });

    // added unnconnected node
    unconnected.map(nds => {
      cleanErr.push({
        type: "unconnected",
        id: nds.id,
      });
    });

    // store error nodes in redux store
    eventOnNodeError(cleanErr);
  }, [edges]);

  useEffect(() => {
    let updateNodes = actions.map(v => {
      return {
        id: v.id,
        position: v.position,
        type: v.type,
        deletable: v.type !== "starting_point",
        data: {
          ai_node_scenario_id: v.ai_node_scenario_id,
          variables: v.variables,
          isConnectable: false,
          maxConnections: 1,
          displayToolAction: false,
          company_id: v.company_id,
          onOpenConfig: (id, data) => handleOnOpenConfig(id, data),
          onAddNode: handleOnAddNode,
          onDelete: handleOnNodeDelete,
          onChange: handleOnChangeNode,
          onClone: handleOnClone,
        },
      };
    });
    let networkNodes = networks.map(v => {
      return {
        id: v.id,
        source: v.source_node_id,
        target: v.target_node_id,
        sourceHandle: v.source_handle,
        // animated: true,
        // type: 'floating',
      };
    });
    setNodes(updateNodes);
    setEdges(networkNodes);
  }, [actions, networks]);

  const handleOnOpenConfig = useCallback(
    (node, data) => {
      setIsOpenConfig(true);
      setCurrentConfigNode(node);
    },
    [currentConfigNode, setCurrentConfigNode]
  );

  const handleOnAddNode = useCallback(
    (nodeId, node, type, sourceHandle) => {
      setIsOpenConfig(false);
      setIsEdited(true)

      let id = uuidv4();
      let newNode = {
        id,
        type,
        // we are removing the half of the node width (75) to center the new node
        position: { x: node.position.x + 375, y: node.position.y },
        data: {
          variables: [
            {
              id: uuidv4(),
              question: "",
            },
          ],
          isConnectable: false,
          ai_node_scenario_id: node.ai_node_scenario_id,
          maxConnections: 1,
          displayToolAction: false,
          company_id: node.company_id,
          content: "",
          onOpenConfig: (id, data) => handleOnOpenConfig(id, data),
          onAddNode: handleOnAddNode,
          onDelete: handleOnNodeDelete,
          onChange: handleOnChangeNode,
          onClone: handleOnClone,
        },
      };

      if (type === "processor") {
        newNode.data.variables = [
          {
            id: uuidv4(),
            question: "Pengetahuan Produk",
            validation_type: 0,
            variable_name: "product",
          },
          {
            id: uuidv4(),
            question: "Pengetahuan layanan",
            validation_type: 0,
            variable_name: "knowledge_base",
          },
          {
            id: uuidv4(),
            question: "Hubungkan ke operator",
            validation_type: 0,
            variable_name: "human_takeover",
          },
        ];
      }

      setNodes(nds => nds.concat(newNode));
      setEdges(eds =>
        eds.concat({
          id,
          source: node.id,
          sourceHandle: sourceHandle,
          target: id,
        })
      );

      setIsOpenConfig(true);
      setCurrentConfigNode(newNode);
    },
    [nodes, edges, setNodes, setEdges, reactFlowInstance]
  );

  const handleOnClone = useCallback(
    (nodeId, nodes) => {
      setIsEdited(true)
      let clonedId = null;
      let sourceNode = null;
      setNodes(nds => {
        let cloned = null;
        nds.map(node => {
          if (node.id === nodeId) {
            clonedId = uuidv4();
            cloned = {
              ...node,
              id: clonedId,
              position: { x: node.position.x + 475, y: node.position.y },
            };
            sourceNode = node;
          }
        });
        if (!cloned) {
          return nds;
        }

        return nds.concat(cloned);
      });

      if (clonedId) {
        // check if source node already connected
        // getOutgoers(nodes, nds, edges)
        setEdges(eds => {
          let outgoers = getOutgoers(sourceNode, nodes, eds);
          if (outgoers && outgoers.length > 0) {
            return eds;
          }

          return eds.concat({ id: uuidv4(), source: nodeId, target: clonedId });
        });
      }
    },
    [edges, setNodes, setEdges]
  );

  const handleOnNodeDelete = useCallback(
    nodeId => {
      setIsEdited(true)
      setNodes(nds =>
        nds.filter(n => {
          return nodeId !== n.id;
        })
      );
      eventOnNodeDeleted(nodeId);
    },
    [setNodes]
  );

  const hanelOnNodesDeleteEditor = useCallback(
    deleted => {
      setCurrentConfigNode(null);
      setIsOpenConfig(false);
      setIsEdited(true)

      // action event

      setEdges(
        deleted.reduce((acc, node) => {
          eventOnNodeDeleted(node.id);
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            edge => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const handleOnFocusNode = (nodeId, openConfig = false) => {
    reactFlowInstance.fitView({ nodes: [{ id: nodeId }], duration: 500, maxZoom: 1 })
    if (openConfig) {
      const node = reactFlowInstance.getNode(nodeId)
      handleOnOpenConfig(node)
    }
  }

  const handleOnChangeNode = useCallback(
    (nodeId, updatedNode) => {
      setIsEdited(true)
      setNodes(nds =>
        nds.map(n => {
          if (n.id != nodeId) {
            return n;
          }

          return updatedNode;
        })
      );
    },
    [setNodes, nodes]
  );

  const onConnectStart = useCallback((event, target) => {
    setIsOpenConfig(false);
    eventOnStartConnection(target.nodeId);
  }, []);

  const onPaneOnClick = useCallback(() => {
    eventOnPaneClick();
    setIsOpenConfig(false);
  }, [edges, nodes]);

  const handleOnInit = instance => {
    setReactFlowInstance(instance);
  };

  const handleOnSave = useCallback(async () => {
    if (nodeErrors && nodeErrors.length > 0) {
      return
    }
    
    const data = {
      'platform_id': platformId,
      'is_test': true,
      'actions': nodes.map(n => ({
        'id': n.id,
        'position': n.position,
        'variables': n.data.variables,
        'type': n.type,
      })),
      'networks': edges.map(e => ({
        'id': e.id,
        'target_node_id': e.target,
        'source_node_id': e.source,
        'source_handle': e.sourceHandle,
      })),
    }

    return post('/scenario', data)
      .then(resp => {
        notificationSuccess(
          'Scenario berhasil di simpan',
        )
        setIsEdited(false)
        return resp
      })
      .catch(err => {
        console.error('error', err)
        notificationError(
          err,
        )
      })
  }, [edges, nodes])

  return (
    <>
      <NavbarCostumScenario
        platformId={platformId}
        platformName={platformName}
        onSave={handleOnSave}
        onFocusNode={handleOnFocusNode}
        scenario={scenario}
        isEdited={isEdited}
        reactFlowInstance={reactFlowInstance}
        toggleOpenConfig={() => setIsOpenConfig(!isOpenConfig)}
      >
        <NodeConfiguration
          isOpen={isOpenConfig}
          node={currentConfigNode}
          onChange={handleOnChangeNode}
          toggle={() => setIsOpenConfig(!isOpenConfig)}
        />

        <ReactFlowProvider>
          <div
            style={{ width: "100%", height: "100vh", position: "relative" }}
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnectStart={onConnectStart}
              onConnect={onConnect}
              onInit={handleOnInit}
              onNodesDelete={hanelOnNodesDeleteEditor}
              connectionLineComponent={BuilderConnectionLine}
              defaultEdgeOptions={defaultEdgeOptions}
              attributionPosition="top-right"
              nodeTypes={nodeTypes}
              onPaneClick={onPaneOnClick}
              fitView
            >
              <Controls />
              <Background
                id="1"
                gap={25}
                color="#203254"
                variant={BackgroundVariant.Lines}
              />
              <Background
                id="2"
                gap={100}
                offset={1}
                color="#324262"
                variant={BackgroundVariant.Lines}
              />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </NavbarCostumScenario>
      <ModalChangeConfirmation isEdited={isEdited} />
    </>
  );
};

const mapStateToProps = states => {
  return states.AgentScenarioBuilder;
};

export default connect(mapStateToProps, {
  eventOnPaneClick,
  eventOnNodeAdded,
  eventOnStartConnection,
  eventOnNodeDeleted,
  eventOnNodeError,
  notificationError: notificationErrorRequestAction,
  notificationSuccess: notificationSuccessRequestAction,
})(withRouter(ScenarioEditor));
