import React, { useCallback } from 'react'
import { connect } from 'react-redux';
import { eventDisplayNodeOption } from 'store/actions';
import { Handle, getOutgoers, getConnectedEdges, useStore, Position } from 'reactflow';
import ActionNodeDialog from './ActionNodeDialog';
import classNames from 'classnames';

const selectorTarget =
  (nodeId, sourceHandle, maxConnections = 1) =>
    (s) => {
      // let connId = nodeId + sourceHandle
      const node = s.nodeInternals.get(nodeId);
      if (!node) {
        return false
      }
      if (node.type === 'processor') {
        maxConnections = 3
      }

      const connectedEdges = getOutgoers(node, s.getNodes(), s.edges);
      if (sourceHandle) {
        const edges = getConnectedEdges([node], s.edges)
        if (edges.length > 0) {
          let isConnected = true
          edges.map(ed => {
            if (ed.target === nodeId) return
            if (ed.sourceHandle === sourceHandle) {
              isConnected = false
            }
          })
          return isConnected
        }
      }
      return connectedEdges.length < maxConnections;
    };

export const SourceHandler = ({ eventDisplayNodeOption, id, selected, disabled, sourceHandle, data }) => {
  let isConnectable = useStore(useCallback(selectorTarget(id, sourceHandle), [
    id,
    sourceHandle
  ]))

  return <div
    onMouseEnter={() => eventDisplayNodeOption(id, sourceHandle)}>
    <Handle
      type="source"
      className={classNames('custom-handle', isConnectable ? 'connectable' : 'unconnectable')}
      position={Position.Right}
      id={sourceHandle ? sourceHandle : id}
      isConnectable={isConnectable && !disabled}
      selected={selected}>
    </Handle>

    <ActionNodeDialog
      isConnectable={isConnectable}
      sourceHandle={sourceHandle}
      id={id}
      data={data}
    />
  </div>
}


const mapStateToProps = (states) => {
  return states.AgentScenarioBuilder.config
}

export default connect(mapStateToProps,
  { eventDisplayNodeOption })(SourceHandler)