import {
  GET_PRODUCTS,
  GET_STORE_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCTS_CATEGORY,
  GET_PRODUCTS_CATEGORY_SUCCESS,
  GET_PRODUCTS_CATEGORY_FAIL,
  SEARCH_PRODUCT,
} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  productCategories: [],
  productIsFetching: false,
  productCategoryIsFetcing: false,
  productCount: 0,
  totalPage: 0,
  error: {},
}

const Product = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productIsFetching: false,
        products: action.payload.products,
        productCount: action.payload.count,
        totalPage: action.payload.total_page,
        title: action.payload.title,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        productIsFetching: false,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productIsFetching: false,
        product: action.payload,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        productIsFetching: false,
        error: action.payload,
      }
    case GET_PRODUCTS_CATEGORY:
      return {
        ...state,
        productCategoryIsFetcing: true
      }
    case GET_PRODUCTS_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategoryIsFetcing: false,
        productCategories: action.payload,
      }
    case GET_PRODUCTS_CATEGORY_FAIL:
      return {
        ...state,
        productCategoryIsFetcing: false,
      }
    case GET_PRODUCTS:
    case SEARCH_PRODUCT:
    case GET_STORE_PRODUCTS:
    case GET_PRODUCT_DETAIL:
      return {
        ...state,
        productIsFetching: true
      }
    default:
      return state
  }
}

export default Product
