/* TICKET_COMMENTS */
export const GET_TICKET_COMMENTS = "GET_TICKET_COMMENTS"
export const GET_TICKET_COMMENTS_SUCCESS = "GET_TICKET_COMMENTS_SUCCESS"
export const GET_TICKET_COMMENTS_FAIL = "GET_TICKET_COMMENTS_FAIL"

export const ADD_NEW_TICKET_COMMENT = "ADD_NEW_TICKET_COMMENT"
export const ADD_TICKET_COMMENT_SUCCESS = "ADD_TICKET_COMMENT_SUCCESS"
export const ADD_TICKET_COMMENT_FAIL = "ADD_TICKET_COMMENT_FAIL"

export const UPDATE_TICKET_COMMENT = "UPDATE_TICKET_COMMENT"
export const UPDATE_TICKET_COMMENT_SUCCESS = "UPDATE_TICKET_COMMENT_SUCCESS"
export const UPDATE_TICKET_COMMENT_FAIL = "UPDATE_TICKET_COMMENT_FAIL"

export const DELETE_TICKET_COMMENT = "DELETE_TICKET_COMMENT"
export const DELETE_TICKET_COMMENT_SUCCESS = "DELETE_TICKET_COMMENT_SUCCESS"
export const DELETE_TICKET_COMMENT_FAIL = "DELETE_TICKET_COMMENT_FAIL"
