import React from "react";
import { Input } from "reactstrap";

const CostumInputField = ({ field, form, ...props }) => {
  const { touched, errors } = form || {};

  return (
    <>
      <Input
        {...field}
        {...props}
        className={`field-input ${
          errors[field.name] && touched[field.name] ? "error" : ""
        }`}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="error-message">{errors[field.name]}</div>
      )}
    </>
  );
};

export default CostumInputField;
