import React from "react";
import { connect } from "react-redux";

const UserWelcome = ({ user }) => (
  <div className="mt-2 mb-2">
    <h1 className="title-page">Hi, {user.fullname}</h1>
    <span className="subtitle-page">
      Silakan pilih platform yang Anda ingin lakukan kustomisasi sesuai
      kebutuhan bisnis Anda. Pastikan Anda telah mengintegrasikan platform ke
      layanan RISA AI.{" "}
    </span>
    {/* <h1 className="mb-2">Hi, {user.fullname}</h1>
    <div>
      <p></p>
    </div> */}
  </div>
);

const mapStateToProps = ({ Profile }) => {
  return {
    user: Profile.user,
  };
};

export default connect(mapStateToProps)(UserWelcome);
