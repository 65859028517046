import {
  GET_QNA_DICTIONARY_PRODUCT,
  GET_QNA_DICTIONARY_PRODUCT_FAIL,
  GET_QNA_DICTIONARY_PRODUCT_SUCCESS,
  DELETE_QNA_DICTIONARY,
  DELETE_QNA_DICTIONARY_FAIL,
  DELETE_QNA_DICTIONARY_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  qnaDictionary: {},
  isFetching: false,
  isDeleting: false,
  error: {},
}

const deleteDictionaryState = (state, id) => {
  let {qnaDictionary} = state;
  return qnaDictionary.map(v=>{
    if(v.id === id){
      return
    }

    return v
  })
}

const qnaDictionary = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QNA_DICTIONARY_PRODUCT:
      return {
        ...state,
        isFetching: true,
      }
    case GET_QNA_DICTIONARY_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        qnaDictionary: action.payload,
      }

    case GET_QNA_DICTIONARY_PRODUCT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }

    case DELETE_QNA_DICTIONARY:
      return {
        ...state,
        isDeleting: true
      }

    case DELETE_QNA_DICTIONARY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        qnaDictionary: deleteDictionaryState(state, action.payload)
      }

    case DELETE_QNA_DICTIONARY_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default qnaDictionary
