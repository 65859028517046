import { call, put, select, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SCENARIO, GET_SCENARIO_DETAIL, ADD_NEW_SCENARIO, DELETE_SCENARIO, UPDATE_SCENARIO } from "./actionTypes"
import {
  getScenarioSuccess,
  getScenarioFail,
  getScenarioDetailSuccess,
  getScenarioDetailFail,
  addScenarioFail,
  addScenarioSuccess,
  updateScenarioSuccess,
  updateScenarioFail,
  deleteScenarioSuccess,
  deleteScenarioFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getScenario, getScenarioDetails, addNewScenario, updateScenario, deleteScenario } from "./api"
import { selectDetailScenario } from "./reducer"

function* fetchScenarios({ payload }) {
  try {
    const response = yield call(getScenario, payload.page)
    yield put(getScenarioSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(getScenarioFail(error))
  }
}

function* fetchScenarioDetail({ scenarioId }) {
  try {
    const response = yield call(getScenarioDetails, scenarioId)
    yield put(getScenarioDetailSuccess(response))
  } catch (error) {
    yield put(getScenarioDetailFail(error))
  }
}

function* onUpdateScenario() {
  try {
    const scenario = yield select(selectDetailScenario)
    const response = yield call(updateScenario, scenario)
    yield put(updateScenarioSuccess(response))
  } catch (error) {
    yield put(updateScenarioFail(error))
  }
}

function* onDeleteScenario({ payload: scenario }) {
  try {
    const response = yield call(deleteScenario, scenario)
    yield put(deleteScenarioSuccess(response))
  } catch (error) {
    yield put(deleteScenarioFail(error))
  }
}

function* onAddNewScenario({ payload: scenario, history }) {
  try {
    const response = yield call(addNewScenario, scenario)
    yield put(addScenarioSuccess(response))

    history.push('/diagram-flow/'+response.id)
  } catch (error) {

    yield put(addScenarioFail(error))
  }
}

function* scenarioSaga() {
  yield takeEvery(GET_SCENARIO, fetchScenarios)
  yield takeEvery(GET_SCENARIO_DETAIL, fetchScenarioDetail)
  yield takeEvery(ADD_NEW_SCENARIO, onAddNewScenario)
  yield takeEvery(UPDATE_SCENARIO, onUpdateScenario)
  yield takeEvery(DELETE_SCENARIO, onDeleteScenario)
}

export default scenarioSaga
