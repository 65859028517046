import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILED,
} from "./actionTypes"

const INIT_STATE = {
  company: {},
  isRequested: false,
  error: {},
}

const Platform = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: {},
      }
      case GET_COMPANY_SUCCESS:
        return {
          ...state,
          isRequested: false,
          company: action.payload,
        }
      case GET_COMPANY_FAILED:
        return {
          ...state,
          error: action.payload,
          isRequested: false,
        }
    default:
      return state
  }
}

export default Platform
