import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { getShop } from "store/actions";
import Tabs from "components/Tabs";
import StatusBar from "components/StatusBar";
import SummaryDashboard from "./SummaryDashboard";
import SycnProductDashboard from "./SycnProductDashboard";
import KnowledgeProductDashboard from "./ServiceKnowledgeDashboard/KnowledgeProductDashboard";
// import RecommendProductDashboard from "./RecommendProductDashboard";

const EcommerceDashboard = props => {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [storeData, setStoreData] = useState(null);
  const [statusSyncProduct, setStatusSyncProduct] = useState(null);

  const DataTabs = [
    {
      id: 0,
      name: "Ringkasan",
      content: (
        <SummaryDashboard
          data={storeData}
          storeId={storeData?.id}
          setStatusSyncProduct={setStatusSyncProduct}
        />
      ),
      url: "summary",
    },
    {
      id: 1,
      name: "Produk Tersinkronisasi",
      content: <SycnProductDashboard data={storeData} />,
      url: "synchronized-products",
    },
    {
      id: 2,
      name: "Pengetahuan Layanan",
      content: <KnowledgeProductDashboard />,
      url: "service-knowledge",
    },
    // {
    //   id: 3,
    //   name: "Produk Rekomendasi",
    //   content: <RecommendProductDashboard />,
    //   url: "recommend-products",
    // },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("tab");

    if (type === null) {
      setActiveTab(0);
    } else {
      const activeTabContent = DataTabs.find(tab => tab.url === type);
      setActiveTab(activeTabContent.id);
    }
  }, [location.search]);

  useEffect(() => {
    const { onGetStore } = props;
    onGetStore();
  }, []);

  useEffect(() => {
    const { Shop } = props;
    const storeDataRes = Shop ? Shop?.shops[0] : null;
    setStoreData(storeDataRes);
  }, [props]);

  const handleTabClick = params => {
    const activeTabContent = DataTabs.find(tab => tab.id === params);
    setActiveTab(params);
    history.push(`/ecommerce?tab=${activeTabContent.url}`);
  };

  return (
    <div className="page-content">
      <div className="page-ecommerce">
        {statusSyncProduct ? (
          <StatusBar type="product" status={statusSyncProduct} />
        ) : (
          <>
            {storeData?.authorize_status && (
              <StatusBar
                type="store"
                status={storeData?.authorize_status?.toLowerCase()}
              />
            )}
          </>
        )}

        <Container fluid>
          <h1 className="title-page">Product Dashboard</h1>
          <span className="subtitle-page">
            Silakan pilih platform yang Anda ingin lakukan kustomisasi sesuai
            kebutuhan bisnis Anda. Pastikan Anda telah mengintegrasikan platform
            ke layanan RISA AI.
          </span>
          <div className="page-ecommerce-content">
            <Tabs
              tabs={DataTabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Shop }) => {
  return {
    Shop,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onGetStore: getShop,
  })(EcommerceDashboard)
);
