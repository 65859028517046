import { post } from "network/http/api";
import React, { useCallback, useState } from "react";
import { Spinner } from "reactstrap";

const RetryInvoiceButton = () => {
    const [isLoading, setIsLoading] = useState(false)

    const retryPaymentRequest = useCallback(() => {
        setIsLoading(true)
        post("/payment/xendit/reissue")
            .then(resp => {
                setIsLoading(false)
                window.open(resp, "_blank")
                window.location.reload()
            })
            .catch(err => {
                setIsLoading(false)
                console.error(err)
            })
    })

    if (isLoading) {
        return <Spinner color="primary" />
    }

    return <span onClick={retryPaymentRequest} style={{ color: "#24B39B", cursor: "pointer" }}>Retry Payment</span>
}

export default RetryInvoiceButton