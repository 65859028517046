import { get, post } from "network/http/api";
import React, { useEffect, useState } from "react";
import { Col, Input, Button, Row, Spinner } from "reactstrap";
import MockLiveChat from "./MockLiveChat";
import UploadLogo from "../ComponentsPage/UploadLogo";
import SimulationChatButton from "./SimulationChatButton";

const StyleForm = ({ apiKey, notificationSuccess, notificationError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [setting, setSetting] = useState({
    title: "Risa AI",
    logo: "",
    primary_color: "#FFFFFF",
    message_color: "#6ea9d7",
  });

  useEffect(() => {
    get("/live-chat-setting")
      .then(resp => {
        if (!resp) {
          return;
        }
        setSetting(resp);
      })
      .catch(err => {
        console.error(err);
        notificationError("Terjadi kesalahan mohon refresh halaman ini");
      });
  }, []);

  const handleOnChangeSetting = (key, value) => {
    let data = {
      ...setting,
    };
    data[key] = value;
    setSetting(data);
  };

  const handleChange = file => {
    const data = {
      ...setting,
      logo: file,
    };
    setSetting(data);
  };

  const handleSaveSettings = () => {
    const payload = setting;

    setIsLoading(true);
    post("/live-chat-setting", payload)
      .then(res => {
        setIsLoading(false);
        notificationSuccess("Tampilan Chat berhasil diubah!");
      })
      .catch(err => {
        console.error(err);
        notificationError("Tampilan Chat gagal diubah!");
        setIsLoading(false);
      });
  };

  return (
    <Row>
      <Col lg={6} sm={1}>
        <div className="tab-general">
          <div className="content-container">
            <div className="field-form">
              <span className="title">Judul Chat </span>
              <Input
                className="field-input"
                value={setting["title"]}
                onChange={e => handleOnChangeSetting("title", e.target.value)}
                placeholder="Masukkan judul chat.."
              />
              <span className="subtitle">
                Bagian ini akan muncul di judul chat Anda. Masukkan nama bisnis
                atau persona chat yang Anda inginkan
              </span>
            </div>
            <div className="field-form">
              <span className="title">Logo Chat</span>
              <div className="field-input">
                <UploadLogo
                  logo={setting["logo"]}
                  onChangeImage={handleChange}
                />
              </div>
              <span className="subtitle">{`(Gunakan format JPG, PNG, atau SVG
        dengan ukuran kurang dari 1MB. Ukuran
        terbaik adalah 50px - 50px)`}</span>
            </div>
            <div className="field-form">
              <span className="title">Warna Judul Chat </span>
              <div className="field-color">
                <Input
                  className="field-input"
                  value={setting["primary_color"]}
                  onChange={e =>
                    handleOnChangeSetting("primary_color", e.target.value)
                  }
                  placeholder="Primary Color"
                />
                <Input
                  className="color field-input"
                  type="color"
                  value={setting["primary_color"]}
                  onChange={e =>
                    handleOnChangeSetting("primary_color", e.target.value)
                  }
                />
              </div>
              <span className="subtitle">
                Warna ini akan muncul pada bagian latar judul chat. Masukkan
                kode warna sesuai format atau pilih langsung pada bagian input
                warna.
              </span>
            </div>
            <div className="field-form">
              <span className="title">Warna Pesan Pelanggan</span>
              <div className="field-color">
                <Input
                  className="field-input"
                  value={setting["message_color"]}
                  onChange={e =>
                    handleOnChangeSetting("message_color", e.target.value)
                  }
                  placeholder="Primary Color"
                />
                <Input
                  className="color field-input"
                  type="color"
                  value={setting["message_color"]}
                  onChange={e =>
                    handleOnChangeSetting("message_color", e.target.value)
                  }
                />
              </div>
              <span className="subtitle">
                Warna ini akan muncul pada bagian latar judul chat. Masukkan
                kode warna sesuai format atau pilih langsung pada bagian input
                warna.
              </span>
            </div>
          </div>
          <div className="btn-custom">
            {isLoading && (
              <div className="text-center">
                <Spinner />
              </div>
            )}
            {!isLoading && (
              <Button color="primary" onClick={() => handleSaveSettings()}>
                Simpan
              </Button>
            )}
          </div>
        </div>
      </Col>
      <Col lg={6} sm={1}>
        <MockLiveChat data={setting} />
        <SimulationChatButton apiKey={apiKey} />
      </Col>
    </Row>
  );
};

export default StyleForm;
