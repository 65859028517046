import React, { useState } from "react";
import { useSelector } from "react-redux";
import FirstStepInputShop from "./1StepInputShop";
import SecondStepInputDataPolis from "./2StepInputDataPolis";
import ThirdStepInputScheduleDelivery from "./3StepInputScheduleDelivery";

const SectionForm = ({ step, setStep, data, setData }) => {
  const DataRender = {
    0: (
      <FirstStepInputShop
        step={step}
        setStep={setStep}
        data={data}
        setData={setData}
      />
    ),
    50: (
      <SecondStepInputDataPolis
        step={step}
        setStep={setStep}
        data={data}
        setData={setData}
      />
    ),
    100: (
      <ThirdStepInputScheduleDelivery
        step={step}
        setStep={setStep}
        data={data}
        setData={setData}
      />
    ),
  };

  return <div>{DataRender[step]}</div>;
};

export default SectionForm;
