import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import { isEmpty, map, size } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getProducts, getStoreProducts, fetchProductCategory } from "store/product/actions";
import { any } from "prop-types";
import { AUTH_USER_KEY } from "network/http/jwt-token-access";
import ProductFilter from "components/Product/ProductFilter";
import ProductPagination from "components/Product/ProductPagination";
import ProductCardItem from "components/Product/ProductCardItem";
import SearchProduct from "components/Product/SearchProduct";


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EcommerceProducts = ({history, match, products,
  totalPage,
  productIsFetching,
  title,
  onGetStoreProducts,
  onGetCategoryProducts,
  onGetProducts})=>{
  const [page, setPage] = useState(0)
  let query = useQuery()

  useEffect(() => {
    if(productIsFetching){
      return
    }

    let newPage = query.get('page')
    if(!newPage){
      newPage = 1
    }

    console.log('useEffect', newPage, page)
    // if(newPage === page){
    //   return
    // }

    newPage = parseInt(newPage)
    setPage(parseInt(newPage))
    fetchProductPage(match, newPage)
  }, [match, query])

  const fetchProductPage = (match, page) => {
    const user = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    const {params, path} = match
    if( path.includes('category')) {
      const {catId} = params
      onGetCategoryProducts(catId, page)
    } else if( path.includes('store')) {
      const { storeId } = params
      onGetStoreProducts(storeId, page)
    }else{
      onGetProducts(user.company_id, page);
    }
  }

  const onChangePage = (newPage) => {
    let {url} = match
    if(newPage == 'next'){
      newPage = parseInt(page) + 1
    }else if (newPage == 'last'){
      newPage = totalPage
    }

    url = `${url}?page=${newPage}`
    history.push(url)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Products" />
          <Row>
            <Col lg="3">
              <ProductFilter
                products={products}/>
            </Col>

            <Col lg="9">
              <Row className="mb-3">
                <Col xl="4" sm="6">
                  <div className="mt-2">
                    <h5>{title}</h5>
                  </div>
                </Col>
                <Col lg="8" sm="6">
                  <SearchProduct />
                </Col>
              </Row>
              <Row>
                {!isEmpty(products) &&
                  products.map((product, key) => (
                    <ProductCardItem
                      history={history}
                      key={product.id}
                      product={product}/>
                  ))}
              </Row>

              <Row>
                <Col lg="12">
                  <ProductPagination
                    onClick={onChangePage}
                    page={page}
                    products={products} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  history: any,
  onGetProducts: PropTypes.func,
};

const mapStateToProps = ({Product}) => ({
  products: Product.products,
  productIsFetching: Product.productIsFetching,
  totalPage: Product.totalPage,
  title: Product.title,
});

export default connect(
  mapStateToProps,
  {
    onGetProducts: getProducts,
    onGetStoreProducts: getStoreProducts,
    onGetCategoryProducts: fetchProductCategory,
  }
)(withRouter(EcommerceProducts));
