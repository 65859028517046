import React from "react";

const HeaderSection = ({ title, subtitle }) => {
  return (
    <div className="settings-header-content">
      <h2>{title}</h2>
      <span>{subtitle}</span>
    </div>
  );
};

export default HeaderSection;
