import {
  GET_RATING_LIST,
  GET_RATING_LIST_SUCCESS,
  GET_RATING_LIST_FAIL,
  SET_FILTER_RATING_LIST,
  RESET_FILTER_RATING_LIST,
} from "./actionTypes";

const INIT_STATE = {
  reviews: [],
  reviewIsFetching: false,
  error: {},
  platforms: [],
  start_range_date: "",
  finish_range_date: "",
  range_date_option: "",
  filter: {
    platform_name: [],
    start_range_date: null,
    finish_range_date: null,
    range_date_option: undefined,
  },
};

const ratings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILTER_RATING_LIST:
      return {
        ...state,
        filter: action.payload,
      };
    case RESET_FILTER_RATING_LIST:
      return {
        ...state,
        filter: {},
      };

    default:
      return state;
  }
};

export default ratings;
