import React, { memo, useState } from 'react';
import { Handle, useStore, Position, useReactFlow } from 'reactflow';
import TextWrapper from './TextWrapper';
import NodeWrapper from './NodeWrapper';
import iconDoubleMessage from 'assets/images/double-bubble-message-icon.svg'
import SourceHandler from './SourceHandler';
import threeDotIcon from 'assets/images/three-dot.svg'

const connectionNodeIdSelector = (state) => state.connectionNodeId;

function QuestionNode({ id, data, selected, focusable, dragging, onClickHeader }) {
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;


  return (
    <NodeWrapper
      title="Ajukan Pertanyaan"
      titleIcon={iconDoubleMessage}
      id={id}
      data={data}
      selected={selected}
      isConnecting={isConnecting}
      isTarget={isTarget}>
      <div className="custom-node__body">
        <div className='text-wrapper mb-1'>
          {data.variables.map(variable => (
            <div className='text-input' key={variable.id}>
              <TextWrapper text={variable.question || "Masukkan pertanyaan Anda di sini…"} isHtml={true} />
              <img className='three-dot-icon' src={threeDotIcon} />
            </div>
          ))}
          <SourceHandler id={id} data={data} selected={selected} />
        </div>
      </div>
    </NodeWrapper>

  );
}

export default memo(QuestionNode);
