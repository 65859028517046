import React, { useEffect, useState } from "react";
import EmptySummaryDashboard from "./EmptySummaryDashboard";
import CardsProductStore from "../ComponentsPage/CardsProductStore";
import { Spinner } from "reactstrap";

const ListStoreProduct = ({ products, isLoading }) => {
  if (isLoading) {
    return (<div className="text-center">
      <Spinner type="grow" color="primary" />
    </div>)
  }

  if (!products || products?.length === 0) {
    return (
      <EmptySummaryDashboard
        title="Belum Ada Produk"
        subtitle="Tidak ada produk di Toko Anda"
      />
    );
  }

  return (
    <div className="store-summary-product">
      {products?.map((itm, idx) => {
        return <CardsProductStore key={idx} itm={itm} />;
      })}
    </div>
  );
};

export default ListStoreProduct;
