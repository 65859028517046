import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty, map, size } from "lodash";
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"


const ProductPagination = ({page, onClick, totalPage = 1}) => {
  if(totalPage == 0){
    return null
  }
  return (
    <Col lg="12">
      <Pagination className="pagination pagination-rounded justify-content-end mb-2">
        <PaginationItem disabled={page == 1}>
          <PaginationLink
            first
            href="#"
            onClick={() => onClick(1)}
          />
        </PaginationItem>

        {page != 1 &&
          <PaginationItem>
            <PaginationLink
              href="#"
              onClick={() => onClick(page - 1)}
            >
            {parseInt(page)-1}
            </PaginationLink>
          </PaginationItem>
        }

        <PaginationItem active={true}>
          <PaginationLink
            // onClick={() => onClick(i + 1)}
            href="#"
          >
            {page}
          </PaginationLink>
        </PaginationItem>

        {page != totalPage &&
          <Fragment>
            <PaginationItem>
              <PaginationLink
                onClick={() => onClick( parseInt(page) + 1)}
                href="#"
              >
                {parseInt(page) + 1}
              </PaginationLink>
            </PaginationItem>

            {totalPage > 3 &&
              <PaginationItem>
                <PaginationLink
                  href="#"
                  onClick={()=> onClick('next')}
                  next
                />
            </PaginationItem>
            }
            {totalPage > 4 &&
              <PaginationItem>
                <PaginationLink
                  onClick={()=> onClick('last')}
                  href="#"
                  last
                />
            </PaginationItem>
            }
          </Fragment>
        }
      </Pagination>
    </Col>
  )
}

const mapStateToProps = ({Product}) => {
  return {
    totalPage: Product.totalPage,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {}
)(ProductPagination))
