import React from "react";
import {
  Card, CardBody,
  CardHeader, ListGroup,
  ListGroupItem, UncontrolledTooltip
} from "reactstrap";
import AskQuestion from "./BuilderNode/AskQuestion";
import StartingNode from "./BuilderNode/StartingNode";
import ProcessorNode from "./BuilderNode/ProcessorNode";
import SendMessage from "./BuilderNode/SendMessage";

export const actionList = {
  "processor": {
    "node": ProcessorNode,
    'is_hide': true,
  },
  "starting_point": {
    'name': 'Start',
    'is_hide': true,
    'node': StartingNode,
    'icon': 'fa fa-flag',
    'defaultContent': "Start!",
  },
  send_message: {
    'name': 'send_message',
    'key': 'send_message',
    'node': SendMessage,
    'icon': 'fa fa-comment',
    'defaultContent': "Hai kak, apakah ada yang dapat kami bantu?",
  },
  ask_question: {
    'name': 'Ask Question',
    'key': 'ask_question',
    'node': AskQuestion,
    'icon': 'fa fa-comment',
    'defaultContent': "Hai kak, apakah ada yang dapat kami bantu?",
  },
}

export const actionNodeType = () => {
  let actionTypes = {}
  Object.keys(actionList).map(key => {
    actionTypes[key] = actionList[key]['node']
  })

  return actionTypes
}

const ActionTools = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Card className='builder-action'>
      <CardHeader color='dark'>
        <i className="fa fa-tasks" /> Tools
        <i id='tool-help' className="fa fa-question-circle" />
        <UncontrolledTooltip target="tool-help">
          Drag and Drop action below to context
        </UncontrolledTooltip>
      </CardHeader>
      <CardBody>
        <ListGroup>
          {Object.keys(actionList).map(key => {
            let action = actionList[key]
            if (action['is_hide']) {
              return null
            }

            return (
              <ListGroupItem
                key={key}
                onDragStart={(event) => onDragStart(event, key, actionList[key])}
                draggable
              >
                <i className={actionList[key]['icon']} />
                {actionList[key]['name']}
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </CardBody>
    </Card>
  )
}

export default ActionTools
