import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { IconShop, IconShakeHand, IconBike } from "../DefaultData";

const SectionStepper = ({ step, setStepper, data, setData, disabled }) => {
  const isStoreDataValid = () =>
    data.store_name !== "" && data.store_url !== "";
  const isPoliciesDataValid = () =>
    data.policies && Object.keys(data.policies).length > 0;

  const goPrev = () => {
    switch (step) {
      case 100:
        setStepper(50);
        break;
      case 50:
        setStepper(0);
        break;
      default:
        break;
    }
  };

  const goMid = () => {
    if (isStoreDataValid()) {
      setStepper(50);
    }
  };

  const goLast = () => {
    if (step === 0) {
      return;
    }

    if (isPoliciesDataValid()) {
      setStepper(100);
    }
  };

  const goNext = () => {
    switch (step) {
      case 0:
        if (isStoreDataValid()) {
          setStepper(50);
        }
        break;
      case 50:
        if (isPoliciesDataValid()) {
          setStepper(100);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="page-integration-shop-stepper">
      <div className="step">
        <ChevronLeftIcon className="icon-stepper" onClick={() => goPrev()} />
        <div className="step-content" onClick={() => setStepper(0)}>
          <div className="step-icon active">
            <IconShop colors="#29CCB1" />
          </div>
          <span className="step-description">Langkah 1</span>
          <h6 className="step-title active">Daftar Toko</h6>
        </div>
      </div>
      <div className="step">
        <div className="step-content" onClick={() => goMid()}>
          <div
            className={`step-icon ${
              disabled ? "disabled" : step >= 50 ? "active" : "normal"
            }`}
          >
            <IconShakeHand
              colors={disabled ? "#A1A3A5" : step >= 50 ? "#29CCB1" : "#000"}
            />
          </div>
          <span className={`${disabled ? "disabled" : " "} step-description`}>
            Langkah 2
          </span>
          <h6
            className={`step-title ${
              disabled ? "disabled" : step >= 50 ? "active" : "normal"
            }`}
          >
            Data Polis
          </h6>
        </div>
      </div>
      <div className="step">
        <div className="step-content" onClick={() => goLast()}>
          <div
            className={`step-icon ${
              disabled ? "disabled" : step === 100 ? "active" : "normal"
            }`}
          >
            <IconBike
              colors={disabled ? "#A1A3A5" : step === 100 ? "#29CCB1" : "#000"}
            />
          </div>
          <span className={`${disabled ? "disabled" : " "} step-description`}>
            Langkah 3
          </span>
          <h6
            className={`step-title ${
              disabled ? "disabled" : step === 100 ? "active" : "normal"
            }`}
          >
            Jadwal Pengiriman
          </h6>
        </div>
        <ChevronRightIcon className="icon-stepper" onClick={() => goNext()} />
      </div>
      <div className="progress-content">
        <div
          className="progress-bar"
          style={{ position: "relative", width: "100%", flex: "1" }}
        >
          <span style={{ width: `${step}%` }}>
            <span className="progress"></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionStepper;
