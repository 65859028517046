import {
  EVENT_SC_DISPLAY_NODE_OPTION,
  EVENT_SC_ON_DRAW_LINE,
  EVENT_SC_ON_NODE_ADDED,
  EVENT_SC_ON_NODE_DELETED,
  EVENT_SC_ON_NODE_OPEN_TOOLTIP,
  EVENT_SC_ON_PANE_CLICK,
  EVENT_SC_ON_START_CONNECTION,
  GET_AGENT_SCENARIO_REQUEST,
  GET_AGENT_SCENARIO_SUCCESS,
  ON_UPDATE_EDGES,
  ON_UPDATE_NODE,
  EVENT_ON_NODE_ERROR_CLEAN,
  ON_NODE_UPDATE_VARIABLE
} from "./actionTypes"

const INIT_STATE = {
  config: {
    toolTip: ''
  },
  scenario: {},
  networks: [],
  actions: [],
  variableNames: {},
  edges: [],
  nodes: [],
  nodeErrors: [],
}

const deleteVariableNameId = (id, variableNames) => {
  delete variableNames[id]
  return variableNames
}

const deleteNodeError = (id, nodeErrors) => {
  let newError = nodeErrors.filter(ne => id === ne.id)
  return newError
}

const extractVariableNames = (payload) => {
  const { nodes } = payload.scenario

  // extract variable
  let variables = {}
  nodes.map((act) => {
    if (act.type === "processor") {
      return
    }
    act.variables.map((v) => {
      if (v.variable_name) {
        variables[v.id] = v.variable_name
      }
    })
  })

  return variables
}


const AgentScenarioBuilder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGENT_SCENARIO_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case GET_AGENT_SCENARIO_SUCCESS: {
      return {
        ...state,
        variableNames: extractVariableNames(action.payload),
        aiAgent: action.payload.ai_agent,
        networks: action.payload.scenario.networks || [],
        scenario: action.payload.scenario,
        actions: action.payload.scenario.nodes || [],
        platformId: action.payload.platform_id,
        isFetching: false,
        nodeErrors: [],
      }
    }
    case ON_UPDATE_EDGES:
      return {
        ...state,

      }
    case ON_UPDATE_NODE:
      return {
        ...state,
        nodes: () => {
          return state.nodes.map(v => {
            for (let index = 0; index < action.payload.length; index++) {
              let node = action.payload[index]

              if (node.id === v.id) {
                return {
                  ...node,
                  id: v.id,
                  type: v.type,
                  data: {
                    isConnectable: false,
                    maxConnections: 1,
                    company_id: v.company_id,
                    content: v.data['content'],
                    // onDelete: () => onNodesDelete(v),
                    // onChange: (data) => handleOnChangeNode(data, v)
                  }
                }
              }
            }

            return v
          })
        }
      }
    case EVENT_SC_ON_NODE_DELETED:
      return {
        ...state,
        variableNames: deleteVariableNameId(action.payload, state.variableNames),
        nodeErrors: deleteNodeError(action.payload, state.nodeErrors),
        config: {
          ...state.config,
          toolTip: '',
          displayOptionOnNode: ''
        }
      }
    case EVENT_SC_ON_NODE_ADDED:
    case EVENT_SC_ON_PANE_CLICK:
    case EVENT_SC_ON_DRAW_LINE:
      return {
        ...state,
        config: {
          ...state.config,
          toolTip: '',
          displayOptionOnNode: ''
        }
      }
    case EVENT_SC_DISPLAY_NODE_OPTION:
      return {
        ...state,
        config: {
          ...state.config,
          displayOptionOnNode: action.payload.sourceHandle
            ? `${action.payload.nodeId}_${action.payload.sourceHandle}`
            : action.payload.nodeId,
        }
      }
    case EVENT_SC_ON_START_CONNECTION:
      return {
        ...state,
        config: {
          ...state.config,
          displayOptionOnNode: action.payload,
        }
      }
    case EVENT_SC_ON_NODE_OPEN_TOOLTIP:
      return {
        ...state,
        config: {
          ...state.config,
          toolTip: action.payload,
        }
      }
    case ON_NODE_UPDATE_VARIABLE:
      return {
        ...state,
        variableNames: action.payload
      }
    case EVENT_ON_NODE_ERROR_CLEAN:
      return {
        ...state,
        nodeErrors: action.payload
      }
    default:
      return state
  }
}

export default AgentScenarioBuilder