const { get, post, put, del } = require("network/http/api")

export const registerShop = (data) => {
  return post(`/store/new`, data)
}

export const fetchShop = () => {
  return get('/store/me')
}

export const getShopDetail = (id) => {
  return get('/store/' + id)
}

export const getShopTrainState = (id) => {
  return get(`/store/${id}/train-state`)
}

export const updateShopPolicy = (storeId, policy) => {
  return put(`/store/${storeId}/policy`, policy)
}

export const updateShop = (storeId, shop) => {
  return put(`/store/${storeId}`, shop)
}

export const updateShopShipment = (storeId, data) => {
  return put(`/store/${storeId}/shipment`, data)
}
