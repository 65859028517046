import { STATUS_BAR_HANDLE } from "./actionTypes";

const initialState = {
  store: {
    btn_close: false,
  },
  product: {
    btn_close: false,
  },
};

const statusBar = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_BAR_HANDLE:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          btn_close: action.payload.btn_close,
        },
      };
    default:
      return state;
  }
};

export default statusBar;
