import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  NavLink,
  CardTitle,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledAlert,
  Alert,
} from "reactstrap";
import { updateShopShipmentRequest } from "store/shop/actions";
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import SelectWeekday from "./SelectWeekday";
import { get, post, put } from "network/http/api";

const toolbarOptions = {
  options: ["inline", "fontSize", "link", "history"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline"],
  },
};


const ShopInfoInput = ({
  shop,
  isFailed,
  isRequested,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [offlineStoreAvailable, setOfflineStoreAvailable] = useState(false);
  const [offlineStoreLocation, setOfflineStoreLocation] = useState('');
  const [shipmentTabActive, setShipmentTabActive] = useState(1);
  const [regularCutOff, setRegularCutOff] = useState('');
  const [instantCutOff, setInstantCutOff] = useState('');

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const content = getShipmentAttribute(shop);
    setEditorState(content);
  }, [shop]);

  const handleOnSubmit = () => {
    if (isLoading) {
      return;
    }

    setIsSuccess(false)
    setIsLoading(true)
    let content = editorState.getCurrentContent()
    const content_html = draftToHtml(
      convertToRaw(content)
    );
    const data = {
      is_offline_available: offlineStoreAvailable,
      offline_store_location: offlineStoreLocation,
      shipment_regular_cut_off: regularCutOff,
      shipment_instant_cut_off: instantCutOff,
      shipment_policy: content_html,
    };
    put(`/store/${shop.id}/shipment`, data)
      .then(resp => {
        setOfflineStoreAvailable(resp.offline_store_available)
        setOfflineStoreLocation(resp.offline_store_location)
        setRegularCutOff(resp.shipment_regular_cut_off)
        setInstantCutOff(resp.shipment_instant_cut_off)
        setIsLoading(false)
        setIsSuccess(true)
      })
      .catch(err => {
        setIsLoading(false)
        setIsSuccess(false)
      })


  };


  const getShipmentAttribute = shop => {
    let content = null;
    get(`/store/${shop.id}/shipment`)
      .then(resp => {
        setOfflineStoreAvailable(resp.offline_store_available)
        setOfflineStoreLocation(resp.offline_store_location)
        setRegularCutOff(resp.shipment_regular_cut_off)
        setInstantCutOff(resp.shipment_instant_cut_off)

        content = EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(resp.note))
        );

        if (!content) {
          content = EditorState.createEmpty();
        }
        setEditorState(content)
      })
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Store Operation Information</CardTitle>

        {isRequested && (
          <div className="text-center mb-2">
            <Spinner color="primary" type="grow" />
          </div>
        )}
        <ListGroup>
          <ListGroupItem>
            <FormGroup switch>
              <Label>Is Offline Store Available?</Label>
              <Input
                checked={offlineStoreAvailable}
                onChange={() =>
                  setOfflineStoreAvailable(!offlineStoreAvailable)
                }
                type="switch"
              />
            </FormGroup>
          </ListGroupItem>
          {offlineStoreAvailable && (
            <ListGroupItem>
              <Label>Offline Store Location</Label>:<br />
              <Input
                multiple
                type="textarea"
                onChange={e => setOfflineStoreLocation(e.target.value)}
                value={offlineStoreLocation}
              />
              <small className="p-1 mt-2 text-muted">
                This will help Customers when they want to visit your offline
                store, please put address your offline store
              </small>
            </ListGroupItem>
          )}
          <ListGroupItem>
            <strong>Store Location</strong>:<br />
            {shop.store_location}
          </ListGroupItem>
          <ListGroupItem>
            <strong>Shipment Location</strong>:<br />
            {shop.shipping_location_district}, {shop.shipping_location_city}
          </ListGroupItem>
          <ListGroupItem>
            <h5 className="font-size-14">
              <i className="bx bx-calendar me-1 text-primary" />
              Shipment Operation Hour:
            </h5>
            <br />
          </ListGroupItem>
          <div className="mb-3 p-3">
            <Nav tabs>
              <NavItem>
                <NavLink
                  onClick={() => setShipmentTabActive(1)}
                  className={shipmentTabActive === 1 ? "active" : ""}
                >
                  Instant Cut Off
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => setShipmentTabActive(2)}
                  className={shipmentTabActive === 2 ? "active" : ""}
                >
                  Regular Cut Off
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={shipmentTabActive}>
              <TabPane tabId={1}>
                <Card>
                  <CardBody>
                    <SelectWeekday
                      shop={shop}
                      value={instantCutOff}
                      onChange={setInstantCutOff}
                    />
                    <small className="text-muted mt-2">
                      NOTE: Waktu terakhir order pengiriman Instant (Gosend/Grab
                      Express) dikirim pada hari yang sama.
                    </small>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={2}>
                <Card>
                  <CardBody>
                    <SelectWeekday
                      shop={shop}
                      value={regularCutOff}
                      onChange={e => setRegularCutOff(e)}
                    />
                    <small className="text-muted mt-2">
                      NOTE: Waktu terakhir order pengiriman Regular (JNE/J&T)
                      dikirim pada hari yang sama.
                    </small>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </div>
          {shipmentTabActive !== 3 && (
            <ListGroupItem>
              <h5 className="font-size-14">
                <i className="bx bx-note me-1 text-primary" />
                Additional Policy Note
              </h5>

              <div className="mt-3">
                <Editor
                  toolbar={toolbarOptions}
                  editorState={editorState}
                  onEditorStateChange={e => setEditorState(e)}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  placeholder="Place Your Note Here..."
                />
              </div>
              <p className="mt-2 mb-3">
                Important, Harap masukan Policy Shipment dengan detail agar AI
                dapat menjawab pertanyaan customer sesuai dengan policy toko
                anda.
              </p>
              {isRequested && (
                <div className="text-center">
                  <Spinner type="grow" color="primary" />
                </div>
              )}

              {isFailed && (
                <UncontrolledAlert color="danger">
                  Failed to update store, please try again.
                </UncontrolledAlert>
              )}

              {isLoading &&
                <div className="text-center">
                  <Spinner type="grow" color="primary" />
                </div>
              }

              {isSuccess &&
                <Alert color="primary">
                  Berhasil memperbarui jadwal pengiriman
                </Alert>
              }

              <Button
                color="primary"
                disabled={isLoading}
                onClick={handleOnSubmit}
              >
                Save
              </Button>
            </ListGroupItem>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = ({ Shop }) => {
  return {
    ...Shop.update,
  };
};

export default connect(mapStateToProps, {
  updateShopShipment: updateShopShipmentRequest,
})(ShopInfoInput);
