import React from "react";
import { Input } from "reactstrap";

const InputFieldCostume = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <>
      <Input
        {...field}
        {...props}
        className={`field-input ${
          errors[field.name] && touched[field.name] ? "error" : ""
        }`}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="error-message">{errors[field.name]}</div>
      )}
    </>
  );
};

export default InputFieldCostume;
