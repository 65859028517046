import React from "react";
import { UncontrolledAlert, Spinner, Button } from "reactstrap";

const ModalActivationIntegration = ({
  enabled,
  name,
  error,
  loading,
  onUpdateIntegration,
  setShowModalToggle,
}) => {
  return (
    <>
      <span className="title">
        {enabled ? "Nonaktifkan Integrasi?" : "Aktifkan Integrasi?"}
      </span>
      <span className="subtitle">
        {enabled
          ? `Menonaktifkan integrasi ${name} hanya akan memutuskan
          integrasi chat dengan sistem RISA AI namun integrasi toko akan tetap
          aktif.`
          : `Aktifkan integrasi di ${name} platform anda akan menyalakan
          integrasi chat dengan sistem RISA AI.`}
      </span>
      {error && (
        <UncontrolledAlert color="danger">
          {error ? error : "Something went wrong"}
        </UncontrolledAlert>
      )}
      {loading ? (
        <div className="spinner">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <Button
            color={enabled ? "danger" : "primary"}
            className={`${enabled ? "cstm-danger" : ""}`}
            onClick={() => onUpdateIntegration()}
          >
            {enabled ? "Nonaktif" : "Aktifkan"}
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => setShowModalToggle(false)}
          >
            Batal
          </Button>
        </>
      )}
    </>
  );
};

export default ModalActivationIntegration;
