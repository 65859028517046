import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFULL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFULL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const registerUserAPI = (payload) => {
  api
}
