import * as actionTypes from "./actionTypes"

export const getAgentOperationHourRequest = () => ({
  type: actionTypes.GET_AGENT_OPERATION_HOUR_REQUEST,
})

export const getAgentOperationHourSuccess = payload => ({
  type: actionTypes.GET_AGENT_OPERATION_HOUR_SUCCESS,
  payload,
})

export const getAgentOperationHourFailed = payload => ({
  type: actionTypes.GET_AGENT_OPERATION_HOUR_FAILED,
  payload,
})

export const insertAgentOperationHourRequest = (data) => ({
  type: actionTypes.INSERT_AGENT_OPERATION_HOUR_REQUEST,
  payload: data,
})

export const insertAgentOperationHourSuccess = payload => ({
  type: actionTypes.INSERT_AGENT_OPERATION_HOUR_SUCCESS,
  payload,
})

export const insertAgentOperationHourFailed = payload => ({
  type: actionTypes.INSERT_AGENT_OPERATION_HOUR_FAILED,
  payload,
})

export const updateAgentOperationHourRequest = (id, data) => ({
  type: actionTypes.UPDATE_AGENT_OPERATION_HOUR_REQUEST,
  payload: {id, data},
})

export const updateAgentOperationHourSuccess = payload => ({
  type: actionTypes.UPDATE_AGENT_OPERATION_HOUR_SUCCESS,
  payload,
})

export const updateAgentOperationHourFailed = payload => ({
  type: actionTypes.UPDATE_AGENT_OPERATION_HOUR_FAILED,
  payload,
})
