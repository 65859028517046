import React, { useState } from "react";
import MessageScenarioConfigInput from "./MessageScenarioConfigInput";
import { Button } from "reactstrap";
import { connect } from "react-redux";

const MessageScenarioConfigIcon = ({ onClick }) => (
	<Button className="scenario-config-icon" disabled={false} onClick={onClick}>
		{/* // <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> */}
		<svg fill="#999999" height="800px" width="800px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 32 32" space="preserve">
			<path d="M29.8,20.8c-0.1-0.5-0.7-0.9-1.2-0.7c-0.5,0.1-0.8,0-0.8-0.1c-0.1-0.1,0-0.4,0.4-0.8c0.4-0.4,0.3-1,0-1.4
	c-0.8-0.8-1.8-1.4-2.9-1.7c-0.5-0.1-1.1,0.2-1.2,0.7c-0.1,0.5-0.4,0.7-0.5,0.7s-0.3-0.2-0.5-0.7c-0.2-0.5-0.7-0.8-1.2-0.7
	c-1.1,0.3-2.1,0.9-2.9,1.7c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,0.4,0.7,0.4,0.8c0,0.1-0.3,0.2-0.8,0.1c-0.5-0.1-1.1,0.2-1.2,0.7
	c-0.1,0.6-0.2,1.2-0.2,1.7s0.1,1.1,0.2,1.7c0.1,0.5,0.7,0.9,1.2,0.7c0.5-0.1,0.8,0,0.8,0.1c0.1,0.1,0,0.4-0.4,0.8
	c-0.4,0.4-0.3,1,0,1.4c0.8,0.8,1.8,1.4,2.9,1.7c0.5,0.1,1.1-0.2,1.2-0.7c0.1-0.5,0.4-0.7,0.5-0.7s0.3,0.2,0.5,0.7
	c0.1,0.4,0.5,0.7,1,0.7c0.1,0,0.2,0,0.3,0c1.1-0.3,2.1-0.9,2.9-1.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-0.4-0.7-0.4-0.8
	c0-0.1,0.3-0.2,0.8-0.1c0.5,0.1,1.1-0.2,1.2-0.7c0.1-0.6,0.2-1.2,0.2-1.7S29.9,21.4,29.8,20.8z M23.5,25c-1.4,0-2.5-1.1-2.5-2.5
	s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5S24.9,25,23.5,25z"/>
			<path d="M15,22.5c0-4.7,3.8-8.5,8.5-8.5c2.1,0,4,0.8,5.5,2V9c0-2.8-2.2-5-5-5H8C5.2,4,3,6.2,3,9v19c0,0.4,0.2,0.7,0.6,0.9
	C3.7,29,3.9,29,4,29c0.2,0,0.5-0.1,0.7-0.2c3-2.6,6.6-4.3,10.4-5.2C15,23.2,15,22.9,15,22.5z M11,11h6c0.6,0,1,0.4,1,1s-0.4,1-1,1
	h-6c-0.6,0-1-0.4-1-1S10.4,11,11,11z M14,17h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h3c0.6,0,1,0.4,1,1S14.6,17,14,17z"/>
		</svg>
	</Button>
)

const MessageScenarioConfig = ({ scenario }) => {
	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => {
		setIsOpen(!isOpen)
	}

	if (!scenario.id) return

	return (
		<div className="scenario-config">
			<MessageScenarioConfigIcon onClick={toggle} />

			<div className="node-configuration">
				<div className={`sidebar ${isOpen ? "sidebar--open" : ""}`}>
					<div className="sidebar-wrapper">
						<div className="header trigger" onClick={toggle}>
							Pengaturan Pesan Sesi Berakhir
							<i className="fa fa-ellipsis-h" />
						</div>
						<MessageScenarioConfigInput />
					</div>
				</div>
			</div>
		</div>
	);

}

const mapStateToProps = (state) => ({
	scenario: state.AgentScenarioBuilder.scenario
})

export default connect(mapStateToProps)(MessageScenarioConfig)