const SizeCompany = [
  {
    label: "Pilih Besar Perusahaan",
    value: "",
  },
  {
    label: "Mikro (1-4 karyawan)",
    value: "1-4 karyawan",
  },
  {
    label: "Kecil (5-19 karyawan)",
    value: "5-19 karyawan",
  },
  {
    label: "Sedang (20-199 karyawan)",
    value: "20-199 karyawan",
  },
  {
    label: "Besar (>200 karyawan)",
    value: ">200 karyawan",
  },
];

const IndustryCompany = [
  {
    label: "Pilih Industry Perusahaan",
    value: "",
  },
  {
    label: "Hukum",
    value: "Hukum",
  },
  {
    label: "Industri kimia",
    value: "Industri kimia",
  },
  {
    label: "Jasa keuangan",
    value: "Jasa keuangan",
  },
  {
    label: "Kehutanan, kayu, pulp dan kertas",
    value: "Kehutanan, kayu, pulp dan kertas",
  },
  {
    label: "Konstruksi",
    value: "Konstruksi",
  },
  {
    label: "Konsultansi",
    value: "Konsultansi",
  },
  {
    label: "Makanan, minum, tembakau",
    value: "Makanan, minum, tembakau",
  },
  {
    label: "Media, budaya, grafis",
    value: "Media, budaya, grafis",
  },
  {
    label: "Pelayanan kesehatan",
    value: "Pelayanan kesehatan",
  },
  {
    label: "Pelayanan publik",
    value: "Pelayanan publik",
  },
  {
    label: "Pengiriman/logistik",
    value: "Pengiriman/logistik",
  },
  {
    label: "Pendidikan",
    value: "Pendidikan",
  },
  {
    label: "Pertambangan (batubara, pertambangan lainnya)",
    value: "Pertambangan (batubara, pertambangan lainnya)",
  },
  {
    label: "Pertanian, perkebunan, sektor pedesaan lainnya",
    value: "Pertanian, perkebunan, sektor pedesaan lainnya",
  },
  {
    label: "Perdagangan",
    value: "Perdagangan",
  },
  {
    label: "Produksi Logam Dasar",
    value: "Produksi Logam Dasar",
  },
  {
    label: "Produksi minyak dan gas, penyulingan minyak",
    value: "Produksi minyak dan gas, penyulingan minyak",
  },
  {
    label: "Teknik mesin dan listrik",
    value: "Teknik mesin dan listrik",
  },
  {
    label: "Tekstil",
    value: "Tekstil",
  },
  {
    label: "Teknologi Informasi",
    value: "Teknologi Informasi",
  },
  {
    label: "Transportasi",
    value: "Transportasi",
  },
  {
    label: "Utilitas (air, gas, listrik)",
    value: "Utilitas (air, gas, listrik)",
  },
  {
    label: "Wirausaha",
    value: "Wirausaha",
  },
];

const DepartmentCompany = [
  {
    label: "Pilih Department Perusahaan",
    value: "",
  },
  {
    label: "Akuntansi",
    value: "Akuntansi",
  },
  {
    label: "GA (Urusan Umum)",
    value: "GA (Urusan Umum)",
  },
  {
    label: "Gudang",
    value: "Gudang",
  },
  {
    label: "HRD (Departemen Sumber Daya Manusia)",
    value: "HRD (Departemen Sumber Daya Manusia)",
  },
  {
    label: "Pembelian",
    value: "Pembelian",
  },
  {
    label: "Penjualan & Pemasaran",
    value: "Penjualan & Pemasaran",
  },
  {
    label: "Produksi",
    value: "Produksi",
  },
  {
    label: "PPIC (Pengendalian Inventaris Perencanaan Produk)",
    value: "PPIC (Pengendalian Inventaris Perencanaan Produk)",
  },
  {
    label: "QA (Jaminan Mutu)",
    value: "QA (Jaminan Mutu)",
  },
  {
    label: "TI (Informasi & Teknologi)",
    value: "TI (Informasi & Teknologi)",
  },
];

export { SizeCompany, IndustryCompany, DepartmentCompany };
