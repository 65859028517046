import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import google from "assets/images/google.svg";
import auth0 from "auth0-js";

const CustomLoginButton = ({ title }) => {
  const login = () => {

    let auth = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENTID,
      redirectUri: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_AUTH0_CALLBACK_URL}`,
      responseType: "code",
      scope: "openid profile email",
    });
    auth.authorize({
      connection: "google-oauth2",
      redirectUri: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_AUTH0_CALLBACK_URL}`,
    });
    auth.popup.callback();
  };

  return (
    <button
      onClick={login}
      className="p-3 w-100 d-flex justify-content-center"
      style={{
        gap: "10px",
        backgroundColor: "#fff",
        borderRadius: "4px",
        border: "1px solid #E1E2E4",
        boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.17), 0px 0px 1px 0px rgba(0, 0, 0, 0.08)`,
      }}
    >
      <img src={google} alt="" style={{ width: "20px", height: "20px" }} />
      <span>{title} dengan Google</span>
    </button>
  );
};

function Auth0ProviderWithNavigate({ children, history, title }) {
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID;
  const redirectUri = process.env.REACT_APP_OAUTH0_CALLBACK_URL;

  if (!clientId && !redirectUri) {
    return null;
  }

  return <CustomLoginButton title={title} />;
}

export default withRouter(Auth0ProviderWithNavigate);
