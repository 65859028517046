import React, { useState } from "react";
import { Alert, Button, Spinner } from "reactstrap";
import { post } from "network/http/api";
import CardContentConfirmation from "./CardContentConfirmation";
import CurrentPlanCardContent from "./CurrentPlanCardContent";

const UpgradePackage = ({
  type,
  currentPlanId,
  packageUpgrade,
  toggle,
}) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const packageInfo = [
    "Paket baru akan mulai aktif setelah proses pembayaran tagihan selesai.",
    // "Mulai {{subscription-term-end}}, Anda akan ditagih {{subscription-fee}}/{{subscription-term}}",
    "Kami akan menggabungkan sisa kuota ini ke paket baru Anda.",
    "Masa berlaku kuota akan mengikuti masa berlaku paket Anda.",
  ];

  const itemEnterprise = packageUpgrade.name.toLowerCase() === "enterprise";

  const onUpgradePackage = async paramsValue => {
    if (isLoading) {
      return;
    }

    const payload = {
      plan_name: paramsValue.name,
      is_yearly: packageUpgrade.is_yearly,
    };

    setIsLoading(true);
    await post(`/payment/xendit/invoice`, payload)
      .then(async resp => {
        window.open(resp, "_blank");
        toggle();
        setIsLoading(false);
        window.location.reload();
      })
      .catch(err => {
        console.error(err)
        setError(err);
        setIsLoading(false);
      });
  };

  const openWaLink = () => {
    window.open("https://wa.link/ds2hkj", "_blank");
  };

  return (
    <div className="section-content-modal-billing">
      <span className="title">Upgrade paket berlangganan</span>
      <span className="sub-title">
        Konfirmasi dan selesaikan proses upgrade paket berlangganan
      </span>
      <div className="section-package-confirmation">
        <CurrentPlanCardContent currentPlanId={currentPlanId} />
        <CardContentConfirmation items={packageUpgrade} type={type} update />
      </div>
      <ul className="section-list">
        {packageInfo.map((itm, idx) => {
          return <li key={idx}>{itm}</li>;
        })}
      </ul>
      {error && <Alert color="danger">{error.detail}</Alert>}
      {isLoading && (
        <div className="loading">
          <Spinner color="primary" />
        </div>
      )}
      <div className="section-button">
        <Button
          onClick={() => {
            itemEnterprise ? openWaLink() : onUpgradePackage(packageUpgrade);
          }}
          color="primary"
          className="button"
          style={{ backgroundColor: "#24b39b", color: "#fff" }}
        >
          {itemEnterprise ? "Hubungi RISA AI" : "Upgrade paket"}
        </Button>
        <span onClick={toggle}>Batal</span>
      </div>
    </div>
  );
};

export default UpgradePackage;
