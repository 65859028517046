import React, { useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Tooltip } from "reactstrap";



const CodeHighlight = ({ apiKey }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [codeString, setCodeString] = useState('')

    useEffect(() => {
        if (!apiKey) return
        let added = `<link href="${process.env.REACT_APP_WIDGET_CHAT_STYLE_URL}" rel="stylesheet" />
<script>
window.risa_account = {
    secret: "${apiKey.secret}",
    client_id: "${apiKey.client_id}"
};

(function () {
    function initRisa() {
        // Create the div element
        var risaDiv = document.createElement('div');
        risaDiv.id = 'risa-ai-embed';

        // Insert the div before the closing </body> tag
        document.body.appendChild(risaDiv);

        // Create and insert the script
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = '${process.env.REACT_APP_WIDGET_CHAT_JS_URL}';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    }

    // Check if the DOM is already loaded
    if (document.readyState === "loading") {
        // If not, add an event listener
        document.addEventListener("DOMContentLoaded", initRisa);
    } else {
        // If it's already loaded, run the function immediately
        initRisa();
    }
})();
</script>`
        setCodeString(added)
    }, [apiKey])

    const handleCopy = () => {
        navigator.clipboard.writeText(codeString).then(() => {
            setTooltipOpen(true)

            setInterval(() => {
                setTooltipOpen(false)
            }, 1000);
        });
    };

    return (
        <div className="code-highlight">
            <SyntaxHighlighter language="javascript" style={dark}>
                {codeString}
            </SyntaxHighlighter>

            <button
                id={'code-highligt-web-copy'} onClick={handleCopy} className="copy-button">
                <i className="fas fa-copy"></i> {/* Font Awesome icon for copy */}
            </button>

            <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                autohide={true}
                target={'code-highligt-web-copy'}
            >
                Copied!!
            </Tooltip>
            <div className="instruction">
                <p>
                    Tempelkan kode di dalam `&lt;head&gt;` atau sebelum tag penutup `&lt;/body&gt;` pada situs web Anda. Simpan dan perbarui halaman situs web.
                </p>
            </div>

            
        </div>
    );
};

export default CodeHighlight