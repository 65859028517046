import React, { Component } from "react";
import PropTypes from "prop-types";
import { Label, Spinner, Button } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, withRouter } from "react-router-dom";
import { post, get } from "network/http/api";

// import images
import PasswordShowHide from "components/PasswordShowHide";
import SectionImage from "./SectionImage";
import SectionMobileLogo from "./SectionMobileLogo";
import Auth0Vendor from "./Auth0Vendor";
import { RegisterSchema } from "./SchemaValidation";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      phone_number: "",
      confirm_password: "",
      referral_code: "",
      showEmailVerification: false,
      data: null,
      loading: false,
      error: null,
      success: null,
      resendEmail: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      email: "",
      password: "",
      confirm_password: "",
    });
  }

  handleOnSubmit = async values => {
    const params = new URLSearchParams(this.props.location.search);
    const plan_name = params.get('plan')
    const referral_code = values.referral_code || params.get('referral_code') 
    const is_yearly = params.get('is_yearly')

    const payload = {
      email: values.email,
      phone_number: values.phone_number,
      password: values.password,
      password_confirmation: values.confirm_password,
      plan_name: plan_name,
      is_yearly: is_yearly,
      referral_code: referral_code,
    };

    this.setState({
      loading: true,
      email: values.email,
    });

    await post(`auth/register`, payload)
      .then(async resp => {
        this.setState({
          loading: false,
          showEmailVerification: true,
          data: resp.user,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          loading: false,
        });
        setTimeout(() => {
          this.setState({ error: null, loading: false });
        }, 2000);
      });
  };

  resendRegister = () => {
    const { data } = this.state;
    get(`/auth/new-token/email/${data.id}`)
      .then(resp => {
        this.setState({
          resendEmail: true,
        });
        this.setState({
          success:
            "Email verifikasi berhasil dikirim, Silahkan cek kembali email Anda.",
        });
        setTimeout(() => {
          this.setState({ success: null });
        }, 2000);
      })
      .catch(error => {
        this.setState({
          resendEmail: false,
        });
      });
  };

  render() {
    const {
      showEmailVerification,
      error,
      success,
      loading,
      email,
      resendEmail,
    } = this.state;

    return (
      <React.Fragment>
        {(error || success) && (
          <div
            className="w-100 py-2 position-absolute text-center left-0 top-0 text-white"
            style={{
              backgroundColor: success !== null ? "#1CAC93" : "#FF5555",
              zIndex: 1,
            }}
          >
            {success !== null
              ? success
              : error?.detail || "Sorry, please try again"}
          </div>
        )}
        <div className="auth-page">
          <div className="section-auth-full">
            <SectionImage />
            <div
              className={`section-content ${showEmailVerification ? "bg-mobile-verify" : ""
                }`}
            >
              <SectionMobileLogo />
              <div className="content">
                {showEmailVerification ? (
                  <div className="content-verification">
                    <h3 className="title-text text-center">
                      Verifikasi Emailmu
                    </h3>
                    <span className="subtitle-text text-center">
                      Kami sudah mengirimkan email ke <strong>{email}</strong>
                      . <br />
                      Silakan cek emailmu dan verifikasi emailmu.
                    </span>
                    {!resendEmail && (
                      <p className="text-center mt-3">
                        Belum dapat email?{" "}
                        <span
                          onClick={() => this.resendRegister()}
                          className="fw-medium text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          Kirim Ulang
                        </span>
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="content-auth">
                    <h3 className="text-center title-text">Registrasi</h3>
                    <p className="text-center subtitle-text">
                      Mulai otomatisasi dan tingkatkan layanan pelanggan
                    </p>
                    <div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: "",
                          password: "",
                          confirm_password: "",
                        }}
                        validationSchema={RegisterSchema}
                        onSubmit={this.handleOnSubmit}
                      >
                        {({ errors, status, touched, isValid }) => (
                          <Form className="form-auth" noValidate>
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ height: "45px" }}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="phone_number" className="form-label">
                                Nomor Telepon
                              </Label>
                              <Field
                                name="phone_number"
                                className={
                                  "form-control" +
                                  (errors.phone_number && touched.phone_number
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ height: "45px" }}
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Kata Sandi
                              </Label>
                              <Field
                                name="password"
                                type="password"
                                autoComplete="true"
                                component={PasswordShowHide}
                                styleClass={
                                  errors.password && touched.password
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                              {errors.password && (
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className={`invalid-feedback ${errors.password ? "d-block" : ""
                                    }`}
                                >
                                  {errors.password.message}
                                </ErrorMessage>
                              )}
                            </div>
                            <div className="mb-3">
                              <Label
                                for="confirm_password"
                                className="form-label"
                              >
                                Konfirmasi Kata Sandi
                              </Label>
                              <Field
                                name="confirm_password"
                                type="confirm_password"
                                autoComplete="true"
                                component={PasswordShowHide}
                                styleClass={
                                  errors.confirm_password &&
                                    touched.confirm_password
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                              {errors.confirm_password && (
                                <ErrorMessage
                                  name="confirm_password"
                                  component="div"
                                  className={`invalid-feedback ${errors.confirm_password ? "d-block" : ""
                                    }`}
                                >
                                  {errors.confirm_password.message}
                                </ErrorMessage>
                              )}
                            </div>
                            <div className="mb-3">
                              <Label
                                for="confirm_password"
                                className="form-label"
                              >
                                Referral Code
                              </Label>
                              <Field
                                name="referral_code"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.referral_code && touched.referral_code
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ height: "45px" }}
                              />
                              {errors.referral_code && (
                                <ErrorMessage
                                  name="referral_code"
                                  component="div"
                                  className={`invalid-feedback ${errors.referral_code ? "d-block" : ""
                                    }`}
                                >
                                  {errors.referral_code.message}
                                </ErrorMessage>
                              )}
                            </div>
                            {loading && (
                              <div className="mt-3 d-grid">
                                <div className="text-center">
                                  <Spinner color="primary" type="grow" />
                                </div>
                              </div>
                            )}

                            <div className="mt-3 d-grid section-button">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={!isValid || loading}
                              >
                                Daftar
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    <div className="position-relative my-4">
                      <div
                        className="position-absolute d-flex align-items-center"
                        aria-hidden="true"
                        style={{ inset: "0px" }}
                      >
                        <div
                          style={{
                            border: "1px solid #E1E2E4",
                            width: "80%",
                            margin: "auto",
                          }}
                        ></div>
                      </div>
                      <div className="position-relative d-flex justify-content-center">
                        <span
                          className="bg-white px-2 text-sm"
                          style={{ color: "#E1E2E4" }}
                        >
                          Atau
                        </span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <Auth0Vendor title={"Daftar"} />
                    </div>
                    <div className="mt-5 text-center">
                      <p>
                        Sudah punya akun?{" "}
                        <Link to="/login" className="fw-medium text-primary">
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

export default withRouter(Register);
