import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { get } from "network/http/api";
import PlatformIcon from "components/Platform/PlatformIcon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const pluck = (arr, key, value) => {
  let obj = {};
  arr.map(i => {
    if (i[key] === value) {
      obj = i;
    }
  });

  return obj;
};

const ScenarioList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [scenarioList, setScenarioList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetchScenarioList();
  }, []);

  const fetchScenarioList = () => {
    if (isLoading) return;

    setIsLoading(true);
    get("/scenario")
      .then(resp => {
        setIsLoading(false);
        const { platform, scenario } = resp;
        let scenarioPlatform = [];

        scenario.map(p => {
          let selectedPlatform = pluck(platform, "id", p.platform_id);
          scenarioPlatform.push({
            platform: selectedPlatform,
            ...p,
          });
        });

        setScenarioList(scenarioPlatform);
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage("Terjadi kesalahan harap refresh halaman ini");
      });
  };

  return (
    <Card className="information-card scenario-list mini-stats-wid">
      <CardBody>
        <div className="card-title">
          <h4>Skenario Terakhir yang Diubah</h4>
          <p>
            Berikut adalah Custom Scenario dari layanan terintegrasi, yang baru
            Anda ubah beberapa waktu lalu.
          </p>
        </div>

        {scenarioList.map(scenario => (
          <div className="scenario-item" key={scenario.id}>
            <PlatformIcon platformName={scenario.platform.platform_name} />
            <div className="information">
              <div className="title">
                <p>{scenario?.platform?.platform_name}</p>
                <small>
                  terakhir diubah pada{" "}
                  {moment(scenario.updated_at).format("DD-MM-YYYY")}
                </small>
              </div>

              <div>
                <Link to="/ai-assistant/profile" className="button-view">
                  Lihat
                </Link>
              </div>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default ScenarioList;
