import { get, post } from "network/http/api";
import { JWT_TOKEN_KEY, AUTH_USER_KEY } from "network/http/jwt-token-access";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Spinner, UncontrolledAlert } from "reactstrap";


function Auth0VendorSuccess(props) {
    const params = new URLSearchParams(props.location.search);
    const userId = params.get("user");
    const tempToken = params.get("token")

    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        callbackVerify()
    }, [])

    console.log('Auth0VendorSuccess: get cookie', document.cookie)
    const callbackVerify = useCallback(() => {
        post("/auth/auth0-callback/verify", {
            'user_id': userId,
            'token': tempToken
        })
            .then(resp => {
                const { access_token, user, is_onboarding_finished } = resp
                localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user))
                localStorage.setItem(JWT_TOKEN_KEY, access_token)

                if (is_onboarding_finished) {
                    window.location.replace("/dashboard")
                    return
                }

                window.location.replace('/onboarding')
            })
            .catch(err => {
                setIsLoading(false)
                setErrorMessage(err)

                setInterval(() => {
                    window.location.replace('/logout')
                }, 3000)
            })
    })

    return (
        <div style={{ 'textAlign': 'center', 'margin': '10px auto' }}>
            {errorMessage &&
                <UncontrolledAlert color="danger">
                    Something went wrong, please try again.
                </UncontrolledAlert>
            }
            <Spinner color={errorMessage ? "danger" : "primary"} />
        </div>
    );
}

export default withRouter(Auth0VendorSuccess)