import React from "react";
import { ReactComponent as IconRobot } from "assets/images/knowledge/robotic-hand.svg";
import { Button } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const FormQuestionAnswer = ({
  questionAnswer,
  onSubmitQuestion,
  handleChangeQuestion,
  isFocused,
  handleFocus,
  handleBlur,
}) => {
  return (
    <React.Fragment>
      <Formik
        className="p-5"
        enableReinitialize={true}
        initialValues={questionAnswer}
        onSubmit={values => onSubmitQuestion(values)}
      >
        {({ errors, touched }) => (
          <Form className="form-knowledge">
            <div className="form-input-knowlede">
              <div className="field-knowledge mt-3 mb-3">
                {isFocused === 3 ? (
                  <IconRobot height={30} width={30} fill={"#1cac93"} />
                ) : (
                  <div style={{ height: 30, width: 30 }} />
                )}

                <Field
                  name="question"
                  placeholder="Berapa 1 + 1 ?"
                  type="text"
                  className="form-control"
                  onChange={handleChangeQuestion}
                  value={questionAnswer.question}
                  onFocus={() => handleFocus(3)}
                  onBlur={() => handleBlur(3)}
                />
              </div>
              <div className="field-knowledge mb-3 items-start">
                {isFocused === 4 ? (
                  <IconRobot height={30} width={30} fill={"#1cac93"} />
                ) : (
                  <div style={{ height: 30, width: 30 }} />
                )}
                <Field
                  name="answer"
                  placeholder="Jawaban nya 2."
                  type="text"
                  className="form-control"
                  onChange={handleChangeQuestion}
                  value={questionAnswer.answer}
                  onFocus={() => handleFocus(4)}
                  onBlur={() => handleBlur(4)}
                />
              </div>

              <div className="container-btn-save">
                <Button
                  className="btn-onboarding"
                  color="primary"
                  type="submit"
                >
                  Add Example Question
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default FormQuestionAnswer;
