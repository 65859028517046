import React from "react";
import { Spinner, Input, Button } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { InsertShopSchema } from "../SchemaValidation";
import { get } from "network/http/api";
import { useDispatch } from "react-redux";
import { notificationErrorRequestAction } from "store/notification/actions";

const FirstStepInputShop = ({ step, setStep, data, setData }) => {
  const dispatch = useDispatch()

  const InputField = ({ field, form: { touched, errors }, ...props }) => (
    <>
      <Input
        {...field}
        {...props}
        className={`field-input ${errors[field.name] && touched[field.name] ? "error" : ""
          }`}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="error-message">{errors[field.name]}</div>
      )}
    </>
  );

  const handleOnGetStoreInformation = (values) => {
    let store_url = new URL(values.url)
    let path = store_url.pathname.replace("/", "")
    get(`/store/tokopedia/store/${path}`)
      .then(resp => {
        setStep(50);
        setData({
          ...data,
          policies: resp.policies,
          store_name: resp.store_name,
          store_url: values.url,
          retail_attribute: resp.retail_attribute,
        });
      })
      .catch(err => {
        console.error(err)
        dispatch(notificationErrorRequestAction("URL toko salah"))
      })
  }

  const onSubmitIntegration = values => {
    handleOnGetStoreInformation(values)

  };

  return (
    <div className="page-integration-shop-form">
      <div
        style={{
          borderBottom: "1px solid #c2c7cf",
          margin: "36px 0px",
        }}
      />
      <h6 className="subtitle">
        Masukkan data toko Tokopedia Anda yang ingin diintegrasikan dengan
        sistem RISA AI.
      </h6>
      <div className="page-integration-shop-form-content">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: data.store_name,
            url: data.store_url,
          }}
          validationSchema={InsertShopSchema}
          onSubmit={values => {
            onSubmitIntegration(values);
          }}
        >
          {({ isValid }) => (
            <Form className="form-integration-shop">
              <div className="field-form">
                <span className="title">Nama Toko</span>
                <Field
                  name="name"
                  component={InputField}
                  placeholder="Masukkan nama toko"
                />
              </div>
              <div className="field-form">
                <span className="title">URL Toko</span>
                <Field
                  name="url"
                  component={InputField}
                  placeholder="Masukkan URL toko"
                />
                <span className="text-muted font-size-9">Masukan url toko anda umumnya
                  {" "}<i>https://tokopedia/nama-toko</i></span>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #C2C7CF",
                  margin: "20px 0px",
                }}
              />
              <div className="field-btn">
                <Button type="submit" color="primary" disabled={!isValid}>
                  Selanjutnya
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FirstStepInputShop;
