import React from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

const BackButton = ({ goTo }) => {
  const history = useHistory();

  const handleGoBack = () => {
    if (goTo) {
      history.push(goTo)
      return
    }
    history.goBack();
  };

  return (
    <div className="back-button" onClick={() => handleGoBack()}>
      <ArrowLeftIcon />
    </div>
  );
};

export default BackButton;
