import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import ListServiceKnowledge from "./ComponentsPage/ListServiceKnowledge";
import { get } from "network/http/api";
import ModalDelete from "./ComponentsPage/ModalDelete";
import { debounce } from "lodash";
import { notificationErrorRequestAction } from "store/notification/actions";
import ServiceKnowledgeSearch from "./ServiceKnowledgeSearch";
import { connect } from "react-redux";
import CustomPagination from "components/CustomPagination";

const ServiceKnowledgeList = ({ notificationError }) => {
  const [dataKnowledge, setDataknowledge] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [pickIdKnowledge, setPickIdKnowledge] = useState(null);
  const [loadingKnowledgeList, setLoadingKnowledgeList] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [query, setQuery] = useState("");
  const [paramsPage, setParamsPage] = useState({
    perPage: 15,
    page: 1,
    query: "",
  });

  const fetchServiceKnowledge = async () => {
    if (loadingKnowledgeList) {
      return;
    }

    setLoadingKnowledgeList(true);
    try {
      let params = `page=${paramsPage.page}&&per_page=${paramsPage.perPage}`;
      let url = `/knowledge-base`;
      if (query !== "") {
        url = `${url}/search?q=${query}`;
      } else {
        url = `${url}?${params}`;
      }

      const res = await get(url);
      setLoadingKnowledgeList(false);
      setDataknowledge(res.knowledges);
      const totalPage = res.total_item
        ? Math.ceil(res.total_item / paramsPage.perPage)
        : 0;
      setTotalPage(totalPage);
    } catch (err) {
      setLoadingKnowledgeList(false);
      console.error(err);
      notificationError("Terjadi kesalahan, mohon coba kembali");
    }
  };

  useEffect(() => {
    if (loadingKnowledgeList) return;

    const fetch = debounce(fetchServiceKnowledge, 500);
    fetch();

    return () => fetch.cancel();
  }, [query, paramsPage]);

  const handleInputChange = event => {
    setQuery(event.target.value);
  };

  const openModalDelete = () => {
    setShowModalDelete(!showModalDelete);
  };

  return (
    <div className="page-content-knowledge">
      <ServiceKnowledgeSearch onChange={handleInputChange} />
      <div className="service-knowledge-list-product-store">
        <ListServiceKnowledge
          data={dataKnowledge}
          openModalDelete={openModalDelete}
          setPickIdKnowledge={setPickIdKnowledge}
          paramsPage={paramsPage}
          setParamsPage={setParamsPage}
          amountPage={totalPage}
          loading={loadingKnowledgeList}
        />

        {!loadingKnowledgeList && dataKnowledge.length > 0 && (
          <CustomPagination
            paramsPage={paramsPage}
            setParamsPage={setParamsPage}
            amountPage={totalPage}
          />
        )}
      </div>

      <Modal
        isOpen={showModalDelete}
        toggle={openModalDelete}
        className="modal-cancel-edit"
        centered
      >
        <ModalDelete
          knowledgeId={pickIdKnowledge?.id}
          type="delete-knowledge"
          toggleModalDelete={openModalDelete}
          setTriggerFetchList={fetchServiceKnowledge}
        />
      </Modal>
    </div>
  );
};

export default connect(null, {
  notificationError: notificationErrorRequestAction,
})(ServiceKnowledgeList);
