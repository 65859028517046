const EmptyImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="184"
      height="110"
      viewBox="0 0 184 110"
      fill="none"
    >
      <path
        d="M176 0H8C3.58172 0 0 3.58172 0 8V102C0 106.418 3.58172 110 8 110H176C180.418 110 184 106.418 184 102V8C184 3.58172 180.418 0 176 0Z"
        fill="#E0E7EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.788 47.3604C108.364 47.3604 110.452 45.254 110.452 42.6558C110.452 40.0576 108.364 37.9512 105.788 37.9512C103.212 37.9512 101.123 40.0576 101.123 42.6558C101.123 45.254 103.212 47.3604 105.788 47.3604ZM92.8777 75.8488H66.034C64.4644 75.8488 63.5066 74.1236 64.3362 72.7914L82.238 44.0482C83.0084 42.8114 84.7947 42.7792 85.5969 43.9956C88.3967 48.241 94.1395 56.9646 99.0793 64.567L104.265 56.1918C105.018 54.9758 106.767 54.9202 107.595 56.0858L119.421 72.7194C120.357 74.0362 119.432 75.8622 117.816 75.8782L106.356 75.9908L92.8777 75.8488Z"
        fill="#94A2AB"
      />
    </svg>
  );
};

const RefreshIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      width={20}
      height={20}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
};

const RightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
      />
    </svg>
  );
};

const dataSet = [
  {
    id: 30,
    created_at: "2023-09-17T16:29:20.098451+00:00",
    updated_at: "2023-11-30T09:16:43.766543+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 6,
    name: "Baby Happy Pants",
    pl_product_id: "7167488960",
    parent_id: "1",
    product_url: "https://www.tokopedia.com/byanakmama/baby-happy-pants-xxl24",
    stock: 0,
    price: 47700.0,
    minimum_order: 1,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: true,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 26,
        created_at: "2023-09-17T16:29:20.170720+00:00",
        updated_at: "2023-11-30T09:16:43.910171+00:00",
        product_id: 30,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url:
          "https://images.tokopedia.net/img/cache/100-square/VqbcmM/2023/5/19/b62f69d3-2938-4513-9eb1-79bc482fb876.jpg.webp?ect=4g",
        thumbnail_url:
          "https://images.tokopedia.net/img/cache/200-square/VqbcmM/2023/2/14/98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        width: 640,
        height: 360,
        url300:
          "https://images.tokopedia.net/img/cache/300/VqbcmM/2023/2/14/98ceb28c-895c-4b00-843e-6264ec7978fe.png",
      },
    ],
    details: [
      {
        id: 145,
        created_at: "2023-09-17T16:29:20.161168+00:00",
        updated_at: "2023-09-17T16:29:20.161168+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "Biarkan si kecil bergerak aktif dan bebas dengan memakaikannya Baby Happy Pants XXL 24. Popok celana yang sangat elastis ini memiliki daya serap tinggi sehingga mampu menyerap cairan dengan cepat. Desainnya yang tipis membuat si kecil tetap nyaman ketika beraktivitas.\n\nTahan lama dipakai hingga 12 jam\n\nMemiliki super absorbent polymer yang mampu menyerap cairan lebih cepat sehingga permukaan tetap kering\n\nTeknologi wetness indicator, jika warna gambar gajah memudar pertanda popok harus segera diganti dengan yang baru",
        product_id: 30,
      },
      {
        id: 144,
        created_at: "2023-09-17T16:29:20.151138+00:00",
        updated_at: "2023-09-17T16:29:20.151138+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 30,
      },
      {
        id: 143,
        created_at: "2023-09-17T16:29:20.141025+00:00",
        updated_at: "2023-09-17T16:29:20.141025+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Popok Sekali Pakai",
        product_id: 30,
      },
      {
        id: 142,
        created_at: "2023-09-17T16:29:20.131062+00:00",
        updated_at: "2023-09-17T16:29:20.131062+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 30,
      },
      {
        id: 141,
        created_at: "2023-09-17T16:29:20.118430+00:00",
        updated_at: "2023-09-17T16:29:20.118430+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "2,5 kg",
        product_id: 30,
      },
      {
        id: 140,
        created_at: "2023-09-17T16:29:20.098451+00:00",
        updated_at: "2023-09-17T16:29:20.098451+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 30,
      },
    ],
    variant: null,
  },
  {
    id: 32,
    created_at: "2023-09-17T16:29:20.369100+00:00",
    updated_at: "2023-11-30T09:16:44.151982+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 1,
    name: "Nike Air Max SYSTM",
    pl_product_id: "6824931833",
    parent_id: "101",
    product_url:
      "https://www.tokopedia.com/sellerapi-risat/test-sepatu-pria-sneakers",
    stock: 12,
    price: 149999.0,
    minimum_order: null,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: false,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 28,
        created_at: "2023-09-17T16:29:20.433811+00:00",
        updated_at: "2023-11-30T09:16:44.256014+00:00",
        product_id: 32,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url:
          "https://images.tokopedia.net/img/cache/100-square/VqbcmM/2023/5/19/b62f69d3-2938-4513-9eb1-79bc482fb876.jpg.webp?ect=4g",
        thumbnail_url:
          "https://images.tokopedia.net/img/cache/200-square/VqbcmM/2023/2/14/98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        width: 640,
        height: 360,
        url300: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
      },
    ],
    details: [
      {
        id: 157,
        created_at: "2023-09-17T16:29:20.423229+00:00",
        updated_at: "2023-09-17T16:29:20.423229+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "NIKE Air Max SYSTM merupakan sepatu yang dapat Anda jadikan pilihan untuk berolahraga. \nTerbuat dari bahan pilihan berkualitas yang akan nyaman dipakai bahkan dalam waktu yang lama. Desain trendi dengan outsole yang rapi memberikan daya tahan yang lama dalam penggunaan.\n\nSepatu Wanita Nike Air Max SYSTM Terlihat Max. Terasa Maks. Air Max SYSTM menghadirkan kembali semua yang Anda sukai dari getaran tahun 80-an favorit Anda (tanpa celana parasut). \n\nPasangan bantalan Nike Air yang teruji dan terlihat nyata dengan bagian atas yang ramping dan terinspirasi dari olahraga. \n\nAir Max mengirimkan lagi. Gaya Vintage, Fitur Modern Terinspirasi oleh semua model Air Max favorit Anda dari tahun 80-an, warisan estetika dengan nuansa modern. \n\nKekuatan Bernapas Bagian atas bahan campuran memberikan daya tahan dan sirkulasi udara. Awalnya dirancang untuk performa lari, bantalan Max Air memberikan kenyamanan yang teruji waktu. Outsole karet menambah traksi yang tahan lama.\n\nSepatu ini tersedia dari berbagai macam ukuran yang dapat Anda sesuaikan dengan keinginan. Gunakan sepatu olahraga dari Nike ini sebagai pilihan Anda.\n\nSize Chart (US)\n*Pilihan Size di Web Menggunakan Ukuran US*\n\nSize US : 12,5\nUK : 10\nEU : 45\n(Panjang : 29,5 cm)\n\nSize US : 13\nUK : 10,5\nEU : 45,5\n(Panjang : 30 cm)",
        product_id: 32,
      },
      {
        id: 156,
        created_at: "2023-09-17T16:29:20.412790+00:00",
        updated_at: "2023-09-17T16:29:20.412790+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 32,
      },
      {
        id: 155,
        created_at: "2023-09-17T16:29:20.402113+00:00",
        updated_at: "2023-09-17T16:29:20.402113+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Sepatu",
        product_id: 32,
      },
      {
        id: 154,
        created_at: "2023-09-17T16:29:20.391442+00:00",
        updated_at: "2023-09-17T16:29:20.391442+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 32,
      },
      {
        id: 153,
        created_at: "2023-09-17T16:29:20.380672+00:00",
        updated_at: "2023-09-17T16:29:20.380672+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "1,5 kg",
        product_id: 32,
      },
      {
        id: 152,
        created_at: "2023-09-17T16:29:20.369100+00:00",
        updated_at: "2023-09-17T16:29:20.369100+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 32,
      },
    ],
    variant: null,
  },
  {
    id: 3,
    created_at: "2023-09-17T16:29:20.369100+00:00",
    updated_at: "2023-11-30T09:16:44.151982+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 1,
    name: "Nike Air Max SYSTM",
    pl_product_id: "6824931833",
    parent_id: "101",
    product_url:
      "https://www.tokopedia.com/sellerapi-risat/test-sepatu-pria-sneakers",
    stock: 12,
    price: 149999.0,
    minimum_order: null,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: false,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 28,
        created_at: "2023-09-17T16:29:20.433811+00:00",
        updated_at: "2023-11-30T09:16:44.256014+00:00",
        product_id: 32,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url:
          "https://images.tokopedia.net/img/cache/100-square/VqbcmM/2023/5/19/b62f69d3-2938-4513-9eb1-79bc482fb876.jpg.webp?ect=4g",
        thumbnail_url: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
        width: 640,
        height: 360,
        url300: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
      },
    ],
    details: [
      {
        id: 157,
        created_at: "2023-09-17T16:29:20.423229+00:00",
        updated_at: "2023-09-17T16:29:20.423229+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "NIKE Air Max SYSTM merupakan sepatu yang dapat Anda jadikan pilihan untuk berolahraga. \nTerbuat dari bahan pilihan berkualitas yang akan nyaman dipakai bahkan dalam waktu yang lama. Desain trendi dengan outsole yang rapi memberikan daya tahan yang lama dalam penggunaan.\n\nSepatu Wanita Nike Air Max SYSTM Terlihat Max. Terasa Maks. Air Max SYSTM menghadirkan kembali semua yang Anda sukai dari getaran tahun 80-an favorit Anda (tanpa celana parasut). \n\nPasangan bantalan Nike Air yang teruji dan terlihat nyata dengan bagian atas yang ramping dan terinspirasi dari olahraga. \n\nAir Max mengirimkan lagi. Gaya Vintage, Fitur Modern Terinspirasi oleh semua model Air Max favorit Anda dari tahun 80-an, warisan estetika dengan nuansa modern. \n\nKekuatan Bernapas Bagian atas bahan campuran memberikan daya tahan dan sirkulasi udara. Awalnya dirancang untuk performa lari, bantalan Max Air memberikan kenyamanan yang teruji waktu. Outsole karet menambah traksi yang tahan lama.\n\nSepatu ini tersedia dari berbagai macam ukuran yang dapat Anda sesuaikan dengan keinginan. Gunakan sepatu olahraga dari Nike ini sebagai pilihan Anda.\n\nSize Chart (US)\n*Pilihan Size di Web Menggunakan Ukuran US*\n\nSize US : 12,5\nUK : 10\nEU : 45\n(Panjang : 29,5 cm)\n\nSize US : 13\nUK : 10,5\nEU : 45,5\n(Panjang : 30 cm)",
        product_id: 32,
      },
      {
        id: 156,
        created_at: "2023-09-17T16:29:20.412790+00:00",
        updated_at: "2023-09-17T16:29:20.412790+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 32,
      },
      {
        id: 155,
        created_at: "2023-09-17T16:29:20.402113+00:00",
        updated_at: "2023-09-17T16:29:20.402113+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Sepatu",
        product_id: 32,
      },
      {
        id: 154,
        created_at: "2023-09-17T16:29:20.391442+00:00",
        updated_at: "2023-09-17T16:29:20.391442+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 32,
      },
      {
        id: 153,
        created_at: "2023-09-17T16:29:20.380672+00:00",
        updated_at: "2023-09-17T16:29:20.380672+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "1,5 kg",
        product_id: 32,
      },
      {
        id: 152,
        created_at: "2023-09-17T16:29:20.369100+00:00",
        updated_at: "2023-09-17T16:29:20.369100+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 32,
      },
    ],
    variant: null,
  },
  {
    id: 9,
    created_at: "2023-09-17T16:29:20.369100+00:00",
    updated_at: "2023-11-30T09:16:44.151982+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 1,
    name: "Nike Air Max SYSTM",
    pl_product_id: "6824931833",
    parent_id: "101",
    product_url:
      "https://www.tokopedia.com/sellerapi-risat/test-sepatu-pria-sneakers",
    stock: 12,
    price: 149999.0,
    minimum_order: null,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: false,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 28,
        created_at: "2023-09-17T16:29:20.433811+00:00",
        updated_at: "2023-11-30T09:16:44.256014+00:00",
        product_id: 32,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url:
          "https://images.tokopedia.net/img/cache/100-square/VqbcmM/2023/5/19/b62f69d3-2938-4513-9eb1-79bc482fb876.jpg.webp?ect=4g",
        thumbnail_url: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
        width: 640,
        height: 360,
        url300: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
      },
    ],
    details: [
      {
        id: 157,
        created_at: "2023-09-17T16:29:20.423229+00:00",
        updated_at: "2023-09-17T16:29:20.423229+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "NIKE Air Max SYSTM merupakan sepatu yang dapat Anda jadikan pilihan untuk berolahraga. \nTerbuat dari bahan pilihan berkualitas yang akan nyaman dipakai bahkan dalam waktu yang lama. Desain trendi dengan outsole yang rapi memberikan daya tahan yang lama dalam penggunaan.\n\nSepatu Wanita Nike Air Max SYSTM Terlihat Max. Terasa Maks. Air Max SYSTM menghadirkan kembali semua yang Anda sukai dari getaran tahun 80-an favorit Anda (tanpa celana parasut). \n\nPasangan bantalan Nike Air yang teruji dan terlihat nyata dengan bagian atas yang ramping dan terinspirasi dari olahraga. \n\nAir Max mengirimkan lagi. Gaya Vintage, Fitur Modern Terinspirasi oleh semua model Air Max favorit Anda dari tahun 80-an, warisan estetika dengan nuansa modern. \n\nKekuatan Bernapas Bagian atas bahan campuran memberikan daya tahan dan sirkulasi udara. Awalnya dirancang untuk performa lari, bantalan Max Air memberikan kenyamanan yang teruji waktu. Outsole karet menambah traksi yang tahan lama.\n\nSepatu ini tersedia dari berbagai macam ukuran yang dapat Anda sesuaikan dengan keinginan. Gunakan sepatu olahraga dari Nike ini sebagai pilihan Anda.\n\nSize Chart (US)\n*Pilihan Size di Web Menggunakan Ukuran US*\n\nSize US : 12,5\nUK : 10\nEU : 45\n(Panjang : 29,5 cm)\n\nSize US : 13\nUK : 10,5\nEU : 45,5\n(Panjang : 30 cm)",
        product_id: 32,
      },
      {
        id: 156,
        created_at: "2023-09-17T16:29:20.412790+00:00",
        updated_at: "2023-09-17T16:29:20.412790+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 32,
      },
      {
        id: 155,
        created_at: "2023-09-17T16:29:20.402113+00:00",
        updated_at: "2023-09-17T16:29:20.402113+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Sepatu",
        product_id: 32,
      },
      {
        id: 154,
        created_at: "2023-09-17T16:29:20.391442+00:00",
        updated_at: "2023-09-17T16:29:20.391442+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 32,
      },
      {
        id: 153,
        created_at: "2023-09-17T16:29:20.380672+00:00",
        updated_at: "2023-09-17T16:29:20.380672+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "1,5 kg",
        product_id: 32,
      },
      {
        id: 152,
        created_at: "2023-09-17T16:29:20.369100+00:00",
        updated_at: "2023-09-17T16:29:20.369100+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 32,
      },
    ],
    variant: null,
  },
  {
    id: 98,
    created_at: "2023-09-17T16:29:20.369100+00:00",
    updated_at: "2023-11-30T09:16:44.151982+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 1,
    name: "Nike Air Max SYSTM",
    pl_product_id: "6824931833",
    parent_id: "101",
    product_url:
      "https://www.tokopedia.com/sellerapi-risat/test-sepatu-pria-sneakers",
    stock: 12,
    price: 149999.0,
    minimum_order: null,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: false,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 28,
        created_at: "2023-09-17T16:29:20.433811+00:00",
        updated_at: "2023-11-30T09:16:44.256014+00:00",
        product_id: 32,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url:
          "https://images.tokopedia.net/img/cache/100-square/VqbcmM/2023/5/19/b62f69d3-2938-4513-9eb1-79bc482fb876.jpg.webp?ect=4g",
        thumbnail_url: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
        width: 640,
        height: 360,
        url300: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
      },
    ],
    details: [
      {
        id: 157,
        created_at: "2023-09-17T16:29:20.423229+00:00",
        updated_at: "2023-09-17T16:29:20.423229+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "NIKE Air Max SYSTM merupakan sepatu yang dapat Anda jadikan pilihan untuk berolahraga. \nTerbuat dari bahan pilihan berkualitas yang akan nyaman dipakai bahkan dalam waktu yang lama. Desain trendi dengan outsole yang rapi memberikan daya tahan yang lama dalam penggunaan.\n\nSepatu Wanita Nike Air Max SYSTM Terlihat Max. Terasa Maks. Air Max SYSTM menghadirkan kembali semua yang Anda sukai dari getaran tahun 80-an favorit Anda (tanpa celana parasut). \n\nPasangan bantalan Nike Air yang teruji dan terlihat nyata dengan bagian atas yang ramping dan terinspirasi dari olahraga. \n\nAir Max mengirimkan lagi. Gaya Vintage, Fitur Modern Terinspirasi oleh semua model Air Max favorit Anda dari tahun 80-an, warisan estetika dengan nuansa modern. \n\nKekuatan Bernapas Bagian atas bahan campuran memberikan daya tahan dan sirkulasi udara. Awalnya dirancang untuk performa lari, bantalan Max Air memberikan kenyamanan yang teruji waktu. Outsole karet menambah traksi yang tahan lama.\n\nSepatu ini tersedia dari berbagai macam ukuran yang dapat Anda sesuaikan dengan keinginan. Gunakan sepatu olahraga dari Nike ini sebagai pilihan Anda.\n\nSize Chart (US)\n*Pilihan Size di Web Menggunakan Ukuran US*\n\nSize US : 12,5\nUK : 10\nEU : 45\n(Panjang : 29,5 cm)\n\nSize US : 13\nUK : 10,5\nEU : 45,5\n(Panjang : 30 cm)",
        product_id: 32,
      },
      {
        id: 156,
        created_at: "2023-09-17T16:29:20.412790+00:00",
        updated_at: "2023-09-17T16:29:20.412790+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 32,
      },
      {
        id: 155,
        created_at: "2023-09-17T16:29:20.402113+00:00",
        updated_at: "2023-09-17T16:29:20.402113+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Sepatu",
        product_id: 32,
      },
      {
        id: 154,
        created_at: "2023-09-17T16:29:20.391442+00:00",
        updated_at: "2023-09-17T16:29:20.391442+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 32,
      },
      {
        id: 153,
        created_at: "2023-09-17T16:29:20.380672+00:00",
        updated_at: "2023-09-17T16:29:20.380672+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "1,5 kg",
        product_id: 32,
      },
      {
        id: 152,
        created_at: "2023-09-17T16:29:20.369100+00:00",
        updated_at: "2023-09-17T16:29:20.369100+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 32,
      },
    ],
    variant: null,
  },
];

const dataRekom = [
  {
    id: 30,
    created_at: "2023-09-17T16:29:20.098451+00:00",
    updated_at: "2023-11-30T09:16:43.766543+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 6,
    name: "Baby Happy Pants",
    pl_product_id: "7167488960",
    parent_id: "1",
    product_url: "https://www.tokopedia.com/byanakmama/baby-happy-pants-xxl24",
    stock: 0,
    price: 47700.0,
    minimum_order: 1,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: true,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 26,
        created_at: "2023-09-17T16:29:20.170720+00:00",
        updated_at: "2023-11-30T09:16:43.910171+00:00",
        product_id: 30,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url:
          "https://images.tokopedia.net/img/cache/100-square/VqbcmM/2023/5/19/b62f69d3-2938-4513-9eb1-79bc482fb876.jpg.webp?ect=4g",
        thumbnail_url:
          "https://images.tokopedia.net/img/cache/200-square/VqbcmM/2023/2/14/98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        width: 640,
        height: 360,
        url300:
          "https://images.tokopedia.net/img/cache/300/VqbcmM/2023/2/14/98ceb28c-895c-4b00-843e-6264ec7978fe.png",
      },
    ],
    details: [
      {
        id: 145,
        created_at: "2023-09-17T16:29:20.161168+00:00",
        updated_at: "2023-09-17T16:29:20.161168+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "Biarkan si kecil bergerak aktif dan bebas dengan memakaikannya Baby Happy Pants XXL 24. Popok celana yang sangat elastis ini memiliki daya serap tinggi sehingga mampu menyerap cairan dengan cepat. Desainnya yang tipis membuat si kecil tetap nyaman ketika beraktivitas.\n\nTahan lama dipakai hingga 12 jam\n\nMemiliki super absorbent polymer yang mampu menyerap cairan lebih cepat sehingga permukaan tetap kering\n\nTeknologi wetness indicator, jika warna gambar gajah memudar pertanda popok harus segera diganti dengan yang baru",
        product_id: 30,
      },
      {
        id: 144,
        created_at: "2023-09-17T16:29:20.151138+00:00",
        updated_at: "2023-09-17T16:29:20.151138+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 30,
      },
      {
        id: 143,
        created_at: "2023-09-17T16:29:20.141025+00:00",
        updated_at: "2023-09-17T16:29:20.141025+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Popok Sekali Pakai",
        product_id: 30,
      },
      {
        id: 142,
        created_at: "2023-09-17T16:29:20.131062+00:00",
        updated_at: "2023-09-17T16:29:20.131062+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 30,
      },
      {
        id: 141,
        created_at: "2023-09-17T16:29:20.118430+00:00",
        updated_at: "2023-09-17T16:29:20.118430+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "2,5 kg",
        product_id: 30,
      },
      {
        id: 140,
        created_at: "2023-09-17T16:29:20.098451+00:00",
        updated_at: "2023-09-17T16:29:20.098451+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 30,
      },
    ],
    variant: null,
  },
  {
    id: 32,
    created_at: "2023-09-17T16:29:20.369100+00:00",
    updated_at: "2023-11-30T09:16:44.151982+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 1,
    name: "Nike Air Max SYSTM",
    pl_product_id: "6824931833",
    parent_id: "101",
    product_url:
      "https://www.tokopedia.com/sellerapi-risat/test-sepatu-pria-sneakers",
    stock: 12,
    price: 149999.0,
    minimum_order: null,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: false,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 28,
        created_at: "2023-09-17T16:29:20.433811+00:00",
        updated_at: "2023-11-30T09:16:44.256014+00:00",
        product_id: 32,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
        thumbnail_url: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
        width: 640,
        height: 360,
        url300: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
      },
    ],
    details: [
      {
        id: 157,
        created_at: "2023-09-17T16:29:20.423229+00:00",
        updated_at: "2023-09-17T16:29:20.423229+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "NIKE Air Max SYSTM merupakan sepatu yang dapat Anda jadikan pilihan untuk berolahraga. \nTerbuat dari bahan pilihan berkualitas yang akan nyaman dipakai bahkan dalam waktu yang lama. Desain trendi dengan outsole yang rapi memberikan daya tahan yang lama dalam penggunaan.\n\nSepatu Wanita Nike Air Max SYSTM Terlihat Max. Terasa Maks. Air Max SYSTM menghadirkan kembali semua yang Anda sukai dari getaran tahun 80-an favorit Anda (tanpa celana parasut). \n\nPasangan bantalan Nike Air yang teruji dan terlihat nyata dengan bagian atas yang ramping dan terinspirasi dari olahraga. \n\nAir Max mengirimkan lagi. Gaya Vintage, Fitur Modern Terinspirasi oleh semua model Air Max favorit Anda dari tahun 80-an, warisan estetika dengan nuansa modern. \n\nKekuatan Bernapas Bagian atas bahan campuran memberikan daya tahan dan sirkulasi udara. Awalnya dirancang untuk performa lari, bantalan Max Air memberikan kenyamanan yang teruji waktu. Outsole karet menambah traksi yang tahan lama.\n\nSepatu ini tersedia dari berbagai macam ukuran yang dapat Anda sesuaikan dengan keinginan. Gunakan sepatu olahraga dari Nike ini sebagai pilihan Anda.\n\nSize Chart (US)\n*Pilihan Size di Web Menggunakan Ukuran US*\n\nSize US : 12,5\nUK : 10\nEU : 45\n(Panjang : 29,5 cm)\n\nSize US : 13\nUK : 10,5\nEU : 45,5\n(Panjang : 30 cm)",
        product_id: 32,
      },
      {
        id: 156,
        created_at: "2023-09-17T16:29:20.412790+00:00",
        updated_at: "2023-09-17T16:29:20.412790+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 32,
      },
      {
        id: 155,
        created_at: "2023-09-17T16:29:20.402113+00:00",
        updated_at: "2023-09-17T16:29:20.402113+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Sepatu",
        product_id: 32,
      },
      {
        id: 154,
        created_at: "2023-09-17T16:29:20.391442+00:00",
        updated_at: "2023-09-17T16:29:20.391442+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 32,
      },
      {
        id: 153,
        created_at: "2023-09-17T16:29:20.380672+00:00",
        updated_at: "2023-09-17T16:29:20.380672+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "1,5 kg",
        product_id: 32,
      },
      {
        id: 152,
        created_at: "2023-09-17T16:29:20.369100+00:00",
        updated_at: "2023-09-17T16:29:20.369100+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 32,
      },
    ],
    variant: null,
  },
  {
    id: 3,
    created_at: "2023-09-17T16:29:20.369100+00:00",
    updated_at: "2023-11-30T09:16:44.151982+00:00",
    deleted_at: null,
    platform_id: 5,
    store_id: 1,
    company_id: 120,
    category_id: 1,
    name: "Nike Sama Datanya",
    pl_product_id: "6824931833",
    parent_id: "101",
    product_url:
      "https://www.tokopedia.com/sellerapi-risat/test-sepatu-pria-sneakers",
    stock: 12,
    price: 149999.0,
    minimum_order: null,
    is_cod: false,
    is_parent: true,
    option_ids: null,
    is_variant: false,
    status: 1,
    campaign: null,
    category: null,
    pictures: [
      {
        id: 28,
        created_at: "2023-09-17T16:29:20.433811+00:00",
        updated_at: "2023-11-30T09:16:44.256014+00:00",
        product_id: 32,
        file_name: "98ceb28c-895c-4b00-843e-6264ec7978fe.png",
        file_path: "VqbcmM/2023/2/14",
        original_url: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
        thumbnail_url: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
        width: 640,
        height: 360,
        url300: "https://staging-api.risa.ai/file/image/nike-systm.jpeg",
      },
    ],
    details: [
      {
        id: 157,
        created_at: "2023-09-17T16:29:20.423229+00:00",
        updated_at: "2023-09-17T16:29:20.423229+00:00",
        deleted_at: null,
        title: "Deskripsi",
        text: "NIKE Air Max SYSTM merupakan sepatu yang dapat Anda jadikan pilihan untuk berolahraga. \nTerbuat dari bahan pilihan berkualitas yang akan nyaman dipakai bahkan dalam waktu yang lama. Desain trendi dengan outsole yang rapi memberikan daya tahan yang lama dalam penggunaan.\n\nSepatu Wanita Nike Air Max SYSTM Terlihat Max. Terasa Maks. Air Max SYSTM menghadirkan kembali semua yang Anda sukai dari getaran tahun 80-an favorit Anda (tanpa celana parasut). \n\nPasangan bantalan Nike Air yang teruji dan terlihat nyata dengan bagian atas yang ramping dan terinspirasi dari olahraga. \n\nAir Max mengirimkan lagi. Gaya Vintage, Fitur Modern Terinspirasi oleh semua model Air Max favorit Anda dari tahun 80-an, warisan estetika dengan nuansa modern. \n\nKekuatan Bernapas Bagian atas bahan campuran memberikan daya tahan dan sirkulasi udara. Awalnya dirancang untuk performa lari, bantalan Max Air memberikan kenyamanan yang teruji waktu. Outsole karet menambah traksi yang tahan lama.\n\nSepatu ini tersedia dari berbagai macam ukuran yang dapat Anda sesuaikan dengan keinginan. Gunakan sepatu olahraga dari Nike ini sebagai pilihan Anda.\n\nSize Chart (US)\n*Pilihan Size di Web Menggunakan Ukuran US*\n\nSize US : 12,5\nUK : 10\nEU : 45\n(Panjang : 29,5 cm)\n\nSize US : 13\nUK : 10,5\nEU : 45,5\n(Panjang : 30 cm)",
        product_id: 32,
      },
      {
        id: 156,
        created_at: "2023-09-17T16:29:20.412790+00:00",
        updated_at: "2023-09-17T16:29:20.412790+00:00",
        deleted_at: null,
        title: "Etalase",
        text: "Semua Etalase",
        product_id: 32,
      },
      {
        id: 155,
        created_at: "2023-09-17T16:29:20.402113+00:00",
        updated_at: "2023-09-17T16:29:20.402113+00:00",
        deleted_at: null,
        title: "Kategori",
        text: "Sepatu",
        product_id: 32,
      },
      {
        id: 154,
        created_at: "2023-09-17T16:29:20.391442+00:00",
        updated_at: "2023-09-17T16:29:20.391442+00:00",
        deleted_at: null,
        title: "Min. Pemesanan",
        text: "1 Buah",
        product_id: 32,
      },
      {
        id: 153,
        created_at: "2023-09-17T16:29:20.380672+00:00",
        updated_at: "2023-09-17T16:29:20.380672+00:00",
        deleted_at: null,
        title: "Berat Satuan",
        text: "1,5 kg",
        product_id: 32,
      },
      {
        id: 152,
        created_at: "2023-09-17T16:29:20.369100+00:00",
        updated_at: "2023-09-17T16:29:20.369100+00:00",
        deleted_at: null,
        title: "Kondisi",
        text: "Baru",
        product_id: 32,
      },
    ],
    variant: null,
  },
];

const dataSelcted = [
  { name: "Penjualaan", value: "penjualaan" },
  { name: "Pembelian", value: "pembelian" },
  { name: "Stock", value: "stock" },
  { name: "Pengiriman", value: "pengiriman" },
  { name: "Akun", value: "akun" },
  { name: "Discount", value: "discount" },
];

export {
  EmptyImage,
  RefreshIcon,
  RightArrowIcon,
  dataSet,
  dataRekom,
  dataSelcted,
};
