import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";
import { isEmpty } from "lodash";

//Import actions
import { getStoreProducts } from "store/product/actions";
import { any } from "prop-types";
import ProductPagination from "components/Product/ProductPagination";
import ProductCardItem from "components/Product/ProductCardItem";


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StoreProducts = ({ history, match, products,
  totalPage,
  store,
  productIsFetching,
  onGetStoreProducts }) => {
  const [page, setPage] = useState(0)
  let query = useQuery()

  useEffect(() => {
    if (productIsFetching) {
      return
    }

    let newPage = query.get('page')
    if (!newPage) {
      newPage = 1
    }

    if (newPage === page) {
      return
    }

    newPage = parseInt(newPage)
    setPage(parseInt(newPage))
    fetchProductPage(match, newPage)
  }, [match, query])

  const fetchProductPage = (match, page) => {
    const { params, path } = match
    const { id } = params
    onGetStoreProducts(id, page)
  }

  const onChangePage = (newPage) => {
    let { url } = match
    if (newPage == 'next') {
      newPage = parseInt(page) + 1
    } else if (newPage == 'last') {
      newPage = totalPage
    }

    url = `${url}?page=${newPage}`
    history.push(url)
  }

  return (
    <Row>
      <Col lg="12">
        <Row>
          {!isEmpty(products) &&
            products.map((product, key) => (
              <ProductCardItem
                history={history}
                key={product.id}
                product={product} />
            ))}
        </Row>
      </Col>
      <Col lg="12">
        <ProductPagination
          onClick={onChangePage}
          page={page}
          products={products} />
      </Col>
    </Row>
  );
}

StoreProducts.propTypes = {
  products: PropTypes.array,
  history: any,
  onGetProducts: PropTypes.func,
};

const mapStateToProps = ({ Product }) => ({
  products: Product.products,
  productIsFetching: Product.productIsFetching,
  totalPage: Product.totalPage,
  title: Product.title,
});


export default connect(
  mapStateToProps,
  {
    onGetStoreProducts: getStoreProducts,
  }
)(withRouter(StoreProducts));
