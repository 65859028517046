import React from "react";

const SummaryContent = ({
  title,
  description,
  type,
  weekday,
  saturday,
  sunday,
}) => {
  if (type === "schedule") {
    return (
      <div className="summary-content">
        <span className="title">{title}</span>
        <span className="description">Senin - Jumat maksimal {weekday} </span>
        <span className="description">Sabtu maksimal {saturday} </span>
        <span className="description">Minggu maksimal {sunday} </span>
      </div>
    );
  }

  return (
    <div className="summary-content">
      <span className="title">{title}</span>
      {description ? (
        <span
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : (
        <span className="description">-</span>
      )}
    </div>
  );
};

export default SummaryContent;
