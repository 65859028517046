import React, { useState, useRef, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const DropdownCheckbox = ({ list, selectedItems, setSelectedItems }) => {
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = item => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem.id !== item.id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleOnChecked = (item) => {

    let selected = false
    selectedItems.map(v => {
      if (item.id === v.id) {
        selected = true
      }
    })
    console.log('item', item, selectedItems, selected)
    return selected
  }

  return (
    <div className="dropdown-checkbox-costum" ref={modalRef}>
      <div className="dropdown-checkbox" onClick={handleToggleDropdown}>
        <span className="dropdown-toggle">
          Kategori{" "}
          {selectedItems.length ? `Terpilih ${selectedItems.length}` : ""}
        </span>
        <ChevronDownIcon className="chevron-down" />
      </div>
      {isOpen && (
        <div className="dropdown-checkbox-list">
          {Boolean(list?.length) && (
            <>
              {list.map((item, index) => (
                <label key={index} className="checkbox-label">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    value={item.name}
                    checked={handleOnChecked(item)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  {item.name}
                </label>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownCheckbox;
