import React, { useEffect, useState } from "react";
import EmptySummaryDashboard from "./EmptySummaryDashboard";
import CardsProductStore from "../ComponentsPage/CardsProductStore";
import { get } from "network/http/api";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { notificationErrorRequestAction } from "store/notification/actions";
import ListStoreProduct from "./ListStoreProduct";

const ListStoreProductContainer = ({
  user,
  notificationError,
  sycnProduct,
}) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleOnFetchProduct();
  }, [user, sycnProduct]);

  const handleOnFetchProduct = () => {
    if (!user.company_id) return;

    setIsLoading(true);
    get(`/product/company/${user.company_id}`)
      .then(resp => {
        const { products } = resp;
        setProducts(products);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        notificationError("Terjadi kesalahan, mohon refresh halaman ini");
        setIsLoading(false);
      });
  };

  return <ListStoreProduct products={products} isLoading={isLoading} />;
};

const mapStateToProps = ({ Profile }) => {
  return {
    user: Profile.user,
  };
};

export default connect(mapStateToProps, {
  notificationError: notificationErrorRequestAction,
})(ListStoreProductContainer);
