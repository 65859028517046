import { call, put, takeEvery } from "redux-saga/effects"

// Redux States
import { NOTIFICATION_ADD_TOKEN_WEB_REQUEST } from "./actionTypes"

// http api
import * as api from './api'


function* addNotificationWebToken({ payload }) {
  try {
    const response = yield call(api.addNotificationWebToken, payload)
    console.log("Success register token web")
  } catch (error) {
    console.error("Failed to register token web", error)
  }
}

function* notificationSaga() {
  yield takeEvery(NOTIFICATION_ADD_TOKEN_WEB_REQUEST, addNotificationWebToken)
}

export default notificationSaga