import React from "react";
// 👇 you need to import the reactflow styles
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import AgentBuilder from "components/CustomScenario/Builder";
import withAuthentication from "network/http/withAuthentication";
import "reactflow/dist/style.css";

function DiagramFlow({ history, match }) {
  document.title = "AI Builder | Risa AI";

  return (
    <React.Fragment>
      <AgentBuilder />
    </React.Fragment>
  );
}

const AuthenticatedDiagramFlow = withAuthentication(DiagramFlow);

export default withRouter(connect(null, {})(AuthenticatedDiagramFlow));
