import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
initializeApp(firebaseConfig);
const messaging = getMessaging();

export const Sendrequest = (notificationAddTokenRequest) => {
  Notification.requestPermission().then(async permission => {
    if (permission === "granted") {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
        if (currentToken) {
          console.log("Client Token: ", currentToken);
          notificationAddTokenRequest(currentToken)
        } else {
          console.error("Failed to generate the registration token.");
        }
      } catch (err) {
        console.log(
          "An error occurred when requesting to receive the token.",
          err
        );
      }
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
