import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { Input, InputGroup } from "reactstrap";

const InputSelected = ({ options, name, defaultValue }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  useEffect(() => {
    // Set nilai default jika belum terisi
    if (!values[name] && defaultValue) {
      setFieldValue(name, defaultValue);
    }
  }, [defaultValue, name, setFieldValue, values]);

  return (
    <InputGroup className="field-input-selected">
      <Field
        type="select"
        name={name}
        as={Input}
        value={values[name]}
        className={`field-input ${
          errors[name] && touched[name] ? "error" : ""
        }`}
      >
        {options.map((item, key) => (
          <option key={key} value={item.value}>
            {item.label}
          </option>
        ))}
      </Field>
      {touched[name] && errors[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </InputGroup>
  );
};

export default InputSelected;
