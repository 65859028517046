import { call, put, select, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_AGENT_INTENT, UPDATE_AGENT_INTENT } from "./actionTypes"
import {
  getAgentIntentFail,
  getAgentIntentSuccess,
  updateAgentIntentFail,
  updateAgentIntentSuccess
} from "./actions"

//Include Both Helper File with needed methods
import * as api from "./api"

function* fetchAgentIntents() {
  try {
    console.log('fetchAgentIntents')
    const response = yield call(api.getAgentIntent)
    yield put(getAgentIntentSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(getAgentIntentFail(error))
  }
}

function* onUpdateAgentIntent({payload}) {
  try {
    const response = yield call(api.updateAgentIntent, payload)
    yield put(updateAgentIntentSuccess(response))
  } catch (error) {
    yield put(updateAgentIntentFail(error))
  }
}

function* agentIntentSaga() {
  yield takeEvery(GET_AGENT_INTENT, fetchAgentIntents)
  yield takeEvery(UPDATE_AGENT_INTENT, onUpdateAgentIntent)
}

export default agentIntentSaga
