import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { JWT_TOKEN_KEY } from "./jwt-token-access";

const withAuthentication = WrappedComponent => {
  const AuthenticatedComponent = props => {
    const history = useHistory();

    useEffect(() => {
      const token = localStorage.getItem(JWT_TOKEN_KEY);

      if (!token) {
        history.push("/login");
      }
    }, []);

    return <WrappedComponent {...props} />;
  };

  return AuthenticatedComponent;
};

export default withAuthentication;
