import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, Input, InputGroup, InputGroupText, UncontrolledAlert } from 'reactstrap'
import { v4 as uuidv4 } from "uuid";
import { connect } from 'react-redux';
import TextEditor from '../TextEditor/TextEditor'

// actions
import { nodeUpdateVariable } from 'store/agent-scenario-builder/actions'
import { checkDuplicateVariableName } from '../utils';
import { debounce } from 'lodash';
import ErrorMessageInput from './ErrorMessageInput';

const variableOptions = {
    'Nama': 1,
    'Alamat email': 2,
    'Nomor telepon': 3,
    'Teks bebas': 0,
    'Numerik': 4,
    'Single Choice': 6,
}

const AskQuestionConfig = ({ node, variables, onChange, variableNames, nodeUpdateVariable }) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const actionUpdateVariableNames = debounce(nodeUpdateVariable, 300)

    useEffect(() => {
        if (variables && variables.length > 0) {
            return
        }
        variables = [{ id: uuidv4(), 'ai_node_id': node.id, 'question': '' }]
        onChange(variables)
    }, [])

    const handleOnChangeQuestion = useCallback((value) => {
        variables[0]['question'] = value

        onChange(variables)
    }, [variables])

    const handleOnChangeValidation = useCallback(e => {
        let value = e.target.value
        variables[0]['validation_type'] = value
        onChange(variables)
    }, [node])

    const handleOnChangeVariable = useCallback(e => {
        let value = e.target.value

        if (checkDuplicateVariableName(node.id, variableNames, value)) {
            setErrorMessage(`Variable ${value} telah digunakan.`)
        } else {
            setErrorMessage(null)
        }

        value = value.replace(" ", "_")
        variables[0]['variable_name'] = value
        variableNames[node.id] = value

        actionUpdateVariableNames(variableNames)
        onChange(variables)

    }, [node, variableNames])

    return (
        <div className='ask-question-config'>
            <Card>
                <CardBody>
                    <div className='input'>
                        <TextEditor
                            text={variables[0].question}
                            onChange={(e) => handleOnChangeQuestion(e)}
                        />
                    </div>
                </CardBody>
            </Card>
            <ErrorMessageInput
                nodeId={node.id}
                data={variables[0].question}
                errorMessage="Mohon berikan isian Anda terlebih dahulu" />
            <Card className='validation'>
                <CardBody>
                    <p className='title'>Jenis validasi jawaban</p>
                    <p className='subtitle'>
                        Pilih jenis data yang Anda harapkan untuk disimpan dari jawaban customer.
                    </p>
                    <Input onChange={handleOnChangeValidation} value={variables[0].validation_type} type='select'>
                        {Object.keys(variableOptions).map(key => (
                            <option key={key} value={variableOptions[key]}>
                                {key}
                            </option>
                        ))}
                    </Input>
                </CardBody>
            </Card>
            <ErrorMessageInput
                nodeId={node.id}
                data={variables[0].validation_type}
                errorMessage="Silakan pilih jenis validasi jawaban terlebih dahulu" />
            <Card className='variable'>
                <CardBody>
                    <p className='title'>Simpan jawaban ke variabel</p>
                    <p className='subtitle'>
                        Pilih jenis data yang Anda harapkan untuk disimpan dari jawaban customer.
                    </p>
                    <InputGroup>
                        <InputGroupText>@</InputGroupText>
                        <Input onChange={handleOnChangeVariable} value={variables[0].variable_name} >
                        </Input>
                    </InputGroup>
                    {errorMessage &&
                        <UncontrolledAlert className='mt-2' color='danger'>
                            {errorMessage}
                        </UncontrolledAlert>
                    }
                </CardBody>
            </Card>
        </div>
    )

}

const mapStateToProps = (state) => ({
    variableNames: state.AgentScenarioBuilder.variableNames
})

export default connect(mapStateToProps, {
    nodeUpdateVariable
})(AskQuestionConfig)