import React from "react";
import risa_logo from "../../assets/images/risa_logo.png";
import login_assets from "../../assets/images/login_logo.svg";

const SectionImage = () => {
  return (
    <div className="section-image">
      <div lg={6} xl={6} className="section-image-banner">
        <div
          onClick={() => window.location.reload("/")}
          className="position-absolute top-0 start-0"
          style={{ padding: "50px 30px", cursor: "pointer" }}
        >
          <img
            src={risa_logo}
            alt="risa logo register"
            style={{ width: "100px", height: "20px" }}
          />
        </div>
        <img
          src={login_assets}
          alt=""
          style={{ width: "500px", height: "auto" }}
        />
      </div>
    </div>
  );
};

export default SectionImage;
