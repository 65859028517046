import { PencilIcon } from '@heroicons/react/24/solid';
import { convertInt2Money } from 'components/PlanService';
import { put } from 'network/http/api';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, InputGroup, InputGroupText, Label, Spinner } from 'reactstrap';
import { notificationErrorRequestAction, notificationSuccessRequestAction } from 'store/notification/actions';

const truncateString = (str, maxLength = 120) => {
  if (str && str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

const ReferralCard = ({ referral, balance }) => {
  const [webhookURL, setWebhookURL] = useState(referral.webhook_url || '')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setWebhookURL(referral.webhook_url)
  }, [referral])

  const handleOnUpdate = () => {
    const data = {
      code: '',
      balance: '',
      webhook_url: webhookURL,
    }
    setIsLoading(true)

    put('/referral', data)
      .then(resp => {
        setIsLoading(false)
        dispatch(notificationSuccessRequestAction("Berhasil memperbarui webhook"))
      })
      .catch(err => {
        setIsLoading(false)
        console.error(err)
        dispatch(notificationErrorRequestAction("Maaf terjadi masalah, mohon coba kembali"))
      })
  }

  if (!referral) {
    return
  }

  return (
    <div className="referral-code-container">
      <h2 className="referral-code-title">Referral Information</h2>
      <div className="referral-code-content">
        <div className="referral-code-item">
          <span className="label">Code:</span>
          <span className="value bold">{referral.code || '-'}</span>
        </div>
        <div className="referral-code-item">
          <span className="label">Balance:</span>
          <span className="value"> {convertInt2Money(balance.balance) || '0'}</span>
        </div>
        <div className="referral-code-item">
          <span className="label">Total Issued:</span>
          <span className="value"> {convertInt2Money(balance.paid_balance) || '0'}</span>
        </div>
        <div className='referral-code-item referral-code-input'>
          <InputGroup>
            <InputGroupText>Webhook URL</InputGroupText>
            <Input disabled={isLoading} onChange={(e) => setWebhookURL(e.target.value)} value={webhookURL} />
          </InputGroup>
          <br />
          <Button onClick={handleOnUpdate} disabled={isLoading} color='primary'>
            {isLoading ? <Spinner /> : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReferralCard;