import React, { useState } from "react";
import { Modal, Alert, Button, Spinner } from "reactstrap";
import { post } from "network/http/api";
import { XMarkIcon } from "@heroicons/react/24/solid";

const ModalAddtionalQuota = ({ billing, isOpen, toggle, setTriggerFetch }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const packageInfo = [
    "Anda hanya bisa membeli paket tambahan jika sudah berlangganan ke paket berbayar",
    "Jika Anda belum berlangganan sebelumnya ke paket berbayar, Anda harus berlangganan terlebih dahulu",
  ];

  const onUpgradePackage = async paramsValue => {
    if (isLoading) {
      return;
    }

    const payload = {
      plan_name: paramsValue.plan_name,
      is_yearly: paramsValue.is_yearly,
    };

    setIsLoading(true);
    await post(`/payment/additional-quota`, payload)
      .then(async resp => {
        window.open(resp, "_blank");
        setTriggerFetch(true);
        toggle();
        setIsLoading(false);
        window.location.reload();
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="section-modal-billing"
    >
      <div className="section-content-modal-billing">
        <div className="mobile-header">
          <div className="content">
            <div className="icon-close">
              <XMarkIcon onClick={toggle} />
            </div>
            <div className="title">Pilih Paket</div>
            <div />
          </div>
        </div>
        <span className="title">Pilih Paket Tambahan</span>
        <span className="sub-title">
          Konfirmasi dan selesaikan proses upgrade paket berlangganan
        </span>
        <div className="section-package-confirmation">
          <div className="section-addtional-quota">
            <span className="title">Paket Tambahan</span>
            <div className="content-description">
              <span className="feature-content">
                <i className="fa fa-check-circle" aria-hidden="true" />
                Kuota pesan: 1.000 pesan per bulan
              </span>
              <span className="feature-content">
                <i className="fa fa-check-circle" aria-hidden="true" />
                Kuota produk: 100 produk
              </span>
            </div>
            <div className="content-price">
              <span className="price">Rp100.000</span>
            </div>
          </div>
        </div>
        <ul className="section-list">
          {packageInfo.map((itm, idx) => {
            return <li key={idx}>{itm}</li>;
          })}
        </ul>
        {error && <Alert color="danger">{error.detail}</Alert>}
        {isLoading && (
          <div className="loading">
            <Spinner color="primary" />
          </div>
        )}
        <div className="section-button">
          <Button
            onClick={() => onUpgradePackage(billing)}
            color="primary"
            className="button"
            style={{ backgroundColor: "#24b39b", color: "#fff" }}
          >
            Tambah Paket
          </Button>
          <span onClick={toggle}>Batal</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddtionalQuota;
