import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const ServiceKnowledgeSearch = ({ onChange }) => {
    return (
        <div className="service-knowledge-product-store">
            <span className="input-title">
                Ingin mencari sesuatu terkait pengetahuan layanan?
            </span>
            <div className="input-search-service-knowledge">
                <div className="input-icon">
                    <MagnifyingGlassIcon className="icon" aria-hidden="true" />
                </div>
                <input
                    type="text"
                    name="query"
                    id="query"
                    className="input-search"
                    placeholder="Cari pengetahuan layanan disini"
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default ServiceKnowledgeSearch