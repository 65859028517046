import React from "react";
import risa_logo from "../../assets/images/risa-ai_logo_black.png";

const SectionMobileLogo = () => {
  return (
    <div onClick={() => window.location.reload("/")} className="mobile-logo">
      <img
        src={risa_logo}
        alt="risa logo register"
        style={{ width: "100px", height: "20px" }}
      />
    </div>
  );
};

export default SectionMobileLogo;
