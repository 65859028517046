import React, { useCallback, useState } from "react";
import { EditorState, Modifier } from 'draft-js';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap";
import { connect } from "react-redux";


const CustomOption = ({ editorState, onChange, variableNames }) => {
    const [isOpen, setIsOpen] = useState(false)

    const addVariable = (val) => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ` @${val} `,
            editorState.getCurrentInlineStyle(),
        );
        setIsOpen(false)
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    }

    return (
        <div className="toolbar-variable" >
            <Dropdown
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)} direction="end">
                <DropdownToggle
                    // color="primary"
                    outline
                >
                    Variable
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(variableNames).map((key) => (
                        <DropdownItem className="variable-item"
                            onClick={() => addVariable(variableNames[key])}
                            key={key}>
                            <span >{variableNames[key]}</span>
                        </DropdownItem>
                    ))}

                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        variableNames: state.AgentScenarioBuilder.variableNames
    }
}

export default connect(mapStateToProps)(CustomOption)