import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";

//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// Token Access
import { AUTH_USER_KEY } from "network/http/jwt-token-access";
import WebSocketStatus from "components/WebSocket/WebSocketStatus";
import EmbedChat from "components/EmbedChat";
import useIsMobile from "hooks/useIsMobile";

const Chat = () => {
  const [user, setUser] = useState({});
  const [webSocketInit, setWebSocketInit] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(AUTH_USER_KEY));
    setUser(user);
  }, []);

  useEffect(() => {
    // meta title
    document.title = "Chat | Risa";
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <WebSocketStatus />

          <div
            className="w-100 user-chat"
            style={{
              height: isMobile ? "100vh" : "80vh",
              position: "relative",
            }}
          >
            <EmbedChat location="/omnichannel" />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Chat;
