import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { getAgentIntent } from "store/actions";
import { getShop } from "store/shop/actions";
import { selectListAgentIntent } from "store/agent-intent/reducer";
import { get } from "network/http/api";
import { Button, Container, Spinner, UncontrolledAlert } from "reactstrap";
import ListIntegration from "./ListIntegration";
import "moment/locale/id";
import RightSideBarIntegration from "./ComponentsPage/RightSideBarIntegration";

moment.locale("id");

const Integration = props => {
  const { intents, isUpdating } = props;
  const [isError, setIsError] = useState(false);
  const [loading, isLoading] = useState(false);
  const [dataListScenario, setDataListScenario] = useState([]);
  const [showRightSidebar, setShowRightSidebar] = useState({
    show: false,
    data: null,
  });

  useEffect(() => {
    const fetchListScenario = async () => {
      isLoading(true);
      try {
        const res = await get("/integration");
        const platformData = {};
        const originalDataPlatform = res.platforms;
        originalDataPlatform.forEach(platform => {
          const platformName = platform.platform_name;
          platformData[platformName] = {
            ...platform,
            integration: res[platformName] || null,
          };
        });
        const newArray = Object.values(platformData);
        const result = res ? newArray : [];

        setDataListScenario(result);
        setIsError(false);
        isLoading(false);
      } catch (err) {
        setIsError(true);
        isLoading(false);
      }
    };

    fetchListScenario();
  }, []);

  return (
    <div className="page-content page-integration">
      <Container fluid>
        {isError && (
          <UncontrolledAlert color="danger">
            Something wrong please refresh this page
          </UncontrolledAlert>
        )}
        <h1 className="title-page">Integration</h1>
        <span className="subtitle-page">
          Hubungkan platform bisnis Anda dengan memilih platform yang ingin
          dihubungkan dengan sistem RISA AI.{" "}
        </span>
        <ListIntegration
          data={dataListScenario}
          loading={loading}
          setShowRightSidebar={setShowRightSidebar}
        />
      </Container>
      {/* <AIAAssitantProfile intents={intents} isUpdating={isUpdating} /> */}
      {showRightSidebar.show && (
        <RightSideBarIntegration
          showRightSidebar={showRightSidebar}
          setShowRightSidebar={setShowRightSidebar}
        />
      )}

      {isUpdating && (
        <div style={{ textAlign: "center" }}>
          <Spinner color="info" type="grow">
            Updating..
          </Spinner>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { AIAssistant } = state;
  let intens = selectListAgentIntent(state);
  let isUpdating = state.AgentIntent.isUpdating;

  return {
    intents: intens,
    isUpdating: isUpdating,
    profile: AIAssistant.profile,
    isFetching: AIAssistant.isFetching,
    error: AIAssistant.error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getShop,
    getAgentIntent,
  })(Integration)
);
