import {
  GET_QNA_DICTIONARY_PRODUCT,
  GET_QNA_DICTIONARY_PRODUCT_FAIL,
  GET_QNA_DICTIONARY_PRODUCT_SUCCESS,
  DELETE_QNA_DICTIONARY,
  DELETE_QNA_DICTIONARY_SUCCESS,
  DELETE_QNA_DICTIONARY_FAIL,
} from "./actionTypes"

export const getQnaDictionaryProduct = (id) => ({
  type: GET_QNA_DICTIONARY_PRODUCT,
  payload: id,
})

export const getQnaDictionarySuccess = dictionaries => ({
  type: GET_QNA_DICTIONARY_PRODUCT_SUCCESS,
  payload: dictionaries,
})

export const getQnaDictionaryFail = error => ({
  type: GET_QNA_DICTIONARY_PRODUCT_FAIL,
  payload: error,
})

export const deleteQnaDictionary = project => ({
  type: DELETE_QNA_DICTIONARY,
  payload: project,
})

export const deleteQnaDictionarySuccess = project => ({
  type: DELETE_QNA_DICTIONARY_SUCCESS,
  payload: project,
})

export const deleteQnaDictionaryFail = error => ({
  type: DELETE_QNA_DICTIONARY_FAIL,
  payload: error,
})
