import { convertInt2Money } from "components/Product/ProductCardItem";
import { get, post } from "network/http/api";
import React, { useEffect, useState } from "react";
import { Spinner, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/solid";

const ChoosePackageNewMember = ({ currentPlanId, toggle, setTriggerFetch }) => {
  const history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState("monthly");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState("");

  const menuPackage = [
    {
      index: 0,
      value: "monthly",
      label: "Bulanan",
      is_yearly: false,
      priceSuffix: "per bulan",
    },
    {
      index: 1,
      value: "annually",
      label: "Tahunan",
      is_yearly: true,
      priceSuffix: "per tahun",
    },
    {
      index: 2,
      value: "free",
      label: "Gratis",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    get("/auth/plan-service")
      .then(resp => {
        setIsLoading(false);
        setPlans(resp);
      })
      .catch(err => {
        setIsLoading(false);
        setError(err);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  }, []);

  const onPickTier = paramsItem => {
    setSelectedPlan(paramsItem);
  };

  const onUpgradePackage = async paramsValue => {
    if (isLoadingSubmit) {
      return;
    }

    if (paramsValue.name.toLowerCase() === "gratis") {
      toggle();
      return;
    }

    const payload = {
      plan_name: paramsValue.name,
      is_yearly: paramsValue.is_yearly,
    };

    setIsLoadingSubmit(true);
    await post(`/payment/xendit/invoice`, payload)
      .then(async resp => {
        window.open(resp, "_blank");
        setTriggerFetch(true);
        toggle();
        setIsLoadingSubmit(false);
        history.push("/billing-invoice?onboarding=false");
      })
      .catch(err => {
        setError(err);
        setTimeout(() => {
          setError("");
          setIsLoadingSubmit(false);
        }, 2000);
      });
  };

  const renderPlanService = () => {
    if (isLoading) return null;

    const filteredPlans = plans.filter(plan => {
      if (selectedMenu === "free") {
        return plan.name.toLowerCase() === "gratis";
      } else if (selectedMenu === "monthly") {
        return plan.name.toLowerCase() !== "gratis" && !plan.is_yearly;
      } else if (selectedMenu === "annually") {
        return plan.name.toLowerCase() !== "gratis" && plan.is_yearly;
      }
      return true;
    });

    filteredPlans.sort((a, b) => a.price - b.price);

    return filteredPlans.map(plan => {
      if (
        plan.id === currentPlanId ||
        plan.name.toLowerCase() == "enterprise"
      ) {
        return null;
      }

      return (
        <div className="tiers" key={plan.id} onClick={() => onPickTier(plan)}>
          <div className="checkbox">
            {selectedPlan?.id === plan.id && (
              <div
                style={{
                  background: "#24b39b",
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                }}
              />
            )}
          </div>
          <div className="tiers-content">
            <div className="tiers-content-onboarding">
              <span className="title">
                {plan.name.toLowerCase() === "gratis" ? "FREE PLAN" : plan.name}
              </span>
              {plan.name.toLowerCase() !== "gratis" && plan.is_yearly && (
                <div className="section-package-info">
                  <i className="fa fa-tag" aria-hidden="true"></i>
                  <span className="text-primary font-normal xl:text-base lg:text-sm">
                    Gratis 2 Bulan
                  </span>
                </div>
              )}
            </div>
            <div className="content">
              <div className="content-description">
                <span className="title-content">
                  {plan.description
                    ? plan.description
                    : "Untuk bisnis baru atau yang sedang berkembang"}
                </span>

                <span className="feature-content">
                  <i className="fa fa-check-circle" aria-hidden="true" />
                  Kuota pesan: {plan.max_number_message} / bulan
                </span>
                <span className="feature-content">
                  <i className="fa fa-check-circle" aria-hidden="true" />
                  Kuota produk: {plan.max_number_product}
                </span>
              </div>
              {plan.price !== 0 ? (
                <div className="content-price">
                  <div className="price-discount">
                    <span>{convertInt2Money(plan.price)}</span>
                    <div className="line" />
                  </div>
                  <span className="price">{convertInt2Money(plan.price)}</span>
                  <span className="price-suffix">
                    {plan.is_yearly ? "per tahun" : "per bulan"}
                  </span>
                </div>
              ) : (
                <div className="content-price">
                  <span className="price-free">FREE</span>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="section-content-modal-billing">
      <div className="mobile-header">
        <div className="content">
          <div className="icon-close">
            <XMarkIcon onClick={toggle} />
          </div>
          <div className="title">Pilih Paket</div>
          <div />
        </div>
      </div>
      <span className="title">Pilih paket berlangganan</span>
      <span className="sub-title">
        Silakan pilih periode tagihan dan paket berlangganan
      </span>
      <div className="section-package-menu">
        {menuPackage.map((items, idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                setSelectedMenu(items.value);
              }}
              className={
                items.value === selectedMenu ? "button-active" : "button"
              }
            >
              {items.label}
            </div>
          );
        })}
      </div>
      <div className="section-package-tiers">
        {isLoading && (
          <div className="loading">
            <Spinner color="primary" />
          </div>
        )}
        {renderPlanService()}
      </div>
      {error && (
        <Alert color="danger" style={{ marginTop: "10px" }}>
          {error.detail}
        </Alert>
      )}
      <div className="section-button">
        <div
          onClick={() => {
            selectedMenu === "free" ? toggle() : onUpgradePackage(selectedPlan);
          }}
          className="button"
          style={
            selectedPlan === null
              ? { backgroundColor: "#CCC", color: "#777777" }
              : { backgroundColor: "#24b39b", color: "#fff" }
          }
        >
          {isLoadingSubmit ? (
            <Spinner color="light" style={{ margin: "2px 10px" }} />
          ) : (
            "Pilih paket"
          )}
        </div>
      </div>
      <p className="section-note">
        Untuk paket berlangganan Enterprise atau perbandingan paket berlangganan
        RISA AI selengkapnya bisa dilihat di{" "}
        <a
          href="https://landing-v2.landing-page-ahy.pages.dev/price"
          target="_blank"
        >
          halaman harga
        </a>
      </p>
    </div>
  );
};

export default ChoosePackageNewMember;
