import * as actionTypes from "./actionTypes"

const INIT_STATE = {
  agentOperationHour: [],
  isFetching: false,
  isUpdate: false,
  successMessage: null,
  error: null,
}

const AgentOperationHour = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_AGENT_OPERATION_HOUR_REQUEST:
    return {
      ...state,
      isFetching: true,
      error: null,
    }
    case actionTypes.UPDATE_AGENT_OPERATION_HOUR_REQUEST:
    case actionTypes.INSERT_AGENT_OPERATION_HOUR_REQUEST:
      return {
        ...state,
        isUpdate: true,
        successMessage: null,
        error: null,
      }
    case actionTypes.GET_AGENT_OPERATION_HOUR_SUCCESS:
      return {
        ...state,
        agentOperationHour: action.payload || {},
        isFetching: false,
      }
    case actionTypes.INSERT_AGENT_OPERATION_HOUR_SUCCESS:
      return {
        ...state,
        agentOperationHour: action.payload,
        isFetching: false,
        successMessage: "Berhasil memperbarui operation hour",
      }
    case actionTypes.UPDATE_AGENT_OPERATION_HOUR_SUCCESS:
      return {
        ...state,
        agentOperationHour: action.payload,
        isUpdate: false,
        isFetching: false,
        successMessage: "Berhasil memperbarui operation hour",
      }
    case actionTypes.INSERT_AGENT_OPERATION_HOUR_FAILED:
    case actionTypes.UPDATE_AGENT_OPERATION_HOUR_FAILED:
      return {
        ...state,
        isUpdate: false,
        isSuccess: false,
        error: action.payload,
      }
    case actionTypes.GET_AGENT_OPERATION_HOUR_FAILED:
      return {
        ...state,
        error: action.payload,
        isRequested: false,
      }
    default:
      return state
  }
}

export default AgentOperationHour
