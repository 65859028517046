import { uploadFile } from "network/http/api";
import React, { useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { connect } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { notificationErrorRequestAction, notificationSuccessRequestAction } from "store/notification/actions";


const UploadImage = ({ text, onChangeImage, notificationSuccessRequestAction, notificationErrorRequestAction }) => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const onChange = (
        imageList,
        addUpdateIndex,
    ) => {

        // data for submit
        setImages(imageList);
        const headers = { 'Content-Type': imageList[0].file.type }

        setIsLoading(true)
        uploadFile('/file/image', imageList[0].file, headers)
            .then(resp => {
                setIsLoading(false)
                onChangeImage(resp.data)
                notificationSuccessRequestAction("Upload image berhasil")

            })
            .catch(err => {
                notificationErrorRequestAction("Upload image gagal")
                setIsLoading(false)
                // onImageRemove(addUpdateIndex)
                console.error(err)
            })
    };

    return <>
        <ImageUploading
            value={images}
            onChange={onChange}
            allowNonImageType={false}
        >
            {({
                onImageUpload,
                isDragging,
                dragProps,
            }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    <Button
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps} color='primary' outline>
                        {!isLoading && text}
                        {isLoading && <Spinner color="primary" type="grow" />}
                    </Button>
                </div>
            )}
        </ImageUploading >
    </>
}

export default connect(null, {
    notificationSuccessRequestAction,
    notificationErrorRequestAction
})(UploadImage)