import ShopOverview from "components/Shop/ShopOverview";
import React, { Component } from "react";

class Shop extends Component{
  render(){
    return <ShopOverview />
  }
}

export default Shop
