import React from "react";
import { Button } from "reactstrap";
import { EmptyImage } from "../IconPage";
import { useHistory } from "react-router-dom";

const EmptySummaryDashboard = ({
  type,
  title,
  subtitle,
  btn,
  actionButton,
}) => {
  const history = useHistory();

  const goToPage = params => {
    history.push(params);
  };

  if (type || type === "service-knowledge") {
    return (
      <div className="service-knowledge-empty">
        <div className="service-knowledge-empty-content">
          <EmptyImage />
          <div className="mt-2 service-knowledge-empty-main-content">
            <span className="title">{title}</span>
            <span className="subtitle mt-2">{subtitle}</span>
            {btn && (
              <Button
                color="primary"
                className="btn-empty"
                onClick={() => goToPage(btn.url)}
              >
                {btn.icon && btn.icon}
                {btn.title}
              </Button>
            )}
            {actionButton && actionButton}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="register-store-summary">
      <div className="register-store-summary-content">
        <EmptyImage />
        <div className="mt-2 register-store-summary-main-content">
          <span className="title">{title}</span>
          <span className="subtitle">{subtitle}</span>
          {btn && (
            <Button
              color="primary"
              className="btn-empty"
              onClick={btn.onClick ? btn.onClick : () => goToPage(btn.url)}
            >
              {btn.icon && btn.icon}
              {btn.title}
            </Button>
          )}
          {actionButton && actionButton}
        </div>
      </div>
    </div>
  );
};

export default EmptySummaryDashboard;
