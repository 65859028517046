import React, { useEffect, useState } from "react";
import {
  MagnifyingGlassIcon,
  XCircleIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/20/solid";
import DropdownCheckbox from "components/DropdownCheckbox";
import { connect } from "react-redux";
import { notificationErrorRequestAction } from "store/notification/actions";
import { withRouter } from "react-router-dom";
import { getShopCategoryByCompanyID } from "store/actions";
import ListStoreProduct from "./ListStoreProduct";
import { get } from "network/http/api";
import { Input } from "reactstrap";
import { debounce } from "lodash";
import FilterBottom from "../ComponentsPage/FilterBottom";

const SycnProductDashboard = ({
  user,
  ShopDashboardCategory,
  onGetCategoryProductsByCompanyID,
  notificationError,
}) => {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listProductStore, setListProductStore] = useState(null);
  const [categoryProductStore, setCategoryProductStore] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openFilterModalBottom, setOpenFilterModalBottom] = useState(false);

  useEffect(() => {
    fetchStoreListProduct();
    const { company_id } = user;
    if (company_id) onGetCategoryProductsByCompanyID(company_id);
  }, [user]);

  useEffect(() => {
    if (selectedItems.length < 1) {
      fetchStoreListProduct();
      return;
    }

    handleOnFetchProductCat();
  }, [selectedItems]);

  useEffect(() => {
    setCategoryProductStore(
      ShopDashboardCategory.shopDashboardproductCategories
    );
  }, [ShopDashboardCategory]);

  useEffect(() => {
    if (query.length < 3) {
      if (listProductStore?.length < 1) {
        fetchStoreListProduct();
        return;
      }
    }

    const fetch = debounce(searchProduct, 500);
    fetch(query);

    return () => fetch.cancel();
  }, [query]);

  const fetchStoreListProduct = async () => {
    try {
      if (!user || !user.id) {
        return;
      }

      setIsLoading(true);
      const res = await get(`/product/company/${user?.company_id}`);
      setListProductStore(res.products);
      setIsLoading(false);
    } catch (err) {
      console.error(err, "list");
      notificationError("Terjadi kesalahan, mohon coba kembali");
      setIsLoading(false);
    }
  };

  const searchProduct = text => {
    setIsLoading(true);

    get("/product/search?keyword=" + text)
      .then(resp => {
        const { products } = resp;
        setListProductStore(products);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        notificationError("Terjadi kesalahan, mohon coba kembali");
      });
  };

  const onUncheckSelected = params => {
    setSelectedItems(
      selectedItems.filter(selectedItem => selectedItem !== params)
    );
  };

  const onUncheckAllSelected = () => {
    setSelectedItems([]);
  };

  const handleOnSeletectedCategory = cat => {
    setSelectedItems(cat);
  };

  const handleOnFetchProductCat = () => {
    if (selectedItems.length < 1) return;

    let catId = [];
    selectedItems.map(v => catId.push(v.id));
    get(`/product/cat_id?category_id=${catId.join(",")}`)
      .then(resp => {
        const { products, count, total_page } = resp;
        setListProductStore(products);
      })
      .catch(err => {
        console.error(err);
        notificationError("Terjadi kesalahan, mohon coba kembali");
      });
  };

  return (
    <div className="integrated-store-summary">
      <div className="store-summary-information">
        <h4 className="title">Daftar Produk</h4>
        <span className="subtitle">
          Daftar semua produk Anda yang sudah diintegrasikan dengan sistem RISA
        </span>
        <div className="store-summary-product-content">
          <div className="header-product-store-section">
            <div className="header-product-store">
              <div className="input-search-product-store">
                <div className="input-icon">
                  <MagnifyingGlassIcon className="icon" aria-hidden="true" />
                </div>
                <Input
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  type="text"
                  name="query"
                  id="query"
                  className="input-search"
                  placeholder="Cari produk di sini"
                />
              </div>
              <DropdownCheckbox
                list={categoryProductStore}
                selectedItems={selectedItems}
                setSelectedItems={handleOnSeletectedCategory}
              />
              <div
                className="dropdown-checkbox-mobile"
                onClick={() => setOpenFilterModalBottom(!openFilterModalBottom)}
              >
                <div className="btn-filter">
                  <AdjustmentsHorizontalIcon />
                </div>
              </div>
            </div>
            {Boolean(selectedItems.length) && (
              <div className="header-product-store-filter">
                <span
                  className="reset-filter"
                  onClick={() => onUncheckAllSelected()}
                >
                  Reset Semua Filter
                </span>
                <span className="spacer">|</span>
                <div className="filter-active">
                  {selectedItems.map((item, idx) => {
                    return (
                      <div key={idx} className="btn-filter">
                        {item.name}
                        <XCircleIcon
                          className="icon"
                          onClick={() => onUncheckSelected(item)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <ListStoreProduct isLoading={isLoading} products={listProductStore} />
          {openFilterModalBottom && (
            <div
              className="modal-filter-bottom"
              onClick={() => setOpenFilterModalBottom(!openFilterModalBottom)}
            >
              <div className="filter-bottom" onClick={e => e.stopPropagation()}>
                <FilterBottom
                  list={categoryProductStore}
                  selectedItems={selectedItems}
                  setSelectedItems={handleOnSeletectedCategory}
                  onClose={() =>
                    setOpenFilterModalBottom(!openFilterModalBottom)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ShopDashboardCategory: state.ShopDashboard,
    user: state.Profile.user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onGetCategoryProductsByCompanyID: getShopCategoryByCompanyID,
    notificationError: notificationErrorRequestAction,
  })(SycnProductDashboard)
);
