import React from "react";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";
import formatCurrency from "helpers/currency";
import "moment/locale/id";
import RetryInvoiceButton from "pages/Invoice/ComponentsPage/RetryInvoiceButton";

moment.locale("id");

const TableReview = ({ data }) => {
  const statusStyles = {
    paid: {
      backgroundColor: "#EBF2EF",
      color: "#649B84",
    },
    expired: {
      backgroundColor: "#FCEBEB",
      color: "#E11A1A",
    },
    refund: {
      backgroundColor: "#D7DFF0",
      color: "#3660B3",
    },
    unpaid: {
      backgroundColor: "#F5F5F5",
      color: "#79747E",
    },
  };

  const handleStatusName = paramsStatus => {
    switch (paramsStatus) {
      case "paid":
        return "Sukses";
      case "expired":
        return "Gagal";
      case "refund":
        return "Refund";
      case "unpaid":
        return "Menunggu";
      default:
        return "Unknown";
    }
  };

  const RenderTable = ({ dataTabel }) => {
    if (dataTabel?.length === 0) {
      return (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th>Asal Chat</th>
                <th>Tanggal</th>
                <th>Admin</th>
                <th>Platform</th>
                <th>Jumlah Rating</th>
                <th>Feedback dari Customer</th>
              </tr>
            </thead>
          </Table>
          <div className="tabel-no-data">Belum ada invoice untuk Anda.</div>
        </>
      );
    }

    const renderActionTextButton = (
      lastInvoiceStatus,
      invoiceStatus,
      isLastInvoice,
      item
    ) => {
      if (invoiceStatus !== "expired" || !isLastInvoice) {
        return (
          <span
            style={{ color: "#24B39B", cursor: "pointer" }}
            onClick={() => {}}
          >
            Detail
          </span>
        );
      }

      if (lastInvoiceStatus === "expired") {
        return <RetryInvoiceButton />;
      }
    };

    console.log(data);

    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Asal Chat</th>
            <th>Tanggal</th>
            <th>Admin</th>
            <th>Platform</th>
            <th>Jumlah Rating</th>
            <th>Feedback dari Customer</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, idx) => {
            return (
              <tr key={idx}>
                <td style={{ fontWeight: "600", color: "#000" }}>
                  {item.username}
                </td>
                <td>
                  {item.date ? moment(item.date).format("DD MMMM YYYY") : "-"}
                </td>
                <td>{item.admin_name ? item.admin_name : "-"}</td>

                <td>{item.platform_name}</td>
                <td>{item.rating}</td>
                <td>{item.comment ? item.comment : "Tidak ada Feedback"}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Row>
      <Col xs={12} lg={12}>
        <div className="section-tabel">
          <RenderTable dataTabel={data} />
        </div>
      </Col>
    </Row>
  );
};

export default TableReview;
