import {
  GET_TICKET_COMMENTS_FAIL,
  GET_TICKET_COMMENTS_SUCCESS,
  ADD_TICKET_COMMENT_SUCCESS,
  ADD_TICKET_COMMENT_FAIL,
  UPDATE_TICKET_COMMENT_SUCCESS,
  UPDATE_TICKET_COMMENT_FAIL,
  DELETE_TICKET_COMMENT_SUCCESS,
  DELETE_TICKET_COMMENT_FAIL,
  GET_TICKET_COMMENTS,
  ADD_NEW_TICKET_COMMENT,
  UPDATE_TICKET_COMMENT,
  DELETE_TICKET_COMMENT,
} from "./actionTypes"

const INIT_STATE = {
  comments: [],
  isFetching: false,
  isAdded: false,
  isUpdating: false,
  isDeleting: false,
  error: {},
}

const comments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TICKET_COMMENTS:
      return {
        ...state,
        isFetching: true,
      }
    case GET_TICKET_COMMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        comments: action.payload,
      }
    case GET_TICKET_COMMENTS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }

    case ADD_NEW_TICKET_COMMENT:
      return {
        ...state,
        isAdded: true,
        error: {},
      }
    case ADD_TICKET_COMMENT_SUCCESS:
      return {
        ...state,
        isAdded: false,
        comments: [action.payload, ...state.comments],
      }

    case ADD_TICKET_COMMENT_FAIL:
      return {
        ...state,
        isAdded: false,
        error: action.payload,
      }

    case UPDATE_TICKET_COMMENT:
      return {
        ...state,
        isUpdating: false,
        error: {},
      }
    case UPDATE_TICKET_COMMENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        comments: state.comments.map(comment =>
          comment.id.toString() === action.payload.id.toString()
            ? { comment, ...action.payload }
            : comment
        ),
      }

    case UPDATE_TICKET_COMMENT_FAIL:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      }

    case DELETE_TICKET_COMMENT:
      return {
        ...state,
        isDeleting: true,
        error: {},
      }

    case DELETE_TICKET_COMMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        comments: state.comments.filter(
          comment => comment.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_TICKET_COMMENT_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default comments
