import React, { useEffect, useState } from "react";
import { convertInt2Money } from "components/Product/ProductCardItem";
import { Spinner } from "reactstrap";
import { get } from "network/http/api";

const CurrentPlanCardContent = ({ currentPlanId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    get(`/auth/plan-service/${currentPlanId}`)
      .then(resp => {
        setCurrentPlan(resp);
        setIsLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading || !currentPlan) {
    return (
      <div className={`tiers`}>
        {error && <span className="error-text">{error.detail}</span>}
        <div className="loading">
          <Spinner color="primary" />
        </div>
      </div>
    );
  }

  return (
    <div className={`tiers`}>
      <div className="badge-title">Paket saat ini</div>

      <div className="tiers-content">
        <span className="title">{currentPlan.name}</span>
        <div className="content">
          <div className="content-description">
            <span className="feature-content">
              <i className="fa fa-check-circle" aria-hidden="true" />
              Kuota pesan: {currentPlan.max_number_message} /
              {currentPlan.is_yearly ? "tahun" : "bulan"}
            </span>
            <span className="feature-content">
              <i className="fa fa-check-circle" aria-hidden="true" />
              Kuota produk: {currentPlan.max_number_product}
            </span>
          </div>
          <div className="content-price">
            {currentPlan.price !== 0 ? (
              <div className="price-discount">
                <span>
                  {" "}
                  {convertInt2Money(currentPlan.price_before_discount)}
                </span>
                <div className="line" />
              </div>
            ) : (
              <div className="price-discount">
                <span>...</span>
              </div>
            )}

            <span className="price">
              {currentPlan.price !== 0
                ? convertInt2Money(currentPlan.price)
                : "Rp.0"}
            </span>

            <span className="price-suffix">
              {currentPlan.is_yearly ? "per tahun" : "per bulan"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlanCardContent;
