import FbImage from "assets/images/agents/fb.png";
import IgImage from "assets/images/agents/ig.png";
import TokpedImage from "assets/images/agents/tokopedia.png";
import WaImage from "assets/images/agents/wa.png";
import WebImage from "assets/images/agents/web.png";
import ShopeeImage from "assets/images/agents/shopee.png";

const MenuAgentProfile = [
  {
    name: "Website",
    title: "Website",
    platform: "web",
    url: "/ai-builder/test/live-chat",
    icon: <img src={WebImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "WhatsApp",
    title: "WhatsApp",
    platform: "whatsapp",
    url: "/ai-builder/whatsapp",
    icon: <img src={WaImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Shopee",
    title: "Shopee",
    platform: "shopee",
    url: "/ai-builder/shopee",
    icon: <img src={ShopeeImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Instagram",
    title: "Instagram",
    platform: "instagram",
    url: "/ai-builder/instagram",
    icon: <img src={IgImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Tokopedia",
    title: "Tokopedia",
    platform: "tokopedia",
    url: "/ai-builder/tokopedia",
    icon: <img src={TokpedImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
  {
    name: "Facebook",
    title: "Facebook",
    platform: "facebook",
    url: "/ai-builder/facebook",
    icon: <img src={FbImage} alt="" />,
    date: "terakhir diubah pada 17 September 2023",
  },
];

export { MenuAgentProfile };
