import React, {useState} from "react";
import { connect } from "react-redux";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ActivationLink = ({user}) => {
  const [modal, setModal] = useState(user.is_email_verified==false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Email Verification</ModalHeader>
          <ModalBody>
          We sent you an activation link. Check your email and click on the link to verify.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
    </div>
  )
}

const mapStateToProps = ({Profile}) => {
  return {
    'user': Profile.user,
  }
}

export default connect(mapStateToProps, null)(ActivationLink);
