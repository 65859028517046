import React, { memo, useEffect, useState } from 'react';
import { Handle, useReactFlow, useEdges, useStore, Position } from 'reactflow';
import TextWrapper from './TextWrapper';
import NodeWrapper from './NodeWrapper';

// icon
import iconRobot from 'assets/images/robot-icon.svg'
import SourceHandler from './SourceHandler';
import classNames from 'classnames';
import { Tooltip } from 'reactstrap';

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const isVariableLoopActive = (variables) => {
  let isActive = false
  variables.map(v => {
    if (v.variable_name === 'loop' && v.answer == 'true') {
      isActive = true
    }
  })
  return isActive
}

const HeaderLoopActive = ({ id }) => {
  const [openTooltip, setOpenTooltip] = useState(false)
  let targetId = `badge-${id}`
  return (
    <div className='loop-active'>
      <span>Processor</span>
      <span className='badge' id={targetId} >Loop Active</span>
      <Tooltip
        placement='bottom'
        autohide={true}
        isOpen={openTooltip}
        target={targetId}
        toggle={() => setOpenTooltip(!openTooltip)}>
        Loop Active, menandakan bahwa prosesor akan terus menjawab pertanyaan customer dengan pengetahuan layanan dan produk dari AI.
      </Tooltip>
    </div>
  )
}

function ProcessorNode({ id, data, selected, focusable, dragging }) {
  const edges = useEdges();
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const [isLoopActive, setIsLoopActive] = useState(false)

  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;

  useEffect(() => {
    setIsLoopActive(isVariableLoopActive(data.variables))
  }, [selected])

  return (
    <NodeWrapper
      title={isLoopActive ? <HeaderLoopActive id={id} /> : <span>Processor</span>}
      titleIcon={iconRobot}
      id={id}
      data={data}
      selected={selected}
      isConnecting={isConnecting}
      isTarget={isTarget}>

      <div className="custom-node__body">
        {data.variables.map((variable, key) => {
          if (variable.variable_name === 'loop') {
            return
          }

          return (
            <div key={key} className={
              classNames('text-wrapper',
                variable.answer == 'true' ? '' : 'closed')}>
              <div className={'text-input ' + variable.id}>
                <TextWrapper text={variable.question} isHtml={true} />

                {!isLoopActive &&
                  <SourceHandler
                    parentId={id}
                    data={data}
                    disabled={variable.answer != 'true'}
                    id={id}
                    sourceHandle={variable.variable_name} />
                }

              </div>
            </div>
          )
        })}
      </div>
    </NodeWrapper >

  );
}

export default memo(ProcessorNode);
