const operationDay = [
  { id: 1, name: "Senin", value: "monday" },
  { id: 2, name: "Selasa", value: "tuesday" },
  { id: 3, name: "Rabu", value: "wednesday" },
  { id: 4, name: "Kamis", value: "thursday" },
  { id: 5, name: "Jumat", value: "friday" },
  { id: 6, name: "Sabtu", value: "saturday" },
  { id: 7, name: "Minggu", value: "sunday" },
];

const operationHour = [
  { id: 1, name: "Pagi", value: "morning", desc: "(00:00 - 09:00)" },
  { id: 2, name: "Siang", value: "afternoon", desc: "(09:00 - 15:00)" },
  { id: 3, name: "Sore", value: "evening", desc: "(15:00 - 19:00)" },
  { id: 4, name: "Malam", value: "night", desc: "(19:00 - 24:00)" },
];

export { operationDay, operationHour };
