import { convertInt2Money } from "components/Product/ProductCardItem";
import React from "react";

const CardContentConfirmation = ({ items: plan, update }) => {
  const isEnterprise = () => plan.name === 'ENTERPRISE'
  const renderPrice = () => {
    if (plan.price < 1) return '-'
    if (isEnterprise()) return <span>Harga Menyesuaikan</span>

    return convertInt2Money(plan.price)
  }

  const renderQuota = () => {
    if (!isEnterprise()) {
      return <span>{plan.max_number_message} /{plan.is_yearly ? "tahun" : "bulan"}</span>
    }

    return "Disesuaikan"
  }

  return (
    <div className={`tiers ${update ? "update" : ""}`}>
      {update ? (
        <div className="badge-title update">Paket baru</div>
      ) : (
        <div className="badge-title">Paket saat ini</div>
      )}

      <div className="tiers-content">
        <span className="title">{plan.name}</span>
        <div className="content">
          <div className="content-description">
            <span className="feature-content" >
              <i className="fa fa-check-circle" aria-hidden="true" />
              Kuota pesan: {renderQuota()}
            </span>
            <span className="feature-content" >
              <i className="fa fa-check-circle" aria-hidden="true" />
              Kuota produk: {isEnterprise() ? 'Disesuaikan' : plan.max_number_product}
            </span>
          </div>
          <div className="content-price">
            {plan.price ? (
              <div className="price-discount">
                {!isEnterprise() &&
                  <>
                    <span>{convertInt2Money(plan.price)}</span>
                    <div className="line" />
                  </>
                }
              </div>
            ) : (
              <div className="price-discount">
                <span>Gratis</span>
              </div>
            )}

            <span className="price">
              {renderPrice()}
            </span>
            <span className="price-suffix">{plan.is_yearly ? "per tahun" : "per bulan"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContentConfirmation;