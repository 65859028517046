import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { post, del } from "network/http/api";
import { Formik, Field, Form } from "formik";
// import * as Yup from "yup";

const ModalQuestion = ({
  editQuestionAnswer,
  isOpenQuestion,
  setIsOpenQuestion,
  handleEditQuestionAnswer,
  fetchKnowledgeQuestion,
  page,
}) => {
  const handleType = editQuestionAnswer.type === "edit" ? true : false;

  const actionQuestion = async paramsType => {
    if (paramsType === "edit") {
      const payload = {
        remark: "",
        knowledge_id: editQuestionAnswer.qna_id,
        question: editQuestionAnswer.question,
        answer: editQuestionAnswer.answer,
      };

      await post(
        `/knowledge-base/question-answer`,
        payload
      )
        .then(async resp => {
          console.log(payload, editQuestionAnswer, "payload");
          console.log(resp, "resp");
        })
        .catch(err => {
          console.log(err, "err");
        });
    } else {
      del(
        `/knowledge-base/question-answer/${editQuestionAnswer.qna_id}`
      )
        .then(async resp => {
          await new Promise(resolve => setTimeout(resolve, 2000));
          fetchKnowledgeQuestion(page);
          setIsOpenQuestion(false);
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenQuestion}
        toggle={() => setIsOpenQuestion(!isOpenQuestion)}
        centered
      >
        <ModalBody>
          <ModalHeader>
            {handleType ? "Edit" : "Delete"} Example Question
          </ModalHeader>
          <ModalBody>
            {handleType ? (
              <Formik
                className="p-5"
                enableReinitialize={true}
                initialValues={editQuestionAnswer}
                onSubmit={values => actionQuestion(values)}
              >
                {({ errors, touched }) => (
                  <Form className="form-knowledge">
                    <div className="form-input-knowlede">
                      <div className="field-knowledge mt-3 mb-3">
                        <Field
                          name="question"
                          placeholder="Berapa 1 + 1 ?"
                          type="text"
                          className="form-control"
                          onChange={handleEditQuestionAnswer}
                          value={editQuestionAnswer.question}
                        />
                      </div>
                      <div className="field-knowledge mb-3">
                        <Field
                          name="answer"
                          placeholder="Jawaban nya 2."
                          type="text"
                          className="form-control"
                          onChange={handleEditQuestionAnswer}
                          value={editQuestionAnswer.answer}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <span>
                Are you sure want to delete{" "}
                <i style={{ color: "#c35555" }}>
                  example question:{" "}
                  {editQuestionAnswer.question
                    ? editQuestionAnswer.question
                    : ""}{" "}
                  & answer:{" "}
                  {editQuestionAnswer.answer ? editQuestionAnswer.answer : ""}
                </i>{" "}
                ?
              </span>
            )}
          </ModalBody>
        </ModalBody>
        <ModalFooter>
          <Button
            color={handleType ? "primary" : "danger"}
            onClick={() => actionQuestion(editQuestionAnswer.type)}
          >
            {handleType ? "Edit" : "Delete"}
          </Button>
          <Button color="dark" onClick={() => setIsOpenQuestion(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ModalQuestion;
